<template>
  <div class="container mt-5 divContainerAdminGeneral">   
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral" v-if="user.role === 'admin'">Default Objectives</h2>
      <h2 class="titleAdminGeneral" v-else>Objectives</h2>
      <div class="d-flex justify-content-between">
        <div class="input-group col-md-4">
          <input type="text" id="objectiveName" class="form-control inputsForm" placeholder="Search by objective name" v-model="objectiveName" @keyup="searchObjective(objectiveName)">
          <button class="btn btn-outline-secondary input-group-text" @click="clearFilter()" type="button" id="button-addon2">CLEAR</button>
        </div>
        <button type="button" class="btn btn_admin_general" v-on:click="openModalDefaultObjectives()">Add New Objective</button>
      </div>
    </div>
    <ObjectivesModal ref="saveModalDefaultObjective" :user="user"/>
    <confirmationModal ref="confirmModal" @confirm="deleteObjectiveApi" />
    <hr class="w-100" />

    <div class="row w-100 divContainerAdminGeneral mt-3 d-none d-md-block">      
      <div class="d-flex w-100">
        <div class="col-5 titleAdminTableGeneral pl-0">Name</div>
        <div class="col-5 titleAdminTableGeneral pl-0">Supporting Material</div>
      </div>
      <div v-for="(objective, index) in objectives" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center">{{objective.name}}</div>
        <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center" style="overflow-wrap: anywhere;">{{objective.supportingMaterial}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-edit p-2 icon_tool iconEdit" v-on:click="openModalDefaultObjectives(objective)"></i></div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(objective)"></i></div>
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !objectives.length">
        <h1 class="m-5">No Default Objectives to display.</h1>
      </div>
      <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
        <div class="loader-whitebg"></div>
      </div>
    </div>

    <div class="row w-100 card-deck d-md-none m-0 justify-content-start divCardToolList">
      
      <div class="col-sm-6 col-8 p-0 mt-5" v-for="(objective, index) in objectives" :key="index">
        <div class="card">
          <div class="d-flex container">
            <h5 class="card-title cardTitleTool col-12 text-center pl-0">{{objective.name}}</h5>
          </div>
          <div class="row card-body pt-0 text-center">
            <span class="col-12 cardSubtitle text-muted">supportingMaterial</span>
            <span class="col-12 cardContent">{{objective.supportingMaterial}}</span>
          </div>
          <div class="d-flex justify-content-around">
            <i class="fas fa-edit p-3 icon_tool iconEdit" v-on:click="openModalDefaultObjectives(objective)"></i>
            <i class="fas fa-trash p-3 icon_tool iconDelete" v-on:click="openConfirmationModal(objective)"></i>
          </div>
        </div>
      </div>    
      <div class="badges-list-border col-12 row-collapse-color2 text-center bg-light" v-if="!loading && !objectives.length">
        <h1 class="my-5">No Default Objectives to display.</h1>
      </div>

    </div>

  </div>
</template>
<script>
import ObjectivesModal from "./modalObjectives"
import confirmationModal from './confirmationDeleteModal.vue';
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name:"defaultObjectivesList",
  data(){
    return{
      objectiveName: "",
      errorMessage:"",
      loading:false,
      objectivesOriginal: [],
    }
  },
  components:{
    ObjectivesModal,
    confirmationModal
  },
  computed:{
    ...mapState('user',['user']),
    ...mapState('objectives',['objectives'])
  },
  methods:{
    ...mapActions('user', ['saveUser']),
    ...mapActions('objectives', ['deleteDefaultObjective', 'deleteObjective']),
    ...mapMutations('objectives',['filterObjective']),

    openModalDefaultObjectives(objective = {}){
      this.$refs.saveModalDefaultObjective.name = objective.name || ""
			this.$refs.saveModalDefaultObjective.supportingMaterial = objective.supportingMaterial || ""
			this.$refs.saveModalDefaultObjective.objectiveId = objective._id || ""
      $('#ObjectivesEditModal').modal('show')
    },
    openConfirmationModal(objective = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? "+objective.name || ""
      this.$refs.confirmModal.assetId = objective._id || ""
      $('#confirmationModal').modal('show')
    },
    deleteObjectiveApi(id, that){
      this.loading = true
      if(this.user.role === "admin"){
        this.deleteDefaultObjective({id, that: this})
        this.loading = false
      }else if(this.user.role === "teacher"){
        this.deleteObjective({id, that: this})
        this.loading = false
      }
    },
    searchObjective(){
      this.filterObjective({objectiveName:this.objectiveName, objectivesOriginal: this.objectivesOriginal})
    },
    clearFilter(){
      this.objectiveName = ''
      this.objectivesOriginal = this.objectives
    },
  },
  created(){
    this.objectivesOriginal = this.objectives
  }
}
</script>