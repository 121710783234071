<template>
  <div
    class="project-container d-flex flex-column align-items-center col-12 gap-5 mt-5"
  >
    <div class="row w-100 mt-5">
      <ProjectTimeline
        @step-changed="onStepChanged"
        :current-step="currentStep"
        :steps="stepsFiltered"
      />
    </div>
    <div
      :class="
        project.supportingMaterial
          ? 'project-title-supporting-material'
          : 'project-title'
      "
    >
      <div class="d-flex flex-column align-items-center">
        <h1 class="f-bold text-blue">{{ project.name }}</h1>
        <p class="f-regular text-blue" id="description">
          {{ project.description }}
        </p>
      </div>
      <ActionButton
        v-if="project.supportingMaterial"
        :color="`blue`"
        class="px-3 py-4"
        @click="openSupportinMaterial(project.supportingMaterial)"
        :text="`Supporting Material`"
      >
      </ActionButton>
    </div>
    <div class="d-flex justify-content-center align-items-center col-12">
      <BaseIcon
        role="button"
        width="10.475"
        height="16.469"
        class="col-1"
        @click="showPreviousStep"
        :iconColor="showLeftArrow() ? '#004aad' : '#b0b3b7'"
      >
        <ArrowLeft />
      </BaseIcon>
      <StepInfo
        :step="stepToShow"
        :approveButton="true"
        :studentStep="stepsFiltered[stepToShow.stepNumber - 1]"
        :imageURL="steps[stepToShow.stepNumber - 1]?.studentImagesURL"
        :length="project?.steps.length"
        class="col-10"
        @completed-step="completedStep"
        @open-multiple-choice-solved-modal="openMultipleChoiceSolvedModal"
        @open-media-modal="handleOpenMediaModal"
        @open-modal-image="showImage"
        :isMobileDevice="isMobileDevice"
      />
      <BaseIcon
        role="button"
        width="10.475"
        height="16.469"
        class="col-1"
        @click="showNextStep"
        :iconColor="showRightArrow() ? '#004aad' : '#b0b3b7'"
      >
        <ArrowRight />
      </BaseIcon>
    </div>

    <MediaModal
      v-if="toggleModal"
      @fileUpload="handleFileUpload"
      :is-mobile-device="dataModalMedia && dataModalMedia.isMobileDevice"
      :index="dataModalMedia && dataModalMedia.index"
      :step="dataModalMedia && dataModalMedia.step"
      :modal-id="dataModalMedia && dataModalMedia.modalId"
      @change-toggle="toggleModal = false"
    />

    <mcModal
      ref="multipleChoiceModal"
      @getproject="getProject"
      :step="stepToShow"
      :user="this.user"
      :project="this.project"
      :userPick="this.steps[stepToShow.stepNumber - 1]"
      :stepNEW="stepNEW"
    />
    <mcSolvedModal
      ref="multipleChoiceSolvedModal"
      :step="this.stepToShow"
      :user="this.user"
      :project="this.project"
      :userPick="selectedStepStudentPick"
    />
    <imageModal :imageURL="selectedImgURL" text="You took this photo" />
  </div>
</template>

<script>
import API from "../services/api";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import mcModal from "../components/modalMultipleChoiceStudent.vue";
import mcSolvedModal from "../components/modalMultipleChoiceSolvedStudent.vue";
import imageModal from "../components/modalImage.vue";
import Button from "../components/button.vue";
import ActionButton from "../components/actionButton.vue";
import ProjectTimeline from "./createProject/components/ProjectTimeline.vue";
import ProjectSummary from "../components/Projects/projectSummary.vue";
import StepInfo from "../components/Projects/stepInfo.vue";
import BaseIcon from "../components/baseIcon.vue";
import ArrowRight from "../assets/icons/arrowRight.vue";
import ArrowLeft from "../assets/icons/arrowLeft.vue";
import StudentProjectPhotoModal from "../components/Modals/studentProjectPhoto.vue";
import MediaModal from "./createProject/components/MediaModal.vue";
import { wait } from "../utils";

const MAX_FILE_SIZE = 1024 * 2 * 1000; // 10MB

export default {
  name: "seeStudentProject",
  data() {
    return {
      toggleModal: false,
      isLoading: false,
      data: "",
      streamActive: false,
      selectedImgURL: "",
      stepIndex: 0,
      selectedStepIndex: 0,
      project: {
        steps: [
          {
            studentImagesURL: [],
          },
        ],
      },
      dataModalMedia: {
        isMobileDevice: null,
        index: null,
        step: null,
        modalId: null,
      },
      stepsFiltered: [],
      currentStep: {},
      currentView: {},
      stepToShow: {},
      steps: [],
      blockingIndex: 1000,
      progress: 0,
      student: null,
      selectedStep: {},
      selectedStepStudentPick: {},
      stepNEW: {
        message: "HI!",
      },
      isMobileDevice: null,
      dataModalMedia: {},
    };
  },
  components: {
    mcModal,
    mcSolvedModal,
    imageModal,
    Button,
    ActionButton,
    ProjectTimeline,
    ProjectSummary,
    StepInfo,
    BaseIcon,
    ArrowRight,
    ArrowLeft,
    StudentProjectPhotoModal,
    MediaModal,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    compVal: {
      get() {
        if (this.selected) {
          return this.valueTrue;
        } else {
          return this.valueFalse;
        }
      },
      set(val) {
        if (this.selected) {
          this.valueTrue = val;
        } else {
          this.valueFalse = val;
        }
      },
    },
  },
  methods: {
    ...mapActions("studentProjects", ["modifyStudentsImage"]),
    ...mapActions("project", ["setProjectProp", "resetProject"]),

    async handleOpenMediaModal(data) {
      this.toggleModal = true;
      this.dataModalMedia = data;
      await wait(0);
      $(`#mediaModal-index-${this.dataModalMedia?.index}`).modal("show");
    },
    findFirstNeedApprovalStepNumber(stepsList) {
      const step = stepsList.find(
        (step) => step.needApproval.length && step.status !== "Approved"
      );
      if (step) {
        return step.stepNumber;
      }
      // return a high number to not block the next steps
      else return 1000;
    },
    showImage(img) {
      this.selectedImgURL = img;
      $("#modal-image").modal("show");
    },
    openSupportinMaterial(link) {
      window.open(link, "_blank");
    },
    showLeftArrow() {
      if (this.stepToShow.stepNumber > 1) {
        return true;
      } else return false;
    },
    showRightArrow() {
      if (this.stepToShow.stepNumber < this.project?.steps?.length) {
        return true;
      } else return false;
    },
    async getSteps() {
      let stepsFiltered = this.project?.steps?.map((step, index) => {
        step.order = index + 1;
        step.nameTimeLine = `Step ${index + 1}`;
        let stepObj = this.steps?.find((s) => {
          return s.stepId === step._id;
        });

          console.log(index)
        // IF THE STEP IS COMPLETED
        if (stepObj?.status === "Completed") {
          step.isCompleted = true;
          return { ...step, status: "isCompleted" };
          // IF THE STEP IS APPROVED
        } else if (stepObj?.status === "Approved") {
          step.isCompleted = true;
          return { ...step, status: "Approved" };
          // IF THE STEP IS PENDING
        } else if (stepObj?.status === "Pending") {
          step.isCompleted = false;
          return { ...step, status: "Pending" };
        } else if (stepObj?.status === "Rejected") {
          step.isCompleted = false;
          this.stepToShow = step;
          this.selectedStepIndex = index;
          return { ...step, status: "Rejected" };
        } else if (!stepObj) {
          // IF IT IS THE FIRST
          if (index === 0) {
            this.currentStep = step;
            this.stepToShow = step;
            this.currentView = step;
            this.selectedStepIndex = index;
            return { ...step, status: "inProgress" };
            // IF THE PREVIOUS STEP IS COMPLETED
          } else {
            let previusStep = this.project?.steps[index - 1];
            let previusCompleted = this.steps?.find((s) => {
              return s.stepId === previusStep._id;
            });
            if (previusCompleted) {
              this.stepToShow = step;
              this.currentStep = step;
              this.currentView = step;
              this.selectedStepIndex = index;
              if (
                previusCompleted?.status === "Pending" ||
                previusCompleted?.status === "Rejected"
              ) {
                return { ...step, status: "isLocked" };
              } else {
                return { ...step, status: "inProgress" };
              }
            } else {
              return { ...step, status: "isLocked" };
            }
          }
        }
      });
      this.stepsFiltered = stepsFiltered;
      let stepNumber = 100 / stepsFiltered?.length;
      this.progress = 0;
      for (let i = 0; i < stepsFiltered?.length; i++) {
        if (
          stepsFiltered[i]?.isCompleted &&
          stepsFiltered[i]?.status !== "Pending"
        ) {
          this.progress = this.progress + stepNumber;
        }
      }
      //IF THE PROJECT IS COMPLETED
      if (Math.round(this.progress) == 100) {
        const step = stepsFiltered[stepsFiltered.length - 1];
        this.currentStep = step;
        this.stepToShow = step;
        this.currentView = step;
        this.selectedStepIndex = stepsFiltered.length - 1;
      }
      this.blockingIndex = this.findFirstNeedApprovalStepNumber(
        this.stepsFiltered
      );
    },
    findFirstNeedApprovalStepNumber(stepsList) {
      const step = stepsList.find(
        (step) => step.needApproval.length && step.status !== "Approved"
      );
      if (step) {
        return step.stepNumber;
      }
      // return a high number to not block the next steps
      else return 1000;
    },
    showPreviousStep() {
      if (this.stepToShow.stepNumber >= 2) {
        this.stepToShow = this.project.steps[this.stepToShow.stepNumber - 2];
        this.currentStep = this.stepToShow;
        this.currentView = this.stepToShow;
      }
    },
    showNextStep() {
      if (this.stepToShow.stepNumber < this.project.steps.length) {
        this.stepToShow = this.project.steps[this.stepToShow.stepNumber];
        this.currentStep = this.stepToShow;
        this.currentView = this.stepToShow;
      }
    },
    onStepChanged(step) {
      this.currentStep = step;
      this.stepToShow = step;
    },
    async completedStep(step) {
      let data;
      if (step.status === "Rejected") {
        data = {
          stepId: step._id,
          status: step.status,
        };
      } else {
        data = {
          stepId: step._id,
          needApproval: step.needApproval,
          studentPick: null,
        };
      }
      if (step.hasMultipleChoice) {
        this.stepNEW = step;
        this.openMultipleChoiceModal();
      } else {
        API.studentProjects
          .completedStep(this.user._id, this.project._id, data)
          .then((res) => {
            let projectId = this.$route.query.project;
            API.studentProjects
              .getProject(this.user._id, projectId)
              .then((response) => {
                this.project = response.data.project;
                this.steps = response.data.steps || [];
                this.getSteps();
              });
          })
          .catch((err) => console.log(err));
      }
    },
    openMultipleChoiceModal() {
      $("#multipleChoiceStudentModal").modal("show");
    },
    openMultipleChoiceSolvedModal(step) {
      this.selectedStep = step;
      this.selectedStepStudentPick = this.steps[step.stepNumber - 1];
      $("#multipleChoiceStudentSolvedModal").modal("show");
    },
    /* openModalImage(index) {
      if (this.steps[index]) {
        if (!this.isMobileDevice) {
          this.openModal(index);
        } else {
          this.mobileOptions(index);
        }
      } else return;
    }, */
    openModalImage() {
      $("#modal-image").modal("show");
    },
    async reloadData() {
      API.studentProjects
        .completedStep(this.user._id, this.project._id, data)
        .then((res) => {
          let projectId = this.$route.query.project;
          API.studentProjects
            .getProject(this.user._id, projectId)
            .then((response) => {
              this.project = response.data.project;
              this.steps = response.data.steps || [];
              this.getSteps(this.steps);
            });
        })
        .catch((err) => console.log(err));
    },
    async getProject() {
      if (this.$route.query.project) {
        let projectId = this.$route.query.project;
        return API.studentProjects
          .getProject(this.user._id, projectId)
          .then((res) => {
            this.project = res.data.project;
            this.steps = res.data.steps || [];
            this.getSteps(this.steps);
          })
          .catch((err) => console.log(err));
      }
    },
    useCamera(i) {
      this.stepsFiltered[i].useCamera = true;
      // This setTimeout gives a proper minimum time to allow the v-else-if to render the "step.useCamera === true" condition
      setTimeout(() => {
        this.startWebcamStream();
      }, 0);
    },
    useLibrary(i) {
      this.stepsFiltered[i].selectIMGsource = true;
      this.stepsFiltered[i].useCamera = false;
      $("#exampleModal").modal("hide");
      $("body").removeClass("modal-open");
      document.getElementById("upload_image").click();
    },
    mobileOptions(i) {
      if (!this.steps[i].studentImagesURL[0]) {
        this.useLibrary(i);
      } else {
        this.openModal(i);
      }
    },
    openModal(i) {
      if (this.steps[i]?.studentImagesURL[0]) {
        this.stepsFiltered[i].selectIMGsource = false;
      }
      $("#exampleModal").modal("show");
      this.stepIndex = i;
    },
    async restoreIMGOptions(i) {
      const projectId = this.$route.query.project;
      const stepId = this.steps[i].stepId;
      this.stepsFiltered[i].useCamera = false;
      try {
        const data = {
          studentImagesUrl: [],
        };
        await this.modifyStudentsImage({ projectId, stepId, data });
        await this.getProject();
      } catch (e) {
        console.log(e);
      }
      if (this.streamActive) {
        this.streamActive = false;
        this.stream.getTracks().forEach((track) => track.stop());
      }
    },
    closeModal(i) {
      if (this.streamActive) {
        this.stepsFiltered[i].selectIMGsource = false;
        this.stepsFiltered[i].useCamera = false;
        this.streamActive = false;
        this.stream.getTracks().forEach((track) => track.stop());
      }
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;

      this.isMobileDevice = regexp.test(userAgent);
    },
    async handleFileUpload(data) {
      console.log(this.stepIndex);
      const {
        e: {
          target: { files },
        },
      } = data;

      if (
        !this.stepsFiltered[this.stepToShow.stepNumber - 1].studentImagesURL
      ) {
        this.stepsFiltered[this.stepToShow.stepNumber - 1].studentImagesURL =
          [];
      }
      if (
        this.stepsFiltered[this.stepToShow.stepNumber - 1].studentImagesURL
          .length > 0
      ) {
        return (this.errorMessage = "Maximum 1 image per step");
      }
      console.log(files.length);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.file = file;
        console.log(this.file);
        this.errorMessage = "";
        if (!file) {
          return console.log("Fail");
        }
        await this.uploadFile(file, this.stepToShow.stepNumber - 1);
      }
    },
    async uploadFile(file, indexStep) {
      const projectId = this.$route.query.project;
      if (!this.steps[indexStep]) {
        await this.completedStep(this.stepsFiltered[indexStep]);
      }
      console.log(indexStep);
      const stepId = this.stepsFiltered[indexStep]._id;
      this.isLoading = true;
      const formData = new FormData();
      formData.append("image", file, file.name);

      try {
        const res = await API.projects.UploadImageSteps(formData);
        const file = res.data;
        const data = {
          studentImagesUrl: [file.linkUrl],
        };
        await this.modifyStudentsImage({ projectId, stepId, data });
        await this.getProject();
      } catch (err) {
        console.log("err", err);
      }
      this.isLoading = false;
    },
    startWebcamStream() {
      this.streamActive = true;
      let webcamStream = document.getElementById("webcam-stream");
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.stream = stream;
          webcamStream.srcObject = stream;
          webcamStream.play();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async takePhoto(index) {
      let webcamStream = document.getElementById("webcam-stream");
      let capturedImage = document.getElementById("captured-image");
      let canvas = document.createElement("canvas");
      if (!this.steps[index]) {
        await this.completedStep(this.stepsFiltered[index]);
      }
      canvas.width = webcamStream.videoWidth;
      canvas.height = webcamStream.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(webcamStream, 0, 0, canvas.width, canvas.height);
      let file = this.dataURItoBlob(canvas.toDataURL(), index);
      const stepNumber = this.currentStep.stepNumber;
      const date = new Date().toISOString();
      file.name = `step-${stepNumber}_date-${date}`;

      let dataURL = canvas.toDataURL();
      capturedImage.src = dataURL;
      this.streamActive = false;
      this.stream.getTracks().forEach((track) => track.stop());
      webcamStream.srcObject = null;
      try {
        await this.uploadFile(file, index);
      } catch (e) {
        console.error(e);
      }
    },
    dataURItoBlob(dataURI, index) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    async showToastAndSelectStep(step, i) {
      this.currentView = step;
      this.stepToShow = step;
      
    },
  },
  async mounted() {
    this.student = this.user;
    await this.getProject();
    this.checkDeviceType();
    this.steps = this.steps.map((step, index) => ({ ...step, order: index }));
    /* this.showToastAndSelectStep(this.currentStep, this.selectedStepIndex); */
    for (let key of Object.keys(this.project)) {
      this.setProjectProp({ prop: key, value: this.project[key] });
    }
  },
  beforeUnmount(){
    this.resetProject()
  }
};
</script>

<style scoped>
h1 {
  font-size: 40px;
}
video {
  width: 360px;
}

svg {
  padding: 0px;
}
.projectIMG {
  max-width: 550px;
}

.w-85 {
  width: 85%;
}

#description {
  font-size: 26px;
  margin: 0px;
}

@media (max-width: 650px) {
  video {
    width: 240px;
  }
  .projectIMG {
    max-width: 250px;
  }
}
.multiple-choice-solved-step {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #cbcbcb;
  text-align: center;
  z-index: 2;
}

.question-mark {
  font-size: 30px;
  color: white;
}

.project-actions {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 2px solid black;
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #004aad;
}

.upload-image {
  font-size: 35px;
  background-color: #004aad;
  color: white;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 10px;
  padding: 10px;
  transition: 0.5s;
}

.upload-image:hover {
  background-color: white;
  color: #004aad;
  transition: 0.5s;
}

.open-multiple-choice {
  font-size: 35px;
  background-color: #004aad;
  color: white;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 0px;
  padding: 10px;
  transition: 0.5s;
}

.border-radius-left {
  border-radius: 0 0 0 10px;
}

.open-multiple-choice:hover {
  background-color: white;
  color: #004aad;
  transition: 0.5s;
}

.take-picture-option {
  display: inline-block;
  font-weight: 400 !important;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0.5px solid black;
  border-radius: 4px !important;
  background: var(--secondary);
  color: white;
}

.take-picture-option:hover {
  background: var(--secondary2) !important;
}

.cancel {
  display: inline-block;
  font-weight: 400 !important;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0.5px solid gray;
  border-radius: 4px !important;
  background: white;
  color: gray;
}

.studentIMG {
  max-width: 450px;
}

.upload-image-blocked {
  font-size: 35px;
  background-color: #004aad;
  color: grey;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 10px;
  padding: 10px;
  transition: 0.5s;
  cursor: not-allowed;
}


.toast-header {
  background-color: #004aad;
  color: #fff;
}

.toast {
  background-color: #fffffffa !important;
  font-size: 1.5rem !important;
}

.toast-container {
  z-index: 11;
}

@media (max-width: 900px) {
  .toast-mobile-width {
    width: 96%;
  }
  .toast {
    width: 96% !important;
  }
}

@media (max-width: 650px) {
  .studentIMG {
    max-width: 250px;
  }

}

@media (max-width: 520px){
  h1{
    font-size: 26px;
  }

  #description{
    font-size: 20px;
  }
}

</style>
