<template>
  <div class="d-flex flex-column align-items-start justify-content-center">
    <label v-if="label"> {{ label }} </label>
    <div :class="`textarea-container ${border && 'gray-border'}`" tabindex="0">
      <slot name="icon" />
      <textarea
        :id="id"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('write', $event)"
        v-bind="$attrs"
      />
      <slot name="actionIcon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    label: {
      type: [String, Boolean],
      default: "",
    },
    modelValue: {
      type: String || Number,
      default: "",
    },
    border: {
      type: Boolean,
      default: false,
    },
    paddingX: { type: String, default: "" },
    paddingY: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputSelected: false,
    };
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
textarea {
  resize: none;
  font-size: 18px;
}

label {
  font-size: 18px;
  font-weight: 400;
  color: var(--capable-blue);
}

.textarea-container {
  width: 100%;
  border-radius: 11px;
  border: none;
  font-size: 15px;
}

textarea,
textarea:focus {
  border: none;
  border-radius: 11px;
}

textarea:focus {
  outline: none;
}

.textarea-container {
  width: 100%;
  border-radius: 11px;
  border: none;
  font-size: 15px;
}

.gray-border {
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 11px;
}

.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px;
}

.font-18px {
  font-size: 18px;
}

.font-20px {
  font-size: 20px;
}

@media (max-width: 1400px) {

  .lg-font-14px {
    font-size: 14px;
  }

  .lg-font-16px {
    font-size: 16px;
  }

  .lg-font-18px {
    font-size: 18px;
  }

  .lg-font-20px {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  label,
  textarea {
    font-size: 15px;
  }
}
</style>
