const studentProjects = axios => {
	return {
		async acceptProject(data){
            return axios.post("/api/student-projects", data)
        },
        async assingProject(data){
            return axios.post("/api/student-projects/students", data)
        },
        async completedStep(studentId, projectId, data){
            return axios.put(`/api/student-projects/completed-step/${studentId}/${projectId}`, data)
        },
        async getMyProjects(studentId){
            return axios.get(`/api/student-projects/${studentId}`)
        },
        async getProject(studentId, projectId){
            return axios.get(`/api/student-projects/${studentId}/${projectId}`)
        },
        async getPendingSteps(teacherId){
            return axios.get(`/api/student-projects/pending-steps/${teacherId}`)
        },
        async approvedStep(studentId, projectId, stepId){
            return axios.put(`/api/student-projects/approved-step/${studentId}/${projectId}/${stepId}`)
        },
        async rejectedStep(studentId, projectId, stepId){
            return axios.put(`/api/student-projects/rejected-step/${studentId}/${projectId}/${stepId}`)
        },
        async modifyStudentsImage(projectId, stepId, data){
            return axios.post(`/api/student-projects/upload/${projectId}/${stepId}`, data)
        },
	}
}

export default studentProjects;