<template>
  <div class="row d-flex justify-content-center gap-5">
    <div class="col-12 text-center step-description">
      What tools are suggested for project completion?
    </div>
    <div class="skip-tools-container">
      <ActionButton
        v-if="!editing"
        color="red"
        :text="'Skip tools'"
        class="py-3 px-4"
        @click="goToNextStep"
        fontsize="text-18-px"
      />
    </div>
    <div class="row col-12">
      <InputComponent
        :border="true"
        :paddingX="'4'"
        :paddingY="'4'"
        :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
        :label-class="'font-16px lg-font-14px'"
        placeholder="Search Tool"
        @update:modelValue="(text) => (q = text)"
      >
        <template #rightSlot>
          <div class="d-flex align-items-center mr-3 magnifier-icon-container">
            <BaseIcon
              :iconColor="`#b0b3b7`"
              width="23.187"
              height="22.871"
              @click="searchProject(false)"
            >
              <MagnifierIcon />
            </BaseIcon>
          </div>
        </template>
      </InputComponent>
    </div>
    <div v-if="config.tools" class="col-12">
      <div class="row mb-4 ml-1">
        <div
          class="col-md-4 my-1"
        >
          <div
            class="row d-flex ml-0 justify-content-center align-items-center"
          >
            <div class="">
              <input
                :disabled="noToolSelected"
                class="form-check-input"
                type="checkbox"
                :id="index + 'id'"
                :value="noToolsRequired._id"
                v-model="project.toolsRequired"
                @change="handleToolCheckboxChange(noToolsRequired)"
              />
            </div>
            <div>
              <span v-if="user?.role === 'admin'">
                <i class="fas fa-trash" @click="openConfirmationModal(noToolsRequired)">
                </i>
              </span>
              <label
              :class="
                  noToolSelected ? 'form-check-label text-decoration-line-through'
                  : 'form-check-label text-decoration-underline'
                "
                :for="index + 'id'"
              >
                No tools required
              </label>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 my-1"
          v-for="(tool, index) in filteredTools"
          :key="index"
        >
          <div
            v-if="tool.name != 'No tools required'"
            class="row d-flex ml-0 justify-content-center align-items-center"
          >
            <div class="">
              <input
                :disabled="areToolsDisabled"
                class="form-check-input"
                type="checkbox"
                :id="index + 'id'"
                :value="tool._id"
                v-model="project.toolsRequired"
                @change="handleToolCheckboxChange(tool)"
              />
            </div>
            <div>
              <span v-if="user?.role === 'admin'">
                <i class="fas fa-trash" @click="openConfirmationModal(tool)">
                </i>
              </span>
              <label
                :class="
                  areToolsDisabled ? 'form-check-label text-decoration-line-through'
                  : 'form-check-label'
                "
                :for="index + 'id'"
              >
                {{ tool.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-md-6" v-if="!tools.length">
        <span class="text-center step-description"> No tools available. </span>
      </div>
    </div>
    <div class="row col-12">
      <InputComponent
        label="Can't find the right tools for this project? Add yours."
        :border="true"
        :paddingX="'4'"
        :paddingY="'4'"
        :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
        :label-class="'font-16px lg-font-14px'"
        placeholder="Add your tool"
        fontSize="16px"
      >
        <template #rightSlot>
          <div class="d-flex align-items-center mr-3 magnifier-icon-container">
            <BaseIcon
              :iconColor="`#b0b3b7`"
              width="23.187"
              height="22.871"
              @click="searchProject(false)"
            >
              <MagnifierIcon />
            </BaseIcon>
          </div>
        </template>
      </InputComponent>
    </div>

    <ConfirmationModal ref="confirmModal" @confirm="deleteToolApi" />
    <ModalAddTool
      @newTool="loadTools"
      @newtooladmin="loadToolsAdmin"
      :user="user"
    />
  </div>
</template>

<script>
import API from "../../../services/api";
import FileUploader from "../../../components/FileUploader.vue";
import ConfirmationModal from "../../../components/confirmationDeleteModal.vue";
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
import BaseIcon from "../../../components/baseIcon.vue";
import MagnifierIcon from "../../../assets/icons/magnifier.vue";
import ActionButton from "../../../components/actionButton.vue";

const sortingTools = (a, b) =>
  a.name.toLowerCase() > b.name.toLowerCase()
    ? 1
    : b.name.toLowerCase() > a.name.toLowerCase() &&
      a.name !== "No tools required"
    ? -1
    : 0;

export default {
  name: "StepName",
  components: {
    InputComponent,
    FileUploader,
    ConfirmationModal,
    BaseIcon,
    MagnifierIcon,
    ActionButton
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    filteredTools() {
      if (!this.q) {
        return this.tools;
      } else
        return this.tools.filter((tool) =>
          tool.name.toLowerCase().includes(this.q.toLowerCase())
        );
    },
  },
  data() {
    return { tools: [], q: "", noToolsRequired: {}, noToolSelected: false, areToolsDisabled: false};
  },
  props: ["currentStep", "steps", "editing"],
  methods: {
    ...mapActions("project", ["setProjectProp"]),
    processFile(event) {
      this.$emit("imageUpload", event, "projectImage");
    },
    loadTools() {
      return API.tools
        .getTools()
        .then((res) => {
          const sortedData = res.data.sort(sortingTools);
          this.tools = sortedData;
        })
        .catch((err) => console.log(err));
    },
    async deleteToolApi(id) {
      if (this.user?.role === "admin") {
        await API.defaultTools.deleteDefaultTool(id);
        this.tools.splice(this.tools.indexOf(id), 1);
        this.$toast.success("Removed Tool", {
          position: "top",
        });
      }
    },
    loadToolsAdmin() {
      return API.defaultTools
        .getDefaultTools()
        .then((res) => (this.tools = res.data))
        .catch((err) => console.log(err));
    },
    openConfirmationModal(tool = {}) {
      this.$refs.confirmModal.message =
        "Are you sure you want to delete this? " + tool.name || "";
      this.$refs.confirmModal.assetId = tool._id || "";
      $("#confirmationModal").modal("show");
    },
    handleToolCheckboxChange(tool) {
      if(this.project.toolsRequired.length == 0) {
        this.areToolsDisabled = false;
        this.noToolSelected = false;
        return;
      }

      if (tool.name == "No tools required") {
        this.areToolsDisabled = true;
      } else {
        this.noToolSelected = true;
      }

    },
    goToNextStep(e) {
      e.preventDefault();
      const nextStep = this.steps.find((s) => {
        return s.order > this.currentStep.order;
      });
      this.$emit("step-changed", nextStep);
      this.$emit("set-skip-tools", true);
      this.$emit("auto-save-project")

    }
  },
  async created() {
    if (!Object.keys(this.user).length || this.user?.role === "admin") {
      this.tools = await API.defaultTools
        .getDefaultTools()
        .then((res) => res.data)
        .then((data) => data.sort(sortingTools));
    } else if (this.user?.role) {
      await this.loadTools();
    }
    this.noToolsRequired = this.filteredTools.filter(tool => tool.name == "No tools required")[0]
    if (this.project.toolsRequired.length == 0) {
      this.noToolSelected = false;
      this.areToolsDisabled = false;
    } else if (this.project.toolsRequired.includes(this.noToolsRequired._id)) {
      this.areToolsDisabled = true;
    } else {
      this.noToolSelected = true;
    }
  },
};
</script>

<style scoped>

.step-description {
  color: #004aad;
  font-size: 20px;
  margin: 30px;
  font-family: "Poppins", sans-serif;
}
.form-check-label {
  color: #004aad;
  margin: 0.25rem 0 0.25rem 2rem;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}
.form-check-input {
  width: 1.75em;
  height: 1.75em;
  border-radius: 5px;
}

.magnifier-icon-container {
  width: 18px
}

.skip-tools-container {
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
