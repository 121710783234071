<template>
  <path
    id="Path_190"
    data-name="Path 190"
    d="M18.717,17.73c-.019.05-.04.1-.057.15a1.211,1.211,0,0,1-1.957.547c-.055-.048-.106-.1-.158-.152q-3.509-3.509-7.017-7.019a1.433,1.433,0,0,1-.13-.176l-.086-.02a.927.927,0,0,1-.114.187q-3.526,3.531-7.054,7.06a1.217,1.217,0,0,1-1.365.337A1.2,1.2,0,0,1,.3,16.728c.056-.064.117-.124.178-.184q3.5-3.5,7.006-7c.052-.052.113-.093.218-.18a1.59,1.59,0,0,1-.21-.146Q3.95,5.682.416,2.146A1.217,1.217,0,0,1,.319.41,1.425,1.425,0,0,1,1.133,0h.146A1.809,1.809,0,0,1,2.335.63q3.43,3.445,6.873,6.878c.046.046.1.091.186.176A1.281,1.281,0,0,1,9.523,7.5Q12.952,4.063,16.382.63A1.8,1.8,0,0,1,17.437,0h.146a4.692,4.692,0,0,1,.659.277,1.231,1.231,0,0,1,.475.746v.4a1.93,1.93,0,0,1-.59.9q-3.455,3.443-6.9,6.894a1.189,1.189,0,0,1-.209.13c.112.095.166.135.213.182q3.456,3.455,6.913,6.908a1.872,1.872,0,0,1,.574.889Z"
    transform="translate(0)"
  />
</template>

<script>
export default {};
</script>
