import API from "../../services/api";
import _ from 'lodash'

export default{

  saveUser:({commit}) => {
    let stringUser = sessionStorage.getItem('user')
		let user = JSON.parse(stringUser)
    commit('setUser', user)
    return
  },

  removeUser:({commit}) => {
    commit('deleteUser', {})
  },

  updateUser:( { commit, dispatch },  data ) => {
    API.user.updateUser(data)
      .then(res => {
        commit('setUpdateUser', res.data)
      })
      .catch(error =>{
        commit('setErrorUserAPI', _.get(error, "response.data.errors.message", "Error please try later.") )} )
        dispatch('saveUser')
      },
}