const tools = (axios) => {
  return {
    async CreateNewTool(data) {
      return axios.post("/api/tools", data);
    },
    async UploadPicture(data) {
      return axios.post("/api/tools/picture", data);
    },
    async getTools() {
      return axios.get(`/api/tools`);
    },
    async deleteTool(id) {
      return axios.delete(`/api/tools/${id}`);
    },
    async updateTool(id, data) {
      return axios.put(`/api/tools/${id}`, data);
    },
  };
};

export default tools;
