export const wait = (timeInMiliseconds) =>
  new Promise((resolve) => setTimeout(resolve, timeInMiliseconds));

export const sortAlphabetically = (a, b) =>
  a.name.toLowerCase() > b.name.toLowerCase()
    ? 1
    : b.name.toLowerCase() > a.name.toLowerCase()
    ? -1
    : 0;

export const checkDeviceType = () => {
  const { userAgent } = navigator;
  let regexp = /android|iphone|kindle|ipad/i;
  return regexp.test(userAgent);
};
