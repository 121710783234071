<template>
  <path
    id="Path_38"
    data-name="Path 38"
    d="M3.834,136.954a5.308,5.308,0,0,1-2.106-.918A4.254,4.254,0,0,1,.01,132.6q-.02-10.871,0-21.742a4.373,4.373,0,0,1,4.254-4.338c.972-.026,1.945,0,2.953,0V107q0,7.923,0,15.846a6.787,6.787,0,0,0,5.6,6.784,7.588,7.588,0,0,0,1.418.112q7.06.012,14.119,0h.5c0,.416.013.811,0,1.206a14.536,14.536,0,0,1-.15,2.611,4.205,4.205,0,0,1-3.472,3.322,1.787,1.787,0,0,0-.206.072Z"
    transform="translate(0 -98.493)"
  />

  <path
    id="Path_39"
    data-name="Path 39"
    d="M153.414,14.422c0,3.317.007,6.634,0,9.95a4.355,4.355,0,0,1-3.426,4.355,4.637,4.637,0,0,1-1.04.113q-8.336.01-16.671,0a4.4,4.4,0,0,1-4.492-4.489q0-9.931,0-19.863A4.4,4.4,0,0,1,132.272,0q8.317,0,16.634,0a4.382,4.382,0,0,1,4.505,4.507c.009,3.3,0,6.609,0,9.913"
    transform="translate(-118.162 0)"
  />
</template>

<script>
export default {};
</script>
