<template>
  <div
    :class="`flip ${shadow && 'card-shadow'}`"
    @mouseenter="(e) => !isMobileDevice ? flipCard(e) : null"
    @mouseleave="(e) => !isMobileDevice ? flipCard(e) : null"
    @click="seeProject(project)"
  >
    <div class="card" ref="card">
      <a class="d-flex flex-column align-items-center face front">
        <div class="card-image-container gap-3">
          <component
            v-bind:is="currentCard.headerComponent"
            :text="!isMobileDevice ? project.projectTheme || `Info & Tools` : `Info & Tools`"
            :project="project"
            :user="user"
            :config="config"
            :isDraft="isDraft"
            :index="index"
            v-model="project.selected"
            fontSize="'10px'"
            @flipCard="(e) => flipCard(e)"
            />
          <div v-if="!project.projectImage" class="not-img-container">
            <div class="not-image-project"></div>
          </div>
          <img :src="project.projectImage" v-else />
          <component
            v-bind:is="currentCard.footerComponent"
            :text="project.name"
            :user="user"
            :students="students"
            :isDraft="isDraft"
            :project="project"
          />
        </div>
      </a>
      <CardInfo @flipCard="flipCard" :project="project" class="face back" />
    </div>
  </div>
</template>

<script>
import baseCardHeader from "./baseCardHeader.vue";
import baseCardFooter from "./baseCardFooter.vue";
import TeacherCardHeader from "./teacherCardHeader.vue";
import TeacherCardFooter from "./teacherCardFooter.vue";
import WarehouseCardHeader from "./warehouseCardHeader.vue";
import CardInfo from "./cardInfo.vue";

/* Used to avoid unnecessary performance overhead */
import { shallowRef } from "vue";

export default {
  props: {
    user: {},
    project: {},
    config: {},
    cardType: {},
    header: {},
    isDraft: {},
    index: {},
    students: {},
    flip: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cards: [
        {
          name: "BaseCard",
          headerComponent: shallowRef(baseCardHeader),
          footerComponent: shallowRef(baseCardFooter),
        },
        {
          name: "TeacherCard",
          headerComponent: shallowRef(TeacherCardHeader),
          footerComponent: shallowRef(TeacherCardFooter),
        },
        {
          name: "WarehouseCard",
          headerComponent: shallowRef(WarehouseCardHeader),
          footerComponent: shallowRef(baseCardFooter),
        },
      ],
      isMobileDevice: null
    };
  },
  components: {
    CardInfo,
  },
  computed: {
    currentCard() {
      return this.cards.find((card) => card.name === this.cardType);
    },
  },
  methods: {
    flipCard(e) {
      e.stopPropagation();

      if (this.flip) {
        this.$refs.card.classList.toggle("flipped");
      }
    },

    seeProject(project) {
      if (
        this.$route.name === "Drafts" ||
        this.$route.name === "Project drafts"
      ) {
        return this.editProject(project);
      } else if (
        !Object.keys(this.user).length ||
        this.$route.name === "Project Warehouse"
      ) {
        return this.$router.push({
          path: `/project/${project._id}/default`,
        });
      } else {
        return this.$router.push({
          path: `/project/${project._id}`,
        });
      }
    },
    editProject(project) {
      this.$router.push({
        path: "/create-projects",
        query: { project: project._id },
      });
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },

  },
  beforeMount() {
    this.checkDeviceType()
  }
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.card-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 294.054px;
}

img {
  border-radius: 12px;
  object-fit: cover;
  width: 294.054px;
  height: 194.810px;
}

.flip {
  -webkit-perspective: 800;
  perspective: 800;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 335.405px;
  height: 340px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
}
.flip .card.flipped {
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
}
.flip .card {
  width: 335.405px;
  height: 340px;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  transform-style: preserve-3d;
  transition: 0.5s;
}
.flip .card .face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
}
.flip .card .front {
  position: absolute;
  width: 335.405px;
  z-index: 1;
}

.flip .card .back {
  padding-top: 10%;
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
  position: absolute;
}

.not-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 11px;
  width: 320px;
  height: 212px;
}

.card-shadow:hover {
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .flip {
    width: 316px;
    height: 290px;
  }
  .flip .card,
  .flip .card .front,
  .flip .card .back,
  .card {
    width: 316px;
  }

  .not-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    min-height: 170px;
  }


  .card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 280px;
    height: 170px;
  }

  img {
    border-radius: 12px;
    object-fit: cover;
    width: 280px;
    height: 170px;
  }
}
</style>
