<template>
  <path
    id="Path_205"
    data-name="Path 205"
    d="M44.676,15.026A24.456,24.456,0,0,1,33.243,26.754,24.594,24.594,0,0,1,.191,15.468a1.7,1.7,0,0,1,.031-1.624A24.222,24.222,0,0,1,18.072.406a24.539,24.539,0,0,1,26.4,13.5c.068.141.139.28.209.421Zm-31.2-.361a8.848,8.848,0,1,0,8.86-8.836,8.854,8.854,0,0,0-8.86,8.836"
    transform="translate(0 0)"
    fill="#FFFFFF"
  />
  <path
    id="Path_206"
    data-name="Path 206"
    d="M147.11,75.61a5.859,5.859,0,1,1-5.874,5.82,5.839,5.839,0,0,1,5.874-5.82"
    transform="translate(-124.766 -66.792)"
    fill="#FFFFFF"
  />
</template>

<script>
export default {};
</script>
