<template>
  <div class="modal fade hide" id="modal-add-tool" tabindex="-1" role="dialog" aria-labelledby="addToolModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" aria-labelledby="addToolModalLabel"> Add a new tool </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="font-size: 25px;">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="display: flex;flex-direction: column;">
          <label style="font-size: 15px;"> Name: </label>
          <input class="form-control input-border-radius" type="text" placeholder="name" v-model="nameTool.name" style="height: 40px;margin-bottom: 20px;font-size: 15px;"/>
          <div class="mb-3">
            <label for="formFile" class="form-label">Tool Image</label>
            <input class="form-control" type="file" accept="image/*" id="formFile" @change="processFile">
          </div>
          <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="uploadingImage">
            <div class="loader-whitebg"></div>
          </div>
          <div v-if="!uploadingImage && nameTool.picture" class="position-relative">
            <div class="delete-tool-picture"><i class="fas fa-window-close delete-tool-picture-icon" @click="deleteToolPicture"></i></div>
            <img :src="nameTool.picture" alt="" class="tool-img">
          </div>
        </div>

        <p class="text-danger text-center w-100" v-if="error">{{ error }}</p>
        <div class="modal-footer">
          <button type="button" class="btn-add-tool" style="border: none;color: #fff;padding: 10px 30px;" :data-dismiss="nameTool.name ? 'modal' : ''" @click="addTool()">Add</button>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import API from "../services/api";
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
export default {
  name: "ModalAddTool",
  data() {
    return {
      nameTool: {
        name: '',
        contentArea: '-',
        picture: "",
      },
      error: '',
      uploadingImage: false,
      pictureName: "",
    }
  },
  props:["user"],
  methods: {
    ...mapActions('tools', ['getTools', 'CreateNewTool', 'updateTool', 'getDefaultTools', 'updateDefaultTool', 'CreateNewDefaultTool']),
    deleteToolPicture(){
      this.picture = '';
      this.pictureName = ''
      document.getElementById('formFile').value = ""
    },
    processFile(e){
      let file = e.target.files[0];
      this.error = ""
      if (!file) {
        return console.log("Fail")
      } if (file.size > 1024 * 2000) {
        this.error = "You exceeded the allowed image size. Maximum size allowed 2mb"
        return
      } else {          
        return this.uploadImage(file);
      }
    },
    uploadImage(file){
      this.uploadingImage = true
      const formData = new FormData();
      formData.append(
        "image", file, file.name
      )
      if(this.user.role === "teacher"){
        return API.tools.UploadPicture(formData)
        .then(result => {
          const file = result.data
          this.uploadingImage = false
          this.nameTool.picture = file.linkUrl;
          this.pictureName = file.filename;
        })
        .catch(err => {
          this.uploadingImage = false
          console.log("err", err)
        });
      }else if(this.user.role === "admin"){
        return API.defaultTools.UploadDefaultPicture(formData)
        .then(result => {
          const file = result.data
          this.uploadingImage = false
          this.nameTool.picture = file.linkUrl;
          this.pictureName = file.filename;
        })
        .catch(err => {
          this.uploadingImage = false
          console.log("err", err)
        });
      }
      this.error = "";
    },
    async addTool() {
      if(!this.nameTool.name) {
        this.error = "Missing name";
      }
      if(this.user.role === "admin"){
        await this.CreateNewDefaultTool(this.nameTool)
        await this.getDefaultTools()
        this.$emit('newtooladmin')
        if(this.nameTool.name){
          this.$toast.success("Added new tool", {
            position: "top",
          });
        }
      } else if (this.user.role === "teacher") {
        await this.CreateNewTool(this.nameTool)
        await this.getTools()
        this.$emit('newTool')
        if(this.nameTool.name){
          this.$toast.success("Added new tool", {
            position: "top",
          });
        }
      }
    },
    cleanModal(){
      this.nameTool.name = "";
      this.error = "";
      this.nameTool.picture = "";
      this.pictureName = "";
      document.getElementById('formFile').value = ""
    }
  },
  mounted() {
    $('#modal-add-tool').on("hidden.bs.modal", this.cleanModal)
  }
}
</script>