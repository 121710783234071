<template>
  <path
    id="Path_52"
    data-name="Path 52"
    d="M319.58,4.379a5.888,5.888,0,0,1-1.546,1.9l-.757.814-4.7-4.709c.635-.659,1.282-1.354,1.959-2.018A2.765,2.765,0,0,1,315.2,0h.816a3.264,3.264,0,0,1,1.339.943q.623.658,1.281,1.282a3.316,3.316,0,0,1,.943,1.339Z"
    transform="translate(-281.578)"
    fill="#004aad"
  />
  <path
    id="Path_53"
    data-name="Path 53"
    d="M350.878,3.563a3.315,3.315,0,0,0-.943-1.339q-.658-.623-1.282-1.282A3.264,3.264,0,0,0,347.315,0h3.563Z"
    transform="translate(-312.876)"
    fill="#004aad"
  />
  <path
    id="Path_54"
    data-name="Path 54"
    d="M31.914,69.349v.362q0,9.685,0,19.369a4.054,4.054,0,0,1-3.489,4.179,3.746,3.746,0,0,1-.664.041q-11.8,0-23.6,0A4.061,4.061,0,0,1,0,89.125Q0,77.344,0,65.563A4.059,4.059,0,0,1,4.165,61.39q9.74,0,19.48,0h.385c-.129.139-.21.232-.3.319q-4.84,4.841-9.685,9.678a4.315,4.315,0,0,0-1.1,1.772c-.479,1.491-1,2.969-1.477,4.46a3.312,3.312,0,0,0,2.37,4.292,3.2,3.2,0,0,0,1.826-.07c1.569-.518,3.135-1.045,4.7-1.567a3.678,3.678,0,0,0,1.453-.928q4.863-4.871,9.732-9.736c.1-.1.179-.2.268-.3l.092.045"
    transform="translate(0 -55.302)"
    fill="#004aad"
  />
  <path
    id="Path_55"
    data-name="Path 55"
    d="M152.044,40.445l4.714,4.712c-.066.07-.155.169-.25.264q-6.7,6.7-13.414,13.4a2.552,2.552,0,0,1-.928.567c-1.418.5-2.851.958-4.275,1.441a1.147,1.147,0,0,1-1.521-1.518c.5-1.472.977-2.95,1.483-4.418a1.849,1.849,0,0,1,.418-.682q6.784-6.806,13.585-13.6c.068-.068.143-.13.187-.169"
    transform="translate(-122.762 -36.435)"
    fill="#004aad"
  />
</template>

<script>
export default {};
</script>
