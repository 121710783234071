<template>
  <projectsAndDraftsTable :projects="projects" :isLoading="isLoading" />
</template>
<script>
import API from "../services/api";
import projectsAndDraftsTable from "../components/projectsAndDraftsTable";

import _ from "lodash";
export default {
  data() {
    return {
      student: "",
      projects: [],
      isLoading: true,
    };
  },
  components: {
    projectsAndDraftsTable,
  },
  async created() {
    this.isLoading = true;
    let stringUser = sessionStorage.getItem("user");
    this.student = JSON.parse(stringUser);
    await API.projects
      .getProjectsByStudent(this.student.teacher)
      .then((res) => {
        this.projects = res.data;
      })
      .catch((err) => console.log(err));

    this.isLoading = false;
  },
};
</script>
