<template>
  <div class="container-fluid px-0">
    <div :class="`mb-3 form-group col-md-12 ${this.isMobileDevice && 'mt-3'}`" style="padding: 0px!important">
      <div class="container d-flex flex-column align-items-center gap-1 mb-0" style="padding: 0px!important">
        <div class="container d-flex gap-2 m-1 w-100 " style="padding: 0px!important">
          <FileUploader
            @change="
              ($event) =>
                $parent.$emit('fileUpload', {
                  e: $event,
                  prop: 'imagesURL',
                  type: 'stepMedia',
                  index: this.index,
                })
            "
            :border="true"
            :paddingY="'4'"
            type="file"
            accept="image/*"
            placeholder="No file chosen"
            :class="['w-100']"
          />
          <!-- Hide the upload photo feature and emphasize the direct camera integration on desktop -->
          <ActionButton
            color="blue"
            class="px-4 py-4"
            type="button"
            @click="openMediaModal(this.index)"
            ><i class="fa fas fa-camera"></i
          ></ActionButton>
        </div>
        <MediaCarousel :index="this.index" v-if="displayMedia(this.index)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
import FileUploader from "../../../components/FileUploader.vue";
import ActionButton from "../../../components/actionButton.vue";
import MediaCarousel from "./MediaCarousel.vue";
export default {
  components: {
    InputComponent,
    FileUploader,
    ActionButton,
    MediaCarousel,
  },
  name: "CamRecorder",
  props: ["step", "index"],
  data() {
    return {
      isMobileDevice: false,
    };
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    displayMedia() {
      return (index) => {
        const { imagesURL, gifsURL, videosURL } = this.project.steps[index];
        return imagesURL.length || gifsURL.length || videosURL.length;
      };
    },
  },
  created() {
    this.checkDeviceType();
  },
  methods: {
    ...mapActions("project", ["setProjectProp"]),
    openMediaModal(index) {
      let data = {
        isMobileDevice: this.isMobileDevice,
        index: index,
        step: this.step,
        modalId: `mediaModal-index-${this.index}`,
      };
      this.$emit("send-data-model-media", data);
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;

      this.isMobileDevice = regexp.test(userAgent);
    },

    deleteMedia(type) {
      let currentStep = this.project.steps[this.index];
      if (type === "gif") {
        currentStep.gifsURL = [];
        this.gifStatus = "noGif";
      } else {
        currentStep.videosURL = [];
        this.videoStatus = "noVideo";
      }
    },
  },
  beforeUnmount() {
    if (this.isStreamingImage || this.isStreamingVideo) {
      this.closeCamera();
    }
  },
};
</script>
