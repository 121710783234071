<script>
// @ts-check
import { createWebHistory, createRouter } from "vue-router";

import routes from './routes.vue'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

export default router;
</script>
