<template>
  <div class="row">
    <div class="col-12 text-center step-description mx-0">
      Under what theme could this project be categorized?
    </div>
    <div class="form-group col-md-12">
      <InputComponent
        :modelValue="project.projectTheme"
        @update:modelValue="
          (e) => setProjectProp({ prop: 'projectTheme', value: e })
        "
        label="Can't find the right one for this project? Add yours."
        :border="true"
        :paddingX="'4'"
        :paddingY="'4'"
        :class="['px-4', 'py-4', 'w-100']"
        placeholder="Add your theme"
      />
    </div>
  </div>
</template>

<script>
import FileUploader from "../../../components/FileUploader.vue";
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
export default {
  name: "StepName",
  components: {
    InputComponent,
    FileUploader,
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("configs", ["config"]),
  },
  methods: {
    ...mapActions("project", ["setProjectProp"]),
  },
};
</script>

<style scoped>
.step-description {
  color: #004aad;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}
</style>
