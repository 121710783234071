<template>
  <path
    id="Path_171"
    data-name="Path 171"
    d="M140.837,0A3.125,3.125,0,0,1,143,1.708c.048.1.26.171.4.176.528.02,1.058,0,1.587.01a.933.933,0,0,1,1.056,1.012q.023,1.806,0,3.612a.943.943,0,0,1-1.081,1.031q-4.584,0-9.168,0a.96.96,0,0,1-1.072-1.081q-.006-1.746,0-3.493a.954.954,0,0,1,1.067-1.081c.529-.006,1.059.011,1.587-.01a.52.52,0,0,0,.393-.183A3.172,3.172,0,0,1,139.884,0Z"
    transform="translate(-123.991)"
    fill="#FFFFFF"
  />
  <path
    id="Path_172"
    data-name="Path 172"
    d="M8.344,47.388c0,.808-.022,1.587,0,2.363a3.821,3.821,0,0,0,3.727,3.764q4.309.045,8.618.009a3.757,3.757,0,0,0,3.714-3.53c.046-.712.021-1.429.03-2.144,0-.142.012-.284.021-.473.606,0,1.185-.013,1.763,0a18.367,18.367,0,0,1,2.65.121,4.809,4.809,0,0,1,3.88,4.357c.013.171.027.344.027.515q0,13.444,0,26.889A4.848,4.848,0,0,1,29.5,83.986a5.219,5.219,0,0,1-1.63.281q-11.478.027-22.957.009A4.892,4.892,0,0,1,0,79.341Q0,65.817,0,52.293a4.862,4.862,0,0,1,4.649-4.9c1.212-.056,2.429-.01,3.69-.01M20.223,61.456H22.09c1.443,0,2.886.006,4.33,0a1.2,1.2,0,0,0,1.227-1.591,1.229,1.229,0,0,0-1.326-.79q-5.978,0-11.956,0c-.185,0-.372-.007-.556.012a1.127,1.127,0,0,0-1.007.779,1.192,1.192,0,0,0,1.225,1.591c2.065.007,4.131,0,6.2,0M20.4,75.917c-2.065,0-4.131,0-6.2,0a1.2,1.2,0,0,0-1.234.751c-.347.837.281,1.634,1.285,1.634q6.117,0,12.233,0a2.042,2.042,0,0,0,.51-.058,1.176,1.176,0,0,0,.029-2.267,2.282,2.282,0,0,0-.589-.055q-3.019-.006-6.037,0m-.254-6.009q3.137,0,6.275,0a1.192,1.192,0,1,0,.005-2.379c-4.157-.006-8.314-.011-12.47.014a1.568,1.568,0,0,0-.914.384,1.027,1.027,0,0,0-.245,1.238,1.124,1.124,0,0,0,1.114.743q3.118,0,6.235,0M7.355,60.016c-.157-.15-.269-.261-.385-.367a1.206,1.206,0,0,0-1.682,0,1.176,1.176,0,0,0,0,1.678c.373.394.759.777,1.151,1.152a1.243,1.243,0,0,0,1.786.013c.948-.923,1.88-1.864,2.808-2.807a1.127,1.127,0,0,0,.287-1.174,1.178,1.178,0,0,0-2.006-.467c-.655.635-1.287,1.294-1.959,1.973M7.5,76.852c-.141-.135-.253-.244-.368-.351a1.206,1.206,0,0,0-1.683-.017,1.18,1.18,0,0,0,.01,1.706q.549.574,1.123,1.123a1.239,1.239,0,0,0,1.785.03c.95-.921,1.881-1.863,2.809-2.806a1.126,1.126,0,0,0,.3-1.17,1.177,1.177,0,0,0-2-.49c-.665.641-1.307,1.3-1.975,1.975M7.271,68.54a3.383,3.383,0,0,0-.3-.4,1.184,1.184,0,0,0-1.7-.035,1.2,1.2,0,0,0,.017,1.708q.548.572,1.122,1.121a1.232,1.232,0,0,0,1.808.007c.935-.914,1.855-1.845,2.776-2.774a1.142,1.142,0,0,0,.343-1.049,1.118,1.118,0,0,0-.778-.921,1.155,1.155,0,0,0-1.244.287c-.654.653-1.3,1.312-2.038,2.055"
    transform="translate(0 -43.598)"
    fill="#FFFFFF"
  />
</template>

<script>
export default {};
</script>
