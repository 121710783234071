import API from "../../services/api";
import _ from 'lodash'

export default{
  getTeachers:( { commit } ) => {
    API.admins.getTeachers()
      .then(res => commit('setTeachers', res.data) )
      .catch(error => commit('errorTeachersAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteTeacher:( { commit, dispatch }, id ) => {
    API.admins.deleteTeacher(id)
      .then(res => dispatch('getTeachers'))
      .catch(error => commit('errorTeachersAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  updateTeacher:( { commit, dispatch }, {teacherId, data} ) => {
    API.admins.updateTeacher(teacherId, data)
      .then(res => dispatch('getTeachers') )
      .catch(error => commit('errorTeachersAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

}