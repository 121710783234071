<template>
  <div class="div_login_container">
    <div class="div_login d-flex shadow bg-white">
      <BaseIcon width="187.426" height="72.014" class="logo-icon">
        <Logo />
      </BaseIcon>
      <div class="d-flex flex-column align-items-center justify-content-start">
        <div class="form-row">
          <div class="form-group col-md-12 mt-3">
            <BaseInput
              placeholder="username / email"
              :border="true"
              :class="`px-4 py-4 w-100 font-18px lg-font-16px`"
              v-model="userInput"
            >
              <template #leftSlot>
                <div class="leftSlot">
                  <BaseIcon width="41.065" height="41.084" iconColor="#004aad">
                    <PersonIcon />
                  </BaseIcon>
                </div>
              </template>
            </BaseInput>
          </div>

          <div class="form-group col-md-12 mt-3">
            <BaseInput
              placeholder="password"
              :border="true"
              :class="`px-4 py-4 w-100 font-18px lg-font-16px`"
              v-model="passwordInput"
              :type="`password`"
            >
              <template #leftSlot>
                <div class="leftSlot">
                  <BaseIcon width="32.503" height="39.72">
                    <LockIcon />
                  </BaseIcon>
                </div>
              </template>
            </BaseInput>
          </div>

          <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
          <div class="w-100 div_login_button mt-5">
            <ActionButton
              :color="'red'"
              :class="['px-5', 'py-4', 'mb-5', 'text-16-px']"
              :text="`Log in`"
              v-on:click="login"
              fontsize="text-18-px"
            >
            </ActionButton>
          </div>
          <div
            class="d-flex justify-content-center align-items-center gap-2 w-100 mt-5"
          >
            <a class="create-account" @click="openSignUp"
              >Create New Account
            </a>
            <p>|</p>
            <a
              href=" "
              data-bs-toggle="modal"
              data-bs-target="#modalRecovery"
              @click="toggle = true"
              >Forgot password?</a
            >
          </div>
        </div>
      </div>
    </div>
    <tr v-if="loading">
      <td
        colspan="5"
        class="form-group py-4 w-100 d-flex justify-content-center"
      >
        <div class="loader-whitebg"></div>
      </td>
    </tr>
  </div>
  <modalRecovery :user="userInput" />
</template>

<script>
import API from "../services/api";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import modalRecovery from "../components/modalRecovery.vue";
import BaseInput from "../components/input.vue";
import ActionButton from "../components/actionButton.vue";
import BaseIcon from "../components/baseIcon.vue";
import Logo from "../assets/logos/logo.vue";
import PersonIcon from "../assets/icons/person.vue";
import LockIcon from "../assets/icons/lock.vue";

export default {
  data() {
    return {
      userInput: "",
      passwordInput: "",
      errorMessage: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions("user", ["saveUser"]),
    ...mapActions("configs", ["getConfig"]),
    login(e) {
      this.errorMessage = "";
      if (!this.userInput) {
        return (this.errorMessage = "Username / Email required!");
      }
      if (!this.passwordInput) {
        return (this.errorMessage = "Password required!");
      }

      let username = this.userInput.toLowerCase();

      const data = {
        username: username,
        password: this.passwordInput,
      };
      this.loading = true;
      API.user
        .Login(data)
        .then(async (res) => {
          let userDecode = jwt_decode(res.data.token);
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          await this.saveUser();
          await this.getConfig();
          if (userDecode.role === "teacher") {
            this.$router.push("/dashboard-teacher");
          } else if (userDecode.role === "admin") {
            this.$router.push("/" + userDecode.role);
          } else if (userDecode.role === "student") {
            this.$router.push("/student");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = _.get(
            error,
            "response.data.errors.message",
            "Error please try later."
          );
          this.loading = false;
        });
    },
    openSignUp() {
      this.$router.push("/signup");
    },
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  components: {
    modalRecovery,
    BaseInput,
    ActionButton,
    BaseIcon,
    Logo,
    PersonIcon,
    LockIcon,
  },
};
</script>

<style scoped>
a,
p {
  color: var(--capable-blue);
  font-size: 16px;
}

p {
  margin: 0px;
}
.form-row {
  width: 400px;
  justify-content: center;
}

svg {
  width: 270px;
  height: 103.608px;
}

.container {
  max-width: none;
}

.div_login_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  border-radius: 11px;
  padding-top: 30px;
  background-color: var(--capable-light-orange);
}

.div_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;
  border-radius: 11px !important;
  width: 550px;
  height: 630px;
}

.form-row svg {
  width: 20px;
  height: 20px;
}

.create-account {
  color: var(--capable-blue) !important;
  cursor: pointer;
}

.create-account:hover {
  text-decoration: underline !important;
}

@media (max-width: 991.98px) {
  .div_login {
    gap: 25px;
    width: 335px;
    height: 550px;
  }

  .logo-icon {
    width: 200px;
  }
  .form-row {
    width: 100%;
  }

  a,
  p {
    color: var(--capable-blue);
    font-size: 14px;
  }
}
</style>
