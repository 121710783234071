<template>
  <div ref="modal" class="modal fade" id="modalReplaceSteps" tabindex="-1" aria-labelledby="modalReplaceSteps" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header d-flex">
          <h5 class="modal-title" style="margin: auto;">Drag steps to reorder them here!</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            style="position: absolute;right: 20px;"></button>
        </div>
        <div class="modal-body">
          <div class="steps-container">
            <draggable
              class="list-group"
              tag="transition-group"
              :component-data="{
                tag: 'ul',
                type: 'transition-group',
                name: !drag ? 'flip-list' : null
              }"
              v-model="steps"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              item-key="order">
              <template #item="{ element }">
                <li class="list-group-item">
                  <i
                    :class="
                      element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                    "
                    @click="element.fixed = !element.fixed"
                    aria-hidden="true"></i>
                  {{ element.name }}
                </li>
              </template>
            </draggable>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveNewOrder">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'reorder-steps-modal',
  props: ['stepsArray'],
  components: { draggable },
  data() {
    return {
      drag: false,
      steps: []
    }
  },
  created() {
    for (let i = 0; i < this.stepsArray.length; i++) {
      let step = this.stepsArray[i]
      this.steps.push({ name: step.name, order: i + 1 })
    }
  },
  methods: {
    saveNewOrder() {
      $(this.$refs.modal).modal("hide")
      this.$emit('submitNewOrder', this.steps)
    },
    closeModal(){
      this.$emit('closeModal')
    },
  },
  mounted() {
    $(this.$refs.modal).on("hidden.bs.modal", this.closeModal)
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    // steps() {
    //   const steps = []
    //   for (let i = 0; i < this.stepsArray.length; i++) {
    //     let step = this.stepsArray[i]
    //     steps.push({ name: step.name, order: i + 1 })
    //   }

    //   return steps
    // }
  }
}
</script>
<style scoped>
.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

.list-group-item {
  width: 90% !important
}

.save-btn {
  border: 1px solid;
  width: 7rem;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3E66FB;
  background-color: white;
  color: #3E66FB;
  border-radius: 5px;
}

.footer {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #DDE5ED;
}

.close-modal-btn {
  border: 1px solid #DDE5ED;
  border-radius: 2px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 15%;
  border-bottom: 1px solid #DDE5ED;

}

.reorder-steps-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 30rem;
  border: 1px solid #DDE5ED;
  border-radius: 10px;
  background-color: white;

}

.list-group-item {
  width: 100% !important;
}

.steps-container {
  padding: 1rem;
  height: 70%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  width: 100%;
}

.draggable-item {
  border: 1px solid #DDE5ED;
  font-size: 2rem !important;
  width: 100%;
  margin-top: 1rem;
  cursor: grab;
}
</style>