<template>
  <label class="container-check">
    <input
      type="checkbox"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.checked)"
      v-bind="$attrs"
    />
    <span class="checkmark pb-1"></span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
/* Customize the label (the container-check) */
.container-check {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  background-color: var(--capable-white);
  border: 1px solid;
  border-radius: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: flex;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  width: 7px;
  height: 14px;
  border: solid red;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

label{
  margin: 0px;
}

</style>
