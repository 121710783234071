import API from "../../services/api";
import _ from 'lodash'

export default {

  getStudentProject: async ({ commit }, { studentId, projectId }) => {
    try {
      return await API.studentProjects.getProject(studentId, projectId);
    } catch (error) {
      commit('setErrorStudentProjectApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

  modifyStudentsImage: async ({ commit }, { projectId, stepId, data }) => {
    try {
      return await API.studentProjects.modifyStudentsImage(projectId, stepId, data);
    } catch (error) {
      commit('setErrorStudentProjectApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

}