<template>
  <div class="modal fade" id="ObjectivesEditModal" tabindex="-1" aria-labelledby="defaultToolsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="defaultToolsModalLabel">Objective</h5>
            <button type="button" class="btn-close" aria-label="Close" v-on:click="closeModal"></button>
          </div>
          <form class="w-100 py-5 px-4">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nameEdit" class="labelForm">Name<span class="text-danger">*</span></label>
                <input type="text" id="nameEdit" class="form-control inputsForm" placeholder="name"  v-model="name" v-on:keyup.enter="saveObjective()">
              </div>
              <div class="form-group col-md-6">
                <label for="supportingMaterialEdit" class="labelForm">Supporting Material</label>
                <input type="text" class="form-control inputsForm" id="supportingMaterialEdit" placeholder=" material" v-model="supportingMaterial" v-on:keyup.enter="saveObjective()">
              </div>
            </div>

            <p class="text-danger" v-if="errorMessage">
              {{ errorMessage }}
            </p>
            
          </form>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg btnModalSecondary" v-on:click="closeModal">Close</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="saveObjective()" v-if="objectiveId">Edit</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="saveObjective()" v-else>Add Objective</button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import confirmationCloseModal from './confirmationCloseModal';
export default {
  name:"ObjectivesModal",
  data(){
    return{
      name:"",
      supportingMaterial:"",
      objectiveId:"",
      errorMessage: "",
    }
  },
  props:["user"],
  components:{confirmationCloseModal},
  methods:{
    ...mapActions('objectives', ['getObjectives', 'getDefaultObjectives', 'updateDefaultObjective', 'updateObjective', 'CreateNewDefaultObjective', 'CreateNewObjective']),
    saveObjective(){
      this.errorMessage = "";
      if (this.name === "") {
        this.errorMessage = "Please insert a valid name";
        return;
      }
      const data = {
        name: this.name,
        supportingMaterial: this.supportingMaterial
      }
      if(this.objectiveId){
        if(this.user.role === "admin"){
          this.updateDefaultObjective({objectiveId:this.objectiveId, data, that: this})
        }else if(this.user.role === "teacher"){
          this.updateObjective({objectiveId:this.objectiveId, data, that: this})
        }
      }else{
        if(this.user.role === "admin"){
          this.CreateNewDefaultObjective({data: data, that: this})
        }else if(this.user.role === "teacher"){
          this.CreateNewObjective({data: data, that: this})
        }
      }
      this.name=""
      this.supportingMaterial=""
      $('#ObjectivesEditModal').modal('hide')
    },
    closeModal(){
      $('#ObjectivesEditModal').modal('hide')
    },
  }
}
</script>