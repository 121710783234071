<template>
  <div class="d-flex flex-column align-items-start justify-content-center px-0 w-100">
    <label
    :class="$attrs['label-class']"
    v-if="label"
    >
      {{ label }} <span class="text-gray"> {{ optional }}</span></label
    >
    <div :class="`input-container`" tabindex="0">
      <slot name="icon" />
      <input
        :value="modelValue"
        v-bind="$attrs"
        type="file"
        :accept="accept"
        :multiple="multiple"
        :capture="capture"
        :class="`${border && 'gray-border'}`"
      />
      <slot name="actionIcon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  inheritAttrs: false,
  props: {
    capture: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "",
    },
    multiple: {
      type: String,
      default: "false",
    },
    label: {
      type: [String, Boolean],
      default: "",
    },
    optional: {
      type: [String, Boolean],
      default: "",
    },
    modelValue: {
      type: String || Number,
      default: "",
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputSelected: false,
    };
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
label {
  font-size: 18px;
  font-weight: 400;
  color: var(--capable-blue);
}

input {
  border-radius: 11px;
  background-color: var(--capable-white);
  color: var(--capable-grey);
  cursor: pointer;
  font-size: 16px;
}

input:hover > file-selector-button {
  background-color: black;
  cursor: pointer;
}

input[type="file"]::file-selector-button:hover {
  cursor: pointer;
}

::-webkit-file-upload-button {
  transition: all ease-in-out;
  color: var(--capable-white);
  background-color: var(--capable-grey);
  border: none;
  border-radius: 11px 0px 0px 11px;
  padding: 1rem 2rem;
  margin-right: 20px;
}

.input-container {
  width: 100%;
  border-radius: 11px;
  font-size: 15px;
}

.gray-border {
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
  border-radius: 11px;
}


.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px;
}

.font-18px {
  font-size: 18px;
}

.font-20px {
  font-size: 20px;
}

@media (max-width: 1400px) {

  .lg-font-14px {
    font-size: 14px;
  }

  .lg-font-16px {
    font-size: 16px;
  }

  .lg-font-18px {
    font-size: 18px;
  }

  .lg-font-20px {
    font-size: 20px;
  }
}


@media (max-width: 768px) {

  ::-webkit-file-upload-button {
    padding: 1.5rem 1.5rem;
    font-size: 15px;
  }

  label,
  input {
    font-size: 15px;
  }
}

</style>
