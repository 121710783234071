export default{
  setUser(state, user){
    state.user = user
  },

  setUpdateUser(state, user){
    sessionStorage.setItem('user', JSON.stringify(user) )
    state.user = user
    state.errorUserAPI = ''
  },

  deleteUser(state){
    state.user = {}
  },

  setErrorUserAPI(state, errorUserAPI){
    state.errorUserAPI = errorUserAPI
  },
}