<template>
  <div
    v-show="isNotLoading"
    class="d-flex flex-column gap-5 justify-content-center align-items-center"
  >
    <div class="d-flex flex-column align-items-center">
      <h1 class="f-bold text-blue mt-5">{{ project.name }}</h1>
      <p class="f-regular text-blue" id="description">
        {{ project.description }}
      </p>
    </div>
    <ProjectSummary :project="project" class="col-10" />
    <div class="d-flex justify-content-center align-items-center col-12">
      <div class=" d-flex justify-content-center col-1 px-0">
        <BaseIcon
          role="button"
          width="10.475"
          height="16.469"
          @click="showPreviousStep"
          :iconColor="showLeftArrow() ? '#004aad' : '#b0b3b7'"
        >
          <ArrowLeft />
        </BaseIcon>
      </div>

      <StepInfo
        :step="stepToShow"
        :length="project?.steps.length"
        class="col-10"
      />
      <div class="d-flex justify-content-center col-1 px-0">
        <BaseIcon
          role="button"
          width="10.475"
          height="16.469"
          @click="showNextStep"
          :iconColor="showRightArrow() ? '#004aad' : '#b0b3b7'"
        >
          <ArrowRight />
        </BaseIcon>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-5">
      <div
        :class="
          !isMobileDevice
            ? 'd-flex justify-content-center'
            : 'd-flex flex-column'
        "
        v-if="!user.role"
      >
        <ActionButton
          color="blue"
          @click="openPDF(project)"
          class="px-3 py-4"
          :text="`Download as PDF`"
        />
      </div>
      <div
        :class="
          !isMobileDevice
            ? 'd-flex justify-content-center gap-5'
            : 'd-flex flex-column gap-5'
        "
        v-else-if="user.role != 'student' && !this.$route.params.default"
      >
        <ActionButton
          color="blue"
          @click="openModalAssign(project)"
          class="px-3 py-3 w-170-px"
          :text="`Assign Students`"
        fontsize="text-18-px"
        />
        <ActionButton
          color="red"
          v-on:click="openConfirmationModal(project)"
          class="px-3 py-3 w-170-px"
          :text="`Delete Project`"
        fontsize="text-18-px"
        />
        <ActionButton
          color="blue"
          @click="editProject(project)"
          class="px-3 py-3 w-170-px"
          :text="`Edit Project`"
        />
      </div>
      <div v-else-if="user.role == 'student'">
        <span
          v-if="isProjectAccepted()"
          class="hint--top hint--info"
          data-hint="Project already accepted"
        >
          <ActionButton
            disabled
            color="blue"
            class="px-3 py-4 w-170-px disabled"
            :text="`Accept Project`"
          />
        </span>
        <div v-else>
          <ActionButton
            color="blue"
            class="px-3 py-4 w-170-px"
            @click="grabProject"
            :text="`Accept Project`"
          />
        </div>
      </div>
      <div v-else-if="defaultProject && user.role" class="d-flex gap-5">
        <ActionButton
          color="red"
          class="px-3 py-4 w-170-px"
          @click="goBack(project)"
          :text="`Return`"
        />
        <ActionButton
          color="blue"
          class="px-3 py-4 w-170-px"
          @click="addProject(project)"
          :text="`Add project`"
        />
      </div>
    </div>
  </div>
  <confirmationModal ref="confirmModal" @confirm="deleteProjectApi" />
  <imageModal :imageURL="selectedImgURL" />
  <modalPdf :pdfUrl="pdfUrl" />
  <div class="project-container">
    <div class="loader-whitebg" v-show="!isNotLoading"></div>
  </div>
  <ModalAssignStudent ref="assignStudentsModal" />
</template>

<script>
import API from "../services/api";
import { mapActions, mapState } from "vuex";
import confirmationModal from "../components/confirmationDeleteModal.vue";
import imageModal from "../components/modalImage.vue";
import ModalAssignStudent from "../components/ModalAssignStudent.vue";
import modalPdf from "../components/modalPdf.vue";

import ProjectSummary from "../components/Projects/projectSummary.vue";
import StepInfo from "../components/Projects/stepInfo.vue";
import BaseIcon from "../components/baseIcon.vue";
import ArrowRight from "../assets/icons/arrowRight.vue";
import ArrowLeft from "../assets/icons/arrowLeft.vue";
import ActionButton from "../components/actionButton.vue";

export default {
  name: "seeProject",
  data() {
    return {
      isNotLoading: true,
      project: {},
      stepToShow: {},
      err: "",
      myProjects: [],
      loading: false,
      defaultProject: false,
      isMobileDevice: null,
      selectedImgURL: "",
      students: [],
      pdfUrl: "",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
  },
  methods: {
    ...mapActions("projects", [
      "deleteProject",
      "deleteDefaultProjects",
      "updateDefaultProjects",
      "updateProject",
    ]),
    showImage(image) {
      this.selectedImgURL = image;
      $("#modal-image").modal("show");
    },
    showLeftArrow() {
      if (this.stepToShow.stepNumber > 1) {
        return true;
      } else return false;
    },
    showRightArrow() {
      if (this.stepToShow.stepNumber < this.project?.steps?.length) {
        return true;
      } else return false;
    },
    openPDF(project) {
      this.isNotLoading = false;
      let newWindow;
      if (this.isMobileDevice) {
        newWindow = window.open();
        newWindow.location = this.project.pdfUrl;
      } else {
        this.pdfUrl = this.project.pdfUrl;
        $("#modalPdf").modal("show");
      }
      this.isNotLoading = true;
    },
    openModalAssign(project) {
      $("#modal-assign-student").modal("show");
      this.$refs.assignStudentsModal.teacher = this.user;
      this.$refs.assignStudentsModal.students = this.students;
      this.$refs.assignStudentsModal.project = project;
    },
    async getStudents() {
      try {
        const response = await API.teacher.GetStudents(this.user._id);
        this.students = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    showPreviousStep() {
      if (this.stepToShow.stepNumber >= 2) {
        this.stepToShow = this.project.steps[this.stepToShow.stepNumber - 2];
      }
    },
    showNextStep() {
      if (this.stepToShow.stepNumber < this.project.steps.length) {
        this.stepToShow = this.project.steps[this.stepToShow.stepNumber];
      }
    },
    openSupportinMaterial(link) {
      window.open(link, "_blank");
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },
    isProjectAccepted() {
      for (let index = 0; index < this.myProjects?.length; index++) {
        if (
          this.project?._id &&
          this.myProjects[index]?.project._id == this.project?._id
        ) {
          return true;
        }
      }
      return false;
    },
    grabProject() {
      this.err = "";
      let data = {
        student: this.user._id,
        teacher: this.user.teacher,
        project: this.project._id,
      };
      API.studentProjects
        .acceptProject(data)
        .then((res) => {
          this.$router.push({
            path: "/see-student-project",
            query: { project: this.project._id },
          });
        })
        .catch((err) => (this.err = "You already accepted this project"));
    },
    editProject(project) {
      this.$router.push({
        path: "/create-projects",
        query: { project: project._id },
      });
    },
    deleteProjectApi(id) {
      if (this.user.role === "admin") {
        this.deleteDefaultProjects({ id, that: this });
        this.$router.push("/projects");
      } else if (this.user.role === "teacher") {
        this.deleteProject({ id, that: this });
        this.$router.push("/projects");
      }
    },
    openConfirmationModal(project = {}) {
      this.$refs.confirmModal.message =
        "Are you sure you want to delete this? " + project.name || "";
      this.$refs.confirmModal.assetId = project._id || "";
      $("#confirmationModal").modal("show");
    },
    goBack(project) {
      this.$router.go(-1);
    },
    async addProject(project) {
      await API.projects.AddDefaultProjects([
        { ...project, status: "published" },
      ]);
      this.$toast.success(`Project added successfully`, {
        position: "top",
      });
      this.$router.go(-1);
    },
  },
  components: {
    confirmationModal,
    imageModal,
    ModalAssignStudent,
    modalPdf,
    ProjectSummary,
    StepInfo,
    BaseIcon,
    ArrowRight,
    ArrowLeft,
    ActionButton,
  },
  mounted() {
    this.loading = true;
    this.checkDeviceType();
    if (this.user.role) {
      this.getStudents();
    }
    if (this.$route.params.id) {
      let projectId = this.$route.params.id;
      if (this.$route.params.default) {
        this.defaultProject = true;
        API.defaultProjects
          .getDefaultProject(projectId)
          .then((res) => {
            this.project = res.data;
            this.stepToShow = this.project.steps[0];
            this.loading = false;
          })
          .catch((err) => console.log(err));
      } else {
        API.projects
          .getProject(projectId)
          .then((res) => {
            this.project = res.data;
            this.stepToShow = this.project.steps[0];
            this.loading = false;
          })
          .catch((err) => console.log(err));
      }
    }
    if (this.user.role === "student") {
      API.studentProjects
        .getMyProjects(this.user._id)
        .then((res) => {
          this.myProjects = res.data;
        })
        .catch((err) => console.log(err));
    }
  },
};
</script>
<style scoped>
h1 {
  font-size: 36px;
}

#description {
  font-size: 26px;
}
.see-supporting-material-button {
  font-size: 1.5rem !important;
}
.sticky-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  height: 120px;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  background: #fff;
  margin-top: 40px;
  z-index: 1;
}

.create-account {
  background-color: #004aad !important;
  font-size: 25px !important;
  border: #004aad !important;
  max-width: none !important;
  padding: 5px 20px !important;
}

@media (max-width: 768px) {
  .sticky-bar {
    height: 140px;
  }

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}
</style>
