<template>
  <div class="h-25 d-flex justify-content-center">
    <h3 class="mt-5 text-blue f-bold">Welcome!</h3>
  </div>
  <h5 class="text-blue f-medium">
    Choose how you want to start to create your new account:
  </h5>

  <div class="modal-body gap-5">
    <div class="block">
      <BaseIcon width="38.071" height="38.209" :iconColor="'#ff1616'">
        <PeopleIcon />
      </BaseIcon>
      <ActionButton
        @click="selectedRole('teacher')"
        color="red"
        text="I am a Teacher"
        class="px-5 py-4"
        fontsize="text-18-px"
      />
    </div>
    <div class="block">
      <BaseIcon width="33.684" height="40.166" iconColor="#004aad"> <StudentIcon /> </BaseIcon>

      <ActionButton
        @click="selectedRole('student')"
        color="blue"
        text="I am a Student"
        class="px-5 py-4"
        fontsize="text-18-px"        
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PeopleIcon from "../assets/icons/people.vue";
import StudentIcon from "../assets/icons/student.vue";
import ActionButton from "../components/actionButton.vue";
import BaseIcon from "../components/baseIcon.vue";

export default {
  components: {
    PeopleIcon,
    StudentIcon,
    BaseIcon,
    ActionButton,
  },
  methods: {
    ...mapActions("signUp", ["setRole"]),
    selectedRole(role) {
      if (role === "student") {
        this.setRole("student");
        this.$router.push("/signup");
      } else if (role === "teacher") {
        this.setRole("teacher");
        this.$router.push("/signup");
      }
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 36px;
}
h5 {
  font-size: 22px;
  text-align: center;
}

svg {
  width: 95px;
  height: 95px;
}

button h2 {
  font-size: 13px;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
}

@media (max-width: 1400px) {
  svg {
    height: 85px;
    width: 85px;
  }
}

@media (max-width: 992px){
  .modal-body{
    flex-direction: column;
  }

  svg {
  width: 90px;
  height: 90px;
}
}
</style>
