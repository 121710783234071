<template>
  <div
    class="modal fade"
    id="modalRecovery"
    tabindex="-1"
    aria-labelledby="modalSignUpLabel"
    aria-hidden="true"
  >
    <div iv class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <h5 class="modal-title" style="margin: auto">Password recovery</h5>
            <h6>You will receive instructions to reset your password</h6>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="position: absolute; right: 20px"
            @click="$"
          ></button>
        </div>
        <div class="modal-body">
          <p v-if="successMessage">{{ successMessage }}</p>
          <div
            class="d-flex flex-column align-items-center"
            v-if="!successMessage && !isStudent"
          >
            <label class="formFile" :for="`input_users`">
              Insert your email
            </label>

            <BaseInput
              class="px-3 py-3"
              type="text"
              :id="`input_users`"
              :border="true"
              v-model="email"
            />
            <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
            <div v-if="!errorMessage" class="freespace"></div>
            <a href="#" @click="isStudent = true"> I'm a student </a>
          </div>
          <div v-if="isStudent">
            <h3>Please contact with your teacher to reset your password</h3>
          </div>
        </div>
        <div class="modal-footer">
          <ActionButton
            type="button"
            data-bs-dismiss="modal"
            :color="`red`"
            class="px-3 py-4"
            :text="`${!isSuccess && !isStudent ? 'Cancel' : 'Close'}`"
          />
          <ActionButton
            v-if="!isSuccess && !isStudent"
            type="button"
            :color="`blue`"
            class="px-3 py-4"
            :text="`Confirm`"
            @click="sendMail()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ActionButton from "./actionButton.vue";
import BaseInput from "./input.vue";

export default {
  name: "modalRecovery",
  props: ["user", "toggle"],
  data() {
    return {
      email: "",
      isSuccess: null,
      successMessage: "",
      errorMessage: "",
      isStudent: false,
    };
  },
  components: { ActionButton, BaseInput },
  methods: {
    ...mapActions("accountRecovery", ["sendRecoveryMail"]),
    async sendMail() {
      const email = {
        email: this.email,
      };
      try {
        const response = await this.sendRecoveryMail({ data: email });
        this.isSuccess = response.success;
        this.successMessage =
          "Check your email and follow the steps to reset your password";
        this.errorMessage = "";
      } catch (e) {
        this.errorMessage = "Please enter a valid email";
        console.error(e);
      }
    },
  },
  created() {
    this.email = this.user;
  },
};
</script>

<style scoped>
a {
  color: #707070 !important;
  font-size: 14px;
}
input {
  width: 300px;
}

p {
  font-size: 1.3rem;
}
h6 {
  font-size: 1.3rem;
}

label {
  font-size: 1.3rem;
  text-align: center;
}
.label-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  font-family: "Quicksand", sans-serif;
  margin: 0;
  padding: 0;
}

.text-danger {
  margin-top: 5px;
  margin-bottom: 0px;
}

.freespace {
  height: 24.5px;
}
</style>
