import { createStore } from "vuex";
import tools from './tools/index'
import objectives from './objectives/index'
import user from './user/index'
import badges from './badges/index'
import project from './project/index'
import projects from './projects/index'
import teachers from './teachers/index'
import students from './students/index'
import signUp from './signUp/index'
import configs from './configs/index'
import accountRecovery from './accountRecovery/index'
import notifications from "./notifications/index"
import studentProjects from "./student-projects/index"

export const store = createStore({
  modules: {
    tools,
    objectives,
    user,
    badges,
    project,
    projects,
    teachers,
    students,
    signUp,
    configs,
    accountRecovery,
    notifications,
    studentProjects
  }
})
