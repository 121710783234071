<template>
  <path
    id="Path_185"
    data-name="Path 185"
    d="M34.276,89.02A17.138,17.138,0,1,1,17.156,71.887,17.161,17.161,0,0,1,34.276,89.02M4.532,89.006a12.609,12.609,0,1,0,12.607-12.59A12.642,12.642,0,0,0,4.532,89.006"
    transform="translate(0 -63.227)"
    fill="#FFFFFF"
  />
  <path
    id="Path_186"
    data-name="Path 186"
    d="M81.074,2.778V6.662a20.232,20.232,0,0,0-10.634.01V2.706c-.747,0-1.462,0-2.177,0a2.507,2.507,0,0,1-.624-.051,1.343,1.343,0,0,1-.994-1.385A1.382,1.382,0,0,1,67.861.013,3.569,3.569,0,0,1,68.311,0H83.186a3.591,3.591,0,0,1,.45.012,1.37,1.37,0,0,1,1.218,1.432A1.341,1.341,0,0,1,83.511,2.7c-.781.016-1.562.012-2.343.018-.012,0-.025.014-.094.056"
    transform="translate(-58.614 0)"
    fill="#FFFFFF"
  />
  <path
    id="Path_187"
    data-name="Path 187"
    d="M6.855,75.465c-.574-.576-1.086-1.059-1.562-1.575a1.346,1.346,0,0,1,.021-1.837,1.318,1.318,0,0,1,1.828-.1c.55.5,1.045,1.066,1.488,1.523L6.855,75.465"
    transform="translate(-4.346 -63.001)"
    fill="#FFFFFF"
  />
  <path
    id="Path_188"
    data-name="Path 188"
    d="M249.634,74.68,247.8,72.958a20.544,20.544,0,0,1,1.609-1.724,1.248,1.248,0,0,1,1.711.209,1.292,1.292,0,0,1,.058,1.732c-.477.535-1.026,1.006-1.545,1.506"
    transform="translate(-217.948 -62.429)"
    fill="#FFFFFF"
  />
  <path
    id="Path_189"
    data-name="Path 189"
    d="M69.988,151.734a9.893,9.893,0,1,1,9.9-9.89,9.934,9.934,0,0,1-9.9,9.89m-1.5-9.231c-.369-.388-.691-.744-1.031-1.082a1.374,1.374,0,0,0-2-.1,1.351,1.351,0,0,0,.084,2c.6.609,1.211,1.209,1.816,1.815a1.4,1.4,0,0,0,2.291.021q1.979-1.97,3.951-3.947c.329-.329.674-.644.981-.993a1.334,1.334,0,0,0-.623-2.2,1.371,1.371,0,0,0-1.426.434c-1.32,1.332-2.646,2.659-4.039,4.056"
    transform="translate(-52.85 -116.053)"
    fill="#FFFFFF"
  />
</template>

<script>
export default {};
</script>
