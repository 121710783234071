<template>
	<div>
		<defaultToolsList v-if="config.tools" :tools="tools" />
		<p class="text-danger w-100 text-center" v-if="errorToolsAPI">
      {{ errorToolsAPI }}
    </p>
	</div>
</template>
<script>
import defaultToolsList from '../components/toolsList'
import { mapActions, mapState} from 'vuex'

export default {
	name:"tools",
	components:{
		defaultToolsList
	},
  data() {
    return {
    };
  },
  methods: {
		...mapActions('tools', ['getTools']),
	},
	computed:{
		...mapState('tools',['tools', 'errorToolsAPI']),
		...mapState('configs',['config'])
	},
	created(){
		this.getTools()
	},
};
</script>