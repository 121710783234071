<template>
  <div
    class="modal fade"
    id="multipleChoiceStudentModal"
    tabindex="-1"
    aria-labelledby="multipleChoiceStudentModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
    v-bind:key="step._id"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-white">
          <h5 class="modal-title" id="multipleChoiceStudentModalLabel">
            Multiple choice
          </h5>
        </div>
        <div
          class="div_modal_form modal-body d-flex flex-column text-blue gap-3 p-5"
        >
          <div class="h1 text-white">
            Question:
            {{ this.stepNEW.multipleChoice?.question }}
          </div>
          <div
            @click="answerQuestion(this.stepNEW.multipleChoice.answer1, 1)"
            :class="`h2 d-flex justify-content-between answer ${
              correctAnswer == 1 && isSolved ? 'correct' : ''
            } ${
              studentPick?.answerNumber === 1 && isSolved
                ? this.step.multipleChoice?.answer1.isCorrect
                  ? ''
                  : 'incorrect'
                : ''
            }`"
          >
            <div>
              1:
              {{ this.stepNEW.multipleChoice?.answer1.answer }}
            </div>
            <div class="d-flex">
              <Transition name="slide-fade"> </Transition>
              <Transition name="slide-fade">
                <div
                  v-show="
                    this.stepNEW.multipleChoice?.answer1.isCorrect &&
                    this.isSolved
                  "
                >
                  <BaseIcon width="40.01" height="29.095" iconColor="#FFFFFF">
                    <TickIcon />
                  </BaseIcon>
                </div>
              </Transition>
              <Transition name="slide-fade">
                <div
                  v-show="
                    !this.stepNEW.multipleChoice?.answer1.isCorrect &&
                    this.isSolved
                  "
                >
                  <BaseIcon width="13.616" height="13.51" iconColor="#ffffff">
                    <CrossIcon />
                  </BaseIcon>
                </div>
              </Transition>
            </div>
          </div>
          <div
            @click="answerQuestion(this.stepNEW.multipleChoice.answer2, 2)"
            :class="`h2 d-flex justify-content-between answer ${
              correctAnswer == 2 && isSolved ? 'correct' : ''
            } ${
              studentPick?.answerNumber === 2 && isSolved
                ? this.step.multipleChoice?.answer2.isCorrect
                  ? ''
                  : 'incorrect'
                : ''
            }`"
          >
            <div>
              2:
              {{ this.stepNEW.multipleChoice?.answer2.answer }}
            </div>
            <div class="d-flex">
              <Transition name="slide-fade"> </Transition>
              <Transition name="slide-fade">
                <div
                  v-show="
                    this.stepNEW.multipleChoice?.answer2.isCorrect &&
                    this.isSolved
                  "
                >
                  <BaseIcon width="40.01" height="29.095" iconColor="#FFFFFF">
                    <TickIcon />
                  </BaseIcon>
                </div>
              </Transition>
              <Transition name="slide-fade">
                <div
                  v-show="
                    !this.stepNEW.multipleChoice?.answer2.isCorrect &&
                    this.isSolved
                  "
                >
                  <BaseIcon width="13.616" height="13.51" iconColor="#ffffff">
                    <CrossIcon />
                  </BaseIcon>
                </div>
              </Transition>
            </div>
          </div>
          <div
            @click="answerQuestion(this.stepNEW.multipleChoice.answer3, 3)"
            :class="`h2 d-flex justify-content-between answer ${
              correctAnswer == 3 && isSolved ? 'correct' : ''
            } ${
              studentPick?.answerNumber === 3 && isSolved
                ? this.step.multipleChoice?.answer3.isCorrect
                  ? ''
                  : 'incorrect'
                : ''
            }`"
          >
            <div>
              3:
              {{ this.stepNEW.multipleChoice?.answer3.answer }}
            </div>
            <div class="d-flex">
              <Transition name="slide-fade"> </Transition>
              <Transition name="slide-fade">
                <div
                  v-show="
                    this.stepNEW.multipleChoice?.answer3.isCorrect &&
                    this.isSolved
                  "
                >
                  <BaseIcon width="40.01" height="29.095" iconColor="#FFFFFF">
                    <TickIcon />
                  </BaseIcon>
                </div>
              </Transition>
              <Transition name="slide-fade">
                <div
                  v-show="
                    !this.stepNEW.multipleChoice?.answer3.isCorrect &&
                    this.isSolved
                  "
                >
                  <BaseIcon width="13.616" height="13.51" iconColor="#ffffff">
                    <CrossIcon />
                  </BaseIcon>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "../services/api";
import _ from "lodash";
import { mapActions } from "vuex";
import BaseIcon from "./baseIcon.vue";
import CrossIcon from "../assets/icons/cross.vue";
import TickIcon from "../assets/icons/tick.vue";
import { wait } from "../utils";
export default {
  data() {
    return {
      data: "",
      question: "",
      questionAnswered: false,
      studentPick: {
        answer: 0,
        isCorrect: false,
        answerNumber: 3,
      },
      correctAnswer: 0,
      isSolved: false,
    };
  },
  props: ["step", "user", "project", "sendData", "stepNEW"],
  components: {
    BaseIcon,
    CrossIcon,
    TickIcon,
  },
  methods: {
    closeModal() {
      this.$emit("getproject");
      this.isSolved = false;
      $("#multipleChoiceStudentModal").modal("hide");
    },
    async answerQuestion(answerChosen, value) {
      if (this.stepNEW.multipleChoice && !this.isSolved) {
        this.studentPick = answerChosen;
        this.studentPick.answerNumber = value;
        for (
          let i = 0;
          i < Object.values(this.stepNEW.multipleChoice).length;
          i++
        ) {
          if (Object.values(this.stepNEW.multipleChoice)[i].isCorrect) {
            this.correctAnswer = i += 1;
          }
        }
        let data;
        if (this.stepNEW.status === "Rejected") {
          data = {
            stepId: this.stepNEW._id,
            status: this.stepNEW.status,
          };
        } else {
          data = {
            studentPick: this.studentPick,
            isSolved: true,
            stepId: this.stepNEW._id,
            needApproval: this.stepNEW.needApproval,
            correctAnswer: this.correctAnswer,
          };
        }
        await API.studentProjects.completedStep(
          this.user._id,
          this.project._id,
          data
        );
        this.isSolved = true;
        await wait(1500);
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
svg {
  width: 15px;
}

.modal-content {
  background-color: var(--capable-blue);
}
.answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: var(--capable-blue);
  border-radius: 8px;
  height: 35px;
  padding: 0px 10px;
  font-size: 18px;
  cursor: pointer;
}

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-25px);
  opacity: 0;
}

.correct-message {
  color: green;
}

.wrong-answer {
  color: red;
}

.correct {
  background-color: var(--capable-green);
}

.incorrect {
  background-color: var(--capable-red);
}
</style>
