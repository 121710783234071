<template>
  <div>
    <objectivesList v-if="config.objectives" :objectives="objectives"/>
    <p class="text-danger w-100 text-center" v-if="errorObjectivesAPI">
      {{ errorObjectivesAPI }}
    </p>
  </div>

</template>
<script>
import objectivesList from '../components/objectivesList'
import { mapActions, mapState} from 'vuex'


export default {
  data(){
    return{
    }
  },
  components:{
    objectivesList,
  },
  computed:{
    ...mapState('objectives',['objectives', 'errorObjectivesAPI']),
    ...mapState('configs',['config'])
  },
  methods:{
    ...mapActions('objectives', ['getObjectives'])
  },
  created(){
    this.getObjectives()
  }
}
</script>