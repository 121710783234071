<template>
  <div class="progress-bar">
    <section
      :class="`${percentage == 100 ? 'blue' : percentage > 0 ? 'red' : 'grey'}`"
      :style="`width:${percentage > 33 ? percentage : '33'}%`"
    >
      <p class="text-white f-bold ml-3">{{ `${percentage}%` }}</p>
    </section>
  </div>
</template>

<script>
export default {
  props: ["percentage"],
};
</script>

<style scoped>
p {
  font-size: 20px;
  margin-bottom: 0px;
}
.progress-bar {
  display: flex;
  background-color: transparent;
}
.progress-bar section {
  display: flex;
  align-items: center;
  height: 54px;
  border-radius: 11px;
}

.blue {
  background-color: var(--capable-blue);
}

.red {
  background-color: var(--capable-red);
}

.grey {
  background-color: var(--capable-grey);
}

@media (max-width: 768px) {
  p {
  font-size: 16px;
  margin-bottom: 0px;
}
  .progress-bar section {
  height: 35px;
}
}
</style>
