<template>
  <div class="div_home">
    <div class="background-img">
      <div class="background-filter d-flex align-items-center justify-content-center gap-5">
        <div class="d-flex flex-column justify-content-around align-items-center h-75">
          <div class="light-bulb d-flex flex-column align-items-center gap-5">
            <baseIcon width="130.333" height="166.449">
              <lightBulbBox />
            </baseIcon>
            <h3 class="text-gotham mb-5">Projects</h3>
          </div>
          <div class="find-project-section d-flex flex-column align-items-center gap-5">
            <h3 class="find-project-text">
              Find the perfect project for teachers and students
            </h3>
            <div class="filter d-flex gap-3 search-form-container">
              <baseInput
              v-model="projectName"
              placeholder="Search by project"
              :class="['px-4', 'py-4', `landing-input`, 'font-18px', 'lg-font-16px']"
                >
                <template #rightSlot>
                  <div class="rightSlot">
                    <i v-if="projectName.length" class="icon-remove" @click="cancelSearch"></i>
                  </div>
                </template>
              </baseInput>
              <actionButton :color="'red'" :class="[`${isMobileDevice ? 'p-3' : 'px-5 py-4'} magnifier-icon-container`]"
                @click="searchProjectsAndScroll" @mouseenter="toggleMagnifier = true"
                @mouseleave="toggleMagnifier = false">
                <template #rightIcon>
                  <baseIcon width="23.187" height="22.871" :iconColor="toggleMagnifier ? '#ff1616' : '#ffffff'">
                    <magnifierIcon class="magnifier-icon" />
                  </baseIcon>
                </template>
              </actionButton>
            </div>
            <actionButton :fontsize="'text-18-px'" color="blue"
              :class="`${isMobileDevice ? 'py-3 px-4' : 'py-4 px-4'} advanced-filter-container`"
              :text="'Advanced Filter'" @mouseenter="toggleSettings = true" @mouseleave="toggleSettings = false"
              data-toggle="modal" data-target="#advancedFilter">
              <template #leftIcon>
                <baseIcon width="38.888" height="35.327" class="mr-3" :iconColor="toggleSettings ? '#004aad' : '#ffffff'">
                  <SettingsWhite />
                </baseIcon>
              </template>
            </actionButton>
          </div>
          <h3 class="find-project-text mt-5">
            or explore the projects by yourself
          </h3>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center" v-if="projectsList.length">
      <div class="box">
        <div class="grid d-flex flex-wrap gap-5 justify-content-center align-items-center" ref="projectsgrid">
          <projectCard v-for="(project, index) in projectsList" :user="user" :project="project" v-bind:key="index"
            cardType="BaseCard" :flip="true" />
        </div>
      </div>
      <h3 v-if="!viewAllToggle && showButtonToggle" class="view-all" @click="viewAll">
        View all
      </h3>
      <h3 v-else-if="showButtonToggle" class="view-all" @click="viewLess">
        View less
      </h3>
    </div>
    <h3 v-else class="text-blue f-semibold my-5 text-center">
      No projects found.
    </h3>

    <div class="blowtorch-img">
      <div class="background-filter d-flex flex-column align-items-center justify-content-around">
        <div class="why-capable">
          <h2>Why working with <span> Capable Maker </span> projects?</h2>
        </div>
        <div class="d-flex flex-wrap justify-content-around w-100">
          <div class="d-flex col-md-4 flex-column align-items-center">
            <baseIcon width="34.277" height="42.92">
              <clockWhite />
            </baseIcon>
            <h3>Just-In-Time Instruction</h3>
            <p>
              Increases active learning, engagement, and retention by giving
              each individual student the right information at the right time.
              No more, no less.
            </p>
          </div>
          <div class="d-flex col-md-4 flex-column align-items-center">
            <baseIcon width="38.705" height="38.697">
              <peopleThinkingWhite />
            </baseIcon>
            <h3>Differentiation</h3>
            <p>
              Relax as students move at their own pace, and still receive all
              information necessary. Assign different projects to different
              students with ease.
            </p>
          </div>
          <div class="d-flex col-md-4 flex-column align-items-center">
            <baseIcon width="32.781" height="40.684">
              <checkListWhite />
            </baseIcon>
            <h3>Optimize Preparation Time</h3>
            <p>
              No-fuss compilation of project steps, and make updates with ease.
              All of which is distributed to students clearly, and in a
              comprehensive manner.
            </p>
          </div>
        </div>
      </div>
    </div>
    <ContactUs />
    <AdvancedFilterModal :projects="projects" @search="handleSearch" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import actionButton from "../components/actionButton.vue";
import baseInput from "../components/input.vue";
import baseIcon from "../components/baseIcon.vue";
import lightBulbBox from "../assets/icons/lightBulbBox.vue";
import magnifierIcon from "../assets/icons/magnifier.vue";
import clockWhite from "../assets/icons/clockWhite.vue";
import peopleThinkingWhite from "../assets/icons/peopleThinkingWhite.vue";
import checkListWhite from "../assets/icons/checkListWhite.vue";
import ContactUs from "../components/contactUs.vue";
import SettingsWhite from "../assets/icons/settingsWhite.vue";
import projectCard from "../components/ProjectCard/projectCard.vue";
import AdvancedFilterModal from "../components/Modals/advancedFilter.vue";

export default {
  data() {
    return {
      projectsList: [],
      projectName: "",
      isMobileDevice: null,
      viewAllToggle: false,
      toggleMagnifier: false,
      toggleSettings: false,
      showButtonToggle: true,
      allProjects: [],
    };
  },
  components: {
    actionButton,
    baseInput,
    projectCard,
    baseIcon,
    lightBulbBox,
    magnifierIcon,
    clockWhite,
    peopleThinkingWhite,
    checkListWhite,
    ContactUs,
    SettingsWhite,
    AdvancedFilterModal,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("projects", ["projects"]),
    ...mapState("tools", ["tools"]),
  },
  methods: {
    ...mapActions("projects", ["getDefaultProjects"]),
    ...mapActions("tools", ["getDefaultTools"]),
    async handleSearch(data) {
      await this.getDefaultProjects(data);
      this.projectsList = this.projects;
    },
    previewProject(projectId) {
      this.$router.push({ path: `/project/${projectId}/default` });
    },
    viewAll() {
      this.viewAllToggle = true;
      return (this.projectsList = this.projects);
    },
    viewLess() {
      this.setInitialProjects();
      this.scrollToGrid();
      this.viewAllToggle = false;
    },
    setInitialProjects() {
      if (this.isMobileDevice) {
        if (this.projects.length <= 4) {
          this.showButtonToggle = false;
        }
        this.projectsList = this.projects.slice(0, 4);
      } else {
        if (this.projects.length <= 12) {
          this.showButtonToggle = false;
        }
        this.projectsList = this.projects.slice(0, 12);
      }
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },
    searchProjectsAndScroll() {
      this.searchProjects();
      this.scrollToGrid();
    },
    searchProjects() {
      this.projectsList = this.projects.filter((project) => {
        return project.name
          .toLowerCase()
          .includes(this.projectName.toLowerCase());
      });
      this.viewAllToggle = false;
    },
    scrollToGrid() {
      const projectsgrid = this.$refs.projectsgrid;
      projectsgrid.scrollIntoView({ behavior: "smooth" });
    },
    cancelSearch() {
      this.projectName = "";
      this.setInitialProjects();
    },
  },
  async created() {
    this.checkDeviceType();
    await this.getDefaultProjects();

    this.setInitialProjects();
    await this.getDefaultTools();
  },
  watch: {
    projects(newTool, old) {
      console.log("Projects: ", { newTool, old })
    },
    projectsList(newTool, old) {
      console.log("Projects List: ", { newTool, old })
    },
  },

};
</script>

<style scoped>
section {
  max-width: 500px;
}

ul {
  padding-left: 15px;
}

li {
  font-size: 16px;
}

button svg {
  width: 30px;
}


.advanced-filter-container svg {
  width: 25px;
}

.blowtorch-img svg {
  width: 125px;
  height: 125px;
}

.blowtorch-img h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: var(--capable-white);
  margin: 20px 0 10px 0;
}

.blowtorch-img p {
  font-size: 16px;
  font-weight: 300;
  color: var(--capable-white);
  text-align: center;
  padding: 0 75px;
}

.disappear {
  animation-duration: 0.5s;
  animation-name: disappear;
  animation-fill-mode: forwards;
}

.icon-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #00000033;
}

.light-bulb {
  margin-top: 55px;
}

.light-bulb svg {
  width: 99.125px;
  height: 125px;
}

.magnifier-icon-container {
  padding: 0.75px 1px;
}

.magnifier-icon-container svg {
  width: 21.5px;
}


.text-gotham {
  color: var(--capable-white);
  font-family: "Gotham-Rounded-Bold";
  /* font-size: 67.241px; */
  font-size: 60px;
  font-weight: 100;
}

.find-project-text {
  margin-bottom: 20px;
  color: var(--capable-white);
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}


@keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.appear {
  animation-duration: 0.5s;
  animation-name: appear;
  animation-fill-mode: forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.div_home {
  display: flex;
  justify-content: center;
  background-color: var(--capable-light-orange) !important;
}


.background-filter {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

/* .background-img .background-filter {
    padding: 100px;
  } */


.view-all {
  font-size: 26px;
  color: var(--capable-blue);
  cursor: pointer;
}

.view-all:hover {
  text-decoration: underline;
}

.find-project-section {
  padding-bottom: 70px;
}

.box {
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  min-height: 40vh;
  margin-top: -110px;
  max-width: 1920px;
}

.background-img {
  position: relative;
  /* width: 100%; */
  height: calc(100vh - 70px);
  /* 70px -> navbar height */
  background-image: url("../assets/img/father-and-son-building-birdhouse.jpg");
  background-repeat: no-repeat;
  background-position: 25% 60%;
  background-size: cover;
  background-position: center center;
  opacity: 1;
}

.blowtorch-img {
  position: relative;
  height: 100vh;
  background-image: url("../assets/img/blowtorch.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 1;
}

.why-capable h2 {
  color: var(--capable-white);
  font-size: 34px;
}

.why-capable span {
  font-weight: 800;
}

.rightSlot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
}

.warehouse-images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 200px;
  width: 200px;
}

.warehouse-images {
  justify-content: center;
  border-radius: 8px;
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.warehouse-project-container {
  max-height: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 250px;
  cursor: pointer;
  border-radius: 8px;
  transition: 150ms all;
}

.warehouse-project-container:hover {
  background-color: #f3f4f4;
}

.image-project-table {
  margin-top: 45px;
}

.advantage-box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.warehouse-project-container {
  max-width: 100% !important;
  margin-bottom: 10px;
}

@media (max-width: 1400px) {

  .advanced-filter-container {
    margin-bottom: 30px;
  }

  .find-project-text {
    font-size: 24px;
  }

  .light-bulb svg {
    width: 85px;
    height: 107.174px;

  }

  .text-gotham {
    font-size: 50px;
  }
}


@media (max-width: 1200px) {
  h3 {
    font-size: 20px;
  }


  .blowtorch-img p {
    padding: 0 40px;
  }

  .background-img {
    width: 100%;
    height: 100vh;
    background-size: auto;
  }



  .blowtorch-img svg {
    height: 80px;
  }

  .blowtorch-img h2 {
    font-size: 32px;
  }

  .blowtorch-img h3 {
    font-size: 28px;
  }

}

@media (max-width: 1024px) {
  section {
    min-width: 58vw;
  }

  h2 {
    text-align: center;
  }

  .box {
    text-align: start;
  }

  .advantages {
    flex-direction: column;
    width: 75vw;
  }

  .box:nth-child(odd) {
    flex-direction: column;
  }

  .box:nth-child(even) {
    flex-direction: column-reverse;
  }

  .project-documentation-img {
    width: 65;
    height: 30vh;
  }

  .project-distribution-img {
    width: 60vw;
    height: 40vh;
  }

  .project-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .advantage-box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 0%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 36px;
    text-align: center;
  }

  h3 {
    font-size: 16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  li {
    max-width: 350px;
  }

  .light-bulb svg {
    width: 115px;
    height: 145px;
  }

  button svg {
    width: 20px;
  }

  .text-gotham {
    font-size: 54px;
    margin: 0px !important;
  }

  .find-project-text {
    font-size: 18px;
    max-width: 234px;
    line-height: 25px;
  }

  .box {
    margin-top: -40px;
  }

  .educational-advantages h2 {
    text-align: center;
  }

  .educational-advantages {
    height: auto;
  }

  .project-documentation-img {
    width: 330px;
    height: 240px;
  }

  .project-distribution-img {
    width: 330px;
    height: 280px;
  }

  .educational-advantages-img {
    width: 330px;
    height: 240px;
  }

  .warehouse-images {
    width: 150px;
    height: 150px;
  }

  .warehouse-images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
  }

  .background-img {
    position: relative;
    width: 100%;
    height: 115vh;
    background-position: 38% 62%;
    opacity: 1;
  }

  .image-project-table {
    margin-top: 65px;
  }

  .blowtorch-img svg {
    height: 60px;
  }

  .blowtorch-img {
    height: auto;
    background-position: 65% 35%;
  }

  .blowtorch-img h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    max-width: 260px;
    margin: 60px 0px;
  }

  .blowtorch-img h3 {
    font-size: 16px;
    font-weight: 700;
  }

  .blowtorch-img p {
    font-size: 14px;
    font-weight: 400;
    max-width: 300px;
    margin-bottom: 60px;
  }
}

@media (max-width: 550px) {
  .background-img {
    height: 100vh;
  }

  .text-container {
    max-width: 150px;
  }

  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .image-project-table {
    margin-top: 70px;
  }

  .blowtorch-img h3 {
    margin: 10px 0 5px;
  }

}

@media (max-height: 1024px) {
  .educational-advantages {
    height: auto;
  }
}
</style>
