const defaultObjectives = axios => {
	return {
		async CreateNewDefaultObjective(data){
			return axios.post("/api/default-objectives", data)
		},
		async getDefaultObjectives(){
			return axios.get(`/api/default-objectives`)
		},
	  async deleteDefaultObjective(id){
			return axios.delete(`/api/default-objectives/${id}`)
		},
    async updateDefaultObjective(id,data){
      return axios.put(`/api/default-objectives/${id}`, data)
		}
	}
}
export default defaultObjectives;