<script>
// @ts-check
import { createWebHistory, createRouter } from "vue-router";
import VueRouterMultiguard from "vue-router-multiguard";
import home from "./views/home.vue";
import signup from "./views/signup.vue";
import login from "./views/login.vue";
import teacher from "./views/teacher.vue";
import student from "./views/student.vue";
import studentProjects from "./views/student-projects.vue";
import tools from "./views/tools.vue";
import objectives from "./views/objectives.vue";
import projects from "./views/projects.vue";
import badges from "./views/badges.vue";
import projectSearch from "./views/projectSearch.vue";
import admin from "./views/admin.vue";
import defaultTools from "./views/defaultTools.vue";
import defaultObjectives from "./views/defaultObjectives.vue";
import defaultBadges from "./views/defaultBadges.vue";
import createProjects from "./views/createProjects.vue";
import defaultProjects from "./views/defaultProjects.vue";
import seeProject from "./views/seeProject.vue";
import seeStudentProject from "./views/seeStudentProject.vue";
import seeStudentProjectTeacher from "./views/seeStudentProjectTeacher.vue";
import {
  isLogin,
  isTeacher,
  isStudent,
  isAdmin,
  isTeacherOrAdmin,
} from "./services/middlewares";
import profile from "./views/profile.vue";
import Roadmap from "./views/roadmap.vue";
import AdminIdeasBoard from "./views/admin-ideas-board.vue";
import addNewBadge from "./views/addNewBadge.vue";
import studentRoadmap from "./views/studentRoadmap.vue";
import configsList from "./views/configsList.vue";
import passwordRecovery from "./views/passwordRecovery.vue";
import projectDrafts from "./views/project-drafts.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: home,
  },
  {
    path: "/account-recovery",
    name: "Account recovery",
    component: passwordRecovery,
    meta: { hideFromNavbar: true },
  },
  {
    path: "/new-projects",
    name: "Create a project",
    component: createProjects,
  },
  {
    path: "/login",
    name: "Log In",
    component: login,
    meta: { isButton: true },
  },
  {
    path: "/signup",
    name: "Create Account",
    component: signup,
    meta: { isButton: true },
  },
  {
    path: "/project-drafts",
    name: "Drafts",
    component: projectDrafts,
    meta: { routeBelongsTo: "teacher", projectDropdown: true },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/projects",
    name: "My Projects",
    component: projects,
    meta: { routeBelongsTo: "teacher", projectDropdown: true },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/create-projects",
    name: "Create project",
    component: createProjects,
    meta: { routeBelongsTo: "teacher", projectDropdown: true },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacherOrAdmin]),
  },
  {
    path: "/add-new-badge",
    name: "",
    component: addNewBadge,
    meta: { routeBelongsTo: "teacher" },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacherOrAdmin]),
  },
  {
    path: "/project/:id",
    name: "See project",
    component: seeProject,
    meta: { hideFromNavbar: true },
    beforeEnter: VueRouterMultiguard([isLogin]),
  },
  {
    path: "/project/:id/:default",
    name: "See default project",
    component: seeProject,
    meta: { hideFromNavbar: true },
  },
  {
    path: "/see-student-project",
    component: seeStudentProject,
    beforeEnter: VueRouterMultiguard([isLogin]),
  },
  {
    path: "/teacher/see-student-project",
    component: seeStudentProjectTeacher,
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/dashboard-teacher",
    name: "Dashboard",
    component: teacher,
    meta: { routeBelongsTo: "teacher" },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/project-warehouse",
    name: "Project Warehouse",
    component: projectSearch,
    meta: { routeBelongsTo: "teacher", projectDropdown: true },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/student",
    name: "Student Dashboard",
    component: student,
    meta: { routeBelongsTo: "student" },
    beforeEnter: VueRouterMultiguard([isLogin, isStudent]),
  },
  {
    path: "/student-projects",
    name: "Student Projects",
    component: studentProjects,
    meta: { routeBelongsTo: "student" },
    beforeEnter: VueRouterMultiguard([isLogin, isStudent]),
  },
  {
    path: "/profile",
    name: "Profile",
    component: profile,
    meta: { routeBelongsTo: "teacher", hideFromNavbar: true },
    beforeEnter: VueRouterMultiguard([isLogin]),
  },
  {
    path: "/tools",
    name: "Tools",
    component: tools,
    meta: { routeBelongsTo: "teacher", feature: "tools" },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/objectives",
    name: "Objectives",
    component: objectives,
    meta: { routeBelongsTo: "teacher", feature: "objectives" },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/badges",
    name: "Badges",
    component: badges,
    meta: { routeBelongsTo: "teacher", feature: "badges" },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/admin",
    name: "DashboardAdmin",
    component: admin,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/configs",
    name: "Configurations",
    component: configsList,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/defaulttools",
    name: "Default Tools",
    component: defaultTools,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/defaultobjectives",
    name: "Default Objectives",
    component: defaultObjectives,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/defaultbadges",
    name: "Default Badges",
    component: defaultBadges,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/defaultprojects",
    name: "Default Projects",
    component: defaultProjects,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Roadmap,
    meta: { routeBelongsTo: "teacher", hideFromNavbar: true },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/admin-ideas-board",
    name: "Ideas Board",
    component: AdminIdeasBoard,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    component: studentRoadmap,
    meta: { routeBelongsTo: "teacher", hideFromNavbar: true },
    beforeEnter: VueRouterMultiguard([isLogin, isTeacher]),
  },
  {
    path: "/drafts",
    name: "Project drafts",
    component: projectDrafts,
    meta: { routeBelongsTo: "admin" },
    beforeEnter: VueRouterMultiguard([isLogin, isAdmin]),
  },
];

export default routes;
</script>
