const projects = (axios) => {
  return {
    async CreateNewProjects(data) {
      return axios.post("/api/projects", data);
    },
    async createNewDraft(data) {
      return axios.post("/api/projects/draft", data);
    },
    async updateDraft(projectId, data) {
      return axios.put(`/api/projects/draft/${projectId}`, data);
    },
    async createUnautheticatedDraft(data) {
      return axios.post("/api/projects/new", data);
    },
    async updateUnautheticatedDraft(id, data) {
      return axios.put(`/api/projects/new/${id}`, data);
    },
    async updateStatusAddTeacherId(id, data) {
      return axios.put(`/api/projects/new/status/${id}`, data);
    },
    async updateProjectDeleteDraft(projectId, data) {
      return axios.put(`/api/projects/deleteDraft/${projectId}`, data);
    },
    async AddDefaultProjects(data) {
      return axios.post(`/api/projects/add-defaults`, data);
    },
    async getProjects(filters = {}) {
      return axios.get(`/api/projects?filters=${JSON.stringify(filters)}`);
    },
    async getDrafts() {
      return axios.get(`/api/projects/drafts`);
    },
    async getProject(projectId) {
      return axios.get(`/api/projects/${projectId}`);
    },
    async getProjectsByStudent(teacherId) {
      return axios.get(`/api/projects/${teacherId}/student`);
    },
    async UploadPlansPdf(data) {
      return axios.post("/api/projects/plan", data);
    },
    async UploadImageSteps(data) {
      return axios.post("/api/projects/imageStep", data);
    },
    async deleteProject(id) {
      return axios.delete(`/api/projects/${id}`);
    },
    async updateProject(id, data) {
      return axios.put(`/api/projects/${id}`, data);
    },
    async copyProject(project) {
      return axios.post(`/api/projects/copy-project`, project);
    },
    async downloadPDF(projectId) {
      return axios({
        url: `/api/projects/pdf/${projectId}`,
        method: "POST",
        responseType: "blob",
      });
    },
    async updateVisibility(id, data) {
      return axios.put(`/api/projects/visibility/${id}`, {
        hide: data,
      });
    },
    async getProjectsThemes() {
      return axios.get("/api/projects/themes");
    },
  };
};

export default projects;
