<template>
  <div>
    <badgesList
      v-if="config.badges"
      :badges="badges"
      :objectives="objectives"
    />
    <p class="text-danger w-100 text-center" v-if="errorBadgesAPI">
      {{ errorBadgesAPI }}
    </p>
  </div>
</template>
<script>
import badgesList from "../components/badgesList";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    badgesList,
  },
  computed: {
    ...mapState("badges", ["badges", "errorBadgesAPI"]),
    ...mapState("objectives", ["objectives"]),
    ...mapState('configs',['config'])
  },
  methods: {
    ...mapActions("badges", ["getBadges"]),
    ...mapActions("objectives", ["getObjectives"]),
  },
  created() {
    this.getBadges();
    this.getObjectives();
  },
};
</script>
