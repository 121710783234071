import API from "../../services/api";
import _ from 'lodash'

export default {

  sendRecoveryMail: async ({ commit }, { data }) => {
    try {
      return await API.accountRecovery.sendRecoveryMail(data);
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

  changePassword: async ({ commit }, { data }) => {
    try {
      return await API.accountRecovery.changePassword(data);
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

  changeStudentPassword: async ({ commit }, { data }) => {
    try {
      return await API.accountRecovery.changeStudentPassword(data);
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

}