<template>
  <div class="modal fade" id="viewIdea" tabindex="-1" role="dialog" aria-labelledby="viewIdeaModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >{{ ideaSelected.title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="confirmClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-description-modal"> {{ ideaSelected.description }} </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="confirmClose()">Close</button>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
export default {
  name: "modalViewIdea",
  data() {
    return {
      
    }
  },
  props: [
    "ideaSelected"
  ],
  methods:{
		confirmClose(){
			$('#viewIdea').modal('hide')
		}
	}
}
</script>