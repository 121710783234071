<template>
  <path
    id="Path_85"
    data-name="Path 85"
    d="M0,17.852V3.04a1.011,1.011,0,0,0,.05-.15A3.465,3.465,0,0,1,3.454,0Q13.889,0,24.324,0a3.47,3.47,0,0,1,3.328,2.543c.045.163.084.328.125.493V17.8c-.013.023-.036.045-.039.069a3.544,3.544,0,0,1-3.659,2.982c-4.445-.039-8.89-.012-13.335-.012-2.412,0-4.824,0-7.236,0A3.455,3.455,0,0,1,.143,18.309C.1,18.156.048,18,0,17.852m11.047-5.714a1.607,1.607,0,0,1,.175-.275Q13.8,9.28,16.381,6.7a2.98,2.98,0,0,1,.474-.4,2.013,2.013,0,0,1,2.593.349Q22.329,9.522,25.2,12.4c.067.067.139.13.255.237v-.317c0-2.884-.017-5.769.01-8.653a1.262,1.262,0,0,0-1.349-1.359q-10.225.026-20.451,0a1.265,1.265,0,0,0-1.36,1.348c.023,3.653.01,7.306.011,10.958,0,.095.009.189.015.325.1-.1.177-.162.245-.23l3.932-3.932a2.047,2.047,0,0,1,3.186.007l1.349,1.351"
    transform="translate(0 0)"
    fill="#fff"
  />
  <path
    id="Path_86"
    data-name="Path 86"
    d="M68.637,66.37a2.311,2.311,0,1,1-2.293-2.322,2.3,2.3,0,0,1,2.293,2.322"
    transform="translate(-60.542 -60.573)"
    fill="#fff"
  />
</template>

<script>
export default {};
</script>
