<template>
  <div class="row">
    <createAccountModal ref="createAccountModal" />
    <div class="col-12 text-center step-description mx-0">
      Create the steps for the project. Brevity encouraged. Use simple words.
      Write imperatively.
    </div>
    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <form
          :class="{ 'carousel-item': true, 'form-steps-container': true, 'active': index === stepNumber - 1 }"
          v-for="(step, index) in project.steps"
          :key="index"
        >
          <div class="row">
            <div class="col-6">
              <h2 class="title-header-projects mb-5">Step {{ index + 1 }}</h2>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-end">
                  <div
                    class="d-flex align-items-center"
                  >
                    <div class="mr-2" style="color: #004aad; font-size: 14px; cursor: pointer;" @click="importStep({ ...step })">
                      Duplicate step
                    </div>
                    <div
                      v-if="config.reorderSteps"
                      class="d-flex justify-content-center align-items-center flex-column gap-2"
                    >
                      <button
                        :disabled="index == 0"
                        class="cursor-pointer reorder-button"
                        type="button"
                        @click="handleReorderSteps('move-up', index)"
                      >
                        <baseIcon class="arrowDown" width="16.5" height="10.5" iconColor="#004aad">
                          <arrowUp />
                        </baseIcon>
                      </button>
                      <button
                        :disabled="index === project.steps.length - 1"
                        class="cursor-pointer reorder-button"
                        type="button"
                        @click="handleReorderSteps('move-down', index)"
                      >
                        <baseIcon class="arrowDown" width="16.5" height="10.5" iconColor="#004aad">
                          <arrowDown />
                        </baseIcon>
                      </button>

                    </div>
                  </div>
                  <div @click="deleteStep(index)" class="trash-icon ml-4 cursor-pointer">
                    <baseIcon class="arrowDown" width="33.843" height="41.652">
                      <trash />
                    </baseIcon>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <TextArea
                :modelValue="project.steps[index].description"
                @update:modelValue="($event) => handle($event)"
                @write="this.instantiateTextEditor(index)"
                :id="'editor-' + index"
                :label="'Step'"
                :paddingX="'4'"
                :paddingY="'4'"
                :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
                :label-class="'font-16px lg-font-14px'"
                border
              />
            </div>

          </div>

          <div class="row mt-2 mt-lg-4">
            <div class="col-12">
              <div class="w-100">
                    <CamRecorder
                      :step="step"
                      :index="index"
                      @send-data-model-media="sendDataModalMedia"
                    />
                  </div>
            </div>
          </div>
          <div class="row">
            <div :class="`col-md-6 ${this.isMobileDevice && 'd-flex flex-column justify-content-center align-items-center'}`">
              <div class="row mt-3">
                <div :class="`col-12 ml-3 ${this.isMobileDevice && 'd-flex flex-column justify-content-between'}`">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="`has-multiple-choice-step-${index}`"
                    :value="step?.hasMultipleChoice"
                    @click="toggleMultipleChoice(index, step)"
                  />
                  <label
                    class="form-check-label"
                    :for="`has-multiple-choice-step-${index}`"
                  >
                    Add multiple choice
                  </label>
                </div>
              </div>

              <div class="row" v-if="step?.hasMultipleChoice">
                <div class="col-12 mt-5">
                  <div class="row mb-3">
                    <InputComponent
                      :modelValue="project.steps[index].multipleChoice.question"
                      @update:modelValue="
                        (e) =>
                          setProjectStepProp({
                            index,
                            prop: 'multipleChoice.question',
                            value: e,
                          })
                      "
                      :label="'Question 1'"
                      :paddingX="'4'"
                      :paddingY="'4'"
                      :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
                      :label-class="'font-16px lg-font-14px'"
                      border
                    />
                  </div>
                  <div class="row mb-3">
                    <InputComponent
                      :modelValue="
                        project.steps[index].multipleChoice.answer1.answer
                      "
                      @update:modelValue="
                        (e) =>
                          setProjectStepProp({
                            index,
                            prop: 'multipleChoice.answer1.answer',
                            value: e,
                          })
                      "
                      :label="'Answer 1'"
                      :paddingX="'4'"
                      :paddingY="'4'"
                      :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
                      :label-class="'font-16px lg-font-14px'"
                      border
                    >
                      <template #rightSlot>
                        <AnswerIcon
                          @markAnswerAsRight="handleMarkAnswerAsRight(index, 1)"
                          :isCorrect="
                            project.steps[index].multipleChoice.answer1.isCorrect
                          "
                        />
                      </template>
                    </InputComponent>
                  </div>
                  <div class="row mb-3">
                    <InputComponent
                      :modelValue="
                        project.steps[index].multipleChoice.answer2.answer
                      "
                      @update:modelValue="
                        (e) =>
                          setProjectStepProp({
                            index,
                            prop: 'multipleChoice.answer2.answer',
                            value: e,
                          })
                      "
                      :label="'Answer 2'"
                      :paddingX="'4'"
                      :paddingY="'4'"
                      :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
                      :label-class="'font-16px lg-font-14px'"
                      border
                    >
                      <template #rightSlot>
                        <AnswerIcon
                          @markAnswerAsRight="handleMarkAnswerAsRight(index, 2)"
                          :isCorrect="
                            project.steps[index].multipleChoice.answer2.isCorrect
                          "
                        />
                      </template>
                    </InputComponent>
                  </div>
                  <div class="row mb-3">
                    <InputComponent
                      :modelValue="
                        project.steps[index].multipleChoice.answer3.answer
                      "
                      @update:modelValue="
                        (e) =>
                          setProjectStepProp({
                            index,
                            prop: 'multipleChoice.answer3.answer',
                            value: e,
                          })
                      "
                      :label="'Answer 3'"
                      :paddingX="'4'"
                      :paddingY="'4'"
                      :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px' ]"
                      :label-class="'font-16px lg-font-14px'"
                      border
                      ><template #rightSlot>
                        <AnswerIcon
                          @markAnswerAsRight="handleMarkAnswerAsRight(index, 3)"
                          :isCorrect="
                            project.steps[index].multipleChoice.answer3.isCorrect
                          "
                        />
                      </template>
                    </InputComponent>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="user?.role === 'teacher' || !user.role"
              :class="`col-md-6 d-flex ${this.isMobileDevice ? 'justify-content-around' :'justify-content-end'} mt-3`"
            >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  @click="filterToApproval(index, 'teacher')"
                  :checked="step.checked === 'teacher'"
                  id="teacher-approval"
                  />
                <label for="teacher-approval" class="form-check-label"> Teacher approval </label>
              </div>
              <div class="form-check ml-4">
                <input
                  id="checkbox-none"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  @click="filterToApproval(index)"
                  :checked="step.checked === 'none'"
                />
                <label for="checkbox-none" class="form-check-label"> None </label>
              </div>
            </div>
          </div>

          <div v-if="step.showListStudents">
            <div v-if="students && students.length" class="students-list-container">
              <div v-for="(student, index) in students" :key="index">
                <input
                  class="form-check-input-student"
                  type="checkbox"
                  :id="index"
                  :value="student._id"
                  v-model="step.needApproval"
                  :checked="step.needApproval.indexOf(student._id) > -1"
                />
                <label class="form-check-label-student" :for="index">{{
                  student.name
                }}</label>
              </div>
            </div>
            <div v-else class="col-12 row row-collapse-color2 text-center bg-light">
              <h1 class="m-5">No students to display.</h1>
            </div>
          </div>
          <button v-show="index !== 0" class="carousel-step-button-arrow carousel-step-button-arrow-back" data-bs-target="#carouselExample" data-bs-slide="prev">
            <baseIcon width="20" height="20" iconColor="#004aad">
              <arrowLeft />
            </baseIcon>
          </button>
          <button v-show="index < project.steps.length - 1" class="carousel-step-button-arrow carousel-step-button-arrow-next" data-bs-target="#carouselExample" data-bs-slide="next">
            <baseIcon width="20" height="20" iconColor="#004aad">
              <arrowRight />
            </baseIcon>
          </button>
        </form>
      </div>
    </div>

    <div class="button-container-add-step">
      <button class="button-standard-white mr-2 button-steps" @click="addStep">
        Add steps
      </button>
      <button
        class="button-standard-white ml-2 button-steps"
        data-toggle="modal"
        data-target="#importStepsModal"
      >
        Import steps
      </button>
    </div>
    <div
      v-if="user?.role == 'teacher' && !this.$route.query.project"
      class="mb-5 button-styles-add-step"
    >
      <div class="d-flex justify-content-end align-items-center">
        <label
        class="project-public-label"
          for="make-project-public"
        >
          Make project public
        </label>
        <input
          id="make-project-public"
          class="form-check-input relative"
          style="width: 2.7em; height: 2.7em"
          type="checkbox"
          v-model="project.addToWarehouse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../services/api";
import FileUploader from "../../../components/FileUploader.vue";
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
import TextArea from "../../../components/TextArea.vue";
import CamRecorder from "./CamRecorder.vue";
import baseIcon from "../../../components/baseIcon.vue";
import arrowDown from "../../../assets/icons/arrowDown.vue";
import wrong from "../../../assets/icons/wrong.vue";
import tick from "../../../assets/icons/tick.vue";

import arrowUp from "../../../assets/icons/arrowUp.vue";
import arrowLeft from "../../../assets/icons/arrowLeft.vue";
import arrowRight from "../../../assets/icons/arrowRight.vue";
import { wait } from "../../../utils";
import actionButton from "../../../components/actionButton.vue";
import AnswerIcon from "./AnswerIcon.vue";
import _ from "lodash";
import Trash from "../../../assets/icons/trash.vue";
import createAccountModal from '../../../components/Modals/createAccount.vue'

export default {
  name: "StepName",
  components: {
    InputComponent,
    FileUploader,
    CamRecorder,
    TextArea,
    baseIcon,
    arrowDown,
    arrowUp,
    arrowLeft,
    arrowRight,
    actionButton,
    wrong,
    tick,
    AnswerIcon,
    Trash,
    createAccountModal,
  },
  props: ["instantiateTextEditor", "isMobileDevice"],
  data() {
    return { stepNumber: 1 };
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    allStepsHaveAName() {
      for (let i = 0; i < this.project.steps.length; i++) {
        if (!this.project.steps[i].name) {
          return false;
        }
      }
      return true;
    },
  },
  async created() {
    if (this.user?.role) {
      this.students = await API.teacher
        .GetStudents(this.user?._id)
        .then((res) => res.data);
    }
  },
  methods: {
    ...mapActions("project", [
      "setProjectProp",
      "setProjectStepProp",
      "addProjectStep",
      "deleteProjectStep",
    ]),
    handleMarkAnswerAsRight(index, number) {
      let multipleChoice = { ...this.project.steps[index].multipleChoice };
      for (let i = 1; i <= 3; i++) {
        multipleChoice[`answer${i}`].isCorrect = i === number ? true : false;
      }

      this.setProjectStepProp({
        index,
        prop: "multipleChoice",
        value: multipleChoice,
      });
    },
    async addStep() {
      this.stepNumber = this.stepNumber + 1;

      const step = {
        imagesURL: [],
        gifsURL: [],
        videosURL: [],
        name: "",
        description: "",
        needApproval: [],
        showListStudents: false,
        uploadingImage: false,
        uploadingGif: false,
        uploadingVideo: false,
        gifStatus: "noGif",
        videoStatus: "noVideo",
        hasMultipleChoice: false,
        stepNumber: this.stepNumber,
        multipleChoice: {
          question: "",
          answer1: {
            answer: "",
            isCorrect: true,
          },
          answer2: {
            answer: "",
            isCorrect: false,
          },
          answer3: {
            answer: "",
            isCorrect: false,
          },
        },
      };
      this.addProjectStep({ step });
      let index = this.project.steps.length - 1;
      await wait(0);
      this.instantiateTextEditor(index);
    },
    deleteStep(index) {
      if (this.project.steps.length > 1) {
        this.deleteProjectStep(index);
      }
    },

    filterToApproval(index, role) {
      if(!this.user.role) {
        return $('#createAccountModal').modal('show')
      }
      if (role === "student") {
        this.setProjectStepProp({ index, prop: "checked", value: "student" });
        this.setProjectStepProp({
          index,
          prop: "needApproval",
          value: this.project.steps[index].originalApprovalStudentList,
        });
        this.setProjectStepProp({
          index,
          prop: "showListStudents",
          value: true,
        });
      } else if (role === "teacher") {
        this.setProjectStepProp({ index, prop: "checked", value: "teacher" });
        this.setProjectStepProp({
          index,
          prop: "needApproval",
          value: [this.user?._id],
        });
        this.setProjectStepProp({
          index,
          prop: "showListStudents",
          value: false,
        });
      } else {
        this.setProjectStepProp({ index, prop: "checked", value: "none" });
        this.setProjectStepProp({ index, prop: "needApproval", value: [] });
        this.setProjectStepProp({
          index,
          prop: "showListStudents",
          value: false,
        });
      }
    },
    toggleMultipleChoice(index) {
      this.setProjectStepProp({
        index,
        prop: "hasMultipleChoice",
        value: this.project.steps[index].hasMultipleChoice ? false : true,
      });
    },
    reorderArray(array, a, b) {
      const temp = array[a];
      array[a] = array[b];
      array[b] = temp;

      return [...array];
    },
    handleReorderSteps(direction, index) {
      const steps = [...this.project.steps];
      const reordered =
        direction === "move-up"
          ? this.reorderArray(steps, index, index - 1)
          : this.reorderArray(steps, index, index + 1);

      this.setProjectProp({ prop: "steps", value: reordered });
      this.instantiateTextEditor(index, true);
      this.instantiateTextEditor(
        direction === "move-up" ? index - 1 : index + 1,
        true
      );
    },
    async importStep(newStep) {
      this.stepNumber = this.stepNumber + 1;
      /* THIS METHOD IS USED WHEN DUPLICATE AND IMPORT */
      let stepImport = _.cloneDeep(newStep);
      const step = {
        imagesURL: stepImport.imagesURL,
        gifsURL: stepImport.gifsURL,
        videosURL: stepImport.videosURL,
        name: stepImport.name,
        description: stepImport.description,
        needApproval: stepImport.needApproval,
        showListStudents: stepImport.showListStudents,
        uploadingImage: stepImport.uploadingImage,
        uploadingGif: stepImport.uploadingGif,
        uploadingVideo: stepImport.uploadingVideo,
        gifStatus: stepImport.gifStatus,
        videoStatus: stepImport.videoStatus,
        hasMultipleChoice: stepImport.hasMultipleChoice,
        multipleChoice: stepImport.multipleChoice,
        stepNumber: this.stepNumber,
        isStreamingImage: false,
        isStreamingVideo: false,
      };
      this.addProjectStep({ step });
      let index = this.project.steps.length - 1;
      await wait(0);
      this.instantiateTextEditor(index);
    },

    showImage(image) {
      this.selectedImgURL = image;
      $("#modal-image").modal("show");
    },
    sendDataModalMedia(data) {
      this.$emit("open-media-modal", data);
    },
  },
  async mounted() {
    this.project.steps.forEach(async (step, index) => {
      await wait(0);

      this.instantiateTextEditor(index);

      if (!step.multipleChoice) {
        this.setProjectStepProp({
          index,
          prop: "multipleChoice",
          value: {
            question: "",
            answer1: {
              answer: "",
              isCorrect: true,
            },
            answer2: {
              answer: "",
              isCorrect: false,
            },
            answer3: {
              answer: "",
              isCorrect: false,
            },
          },
        });
      }
      if (step.needApproval.indexOf(this.user?._id) > -1) {
        this.setProjectStepProp({
          index,
          prop: "checked",
          value: "teacher",
        });
      } else if (
        !step.needApproval.indexOf(this.user?._id) > -1 &&
        step.needApproval.length
      ) {
        this.setProjectStepProp({
          index,
          prop: "checked",
          value: "student",
        });
        this.setProjectStepProp({
          index,
          prop: "showListStudents",
          value: true,
        });
        this.setProjectStepProp({
          index,
          prop: "originalApprovalStudentList",
          value: step.needApproval,
        });
      } else {
        this.setProjectStepProp({
          index,
          prop: "checked",
          value: "none",
        });
      }
    });
  },
};
</script>

<style scoped>
.carousel-step-button-arrow {
  background: transparent;
  border: none;
}
.carousel-step-button-arrow-back {
  position: absolute;
  left: -5px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.carousel-step-button-arrow-next {
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.button-steps {
  font-size: 16px;
}

.reorder-button {
  border: none;
  background-color: var(--capable-white);
}

.reorder-button:disabled {
  opacity: 0.5;
  cursor: default;
}
.step-description {
  color: #004aad;
  font-size: 22px;
  margin: 40px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}


.form-steps-container {
  background-color: #fff;
  padding: 30px;
  padding-bottom: 0;
  border-radius: 10px;
  position: relative;
  transform: translateZ(-1px);
  margin-bottom: 40px;
}


.form-check-label {
  color: #004aad;
  margin-left: 1em;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
}
.form-check-input {
  width: 1.7em;
  height: 1.7em;
  border-radius: 5px;
}

.project-public-label {
  margin-right: 45px;
  margin-left: 45px;
  font-size: 18px;
  color: var(--capable-blue);

}

.trash-icon svg {
  width: 20px;
  height: 45px;
}
.title-header-projects {
  font-size: 18px;
}

</style>
