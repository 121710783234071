<template>
  <div class="row">
    <div class="col-12 text-center step-description mx-0">
      Are there any badges students should earn before attempting project?
      Select just if it is necessary
    </div>

    <div
      class="form-group flex-wrap justify-content-center col-md-12 w-100"
      v-if="config.preRequisites"
    >
      <div
        class="form-check form-group"
        style="margin: 0 20px 20px 0"
        v-for="(badge, index) in badges"
        :key="index"
      >
        <input
          class="form-check-input"
          type="checkbox"
          :id="index + 'idBadge'"
          :value="badge._id"
          @change="handleSelectCheckbox('preRequisite', badge._id)"
          :checked="this.project.preRequisite.includes(badge._id)"
        />
        <label class="form-check-label" :for="index + 'idBadge'">{{
          badge.name
        }}</label>
      </div>
      <h3 class="labelForm text-muted" v-if="!badges.length">
        No badges available.
      </h3>
    </div>

    <div class="col-12 text-center step-description mx-0">
      Are there any projects students should complete before attempting project?
    </div>

    <div
      v-if="user?.role !== 'admin' && config.preRequisites"
      class="form-group flex-wrap justify-content-center col-md-12 w-100 mb-3"
    >
      <div
        class="form-check form-group"
        style="margin: 0 20px 20px 0"
        v-for="(project, index) in projects"
        :key="index"
      >
        <input
          class="form-check-input"
          type="checkbox"
          :id="index + 'idproject'"
          :value="project._id"
          @change="handleSelectCheckbox('projectsRequired', project._id)"
          :checked="this.project.projectsRequired.includes(project._id)"
        />
        <label class="form-check-label" :for="index + 'idproject'">{{
          project.name
        }}</label>
      </div>
      <h3 class="labelForm text-muted" v-if="!projects.length">
        No projects available.
      </h3>
    </div>
  </div>
</template>

<script>
import API from "../../../services/api";
import FileUploader from "../../../components/FileUploader.vue";
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
import { sortAlphabetically } from "../../../utils";

export default {
  name: "StepName",
  components: {
    InputComponent,
    FileUploader,
  },
  data() {
    return {
      badges: [],
      projects: [],
    };
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
  },
  methods: {
    ...mapActions("project", ["setProjectProp"]),
    handleSelectCheckbox(prop, id) {
      let value =
        prop === "projectsRequired"
          ? [...this.project.projectsRequired]
          : [...this.project.preRequisite];

      if (!value.includes(id)) {
        value.push(id);
      } else {
        value = value.filter((p) => p != id);
      }

      console.log({ prop, value });

      this.setProjectProp({
        prop,
        value,
      });
    },
  },
  async created() {
    if (this.user?.role === "admin") {
      const [badges, projects] = await Promise.all([
        API.badges
          .getBadges()
          .then((res) => res.data)
          .then((data) => data.sort(sortAlphabetically)),
        API.projects
          .getProjects()
          .then((res) => res.data)
          .then((data) => data.sort(sortAlphabetically)),
      ]).catch((err) => console.log(err));

      this.badges = badges;
      this.projects = projects;
    } else {
      const [badges, projects] = await Promise.all([
        API.defaultBadges
          .getDefaultBadges()
          .then((res) => res.data)
          .then((data) => data.sort(sortAlphabetically)),
        API.defaultProjects
          .getDefaultProjects()
          .then((res) => res.data)
          .then((data) => data.sort(sortAlphabetically)),
      ]).catch((err) => console.log(err));

      this.badges = badges;
      this.projects = projects;
    }
    console.log(this.project);
  },
};
</script>

<style scoped>
.step-description {
  color: #004aad;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}
.form-check-label {
  color: #004aad;
  margin-left: 1em;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
}
.form-check-input {
  width: 1.7em;
  height: 1.7em;
  border-radius: 5px;
}
</style>
