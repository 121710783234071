<template>
  <path
    id="Path_179"
    data-name="Path 179"
    d="M0,16.709V.841A1.461,1.461,0,0,1,1.523,0Q19.4.021,37.271.01c1.02,0,1.426.4,1.426,1.407q0,7.348,0,14.7c0,1.014-.406,1.424-1.412,1.424q-6.651,0-13.3-.007a.7.7,0,0,0-.626.308c-.983,1.261-1.981,2.511-2.976,3.762a1.174,1.174,0,0,1-2.062,0c-1-1.251-1.994-2.5-2.977-3.762a.7.7,0,0,0-.626-.308c-4.4.01-8.792,0-13.188.017A1.475,1.475,0,0,1,0,16.709M7.215,4.836c-.869,0-1.738,0-2.607,0A1.123,1.123,0,0,0,3.4,6.04q-.007,2.607,0,5.213a1.138,1.138,0,0,0,1.21,1.208q2.607.012,5.214,0a1.139,1.139,0,0,0,1.206-1.213q.007-2.607,0-5.213a1.124,1.124,0,0,0-1.21-1.2c-.869,0-1.738,0-2.607,0m12.091,7.953c1.247,0,2.494-.006,3.741,0a1.138,1.138,0,0,0,1.1-.589,1.161,1.161,0,0,0-.09-1.249c-1.226-1.934-2.443-3.874-3.671-5.807a1.145,1.145,0,0,0-2.074-.007c-1.226,1.934-2.442,3.875-3.671,5.807a1.158,1.158,0,0,0,1.037,1.847c1.209-.015,2.418,0,3.627,0m11.847,0a4.152,4.152,0,1,0-4.171-4.132,4.153,4.153,0,0,0,4.171,4.132"
    transform="translate(0 0)"
    fill="#FFFFFF"
  />
  <path
    id="Path_180"
    data-name="Path 180"
    d="M134.77,258.917c.013-.426.043-.852.035-1.278a5.691,5.691,0,0,1,4.972-5.843,1.462,1.462,0,0,0,.224-.08,3.489,3.489,0,0,1-2.325-1.514,3.293,3.293,0,0,1-.455-2.673,3.4,3.4,0,0,1,6.682.558,3.466,3.466,0,0,1-2.84,3.63,1.876,1.876,0,0,0,.264.087,5.667,5.667,0,0,1,4.871,5.269c.038.613.042,1.229.061,1.844Z"
    transform="translate(-121.166 -220.219)"
    fill="#FFFFFF"
  />
  <path
    id="Path_181"
    data-name="Path 181"
    d="M0,256.884c.072-.3.125-.615.218-.913a5.643,5.643,0,0,1,4.7-4.159c.069-.012.136-.036.242-.064-2.164-.8-3.143-2.228-2.787-4.029a3.394,3.394,0,0,1,6.7.2,3.433,3.433,0,0,1-2.828,3.81,1.959,1.959,0,0,0,.266.085,5.7,5.7,0,0,1,4.9,5.564c.006.516,0,1.033,0,1.549H.831A1.747,1.747,0,0,1,0,258.093v-1.209"
    transform="translate(0 -220.227)"
    fill="#FFFFFF"
  />
  <path
    id="Path_182"
    data-name="Path 182"
    d="M270.253,258.92a10.329,10.329,0,0,1,.209-2.924,5.646,5.646,0,0,1,4.709-4.189c.069-.012.135-.037.243-.067-2.123-.744-3.094-2.131-2.805-3.952a3.4,3.4,0,0,1,6.694-.03,3.467,3.467,0,0,1-2.819,3.99c.257.049.451.082.642.123a5.723,5.723,0,0,1,4.54,5.942,1.3,1.3,0,0,1-.831,1.106Z"
    transform="translate(-242.969 -220.222)"
    fill="#FFFFFF"
  />
  <rect
    id="Rectangle_131"
    data-name="Rectangle 131"
    width="3.055"
    height="3.049"
    transform="translate(5.69 7.12)"
    fill="#FFFFFF"
  />
  <path
    id="Path_183"
    data-name="Path 183"
    d="M174.5,79.506l1.734-2.747,1.735,2.747Z"
    transform="translate(-156.882 -69.011)"
    fill="#FFFFFF"
  />
  <path
    id="Path_184"
    data-name="Path 184"
    d="M291.636,70.662a1.879,1.879,0,0,1-1.87-1.908,1.888,1.888,0,0,1,3.777.036,1.878,1.878,0,0,1-1.906,1.872"
    transform="translate(-260.515 -60.139)"
    fill="#FFFFFF"
  />
</template>

<script>
export default {};
</script>
