<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    data-target="#exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="!isMobileDevice"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            {{
              `${
                !steps[stepIndex]?.studentImagesURL.length > 0
                  ? "Upload image"
                  : "Uploaded image"
              }`
            }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body my-5 px-5"
          style="
            font-size: 15px;
            text-align: center;
            display: flex;
            flex-direction: column;
          "
          v-if="
            !steps[stepIndex]?.studentImagesURL.length > 0 &&
            !step?.useCamera &&
            !isLoading
          "
        >
          <div class="d-flex justify-content-center align-items-center">
            <ActionButton
              color="blue"
              :class="`px-4 py-3`"
              :text="'OPEN CAMERA TO TAKE A PICTURE'"
              @click="this.$emit('use-camera', step.stepNumber - 1)"
            />
          </div>
          <br />
          <div
            style="
              width: 100%;
              height: 15px;
              border-bottom: 1px solid black;
              text-align: center;
              margin-bottom: 10px;
            "
          >
            <span
              style="font-size: 20px; background-color: white; padding: 0 10px"
            >
              OR
            </span>
          </div>
          <br />
          <div class="d-flex justify-content-center align-items-center">
            <ActionButton
              color="blue"
              :class="`px-4 py-3`"
              :text="'UPLOAD AN IMAGE FILE FROM YOUR LIBRARY'"
              :button_type="'action p-3'"
              capture
              @click="this.$emit('use-library', index)"
            />
            <input
              type="file"
              id="upload_image"
              accept="image/*"
              @change="
                this.$emit('process-file', { e: $event, indexStep: stepIndex })
              "
              v-show="false"
            />
          </div>
        </div>
        <div
          v-else-if="step?.useCamera === true && !isLoading"
          class="modal-body d-flex flex-column justify-content-center"
        >
          <!-- WEBCAM TEST -->
          <div class="d-flex justify-content-center">
            <video id="webcam-stream" width="450" v-show="streamActive"></video>
            <img
              style="width: 450px"
              id="captured-image"
              v-show="!streamActive"
            />
          </div>
          <div class="d-flex justify-content-center">
            <div>
              <ActionButton
                color="blue"
                :class="`px-4 py-3`"
                :text="'TAKE IMAGE'"
                :button_type="'action p-3'"
                @click="takePhoto(stepIndex)"
                v-show="streamActive"
              />
            </div>
            <div class="d-flex justify-content-center my-3">
              <ActionButton
                color="blue"
                :class="`px-4 py-3`"
                :text="'Delete image'"
                :button_type="'delete'"
                :fontsize="'font-12'"
                @click="restoreIMGOptions(stepIndex)"
                v-show="!streamActive"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="isLoading"
          class="d-flex justify-content-center w-100 my-5"
        >
          <div class="loader-whitebg"></div>
        </div>
        <div v-else class="modal-body">
          <div class="d-flex flex-column justify-content-center">
            <div class="d-flex justify-content-center align-items-center">
              <img
                :src="steps[stepIndex]?.studentImagesURL[0]"
                alt=""
                srcset=""
                class="studentIMG"
              />
            </div>
            <div class="d-flex justify-content-center my-3">
              <ActionButton
                color="blue"
                :class="`px-4 py-3`"
                :text="'Delete image'"
                :button_type="'delete'"
                :fontsize="'font-12'"
                @click="restoreIMGOptions(stepIndex)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-else
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Upload image</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body my-5 px-5"
          style="
            font-size: 15px;
            text-align: center;
            display: flex;
            flex-direction: column;
          "
          v-if="
            !steps[stepIndex]?.studentImagesURL.length > 0 &&
            !step.useCamera &&
            !isLoading
          "
        >
          <div class="d-flex justify-content-center align-items-center">
            <ActionButton color="blue" :class="`px-4 py-3`"
              :text="'TAKE IMAGE'"
              :button_type="'action p-3'"
              @click="this.$emit('use-library',index)"
            />
            <input
              type="file"
              id="upload_image"
              accept="image/*"
              @change="processFile($event, stepIndex)"
              v-show="false"
            />
          </div>
        </div>
        <div v-else class="modal-body">
          <div
            v-if="isLoading"
            class="d-flex justify-content-center w-100 my-5"
          >
            <div class="loader-whitebg"></div>
          </div>
          <div v-else class="d-flex flex-column justify-content-center">
            <div class="d-flex justify-content-center align-items-center">
              <img
                :src="steps[stepIndex].studentImagesURL[0]"
                alt=""
                srcset=""
                class="studentIMG"
              />
            </div>
            <div class="d-flex justify-content-center my-3">
              <ActionButton color="blue" :class="`px-4 py-3`"
                :text="'Delete image'"
                :button_type="'delete'"
                :fontsize="'font-12'"
                @click="restoreIMGOptions(stepIndex)"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <ActionButton color="blue" :class="`px-4 py-3`"
            :text="'Close'"
            :button_type="'close_button'"
            :fontsize="'font-12'"
            data-bs-dismiss="modal"
          />
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import ActionButton from "../actionButton.vue";
export default {
  props: ["step", "steps"],
  components: {
    ActionButton,
  },
};
</script>
