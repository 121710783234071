<template>
  <div
    @click="$emit('markAnswerAsRight')"
    v-if="!isCorrect"
    class="cursor-pointer h-100 d-flex justify-content-center align-items-center mr-3"
  >
    <baseIcon width="16.5" height="13.5">
      <wrong />
    </baseIcon>
  </div>
  <div
    v-else
    class="cursor-pointer h-100 d-flex justify-content-center align-items-center mr-3"
  >
    <div class="tick-icon">
      <baseIcon width="40.01" height="29.095" iconColor="#39c776">
        <tick />
      </baseIcon>
    </div>
  </div>
</template>
<script>
import baseIcon from "../../../components/baseIcon.vue";
import wrong from "../../../assets/icons/wrong.vue";
import tick from "../../../assets/icons/tick.vue";

export default {
  name: "answer-icon",
  props: ["isCorrect"],
  components: { wrong, tick, baseIcon },
};
</script>

<style scoped>
svg {
    width: 16px;
  }
</style>
