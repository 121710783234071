<template>
  <path
    id="Path_36"
    data-name="Path 36"
    d="M10.8,162.5a14.276,14.276,0,0,0,19.48-.022,19.576,19.576,0,0,1,4.822,3.618,20.345,20.345,0,0,1,5.958,14.245,1.594,1.594,0,0,1-1.75,1.791q-18.783,0-37.567,0A1.574,1.574,0,0,1,0,180.493c.231-7.911,3.789-13.864,10.579-17.9a1.59,1.59,0,0,1,.178-.084.143.143,0,0,1,.047,0"
    transform="translate(0 -141.055)"
  />
  <path
    id="Path_37"
    data-name="Path 37"
    d="M82.889,0A11.075,11.075,0,0,1,93.952,11.036,11.3,11.3,0,0,1,82.861,22.121,11.061,11.061,0,0,1,82.889,0"
    transform="translate(-62.362 0)"
  />
</template>

<script>
export default {};
</script>
