<template>
  <div class="modal fade" id="teachersEditModal" tabindex="-1" aria-labelledby="objectiveModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="objectiveModalLabel">Teacher</h5>
            <button type="button" class="btn-close" aria-label="Close" v-on:click="closeModal"></button>
          </div>
         
          <form class="w-100 py-5 px-4">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nameEdit" class="labelForm">Name</label>
                <input type="text" id="nameEdit" class="form-control inputsForm" placeholder="name"  v-model="name">
              </div>
              <div class="form-group col-md-6">
                <label for="modificationsEdit" class="labelForm">Email</label>
                <input type="text" class="form-control inputsForm" id="modificationsEdit" placeholder="email" v-model="email" v-on:keyup.enter="editTeacher()">
              </div>
            </div>

            <p class="text-danger" v-if="errorMessage">
              {{ errorMessage }}
            </p>
            
          </form>


          
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg btnModalSecondary" v-on:click="closeModal">Close</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="editTeacher(teacher)">Edit</button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState} from 'vuex'
import confirmationCloseModal from './confirmationCloseModal';
export default {
  name:"ModalAdmin",
  data(){
    return{
      name:"",
      email:"",
      teacherId:"",


      errorMessage:"",
    }
  },
  components:{confirmationCloseModal},
  methods:{
    ...mapActions('teachers', ['updateTeacher']),
    editTeacher(){
      const data = {
        name: this.name,
        email: this.email,
      };
      this.updateTeacher({teacherId : this.teacherId, data})
      $('#teachersEditModal').modal('hide')
    },
    closeModal(){
      $('#teachersEditModal').modal('hide')
    },
  }
}
</script>