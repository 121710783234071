<template>
  <div>
    <objectivesList :objectives="objectives"/>
    <p class="text-danger w-100 text-center" v-if="errorObjectivesAPI">
      {{ errorObjectivesAPI }}
    </p>
  </div>
</template>
<script>
import objectivesList from '../components/objectivesList'
import { mapActions, mapState } from 'vuex'
export default {
  data(){
    return{
    }
  },
  components:{
    objectivesList
  },
  computed:{
    ...mapState('objectives',['objectives', 'errorObjectivesAPI']),
  },
  methods:{
    ...mapActions('objectives',['getDefaultObjectives'])
  },
  created(){
    this.getDefaultObjectives()
  }
}
</script>