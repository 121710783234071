<template>
  <div class="d-flex justify-content-start align-items-center w-100">
    <p class="f-semibold"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style scoped>
p {
  font-size: 21px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  color: var(--capable-blue);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 768px) {
  p{
    margin: 0px;
    font-size: 20px;
  }
}
</style>
