const defaultBadges = (axios) => {
  return {
    async CreateNewDefaultBadge(data) {
      return axios.post("/api/default-badges", data);
    },
    async getDefaultBadges() {
      return axios.get(`/api/default-badges`);
    },
    async deleteDefaultBadge(id) {
      return axios.delete(`/api/default-badges/${id}`);
    },
    async getDefaultBadge(defaultBadgeId) {
      return axios.get(`/api/default-badges/${defaultBadgeId}`);
    },
    async updateDefaultBadge(id, data) {
      return axios.put(`/api/default-badges/${id}`, data);
    },
  };
};
export default defaultBadges;
