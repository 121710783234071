const user = axios => {
	return {
		async CreateNewAccountTeacher(data) {
			return axios.post("/api/auth/teacher/signup", data)
		},

		async CreateNewAccountStudent(data) {
			return axios.post("/api/auth/student/signup", data)
		},

		async AddPictureProfile(data){
			return axios.post('/api/users/image-profile', data)
		},

		async Login(data) {
			return axios.post("/api/auth/signin", data)
		},

		async updateUser(data){
			return axios.put(`/api/users/me/profile/`, data)
		},

		async checkPin(data){
			return axios.post(`/api/users/check-availability`, data)
		}
	}
}

export default user;
