import API from "../../services/api";
import _ from 'lodash'

export default{
  getObjectives:( { commit } ) => {
    API.objectives.getObjectives()
      .then(res => commit('setObjectives', res.data) )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },
  
  deleteObjective:( { commit, dispatch }, {id, that} ) => {
    API.objectives.deleteObjective(id)
      .then(res => {
        that.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The objective has been successfully deleted',
        })
        dispatch('getObjectives')
      } )
      .catch(error => {
        that.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Error deleting objective',
        })
      })
  },
  
  updateObjective:( { commit, dispatch }, {objectiveId, data, that} ) => {
    API.objectives.updateObjective(objectiveId, data)
      .then(res => {
        that.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The objective has been successfully updated',
        })
        dispatch('getObjectives')
      } )
      .catch(error => {
        that.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Error updating objective',
        })
      })  
  },

  CreateNewObjective:( { commit, dispatch }, {data, that} ) => {
    API.objectives.CreateNewObjective(data)
      .then(res => {
        that.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The objective has been successfully created',
        })
        dispatch('getObjectives')
      } )
      .catch(error => {
        that.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Error creating objective',
        })
      })
  },

  getDefaultObjectives:( { commit } ) => {
    API.defaultObjectives.getDefaultObjectives()
      .then(res => commit('setObjectives', res.data) )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },
  
  deleteDefaultObjective:( { commit, dispatch }, {id, that} ) => {
    API.defaultObjectives.deleteDefaultObjective(id)
      .then(res => {
        that.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The objective has been successfully deleted',
        })
        dispatch('getDefaultObjectives')
      } )
      .catch(error => {
        that.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Error deleting objective',
        })
      })
  },
  
  updateDefaultObjective:( { commit, dispatch }, {objectiveId, data, that} ) => {
    API.defaultObjectives.updateDefaultObjective(objectiveId, data)
      .then(res => {
        that.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The objective has been successfully updated',
        })
        dispatch('getDefaultObjectives')
      } )
      .catch(error => {
        that.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Error updating objective',
        })
      })
  },
  CreateNewDefaultObjective:( { commit, dispatch }, {data, that} ) => {
    API.defaultObjectives.CreateNewDefaultObjective(data)
      .then(res => {
        that.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The objective has been successfully created',
        })
        dispatch('getDefaultObjectives')
      } )
      .catch(error => {
        that.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Error creating objective',
        })
      })    
  },
}