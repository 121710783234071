<template>
  <path
    id="Path_65"
    data-name="Path 65"
    d="M0,258.337a10.055,10.055,0,0,1,.936-1.715A2.911,2.911,0,0,1,3.284,255.6q19.309,0,38.618,0a3.256,3.256,0,0,1,3.3,3.3,8.575,8.575,0,0,1-.1,1.71,3.2,3.2,0,0,1-2.891,2.5c-.264.021-.529.027-.793.027-12.417,0-24.833-.021-37.25.023-2.07.007-3.539-.635-4.171-2.708v-2.119"
    transform="translate(0 -225.45)"
    fill="#004aad"
  />
  <path
    id="Path_66"
    data-name="Path 66"
    d="M102.486,13.2V12.63q0-5.031,0-10.063A2.356,2.356,0,0,1,104.213.1a3.465,3.465,0,0,1,.873-.093q2.118-.014,4.237,0a2.374,2.374,0,0,1,2.6,2.609q.008,5.009,0,10.019V13.2h1.4c1.192,0,2.384.017,3.575-.006a1.427,1.427,0,0,1,1.493.865,1.447,1.447,0,0,1-.407,1.68q-3.924,4.094-7.833,8.2c-.609.639-1.215,1.281-1.829,1.914a1.421,1.421,0,0,1-2.237,0c-3.232-3.382-6.448-6.779-9.7-10.144a1.444,1.444,0,0,1,.515-2.45,2.125,2.125,0,0,1,.566-.057c1.647,0,3.294,0,5.02,0"
    transform="translate(-84.599 0)"
    fill="#004aad"
  />
</template>

<script>
export default {};
</script>
