<template>
  <div class="d-flex justify-content-between gap-5 image-and-summary">
    <div class="image-container">
      <img v-if="project.projectImage" :src="project.projectImage" />
      <div v-else class="not-img-container">
        <div class="not-image-project"></div>
      </div>
    </div>
    <div class="text-white text-nowrap project-summary">
      <div class="d-flex justify-content-between info">
        <div class="col-lg-4 right-border box-padding">
          <h2 class="f-bold">Project Theme:</h2>
          <p>{{ project.theme ? projectTheme : "-" }}</p>
        </div>
        <div class="col-lg-4 right-border box-padding">
          <h2 class="f-bold">Project Tools:</h2>
          <ul v-if="project?.toolsRequired.length">
            <li v-for="(tool, index) in project.toolsRequired" v-bind:key="index">
              {{ tool.name }}
            </li>
          </ul>
          <p v-else>No tools required.</p>
        </div>
        <div class="col-lg-4 box-padding">
          <h2 class="f-bold">Project Length:</h2>
          <p>{{ project.projectLength }}</p>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center top-border date gap-3">
        <p>Created at: {{ formatDate(project.createdAt) }}</p>
        <p class="d-none d-sm-flex">|</p>
        <p>
          Updated at:
          {{ formatDate(project.updatedAt) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ProjectCard from "../ProjectCard/projectCard.vue";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("user", ["user"]),
  },
  props: ["project"],
  components: {
    ProjectCard,
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 22px;
}

p,
li {
  font-size: 16px;
  margin: 0px;
}

img {
  justify-content: center;
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: auto;
}

ul {
  list-style-type: none;
  padding: 0px;
}

.image-container {
  width: 25%;
}

.not-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 11px;
  width: 100%;
  height: auto;
}

.info {
  height: 85%;
}

.date {
  height: 15%;
}

.project-summary {
  background-color: var(--capable-blue);
  border-radius: 11px;
  height: auto;
  min-height: 250px;
  width: 75%;
}

.right-border {
  border-right: 1px solid white;

}

.top-border {
  border-top: 1px solid white;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
}

.box-padding {
  padding: 3rem;
  padding-bottom: 0;
}

@media (max-width: 1400px) {

  .date p {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  h2 {
    font-size: 20px;
  }

  p {
    font-size: 13px;
    margin: 0px;
  }

  .box-padding {
    padding: 1.5rem 0px 0px 0.5rem;
  }
}

@media (max-width: 520px) {
  h2 {
    font-size: 16px;
  }

  .image-and-summary {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    width: 100%;
  }

  .date {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center;
    gap: 3px !important;
    padding: 1rem 2rem;
  }


  .info {
    flex-direction: column;
  }

  .not-img-container {
    padding: 2.5rem;
  }

  .project-summary {
    width: 100%;
  }

  .right-border {
    border-right: 0px;
  }

  .box-padding {
    padding: 2rem;
  }
}
</style>
