<template>
  <button :class="`${button_type} common d-flex justify-content-center align-items-center px-3 py-2`"> <h2 :class="fontsize">{{text}}</h2> </button>
</template>

<script>
export default {
  name: "Button",
  props: ["text", "button_type", "fontsize"],
};
</script>

<style scoped>
h2{
  font-size: 1.5rem;
  margin: 0px;
}

.font-20{
  font-size: 20px !important;
}

.delete{
  background-color: #F22016;
  color: white;
  border:none;
  border-radius: 4px !important;
}

.delete:hover{
  filter: brightness(115%);
}

.close_button{
  border: 0.5px solid gray !important;
  border-radius: 4px !important;
  background-color: white;
  color: gray;
  transition: 250ms;
}

.close_button:hover{
  background-color: gray;
  color: white;
}

.action{
  border: 0.5px solid gray !important;
  border-radius: 4px !important;
  background-color: #2C4BAD;
  color: white;
  transition: 250ms;
}

.action:hover{
  filter: brightness(115%);
  color: white;
}
</style>
