<template>
  <div class="modal fade" id="advancedFilter" tabindex="-1" aria-labelledby="advancedFilterLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content align-items-center py-5">
        <div class="d-flex flex-column align-items-start justify-content-center w-100 px-5">
          <div class="d-flex w-100 justify-content-between align-items-center">
            <h2 class="text-white f-bold">Do a better search</h2>
            <BaseIcon class="cursor-pointer" width="13.616" height="13.51" @mouseenter="toggleCross = true"
              @mouseleave="toggleCross = false" :iconColor="toggleCross ? '#b0b3b7' : '#ffffff'" data-dismiss="modal">
              <CrossIcon />
            </BaseIcon>
          </div>

          <div class="d-flex flex-column">
            <h3 class="text-white f-semibold">Project theme</h3>
            <div class="d-flex justify-content-start align-items-center mb-2 gap-3">
              <ThemeSelector v-model="filters.theme" :options="themes" />
            </div>
          </div>

          <div class="d-flex flex-column w-100">
            <h3 class="text-white f-semibold">Duration time</h3>
            <input type="range" class="form-range w-100" min="0" max="50" step="1" id="customRange3"
              v-model="filters.duration" />
            <h3 class="text-white f-regular d-flex justify-content-center align-items-center">
              {{
                filters.duration == "0"
                ? "Any duration"
                : `Duration: ${filters.duration} steps`
              }}
            </h3>
          </div>

          <h3 class="text-white f-semibold">Tools required</h3>
          <!-- class="tools-container" -->
          <div
          class="d-flex flex-wrap mx-3 w-100 "
          >
            <div v-for="(tool, index) in tools" v-bind:key="index" class="tool d-flex sm-flex-col align-items-center gap-4 p-2 col-md-3">
              <input class="form-check-input mt-0" type="checkbox" :id="index + 'id'" :value="tool._id"
                v-model="filters.tools" />
              <h4 v-if="tool?.name" class="text-white d-flex justify-content-center align-items-center ml-3">
                {{ tool.name }}
              </h4>
            </div>
          </div>
        </div>

        <ActionButton data-dismiss="modal" color="red" class="px-5 py-4 w-25 search-icon-container"
          @mouseenter="toggleMagnifier = true" @mouseleave="toggleMagnifier = false" @click="$emit('search', filters)">
          <template #rightIcon>
            <BaseIcon width="23.187" height="22.871" :iconColor="toggleMagnifier ? '#ff1616' : '#ffffff'">
              <MagnifierIcon />
            </BaseIcon>
          </template>
        </ActionButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import MagnifierIcon from "../../assets/icons/magnifier.vue";
import BaseIcon from "../baseIcon.vue";
import ActionButton from "../actionButton.vue";
import Checkbox from "../checkbox.vue";
import ThemeSelector from "../themeSelector.vue";
import CrossIcon from "../../assets/icons/cross.vue";

export default {
  name: "advancedFilter",
  props: {
    messages: {},
    isDefault: {
      default: true,
    },
  },
  data() {
    return {
      toggleMagnifier: false,
      toggleCross: false,
      themes: [], // Options
      filters: {
        duration: "0",
        theme: "",
        tools: [],
      },
    };
  },
  computed: {
    ...mapState("tools", ["tools"]),
    ...mapState("projects", ["projects"]),
  },
  components: {
    MagnifierIcon,
    ActionButton,
    BaseIcon,
    CrossIcon,
    Checkbox,
    ThemeSelector,
  },
  async created() {
    await this.handleGetThemes();
  },
  methods: {
    ...mapActions("tools", ["getDefaultTools"]),
    ...mapActions("projects", [
      "getProjectsThemes",
      "getDefaultProjectsThemes",
    ]),
    handleChecked(theme) {
      this.filters.theme = theme;
    },
    async handleGetThemes() {
      if (this.isDefault) {
        this.themes = await this.getDefaultProjectsThemes();
      } else {
        this.themes = await this.getProjectsThemes();
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 36px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

input[type="range"]::-webkit-slider-thumb {
  background-color: var(--capable-red);
  /* Replace "red" with your desired color */
}

input[type="range"]::-moz-range-thumb {
  background-color: var(--capable-red);
  /* Replace "red" with your desired color */
}

input[type="range"]::-ms-thumb {
  background-color: var(--capable-red);
  /* Replace "red" with your desired color */
}


.modal-dialog {
  max-width: 900px;
}

.modal-content {
  background-color: var(--capable-blue);
  padding: 75px;
}

.form-range {
  margin: 10px 0px;
}

.form-check-label {
  color: #004aad;
  margin-left: 1em;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
}

.form-check-input {
  width: 1.7em;
  height: 1.7em;
  border-radius: 3px;
}

.form-check-input:checked {
  background-color: var(--capable-red);
}

.search-icon-container {
  margin: 35px 0 10px 0;
}

.tool {
  min-height: 40px;
  width: 100px;
}

.tools-container {
  width:100%;
  }

.tools-item {
  display: flex;
  align-items: center;
}

svg {
  width: 18px;
  height: 18px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 20px;
  }

  svg {
    width: 13px;
    height: 13px;
  }

  .modal-content {
    padding: 15px;
    max-width: 360px;
    margin: auto !important;
  }

  .modal-dialog {
    height: 100%;
  }

  .tool {

  width: 100%;
  }
}
</style>
