<template>
  <div
    class="project-container d-flex flex-column align-items-center col-12 gap-5 mt-5"
  >
    <div class="row w-100 mt-5">
      <ProjectTimeline
        @step-changed="onStepChanged"
        :current-step="currentStep"
        :steps="stepsFiltered"
      />
    </div>
    <div
      :class="
        project.supportingMaterial
          ? 'project-title-supporting-material'
          : 'project-title'
      "
    >
      <div class="d-flex flex-column align-items-center">
        <h1 class="f-bold text-blue">{{ project.name }}</h1>
        <p class="f-regular text-blue project-description" id="description">
          {{ project.description }}
        </p>
      </div>
      <ActionButton
        v-if="project.supportingMaterial"
        :color="`blue`"
        class="px-3 py-4"
        @click="openSupportinMaterial(project.supportingMaterial)"
        :text="`Supporting Material`"
      >
      </ActionButton>
    </div>
    <div class="d-flex justify-content-center align-items-center col-12">
      <BaseIcon
        role="button"
        width="10.475"
        height="16.469"
        class="col-1"
        @click="showPreviousStep"
        :iconColor="showLeftArrow() ? '#004aad' : '#b0b3b7'"
      >
        <ArrowLeft />
      </BaseIcon>
      <StepInfo
        :step="stepToShow"
        :approveButton="true"
        :studentStep="stepsFiltered[stepToShow.stepNumber - 1]"
        :imageURL="steps[stepToShow.stepNumber - 1]?.studentImagesURL"
        class="col-10"
        :length="project?.steps.length"
        @completed-step="completedStep"
        @open-multiple-choice-solved-modal="openMultipleChoiceSolvedModal"
        @open-media-modal="handleOpenMediaModal"
        @open-modal-image="showImage"
        :isMobileDevice="isMobileDevice"
      />
      <BaseIcon
        role="button"
        width="10.475"
        height="16.469"
        class="col-1"
        @click="showNextStep"
        :iconColor="showRightArrow() ? '#004aad' : '#b0b3b7'"
      >
        <ArrowRight />
      </BaseIcon>
    </div>

    <MediaModal
      v-if="toggleModal"
      @fileUpload="handleFileUpload"
      :is-mobile-device="dataModalMedia && dataModalMedia.isMobileDevice"
      :index="dataModalMedia && dataModalMedia.index"
      :step="dataModalMedia && dataModalMedia.step"
      :modal-id="dataModalMedia && dataModalMedia.modalId"
      @change-toggle="toggleModal = false"
    />

    <mcModal
      ref="multipleChoiceModal"
      @getproject="getProject"
      :step="stepToShow"
      :user="this.user"
      :project="this.project"
      :userPick="this.steps[stepToShow.stepNumber - 1]"
      :stepNEW="stepNEW"
    />
    <mcSolvedModal
      ref="multipleChoiceSolvedModal"
      :step="this.stepToShow"
      :user="this.user"
      :project="this.project"
      :userPick="this.selectedStepStudentPick"
    />
    <imageModal :imageURL="selectedImgURL" text="You took this photo"/>
  </div>
</template>

<script>
import API from "../services/api";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import mcModal from "../components/modalMultipleChoiceStudent.vue";
import mcSolvedModal from "../components/modalMultipleChoiceSolvedStudent.vue";
import imageModal from "../components/modalImage.vue";
import Button from "../components/button.vue";
import ActionButton from "../components/actionButton.vue";
import ProjectTimeline from "./createProject/components/ProjectTimeline.vue";
import ProjectSummary from "../components/Projects/projectSummary.vue";
import StepInfo from "../components/Projects/stepInfo.vue";
import BaseIcon from "../components/baseIcon.vue";
import ArrowRight from "../assets/icons/arrowRight.vue";
import ArrowLeft from "../assets/icons/arrowLeft.vue";
import StudentProjectPhotoModal from "../components/Modals/studentProjectPhoto.vue";
import MediaModal from "./createProject/components/MediaModal.vue";
import { wait } from "../utils";

const MAX_FILE_SIZE = 1024 * 2 * 1000; // 10MB

export default {
  name: "seeStudentProjectTeacher",
  data() {
    return {
      data: "",
      selectedImgURL: "",
      stepIndex: 0,
      selectedStepIndex: 0,
      project: {
        steps: [],
      },
      stepsFiltered: [],
      stepToShow: {},
      steps: [],
      progress: 0,
      student: null,
      selectedStep: {},
      selectedStepStudentPick: {},
      stepNEW: {
        message: "HI!",
      },
      isMobileDevice: null,
    };
  },
  components: {
    mcModal,
    mcSolvedModal,
    imageModal,
    Button,
    ActionButton,
    ProjectTimeline,
    ProjectSummary,
    StepInfo,
    BaseIcon,
    ArrowRight,
    ArrowLeft,
    StudentProjectPhotoModal,
    MediaModal,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
  },
  methods: {
    ...mapActions("studentProjects", ["getStudentProject"]),
    showImage(img) {
      if (img.length > 0){
        this.selectedImgURL = img;
        $("#modal-image").modal("show");
      }
    },
    async getSteps() {
      let stepsFiltered = this.project?.steps?.map((step, index) => {
        step.order = index + 1;
        step.nameTimeLine = `Step ${index + 1}`;
        let stepObj = this.steps?.find((s) => {
          return s.stepId === step._id;
        });

        // IF THE STEP IS COMPLETED
        if (stepObj?.status === "Completed") {
          step.isCompleted = true;
          return { ...step, status: "isCompleted" };
          // IF THE STEP IS APPROVED
        } else if (stepObj?.status === "Approved") {
          step.isCompleted = true;
          return { ...step, status: "Approved" };
          // IF THE STEP IS PENDING
        } else if (stepObj?.status === "Pending") {
          step.isCompleted = false;
          return { ...step, status: "Pending" };
        } else if (stepObj?.status === "Rejected") {
          step.isCompleted = false;
          return { ...step, status: "Rejected" };
        } else if (!stepObj) {
          // IF IT IS THE FIRST
          if (index === 0) {
            this.currentStep = step;
            this.stepToShow = step;
            this.currentView = step;
            this.selectedStepIndex = index;
            return { ...step, status: "inProgress" };
            // IF THE PREVIOUS STEP IS COMPLETED
          } else {
            let previusStep = this.project?.steps[index - 1];
            let previusCompleted = this.steps?.find((s) => {
              return s.stepId === previusStep._id;
            });
            if (previusCompleted) {
              this.stepToShow = step;
              this.currentStep = step;
              this.currentView = step;
              this.selectedStepIndex = index;
              if (
                previusCompleted?.status === "Pending" ||
                previusCompleted?.status === "Rejected"
              ) {
                return { ...step, status: "isLocked" };
              } else {
                return { ...step, status: "inProgress" };
              }
            } else {
              return { ...step, status: "isLocked" };
            }
          }
        }
      });
      this.stepsFiltered = stepsFiltered;
      let stepNumber = 100 / stepsFiltered?.length;
      this.progress = 0;
      for (let i = 0; i < stepsFiltered?.length; i++) {
        if (
          stepsFiltered[i]?.isCompleted &&
          stepsFiltered[i]?.status !== "Pending"
        ) {
          this.progress = this.progress + stepNumber;
        }
      }
      //IF THE PROJECT IS COMPLETED
      if (Math.round(this.progress) == 100) {
        const step = stepsFiltered[stepsFiltered.length - 1];
        this.currentStep = step;
        this.stepToShow = step;
        this.currentView = step;
        this.selectedStepIndex = stepsFiltered.length - 1;
      }
    },
    openMultipleChoiceModal() {
      $("#multipleChoiceStudentModal").modal("show");
    },
    openMultipleChoiceSolvedModal(step) {
      this.selectedStep = step;
      this.selectedStepStudentPick = this.steps[step.stepNumber - 1];
      $("#multipleChoiceStudentSolvedModal").modal("show");
    },
    async getProject() {
      const projectId = this.$route.query.project;
      const studentId = this.$route.query.student;
      try {
        const res = await this.getStudentProject({ studentId, projectId });
        this.project = res.data.project;
        this.steps = res.data.steps || [];
        this.getSteps(this.steps);
      } catch (e) {
        console.error(e);
      }
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;

      this.isMobileDevice = regexp.test(userAgent);
    },
    async showToastAndSelectStep(step, i) {
      this.stepToShow = step;
      this.selectedStepIndex = i;
    },
    showPreviousStep() {
      if (this.stepToShow.stepNumber >= 2) {
        this.stepToShow = this.project.steps[this.stepToShow.stepNumber - 2];
        this.currentStep = this.stepToShow;
        this.currentView = this.stepToShow;
      }
    },
    showNextStep() {
      if (this.stepToShow.stepNumber < this.project.steps.length) {
        this.stepToShow = this.project.steps[this.stepToShow.stepNumber];
        this.currentStep = this.stepToShow;
        this.currentView = this.stepToShow;
      }
    },
    showLeftArrow() {
      if (this.stepToShow.stepNumber > 1) {
        return true;
      } else return false;
    },
    showRightArrow() {
      if (this.stepToShow.stepNumber < this.project?.steps?.length) {
        return true;
      } else return false;
    },
  },
  mounted() {
    this.student = this.user;
    this.getProject();
    this.checkDeviceType();
    $(document).ready(() => {
      $(document).on("hidden.bs.modal", "#exampleModal", (event) => {
        this.closeModal(this.stepIndex);
      });
      this.showToastAndSelectStep(this.stepToShow, this.selectedStepIndex);
    });
  },
};
</script>

<style scoped>
h1 {
  font-size: 32px;
}

video {
  width: 360px;
}
.projectIMG {
  max-width: 550px;
}

@media (max-width: 650px) {
  video {
    width: 240px;
  }
  .projectIMG {
    max-width: 250px;
  }
}
.multiple-choice-solved-step {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #cbcbcb;
  text-align: center;
  z-index: 2;
}

.question-mark {
  font-size: 30px;
  color: white;
}

.project-actions {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 2px solid black;
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #004aad;
}

.project-title {
  font-size: 20px;
}

.upload-image {
  font-size: 35px;
  background-color: #004aad;
  color: white;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 10px;
  padding: 10px;
  transition: 0.5s;
}

.upload-image-blocked {
  font-size: 35px;
  background-color: #004aad;
  color: grey;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 10px;
  padding: 10px;
  transition: 0.5s;
  cursor: not-allowed;
}

.upload-image:hover {
  background-color: white;
  color: #004aad;
  transition: 0.5s;
}

.open-multiple-choice {
  font-size: 35px;
  background-color: #004aad;
  color: white;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 0px;
  padding: 10px;
  transition: 0.5s;
}

.border-radius {
  border-radius: 0 0 10px 10px;
}

.open-multiple-choice:hover {
  background-color: white;
  color: #004aad;
  transition: 0.5s;
}

.take-picture-option {
  color: white;
  background-color: #004aad;
  cursor: pointer;
}

.studentIMG {
  max-width: 450px;
}

.project-description {
  margin: 0 100px;
}

@media (max-width: 1400px) {
.project-description {
  margin: 0 75px;
}
}

@media (max-width: 650px) {
  .studentIMG {
    max-width: 250px;
  }
}

.toast-header {
  background-color: #004aad;
  color: #fff;
}

.toast {
  background-color: #fffffffa !important;
  font-size: 1.5rem !important;
}

.toast-container {
  z-index: 11;
}

@media (max-width: 900px) {
  .toast-mobile-width {
    width: 96%;
  }
  .toast {
    width: 96% !important;
  }
}

@media (max-width: 520px){
  h1{
    font-size: 26px;
  }

  #description{
    font-size: 20px;
  }
}
</style>
