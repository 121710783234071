import API from "../../services/api";
import _ from "lodash";

export default {
  getNotifications: async ({ commit }) => {
    try {
      const res = await API.notifications.getNotifications();
      commit("setNotifications", res.data);
    } catch (error) {
      commit(
        "setErrorNotificationsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  deleteNotification: async ({ commit },  id ) => {
    try {
      await API.notifications.deleteNotification(id);
    } catch (error) {
      commit(
        "setErrorNotificationsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  deleteAllNotifications: async ({ commit },  id ) => {
    try {
      await API.notifications.deleteAllNotifications();
    } catch (error) {
      commit(
        "setErrorNotificationsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  markAsReadNotifications: async ({ commit }, { data }) => {
    try {
      return await API.notifications.markAsReadNotifications(data);
    } catch (error) {
      commit(
        "setErrorNotificationsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },
};
