<template>
  <div class="col-12">
    <div class="timeline-wrapper">
      <ul class="timeline">
        <li
          v-for="step in steps"
          :class="{
            'checked-tl': step.order < currentStep.order,
            'unchecked-tl': step.order >= currentStep.order,
            'active-tl': step.name === currentStep.name,
            dot: true,
          }"
          :key="`project-step-${step}`"
          @click="changeStep(step)"
          :style="`width: ${100 / steps.length}%;`"
        >
          <span :data-info="step.nameTimeLine || step.name"> </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsContainer",
  props: ["currentStep", "steps"],
  methods: {
    changeStep(step) {
      this.$emit("step-changed", step);
    },
  },
};
</script>

<style>
.timeline-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  margin: 0 auto;
}
.dot span::before {
  content: attr(data-info);
  position: relative;
  top: -65px;
  width: 70px;
  font-size: 14px;
  text-align: center;
  text-indent: -10px;
}

.timeline {
  position: relative;
  width: 100%;
  z-index: 50;
  padding: 0;
}

.timeline li {
  list-style: none;
  float: left;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

ul:nth-child(1) {
  color: #004aad;
}

.unchecked-tl:before {
  content: "";
  width: 22px;
  height: 22px;
  border: 1px solid #004aad;
  border-radius: 100%;
  display: block;
  text-align: center;
  line-height: 24px;
  margin: 0 auto ;
  background: #fff;
  color: #000;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.checked-tl:before {
  content: url("data:image/svg+xml; utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14.0' height='10' viewBox='0 0 40.01 29.095'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_48' data-name='Rectangle 48' width='40.01' height='29.095' fill='%23004aad'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_58' data-name='Group 58' transform='translate(0 0)'%3E%3Cg id='Group_57' data-name='Group 57' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_70' data-name='Path 70' d='M40.01,4.541a5.907,5.907,0,0,1-1.961,3.1Q27.959,17.688,17.9,27.774a4.1,4.1,0,0,1-3.41,1.3,3.83,3.83,0,0,1-2.163-.894c-.15-.125-.291-.261-.429-.4Q6.61,22.494,1.318,17.205a4.075,4.075,0,0,1-1.257-3.72,3.959,3.959,0,0,1,3.475-3.246,4.037,4.037,0,0,1,3.421,1.248q3.8,3.8,7.6,7.6c.1.1.186.218.308.361.146-.139.257-.239.362-.345Q24.1,10.22,32.983,1.334A4.07,4.07,0,0,1,36.19.006a3.936,3.936,0,0,1,3.746,3.227,1.689,1.689,0,0,0,.074.215Z' transform='translate(0 0)' fill='%2339c776'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 22px;
  height: 22px;
  border: 1px solid #004aad;
  border-radius: 100%;
  display: block;
  text-align: center;
  line-height: 24px;
  margin: 0 auto 10px auto;
  background: #fff;
  color: #000;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.timeline li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #004aad !important;
  top: 10px;
  left: -50%;
  z-index: -999;
  transition: all ease-in-out 0.3s;
}

.timeline li:first-child:after {
  content: none;
}
.timeline li.active-tl {
  color: #004aad;
  font-weight: bold;
}
.timeline li.active-tl:before {
  border: 3px solid #004aad;
  color: #f1f1f1;
}

.timeline li.active-tl + li:after {
  background: #004aad;
}

@media (max-width: 768px) {
  .timeline-wrapper {
  max-width: 100%;
  width: 100%;
}

.dot span::before {
  font-size: 12px;
}
}
</style>
