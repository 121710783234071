<template>
  <div class="projects-container" v-if="!isLoading">
    <div class="mb-5 mt-5 divContainerAdminGeneral">
      <div class="d-flex col-10 px-1 col-md-11 flex-wrap justify-content-between w-100">
        <h2 class="titleAdminGeneral text-blue f-bold" v-if="isDraft">
          Drafts
        </h2>
        <h2 class="titleAdminGeneral text-blue f-bold" v-else>My Projects</h2>
        <div class="d-flex justify-content-between align-items-center search-button gap-3" v-if="!isDraft">
          <div class="d-flex justify-content-between gap-3 w-100">
            <BaseInput class="px-4 py-3 w-100" :placeholder="'Search by project'" v-model="projectName" :border="true">
              <template #rightSlot>
                <div class="d-flex align-items-center mr-3">
                  <BaseIcon :iconColor="`#b0b3b7`" width="23.187" height="22.871">
                    <MagnifierIcon />
                  </BaseIcon>
                </div>
              </template>
            </BaseInput>
            <ActionButton
              color="blue"
              class="py-3 px-4 d-lg-none"
              @mouseenter="toggleSettings = true"
              @mouseleave="toggleSettings = false"
              data-toggle="modal"
              data-target="#advancedFilter"
              fontsize="text-18-px">
              <template #leftIcon>
                <BaseIcon width="38.888" height="35.327" :iconColor="toggleSettings ? '#004aad' : '#ffffff'">
                  <SettingsIcon />
                </BaseIcon>
              </template>
            </ActionButton>
          </div>

          <div v-if="user.role === 'teacher'" class="d-flex justify-content-between buttons w-100">
            <router-link to="/create-projects">
              <div>
                <ActionButton color="red" :text="isMobileDevice ? 'Create' : 'Create new project'"
                  class="px-4 py-4 text-nowrap" fontsize="text-18-px" />
              </div>
            </router-link>
            <router-link to="/project-warehouse" class="ml-4">
              <div>
                <ActionButton color="blue" :text="isMobileDevice
                    ? 'Add from warehouse'
                    : 'Add project from warehouse'
                  " class="px-5 py-4 text-nowrap" fontsize="text-18-px" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
      <div class="loader-whitebg"></div>
    </div>
    <div class="form-group col-md-12 py-4 text-center mt-5" style="font-size: 30px" v-else-if="!projects.length">
      {{ `No ${!isDraft ? "projects" : "drafts"} availables` }}
    </div>
    <CardGrid>
      <ProjectCard
        v-for="(project, index) in filteredByName"
        :user="user"
        :project="project"
        v-bind:key="index"
        :cardType="user.role == 'teacher' ? 'TeacherCard' : 'BaseCard'"
        :config="config"
        :isDraft="isDraft"
        :index="index"
        :students="students"
        @open-pdf="openPDF"
        @delete-confirmation="openConfirmationModal"
        @assign-students="openModalAssign"
        :shadow="true" />
    </CardGrid>

    <div class="container" style="margin-top: 50px; margin-bottom: 80px" v-if="pendingApprovals?.length && !isDraft">
      <h2 class="text-blue f-bold pending-approvals-text" v-if="user.role === 'teacher'">
        Pending Approvals
      </h2>
      <div class="table row w-100 divContainerAdminGeneral mt-5 d-lg-block">
        <div class="w-100 d-flex text-center align-items-center pr-0">
          <div class="col-3 titlesTableBadges text-start pl-0">Student</div>
          <div class="col-5 titlesTableBadges text-start pl-0">Project</div>
          <div class="col-1 titlesTableBadges text-start pl-0">Step</div>

          <div class="actions col-3 titlesTableBadges pl-0 pr-0">Actions</div>
        </div>
        <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
          <div class="loader-whitebg"></div>
        </div>
        <div style="padding-right: 0px" v-else-if="pendingApprovals?.length">
          <div v-for="(step, index) in pendingApprovals" :key="index" :class="'badges-list-border d-flex py-2' +
            (index % 2 ? 'row-collapse-color2' : 'row-collapse-color1')
            " style="padding: 10px 0">
            <div class="col-3 textTableAdminGeneral d-flex justify-content-start align-items-center">
              <p style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0px;

                ">
                {{ step?.studentName }}
              </p>
            </div>
            <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center">
              <p style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0px;
                ">
                {{ step?.projectName }}
              </p>
            </div>

            <div class="col textTableAdminGeneral d-flex justify-content-start align-items-center">
              #{{ step.stepNumber ? step.stepNumber : "-" }}
              <span class="see-description" href="#" @click="
                openModalDescription(step?.stepDescription, step?.stepName)
                ">
                <i class="icon-info-sign ml-1"></i>
              </span>
            </div>
            <div class="d-flex justify-content-end align-items-center pr-0">
              <div class="d-flex justify-content-end align-items-center">
                <i class="fas fa-check icon-check-table px-2" @click="
                  approveStep(step.studentId, step.projectId, step.stepId)
                  "></i>
                <i class="fas fa-trash-alt px-2" style="color: rgb(209, 11, 11)" @click="
                  rejectStep(step.studentId, step.projectId, step.stepId)
                  "></i>
              </div>
            </div>
          </div>
        </div>
        <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-else></div>
      </div>
    </div>
    <modalPdf :pdfUrl="pdfUrl" />

    <AdvancedFilterModal :tools="tools" :isDefault="false" @search="handleSearch" />
    <confirmationModal ref="confirmModal" @confirm="deleteProjectApi" />
    <ModalAssignStudent ref="assignStudentsModal" />
    <modalDescription ref="modalDescription" :message="description" :title="title"></modalDescription>
  </div>
  <div class="project-container" v-else>
    <div class="loader-whitebg"></div>
  </div>
</template>

<script>
import API from "../services/api";
import ModalAssignStudent from "./ModalAssignStudent.vue";
import { mapActions, mapState } from "vuex";
import confirmationModal from "./confirmationDeleteModal.vue";
import modalDescription from "./modalDescription.vue";
import modalPdf from "./modalPdf.vue";
import ActionButton from "./actionButton.vue";
import ProjectCard from "./ProjectCard/projectCard.vue";
import CardGrid from "./ProjectCard/cardGrid.vue";
import BaseInput from "./input.vue";
import MagnifierIcon from "../assets/icons/magnifier.vue";
import BaseIcon from "./baseIcon.vue";
import AdvancedFilterModal from "./Modals/advancedFilter.vue";
import SettingsIcon from "../assets/icons/settingsWhite.vue";

export default {
  name: "projectsAndDraftsTable",
  data() {
    return {
      projectName: "",
      projectsToShow: [],
      isNotLoading: true,
      pdfUrl: null,
      loading: false,
      loadingGrid: false,
      name: "",
      toolsRequired: [],
      preRequisite: [],
      plans: "",
      supportingMaterial: "",
      projectTheme: "",
      projectLength: Number,
      ProjectId: "",
      students: [],
      pendingApprovals: [],
      myProjects: [],
      description: "",
      title: "",
      isMobileDevice: false,
    };
  },
  props: ["badges", "tools", "projects", "isDraft", "isLoading"],
  components: {
    ModalAssignStudent,
    confirmationModal,
    modalDescription,
    modalPdf,
    ProjectCard,
    ActionButton,
    CardGrid,
    BaseInput,
    MagnifierIcon,
    BaseIcon,
    AdvancedFilterModal,
    SettingsIcon,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    filteredByName() {
      if (!this.projectName) return this.projects;
      return this.projects.filter((p) =>
        p.name.toLowerCase().includes(this.projectName.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions("projects", [
      "getProjects",
      "deleteProject",
      "deleteDraft",
      "deleteDefaultProjects",
    ]),
    async handleSearch(data) {
      this.projectName = "";
      await this.getProjects(data);
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },
    openPDF(pdfUrl) {
      this.isNotLoading = false;
      if (this.isMobileDevice) {
        window.open(pdfUrl);
      } else {
        this.pdfUrl = pdfUrl;
        $("#modalPdf").modal("show");
      }
      this.isNotLoading = true;
      return;
    },
    getStudents() {
      API.teacher
        .GetStudents(this.user._id)
        .then((res) => {
          this.students = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openModalAssign(project) {
      $("#modal-assign-student").modal("show");
      this.$refs.assignStudentsModal.teacher = this.user;
      this.$refs.assignStudentsModal.students = this.students;
      this.$refs.assignStudentsModal.project = project;
    },
    openModalDescription(description, title) {
      this.description = description;
      this.title = title;
      $("#modalDescription").modal("show");
    },
    seeProject(project) {
      if (
        this.$route.name === "Drafts" ||
        this.$route.name === "Project drafts"
      ) {
        return this.editProject(project);
      }
      for (let index = 0; index < this.myProjects?.length; index++) {
        if (this.myProjects[index]?.project._id == project?._id) {
          return this.$router.push({
            path: "/see-student-project",
            query: { project: project._id },
          });
        }
      }
      return this.$router.push({
        path: `/project/${project._id}`,
      });
    },
    async approveStep(studentId, projectId, stepId) {
      this.loading = true;
      await API.studentProjects.approvedStep(studentId, projectId, stepId);
      await API.studentProjects
        .getPendingSteps(this.user._id)
        .then((res) => {
          this.pendingApprovals = res.data;
        })
        .catch((err) => console.log(err));
      this.loading = false;
    },
    async rejectStep(studentId, projectId, stepId) {
      this.loading = true;
      await API.studentProjects.rejectedStep(studentId, projectId, stepId);
      await API.studentProjects
        .getPendingSteps(this.user._id)
        .then((res) => {
          this.pendingApprovals = res.data;
        })
        .catch((err) => console.log(err));
      this.loading = false;
    },
    editProject(project) {
      this.$router.push({
        path: "/create-projects",
        query: { project: project._id },
      });
    },
    async deleteProjectApi(id) {
      if (this.isDraft) {
        await this.deleteDraft({ id, that: this });
      } else await this.deleteProject({ id, that: this });
    },
    openConfirmationModal(project = {}) {
      this.$refs.confirmModal.message =
        "Are you sure you want to delete this? " + project.name || "";
      this.$refs.confirmModal.assetId = project._id || "";
      $("#confirmationModal").modal("show");
    },
  },
  mounted() {
    window.addEventListener("getStudents", (e) => {
      this.getStudents();
    });
    if (this.user.role === "student") {
      API.studentProjects
        .getMyProjects(this.user._id)
        .then((res) => {
          this.myProjects = res.data;
        })
        .catch((err) => console.log(err));
    }
  },
  created() {
    this.loadingGrid = true;
    if (this.user.role === "teacher") {
      this.getStudents();
      API.studentProjects
        .getPendingSteps(this.user._id)
        .then((res) => {
          this.pendingApprovals = res.data;
        })
        .catch((err) => console.log(err));
    }
    this.loadingGrid = false;
  },
  mounted() {
    this.checkDeviceType();
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}

i {
  font-size: 20px;
  padding-right: 0px !important;
}

.projects-container {
  align-self: center;
  max-width: 1600px;
}

.table {
  margin-right: 0px;
  margin-left: 0px;
}

.see-description:hover {
  text-decoration: underline;
  cursor: pointer;
}

.actions {
  text-align: end;
}

.search-button {
  width: 60%;
}

h2 {
  font-size: 36px;
}

h2.pending-approvals-text {
  font-size: 20px;
}

@media (max-width: 1220px) {
  .search-button {
    flex-wrap: wrap-reverse;
    width: auto;
  }
}

@media (max-width: 768px) {
  .search-button svg {
    height: 20px;
    width: 20px;
  }

  .divContainerAdminGeneral {
    margin-bottom: 1.5rem !important;
  }

  .titlesTableBadges {
    font-size: 1.4rem;
  }

  .search-button {
    flex-wrap: wrap-reverse;
    width: auto;
  }
}
</style>
