<template>
  <div
    class="div_reset_password_container d-flex justify-content-center align-items-end container"
  >
    <div
      class="box d-flex flex-column align-items-center shadow p-5 bg-white rounded"
    >
      <div class="h-25 d-flex justify-content-center">
        <h3 class="fontFamily h1 f-weight-700">Password recovery</h3>
      </div>
      <form
        class="h-75 w-100 d-flex flex-column align-items-center justify-content-start"
      >
        <div class="form-row d-flex justify-content-center">
          <div
            class="form-group d-flex mt-3 justify-content-center align-items-center"
          >
            <div class="d-flex flex-column">
              <label for="formFile" class="form-label">Password</label>
              <div class="d-flex input-eye">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control inputsFormReset"
                  id="password"
                  v-model="password"
                />
                <i
                  v-if="showPassword"
                  class="icon-eye-open"
                  @click="showPassword = !showPassword"
                ></i>
                <i
                  v-if="!showPassword"
                  class="icon-eye-close"
                  @click="showPassword = !showPassword"
                ></i>
              </div>
            </div>
          </div>

          <div class="form-group col-md-12 mt-3">
            <div class="box d-flex justify-content-around align-items-end">
              <div class="d-flex flex-column">
                <label for="formFile" class="form-label"
                  >Confirm password</label
                >
                <div class="d-flex input-eye">
                  <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    class="form-control inputsFormReset"
                    id="confirmPassword"
                    v-model="confirmPassword"
                  />
                  <i
                    v-if="showConfirmPassword"
                    class="icon-eye-open"
                    @click="showConfirmPassword = !showConfirmPassword"
                  ></i>
                  <i
                    v-if="!showConfirmPassword"
                    class="icon-eye-close"
                    @click="showConfirmPassword = !showConfirmPassword"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="div_login_button flex-column align-items-center">
            <div
              class="free-space"
              v-if="!errorMessage && !succesMessage"
            ></div>
            <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
            <p class="text-success" v-if="succesMessage">{{ succesMessage }}</p>
            <input
              :class="{
                'btn btn-primary btn-lg btnModalSuccess': !loading,
                'btn btn-primary btn-lg btnModalSuccess disabled': loading,
              }"
              type="button"
              value="Reset password"
              @click="resetPassword"
            />
          </div>
        </div>
      </form>
    </div>
    <tr v-if="loading">
      <td
        colspan="5"
        class="form-group py-4 w-100 d-flex justify-content-center"
      >
        <div class="loader-whitebg"></div>
      </td>
    </tr>
  </div>
</template>

<script>
import { delay } from "q";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorMessage: "",
      succesMessage: "",
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    ...mapActions("accountRecovery", ["changePassword"]),
    async resetPassword() {
      this.errorMessage = "";
      if (this.password !== this.confirmPassword) {
        this.errorMessage = "The passwords don't match.";
        this.succesMessage = "";
        return;
      }
      if (!this.password && !this.confirmPassword) {
        this.errorMessage = "Please complete the fields";
        return;
      }
      const data = {
        newPass: this.password,
        passRecoveryCode: this.$route.query.code,
      };
      try {
        await this.changePassword({ data: data });
        this.succesMessage = "The password was succesfully changed.";
        this.errorMessage = "";
        await delay(2000);
        this.$router.push("/login");
      } catch (error) {
        this.errorMessage = error;
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
i {
  font-size: 25px;
  color: #707070;
  padding-top: 2px;
  margin-left: -35px;
}

i:hover {
  color: gray;
}

.box {
  max-width: 500px;
}
.div_reset_password_container {
  height: 50vh;
  max-width: 600px;
}

.text-success {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}

.inputsFormReset {
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem !important;
}

.text-danger,
.text-success {
  margin-left: 0px;
  margin-right: 0px;
}
.form-label {
  font-size: 1.3rem;
}

.form-control {
  width: 250px;
}
.shadow {
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15) !important;
}

.input-eye {
  display: flex;
  justify-content: space-between;
}

.btn {
  line-height: 1.2;
}

.free-space {
  height: 22.5px;
}

.btnModalSuccess {
  padding: 10px 25px !important;
  font-size: 1.6rem !important;
  letter-spacing: 0.5px;
}
</style>
