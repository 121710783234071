<template>
  <div class="container mt-5 divContainerAdminGeneral">    
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral" v-if="user.role === 'admin'">Default Projects</h2>
      <h2 class="titleAdminGeneral" v-else>Projects</h2>
      <confirmationModal ref="confirmModal" @confirm="deleteProjectApi" />
      <div class="d-flex flex-column align-items-end">
        <div :class="{'': tools.length, 'hint--bottom': !tools.length}" aria-label="You have to create a tool first">
          <button type="button" :class="{'btn btn_admin_general': tools.length, 'btn btn_admin_general disabled': !tools.length}" @click="addProyect">Add Projects</button>
        </div>
        <p class="text-danger" v-if="!tools.length"></p>
      </div>
    </div>
    <hr class="w-100" />

    <div class="row w-100 divContainerAdminGeneral mt-3 d-none d-lg-block">      
      <div class="w-100 d-flex">
        <div class="col-2 titlesTableProjects pl-0">Name</div>
        <div class="col-2 titlesTableProjects">Tools Required</div>
        <div class="col-2 titlesTableProjects">Badge</div>
        <div class="col-1 titlesTableProjects">Plans</div>
        <div class="col-2 titlesTableProjects">Supporting Materials</div>
        <div class="col-1 titlesTableProjects">Content Area</div>
        <div class="col-1 titlesTableProjects">Estimated Completion Time</div>
        <div class="col-1 titlesTableProjects"></div>
      </div>
    
      <div v-for="(project, index) in projects" :key="index" :class="'badges-list-border m-0 row '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1') ">
          
          <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{project.name}}</div>

          <div class="col-2 d-flex justify-content-start align-items-center" :id="'a'+index" >
            <ul class="projectsListUl">
              <li class="badges-objectives-list textListProjects" v-for="(tool, index) in project.toolsRequired" :Key="index" v-if="project.toolsRequired">{{tool.name}}</li>               
            </ul>
          </div>

          <div class="col-2 d-flex justify-content-start align-items-center" :id="'b'+index" v-if="config.preRequisites">
            <ul class="projectsListUl">
              <li class="badges-objectives-list textListProjects" v-for="(badge, index) in project.preRequisite" :Key="index">{{badge.name}}</li>                 
            </ul>
          </div>
          
          <div class="col-1 d-flex justify-content-start align-items-center">
            <div v-for="(plan, index) in project?.plans" :key="index" class="mr-3">
              <a :href="plan" class="far fa-file-pdf py-3 iconPDF" target="_blank"></a>
            </div>
          </div>

          <div class="col-2 textListProjects d-flex justify-content-start align-items-center">{{project.supportingMaterial}}</div>
          <div class="col-1 textListProjects d-flex justify-content-center align-items-center">{{project.projectTheme}}</div>
          <div class="col-1 textListProjects d-flex justify-content-start align-items-center">{{project.projectLength}}</div>
          <div class="col-1 d-flex">
            <i class="fas fa-edit p-3 icon_tool iconEdit" @click="editProject(project)"></i>
            <i class="fas fa-trash p-3 icon_tool iconDelete" v-on:click="openConfirmationModal(project)"></i>
          </div>

      </div>
      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !projects.length">
        <h1 class="m-5">No Projects to display.</h1>
      </div>

      <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
        <div class="loader-whitebg"></div>
      </div>
    </div>

    <div class="row w-100 card-deck d-lg-none m-0 justify-content-start divCardToolList">
      
      <div class="col-sm-6 col-8 p-0 mt-5" v-for="(project, index) in projects" :key="index">
        <div class="card">
          <div class="d-flex text-center">
            <h5 class="card-title cardTitleTool col-12 text-center px-2">{{project.name}}</h5>
          </div>

          <div class="row card-body">
            <span class="col-6 cardSubtitle text-muted">Tools Required: </span>
            <span class="col-6 cardSubtitle text-muted">Pre-requisite: </span>
            
            <div class="col-6" :id="'a'+index" >
              <ul class="">
                <li class="cardContentList" v-for="(tool, index) in project.toolsRequired" :Key="index" v-if="project.toolsRequired">{{tool.name}}</li>                
              </ul>
            </div>       

            <div class="col-6" :id="'b'+index" >
              <ul class="">
                <li class="cardContentList" v-for="(badge, index) in project.preRequisite" :Key="index">{{badge.name}}</li> 
                <li class="text-muted" v-if="!project.preRequisite.length">No badges available.</li>
              </ul>
            </div>

            <span class="col-6 cardSubtitle text-muted mt-2">Duration time: </span>
            <span class="col-6 cardSubtitle text-muted mt-2">Project Theme: </span>

            <span class="col-6 cardContent">{{project.projectLength}}</span>
            <span class="col-6 cardContent">{{project.projectTheme}}</span>

            <span :class="{'col-8 cardSubtitle text-muted mt-4': project.plans, 'col-12 cardSubtitle text-muted mt-4': !project.plans}">Supporting Material: </span>
            <span class="col-4 cardSubtitle text-muted mt-4" v-if="project.plans">Plans: </span>

            <span :class="{'col-8 cardContent': project.plans, 'col-12 cardContent': !project.plans}">{{project.supportingMaterial}}</span>

            <div class="col-4 d-flex justify-content-start align-items-center">
              <div v-for="(plan, index) in project?.plans" :key="index" class="mr-3">
                <a :href="plan" class="far fa-file-pdf py-3 iconPDF" target="_blank"></a>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-around">
            <i class="fas fa-edit p-3 icon_tool iconEdit col-2" @click="editProject(project)"></i>
            <i class="fas fa-trash p-3 icon_tool iconDelete col-2" v-on:click="openConfirmationModal(project)"></i>
          </div>
        </div>
      </div>  

      <div class="badges-list-border col-12 row-collapse-color2 text-center bg-light" v-if="!loading && !projects.length">
        <h1 class="my-5">No Projects to display.</h1>
      </div>  

    </div>

  </div>
</template>
<script>
import confirmationModal from './confirmationDeleteModal.vue';
import { mapActions, mapState} from 'vuex'
export default {
  name: "projectsList",
  data(){
    return{
      loading : false,
      name:'',
      toolsRequired:[],
      preRequisite:[],
      plans:'',
      supportingMaterial:'',
      projectTheme:'',
      projectLength: Number,
      ProjectId:'',
      project: {},
    }
  },
  props:['badges','tools','projects'],
  components:{
    confirmationModal
  },
  computed:{
    ...mapState('user',['user']),
    ...mapState('configs', ['config'])
  },
  methods:{
    ...mapActions('projects', ['deleteProject', 'deleteDefaultProjects']),
    addProyect(){
      this.$router.push({ path: '/create-projects'})
    },
    openConfirmationModal(project = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? "+project.name || ""
			this.$refs.confirmModal.assetId = project._id || ""
			$('#confirmationModal').modal('show')
    },
    deleteProjectApi(id){
      this.loading = true
      if(this.user.role === "admin"){
        this.deleteDefaultProjects({id, that:this})
        this.loading = false
      }else if(this.user.role === "teacher"){
        this.deleteProject({id, that:this})
        this.loading = false
      }
    },
    editProject(defaultProjectId){
      this.$router.push({path: '/create-projects', query: {defaultProject: defaultProjectId._id}})
    }
  },
  mounted(){
    if (this.$route.query.project) {
      let projectId = this.$route.query.project
      API.projects.getProject(projectId)
      .then(res => {
        this.project = res.data
        console.log("#####",this.project)
      })
      .catch(err => console.log(err))
    }
  }
}
</script>