<template>
  <div>
    <badgesList :badges="badges" :objectives="objectives"/>
    <p class="text-danger w-100 text-center" v-if="errorBadgesAPI">
      {{ errorBadgesAPI }}
    </p>
  </div>
</template>
<script>
import badgesList from '../components/badgesList'
import { mapActions, mapState } from 'vuex'
export default {
  data(){
    return{
    }
  },
  components:{
    badgesList
  },
  computed:{
    ...mapState('badges',['badges', 'errorBadgesAPI']),
    ...mapState('objectives',['objectives'])
  },
  methods:{
    ...mapActions('badges',['getDefaultBadges']),
    ...mapActions('objectives',['getDefaultObjectives'])
  },
  created(){
    this.getDefaultBadges()
    this.getDefaultObjectives()
  }
}
</script>