<template>
  <div
    @click.stop
    class="d-flex justify-content-between align-items-center w-100"
  >
    <p class="text-blue f-semibold clamp-two-lines">{{ project.name }}</p>
    <span
      v-if="user.role === 'teacher' && students.length > 0 && !isDraft"
      class="hint--top ml-5"
      data-hint="Assign Students"
    >
      <BaseIcon
        width="33.684"
        height="40.166"
        @click="emitOpenModalAssign(project)"
        iconColor="#ff1616"
      >
        <StudentIcon />
      </BaseIcon>
    </span>
  </div>
</template>

<script>
import StudentIcon from "../../assets/icons/student.vue";
import BaseIcon from "../baseIcon.vue";
export default {
  props: {
    text: {},
    user: {},
    students: {},
    isDraft: {},
    project: {},
  },
  components: {
    BaseIcon,
    StudentIcon,
  },
  methods: {
    emitOpenModalAssign(project) {
      this.$parent.$emit("assign-students", project);
    },
  },
};
</script>

<style scoped>
p {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  color: var(--capable-blue);
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clamp-two-lines {
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

svg {
  height: 34px
}

@media (max-width: 768px) {
  p {
    font-size: 20px;
  }
  svg {
    height: 24px;
  }
}
</style>
