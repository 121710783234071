import _ from "lodash";
import projectStateTemplate from "./projectStateTemplate";

export default {
  setProjectProp(state, { prop, value }) {
    _.set(state, prop, value);
  },
  setProjectStepProp(state, { prop, index, value }) {
    _.set(state, `steps.${index}.${prop}`, value);
  },
  addProjectStep(state, { step }) {
    if (state.steps && state.steps.length) {
      state.steps.push(step);
    } else {
      state.steps = [step];
    }
  },
  deleteProjectStep(state, { index }) {
    state.steps.splice(index, 1);
  },
  setErrorProjectApi(state, errorProjectAPI) {
    state.errorProjectAPI = errorProjectAPI;
  },
  resetProject(state) {
    const initialState = {...projectStateTemplate};
    Object.assign(state, initialState);
  },
};
