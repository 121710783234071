<template>
  <path
    id="Path_191"
    data-name="Path 191"
    d="M10,0h4.152a.361.361,0,0,0,.081.046,1.8,1.8,0,0,1,1.386,1.449c.131.569.249,1.141.389,1.708a.482.482,0,0,0,.207.262q.865.522,1.747,1.015a.479.479,0,0,0,.33.052c.56-.164,1.113-.352,1.671-.524a1.821,1.821,0,0,1,2.237.875q.839,1.439,1.667,2.885a1.835,1.835,0,0,1-.355,2.378c-.421.394-.854.775-1.267,1.176a.52.52,0,0,0-.134.333q-.019,1,0,2a.519.519,0,0,0,.135.333c.408.4.834.771,1.25,1.159a1.838,1.838,0,0,1,.359,2.416q-.814,1.412-1.631,2.821a1.831,1.831,0,0,1-2.331.893c-.525-.166-1.051-.329-1.574-.5a.405.405,0,0,0-.383.057c-.552.341-1.112.669-1.682.979a.46.46,0,0,0-.267.341c-.125.587-.253,1.174-.4,1.757a1.783,1.783,0,0,1-1.774,1.4q-1.742.008-3.484,0a1.786,1.786,0,0,1-1.773-1.4c-.148-.6-.269-1.2-.419-1.8a.484.484,0,0,0-.207-.263q-.865-.522-1.747-1.015a.477.477,0,0,0-.33-.046c-.56.163-1.113.35-1.672.521a1.825,1.825,0,0,1-2.236-.878Q1.121,18.993.3,17.559A1.838,1.838,0,0,1,.66,15.144c.422-.392.853-.776,1.269-1.175a.453.453,0,0,0,.116-.294c.01-.674.011-1.349,0-2.023a.519.519,0,0,0-.136-.332c-.42-.407-.859-.793-1.285-1.194A1.819,1.819,0,0,1,.265,7.809q.849-1.49,1.716-2.971A1.8,1.8,0,0,1,4.143,4q.85.256,1.693.534a.4.4,0,0,0,.382-.055q.84-.515,1.7-.99A.389.389,0,0,0,8.154,3.2c.132-.611.265-1.222.414-1.828A1.743,1.743,0,0,1,9.64.136C9.759.086,9.882.045,10,0M9.91,16c1.423,0,2.814,0,4.206-.005a.225.225,0,0,0,.171-.122A2.59,2.59,0,0,1,15.18,14.6a4.513,4.513,0,0,0,1.635-3.339,4.689,4.689,0,0,0-5.321-4.889A4.507,4.507,0,0,0,7.547,9.715,4.445,4.445,0,0,0,8.98,14.6,2.461,2.461,0,0,1,9.91,16m.025,1.06a2.765,2.765,0,0,0,.12,1.214,1.081,1.081,0,0,0,1.016.7c.675.016,1.35.012,2.025,0a1.125,1.125,0,0,0,1.089-.878,8.055,8.055,0,0,0,.059-1.037Z"
    transform="translate(0)"
  />
</template>

<script>
export default {};
</script>
