<template>
  <path
    id="Path_216"
    data-name="Path 216"
    d="M0,12.158v-.868c.017-.024.045-.046.05-.073a2.832,2.832,0,0,1,1.465-1.97c.859-.495,1.709-1,2.556-1.519a.642.642,0,0,0,.257-.317c.342-1.027.713-2.048.99-3.094A2.85,2.85,0,0,1,8.336,2.274c1.1.107,2.2.17,3.306.265a.636.636,0,0,0,.527-.179c.6-.541,1.2-1.072,1.808-1.606a2.775,2.775,0,0,1,3.8-.005c.6.533,1.213,1.062,1.806,1.607a.665.665,0,0,0,.557.18c1.081-.091,2.163-.169,3.244-.255a2.785,2.785,0,0,1,3.1,2.243c.211.883.416,1.768.644,2.646a.676.676,0,0,0,.274.37c.95.584,1.912,1.148,2.867,1.723a2.78,2.78,0,0,1,1.216,3.678c-.369.9-.746,1.79-1.1,2.692a.682.682,0,0,0,0,.457c.351.893.723,1.777,1.088,2.664A2.792,2.792,0,0,1,30.224,22.5c-.93.557-1.857,1.119-2.794,1.664a.633.633,0,0,0-.324.449c-.2.875-.418,1.746-.625,2.619a2.87,2.87,0,0,1-1.093,1.712,3.105,3.105,0,0,1-2.242.5c-1.018-.093-2.039-.167-3.059-.231a.641.641,0,0,0-.41.137c-.477.4-.947.8-1.4,1.225a4.056,4.056,0,0,1-2.027,1.182h-.744a4.029,4.029,0,0,1-2-1.162c-.452-.423-.921-.83-1.4-1.223a.73.73,0,0,0-.462-.157c-1.134.07-2.265.187-3.4.242A2.758,2.758,0,0,1,5.273,27.2c-.209-.872-.424-1.744-.626-2.618a.639.639,0,0,0-.333-.444c-.963-.56-1.919-1.134-2.877-1.7A2.774,2.774,0,0,1,.053,20.514.468.468,0,0,0,0,20.406V19.6c.115-.327.219-.659.349-.981.342-.849.7-1.691,1.037-2.542a.6.6,0,0,0,.01-.4C.939,14.5.468,13.329,0,12.158m26.114,3.731A10.234,10.234,0,1,0,15.862,26.111,10.249,10.249,0,0,0,26.115,15.889"
    transform="translate(-0.001 0)"
  />
  <path
    id="Path_217"
    data-name="Path 217"
    d="M98.968,90.617a8.372,8.372,0,1,1-8.369,8.36,8.385,8.385,0,0,1,8.369-8.36m-.915,9.938a4.57,4.57,0,0,0-.281-.361q-1.356-1.361-2.716-2.718a.954.954,0,0,0-.949-.317.928.928,0,0,0-.391,1.609c1.105,1.115,2.24,2.2,3.322,3.339.766.807,1.277.754,2.011,0,1.686-1.731,3.41-3.427,5.122-5.133a1,1,0,0,0,.362-.966.885.885,0,0,0-.666-.7.93.93,0,0,0-.955.29q-2.3,2.3-4.6,4.6a3.292,3.292,0,0,0-.257.356"
    transform="translate(-83.093 -83.11)"
  />
</template>

<script>
export default {};
</script>
