import API from "../../services/api";
import _ from 'lodash'

export default{

  getTools: async (  { commit } ) => {
    await API.tools.getTools()
      .then(res => commit('setTools', res.data) )
      .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  CreateNewTool:( { commit, dispatch }, data ) => {
    API.tools.CreateNewTool(data)
      .then(res => dispatch('getTools'))
      .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteTool:( { commit, dispatch }, id ) => {
    API.tools.deleteTool(id)
      .then(res => dispatch('getTools'))
      .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  updateTool:( { commit, dispatch }, {toolId, data} ) => {
    API.tools.updateTool(toolId, data)
      .then(res => dispatch('getTools') )
      .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },
  
  getDefaultTools:( { commit } ) => {
    API.defaultTools.getDefaultTools()
    .then(res => commit('setTools', res.data) )
    .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  CreateNewDefaultTool:( { commit, dispatch }, data ) => {
    API.defaultTools.CreateNewDefaultTool(data)
      .then(res => dispatch('getDefaultTools'))
      .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteDefaultTool:( { commit, dispatch }, id ) => {
    API.defaultTools.deleteDefaultTool(id)
    .then(res => dispatch('getDefaultTools') )
    .catch(error => commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },
  
  updateDefaultTool:( { commit, dispatch }, {toolId, data} ) => {
    API.defaultTools.updateDefaultTool(toolId, data)
      .then(res => dispatch('getDefaultTools') )
      .catch(error => {
        commit('setErrorToolsApi', _.get(error, "response.data.errors.message", "Error please try later."))
        console.log(error)
      })
  },

}