<template>
  <div class="d-flex justify-content-between align-items-center w-100">
    <Checkbox @click.stop v-model="project.selected"/>
    <p>{{ text }}</p>
  </div>
</template>

<script>
import Checkbox from "../checkbox.vue";
export default {
  components: { Checkbox },
  props: ["text", "project"],
};
</script>

<style scoped>
p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--capable-blue);
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  p {
  margin-bottom: 0px !important;
}
}
</style>
