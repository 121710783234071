<template>
  <div class="row d-flex justify-content-center">
    <div class="col-6 col-md-3">
      <div class="row">
        <ActionButton
          color="red"
          class="px-3 py-4"
          v-if="currentStep.order < steps[steps.length - 1].order"
          @click="goToNextStep"
          fontsize="text-18-px"
        >
          Save and continue
        </ActionButton>
        <ActionButton
        color="red"
        v-else
        @click="save"
        class="py-4"
        fontsize="text-16-px">
          {{ this.$route.query.project ? "Edit Project" : "Create Project" }}
        </ActionButton>
      </div>
      <div class="row">
        <i class="mt-3 text-center text-blue">
          You can return later and make changes
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "../../../components/actionButton.vue";
export default {
  name: "ProjectImage",
  props: ["currentStep", "steps"],
  components: {
    ActionButton,
  },
  methods: {
    goToNextStep(e) {
      e.preventDefault();
      const nextStep = this.steps.find((s) => {
        return s.order > this.currentStep.order;
      });
      if (!nextStep) {
        console.log("Last step reached");
        return;
      }
      this.$emit("step-changed", nextStep);
    },
    save(e) {
      e.preventDefault();
      console.log("Emiting project save");
      this.$emit("project-save");
    },
  },
};
</script>
