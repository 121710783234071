<template>
  <div class="container mt-5 divContainerAdminGeneral">
    <ModalAdmin ref="ModalAdmin" @reloadTeacherList="getTeachers"/>    
    <confirmationModal ref="confirmModal" @confirm="deleteTeacherAPI" />
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral">Teachers</h2>
    </div>
    <hr class="w-100" />
    <p class="text-danger" v-if="errorMessage">
      {{ errorMessage }}
    </p>
    <div class="row w-100 divContainerAdminGeneral mt-3 d-none d-lg-block">      
      <div class="d-flex w-100">
        <div class="col-2 titleAdminTableGeneral pl-0">Name</div>
        <div class="col-3 titleAdminTableGeneral pl-0">Email</div>
        <div class="col-2 titleAdminTableGeneral pl-0">Pin</div>
        <div class="col-2 titleAdminTableGeneral pl-0">Created</div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
      </div>
      <div v-for="(teacher, index) in teachers" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{teacher.name}}</div>
        <div class="col-3 textTableAdminGeneral d-flex justify-content-start align-items-center">{{teacher.email}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{teacher.teacherPin}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{formatDate(teacher.createdAt)}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(teacher)"></i></div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-edit p-2 icon_tool iconEdit" v-on:click="openModalTeachers(teacher)"></i></div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-door-open table-icon-sideAccess" @click="sideAccess(teacher._id)"></i></div>        
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !teachers.length">
        <h1 class="m-5">No Teachers to display.</h1>
      </div>

    </div>

    <div class="row w-100 card-deck m-0 justify-content-start divCardToolList d-lg-none">

      <div class="col-sm-6 col-8 p-0 mt-5" v-for="(teacher, index) in teachers" :key="index">
        <div class="card">
          <div class="d-flex text-center">
            <h5 class="card-title cardTitleTool col-12 text-center px-2">{{teacher.name}}</h5>
          </div>

          <div class="row card-body">
            <span class="col-12 cardSubtitle text-muted">Email: </span>
            <span class="col-12 cardContent">{{teacher.email}}</span>


            <span class="col-6 cardSubtitle text-muted mt-2">Created: </span>
            <span class="col-6 cardSubtitle text-muted mt-2">Pin: </span>
            <span class="col-6 cardContent">{{formatDate(teacher.createdAt)}}</span>
            <span class="col-6 cardContent">{{teacher.teacherPin}}</span>

          </div>

          <div class="d-flex justify-content-around">
            <i class="fas fa-edit p-3 icon_tool iconEdit col-2" v-on:click="openModalTeachers(teacher)"></i>
            <i class="fas fa-door-open p-3 table-icon-sideAccess" @click="sideAccess(teacher._id)"></i>
            <i class="fas fa-trash p-3 icon_tool iconDelete col-2" v-on:click="openConfirmationModal(teacher)"></i>
          </div>
        </div>
      </div>

              
    </div>

    <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
      <div class="loader-whitebg"></div>
    </div>

  </div>
</template>
<script>
import API from "../services/api";
import _ from 'lodash'
import moment from 'moment';
import confirmationModal from './confirmationDeleteModal.vue';
import ModalAdmin from './ModalAdmin'
import { mapActions, mapState} from 'vuex'
import jwt_decode from 'jwt-decode'
export default {
  name:"teacherList",
  data(){
    return{
      errorMessage: "",
      loading:false,

    }
  },
  components:{
    confirmationModal,
    ModalAdmin
  },
  computed:{
    ...mapState('teachers',['teachers', 'errorTeachersAPI']),
  },
  methods:{
    ...mapActions('teachers', ['getTeachers','deleteTeacher']),
    ...mapActions('user', ['saveUser']),
    sideAccess(userId) {
      API.admins.sideAccess(userId)
        .then(response => {
          let userDecode = jwt_decode(response.data)
          sessionStorage.setItem('token', response.data)
          sessionStorage.setItem('user', JSON.stringify(userDecode))
          this.$router.push('/project-search')
        })
        .catch(error => console.log(error))
    },
    deleteTeacherAPI(id){
      this.deleteTeacher(id)
    },
    openConfirmationModal(teacher = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? "+teacher.name || ""
			this.$refs.confirmModal.assetId = teacher._id || ""
      
      $('#confirmationModal').modal('show')
    },
    openModalTeachers(teacher = {}){
      this.$refs.ModalAdmin.name = teacher.name || ""
      this.$refs.ModalAdmin.email = teacher.email || ""
      this.$refs.ModalAdmin.teacherId = teacher._id || ""
      $('#teachersEditModal').modal('show')
    },
    formatDate(date){
      return moment(date).format('MM/DD/YYYY')
    },
  },
  created(){
    this.getTeachers()
  }
}
</script>