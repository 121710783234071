<template>
  <div v-bind:key="step.stepNumber" class="step-info col-10 mb-5">
    <div class="step-title text-white">
      <h3 class="f-bold">
        {{ `Step ${step.stepNumber}` }}
      </h3>
    </div>
    <div class="info">
      <p class="text-blue f-regular text-center info-step-desc" v-html="step?.description ? step?.description : 'No description available.'
        "></p>
      <div id="mediaCarousel" class="carousel slide d-flex justify-content-center align-items-center" data-ride="carousel"
        data-interval="false">
        <BaseIcon href="#mediaCarousel" role="button" data-slide="prev" width="10.475" height="16.469" class="px-3"
          iconColor="#004aad" v-if="showArrows()">
          <ArrowLeft />
        </BaseIcon>
        <div v-if="step?.imagesURL?.length ||
          step?.gifsURL?.length ||
          step?.videosURL?.lengthπ
          " class="carousel-inner">
          <div v-if="step?.imagesURL?.length > 0" class="carousel-item active">
            <div class="d-flex flex-column gap-3 justify-content-center align-items-center">
              <div class="img-container">
                <img class="projectIMG cursor-pointer" :src="step?.imagesURL[0]" @click="showImage(step?.imagesURL[0])" />
              </div>
            </div>
          </div>

          <div v-if="step?.gifsURL?.length > 0" class="carousel-item">
            <div v-for="(gif, index) in step?.gifsURL" :key="index"
              class="d-flex flex-column justify-content-center align-items-center">
              <div class="img-container">
                <img class="projectIMG" :src="gif" />
              </div>
            </div>
          </div>
          <div v-if="step?.videosURL?.length > 0" class="carousel-item">
            <div v-for="(video, index) in step?.videosURL" :key="index"
              class="d-flex flex-column justify-content-center align-items-center">
              <div class="img-container">
                <video class="text" height="240" controls muted playsinline :src="step?.videosURL[index]"
                  type="video/webm"></video>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="not-image-project"></div>
        </div>
        <BaseIcon href="#mediaCarousel" role="button" data-slide="next" width="10.475" height="16.469" class="px-3"
          iconColor="#004aad" v-if="showArrows()">
          <ArrowRight />
        </BaseIcon>
      </div>
      <div v-if="approveButton" class="d-flex flex-wrap justify-content-center gap-5 mb-5">
        <ActionButton color="red" :class="`action-button px-5 py-3 ${isDisabled() || user.role == 'teacher'
            ? studentStep?.status === 'isCompleted' ||
              studentStep?.status === 'Approved'
              ? 'disabled-green'
              : 'disabled'
            : 'red'
          } ${studentStep?.status === 'Pending' && 'orange-bg'}`" @click="handleEmit(step)">
          <BaseIcon width="40.01" height="29.095">
            <TickIcon />
          </BaseIcon>
        </ActionButton>

        <ActionButton v-if="studentStep?.status === 'isCompleted' &&
          studentStep?.hasMultipleChoice
          " color="red" :class="`px-5 py-3 mc-button`" @click="this.$emit('open-multiple-choice-solved-modal', step)">
          <i :class="{
            'open-multiple-choice icon-question': true,
            ' border-radius-left': !config?.studentUploadImages,
          }"></i>
        </ActionButton>
        <ActionButton v-if="config.studentUploadImages &&
          showCamera() &&
          user.role !== 'teacher'
          " color="blue" text="Button" @click="emitOpenModalImage" class="px-5 py-3">
          <BaseIcon width="39" height="31.751" class="camera-icon">
            <CameraIcon />
          </BaseIcon>
        </ActionButton>
        <ActionButton v-if="imageURL?.length" color="blue" text="Button" @click="this.$emit('open-modal-image', imageURL)"
          class="px-5 py-3">
          <BaseIcon width="41.951" height="31.486" class="camera-icon">
            <ImageIcon />
          </BaseIcon>
        </ActionButton>
      </div>
      <div v-else class="approvals">
        <p v-if="!step?.needApproval.length" class="text-blue f-bold text-center">
          No approval needed
        </p>
        <p v-else class="text-blue f-bold text-center">Need approval</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ArrowLeft from "../../assets/icons/arrowLeft.vue";
import ArrowRight from "../../assets/icons/arrowRight.vue";
import CameraIcon from "../../assets/icons/camera.vue";
import ImageIcon from "../../assets/icons/image.vue";
import TickIcon from "../../assets/icons/tick.vue";
import CamRecorder from "../../views/createProject/components/CamRecorder.vue";
import ActionButton from "../actionButton.vue";
import BaseIcon from "../baseIcon.vue";
import Checkbox from "../checkbox.vue";

export default {
  props: {
    step: {},
    approveButton: {
      type: Boolean,
      default: false,
    },
    studentStep: {},
    isMobileDevice: {
      type: Boolean,
      default: false,
    },
    imageURL: "",
    length: 0,
  },
  computed: {
    ...mapState("configs", ["config"]),
    ...mapState("user", ["user"]),
    stepStatus: {
      get() {
        if (this.studentStep?.isCompleted) {
          return this.studentStep.isCompleted;
        } else {
          return false;
        }
      },
      set(val) {
        if (this.studentStep.isCompleted) {
          this.studentStep.isCompleted = val;
        } else {
          this.studentStep.isCompleted = val;
        }
      },
    },
  },
  components: {
    ArrowLeft,
    ArrowRight,
    BaseIcon,
    Checkbox,
    ActionButton,
    TickIcon,
    CamRecorder,
    CameraIcon,
    ImageIcon,
  },
  methods: {
    showArrows() {
      if (
        (this.step?.imagesURL.length > 0 && this.step?.gifsURL.length > 0) ||
        (this.step?.imagesURL.length > 0 && this.step?.videosURL.length > 0) ||
        (this.step?.gifsURL.length > 0 && this.step?.videosURL.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDisabled() {
      return (
        this.studentStep?.status == "Pending" ||
        this.studentStep?.status == "isLocked" ||
        this.studentStep?.status == "Approved" ||
        this.studentStep?.status == "isCompleted"
      );
    },
    showCamera() {
      return (
        this.studentStep?.status === "isCompleted" ||
        this.studentStep?.status === "Approved" ||
        this.studentStep?.status === "Pending" ||
        this.studentStep?.status === "Rejected" ||
        this.studentStep?.status === "inProgress"
      );
    },
    handleEmit(step) {
      if (!this.isDisabled()) {
        this.$emit("completed-step", step);
      }
    },
    emitOpenModalImage() {
      let data = {
        isMobileDevice: this.isMobileDevice,
        index: this.step.stepNumber - 1,
        step: this.step,
        modalId: `mediaModal-index-${this.step.stepNumber - 1}`,
      };
      this.$emit("open-media-modal", data);
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 22px;
  margin: 0px;
}

p {
  font-size: 22px;
  text-align: start;
}

img,
video {
  justify-content: center;
  border-radius: 8px;
  object-fit: cover;
  width: 280px;
  height: 200px;
}

svg {
  width: 30px;
  height: 30px;
}


.approvals p {
  font-size: 18px;
}

.icon-question {
  font-size: 25px;
  width: 20px;
  height: 45px;
}

.action-button svg {
  width: 35px;
}

.not-image-project {
  width: 200px;
  height: 180px;
}

.step-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 11px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
  height: 600px;
  padding: 0px;
}

.step-title {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--capable-red);
  border-radius: 11px 11px 0px 0px;
  min-height: 78px;
}

.mc-button {
  padding: 0px 4rem !important;
}

.active {
  display: flex !important;
  justify-content: center;
  gap: 20px;
}

.carousel-inner {
  width: 75%;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--capable-white);
  border-radius: 0px 0px 11px 11px;
  height: 100%;
  gap: 15px;
}

.info-step-desc {
  padding:0 10%;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 200px;
}


.step-title h3 {
  font-size: 18px;
}

.info p {
  font-size: 18px;
  line-height: 20px;
}

@media (max-width: 1250px) {
  p {
    font-size: 16px;
    margin: 0px;
  }
}

@media (max-width: 768px) {
  p {
    padding: 5px;
    margin-top: 15px;
  }

  svg {
    width: 35px;
    height: 20px;
  }

  .step-title {
    min-height: 53px;
  }

  .step-title h3 {
    font-size: 14px;
  }

  .info p {
    font-size: 14px;
  }

  .step-info {
    height: auto;
    margin: auto;
  }

  .approvals p {
    font-size: 13px;
  }
}
</style>
