<template>
  <div
    class="modal fade"
    id="modal-image"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content d-flex flex-column gap-3">
        <div class="d-flex justify-content-between align-items-start">
          <h2 class="f-bold text-white">{{ text }}</h2>
          <button
            type="button"
            class="btn-close btn-close-white d-flex"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>

        <img :src="imageURL" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["imageURL", "text"],
  methods: {
    closeModal() {
      $("#modal-image").modal("hide");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 30px;
  margin: 0px;
}
.modal-content {
  padding: 25px 60px;
  background-color: var(--capable-blue);
}

.btn-close {
  position: relative;
  margin: 0px;
}

button {
  position: absolute;
  right: 0;
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
}

img{
  border-radius: 8px;
}
</style>
