<template>
  <div class="modal fade" id="confirmationCloseModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
							<div class="div_modal_form modal-body">
								<h3 class="messageDeleteModal">Are you sure you want to close?</h3>
							</div>
							<div class="modal-footer">
								<button type="button" class="btn btn-secondary btn-lg btnModalSecondary" v-on:click="cancelClose()">No</button>
								<button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="confirmClose()">Yes</button>
							</div>
					</div>
        </div>
    </div>
</template>
<script>
export default {
	name: "confirmationCloseModal",
  data(){
    return{
			modalId:''
    }
  },
	methods:{
		cancelClose(){
			$('#confirmationCloseModal').modal('hide')
		},
		confirmClose(){
			this.$emit("confirm")
			$('#'+this.modalId).modal('hide')
			$('#confirmationCloseModal').modal('hide')
		}
	}
}
</script>