const accountRecovery = axios => {
  return {
    async sendRecoveryMail(data) {
      const res = await axios.post(`/api/account-recovery`, data)
      return res.data
    },
    async changePassword(data) {
      return axios.put(`/api/account-recovery/reset`, data)
    },
    async changeStudentPassword(data) {
      return axios.put(`/api/account-recovery/student/reset`, data)
    },
  }
}
export default accountRecovery;