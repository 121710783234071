<template>
  <div
    v-for="(student, index) in students"
    :key="index"
    :class="`d-flex background-white p-0 ${index != students.length - 1 ? 'bottom-border' : 'border-rounded'}`"
  >
    <div
      class="col-2 d-flex justify-content-center align-items-center right-border"
    >
      <p class="text-blue table-data-text f-bold">
        {{ student.name }}
      </p>
    </div>
    <div
      class="col-2 d-flex justify-content-center align-items-center right-border"
    >
      <p class="text-blue table-data-text f-medium">
        {{ student.username ? student.username : "None" }}
      </p>
    </div>

    <div
      class="col-1 d-flex justify-content-center align-items-center right-border"
    >
      <p class="text-blue table-data-text f-medium">
        {{ `${student.grade}th` }}
      </p>
    </div>

    <div
      class="col-2 d-flex justify-content-center align-items-center right-border"
    >
      <p class="text-blue table-data-text">{{ formatDate(student.lastLogin) }}</p>
    </div>
    <div
      class="col-4 d-flex justify-content-between align-items-center right-border"
    >
      <p class="text-blue f-bold table-data-text" v-if="!student?.projects?.length">None</p>
      <div v-else class="w-100">
        <div
          v-for="(project, index) in student.projects"
          :key="index"
          class="w-100"
        >
          <div
            @click="seeStudentProject(project._id, student._id)"
            v-if="project?.name"
            class="project-link d-flex justify-content-between align-items-center py-3"
          >
            <p class="f-bold text-blue d-flex w-40 justify-content-between m-0 table-data-text">
              {{
                project?.name.length < 45
                  ? project?.name
                  : project?.name.substring(0, 45) + "..."
              }}
            </p>
            <ProgressBar
              :percentage="project?.percentageOfCompletion"
              class="w-60"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-1 d-flex justify-content-center align-items-center">
      <span class="hint--top" :data-hint="'Change password'">
        <BaseIcon width="32.503" height="39.72" @click="$emit('openModal', student)"> <LockIcon /> </BaseIcon>
      </span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ProgressBar from "./progressBar.vue";
import BaseIcon from "./baseIcon.vue";
import LockIcon from "../assets/icons/lock.vue";

export default {
  props: ["students"],
  methods: {
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    seeStudentProject(projectId, studentId) {
      return this.$router.push({
        path: "/teacher/see-student-project",
        query: { project: projectId, student: studentId },
      });
    },
  },
  components: {
    ProgressBar,
    BaseIcon,
    LockIcon,
  },
    emits: ["openModal"],
};
</script>

<style scoped>
p {
  font-size: 18px;
  margin-bottom: 0px;
}

svg{
  width: 25px;
  height: 25px;
}

.background-white{
  background-color: var(--capable-white);
}

.hint--top{
  cursor: pointer;
}
.project-link:hover {
  cursor: pointer;
}
.bottom-border {
  border-bottom: 0.5px solid var(--capable-grey);
}
.right-border {
  border-right: 0.5px solid var(--capable-grey);
}

.border-rounded{
  border-radius: 0px 0px 11px 11px;
}

.w-40{
  width: 40%;
}

.w-60{
  width: 60%;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6{
  min-height: 70px;
}

.table-data-text{
  font-size: 16px;
}
</style>
