<template>
  <rect
    id="Rectangle_345"
    data-name="Rectangle 345"
    width="35.758"
    height="7.946"
    rx="3"
    transform="translate(282 1035.406)"
    fill="#004aad"
  />
  <rect
    id="Rectangle_346"
    data-name="Rectangle 346"
    width="35.758"
    height="7.946"
    rx="3"
    transform="translate(295.906 1057.258) rotate(-90)"
    fill="#004aad"
  />
</template>

<script>
export default {};
</script>
