<template>
  <div class="card-info d-flex flex-column justify-content-center align-items-start">
    <div
    class="rotate-card-info-container"
    v-if="isMobileDevice"
    >
      <BaseIcon
          class="cursor-pointer rotate-card-info"
          width="13.616" height="13.51"
          @click="(e) => $emit('flipCard', e)"
          :iconColor="toggleCross ? '#b0b3b7' : '#ffffff'"
          data-dismiss="modal"
        >
        <CrossIcon />
      </BaseIcon>

    </div>
    <div class="ml-5" v-if="project.projectTheme">
      <h3>Project Theme:</h3>
      <p>{{ project.projectTheme }}</p>
    </div>
    <div v-if="project?.toolsRequired.length" class="ml-5">
      <h3>Tools required:</h3>
      <ul>
        <li v-for="(tool, index) in project.toolsRequired" v-bind:key="index">
          {{ tool?.name }}
        </li>
      </ul>
    </div>
    <div class="ml-5">
      <h3>Duration time:</h3>
      <p>{{ project.steps.length }} steps</p>
    </div>
  </div>
</template>

<script>

import BaseIcon from "../baseIcon.vue";
import CrossIcon from "../../assets/icons/cross.vue";
export default {
  props: ["project"],
  data() {
    return {
      projectTools: ["First tool", "Second toold", "Third tool"],
      isMobileDevice: null
    };
  },
  components: {
    BaseIcon,
    CrossIcon,
  },
  methods: {
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },
  },
  beforeMount() {
    this.checkDeviceType()
  }
};
</script>

<style scoped>
.card-info {
  color: var(--capable-white);
  background-color: var(--capable-blue);
  border-radius: 11px;
  width: 335.405px;
  height: 340px;
  padding-top: 0 !important;
  position: relative;
}

.rotate-card-info-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.rotate-card-info {
  width: 20px;
  height: 20px;
}

h3 {
  margin: 0;
  font-size: 17px;
  color: var(--capable-white);
  text-align: start;
}

ul {
  padding: 0px;
}

p,
li {
  text-align: start;
  font-size: 15px;
  font-weight: 400;
}

li {
  list-style-type: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .card-info {
    width: 316px;
    height: 290px;
  }
}
</style>
