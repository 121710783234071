<template>
  <div
    class="modal fade"
    id="whiteModal"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-blue">
        <div class="modal-header px-5 pt-5 d-flex justify-content-between align-items-center">
          <h5 class="modal-title f-bold">{{ title }}</h5>
          <button type="button" class="close" data-bs-dismiss="modal">
            <BaseIcon width="18.717" height="18.73" iconColor="currentColor"> <CrossThinIcon /> </BaseIcon>
          </button>
        </div>
        <div class="w-100 p-0">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "../baseIcon.vue";
import CrossThinIcon from "../../assets/icons/crossThin.vue";
import ActionButton from "../actionButton.vue";

export default {
  name: "whiteModal",
  props: ["title"],
  components: { ActionButton, BaseIcon, CrossThinIcon },
  methods: {
    confirm() {
      this.$emit("confirm", this.id);
    },
  },
};
</script>

<style scoped>

svg{
  color: var(--capable-blue);
}

h5 {
  font-size: 20px;
}

.close{
  opacity: 1;
}
.modal-header {
  margin: 0px;
  border: 0px;
}


.text-success {
  margin: auto;
}

.password {
  color: var(--capable-red);
}

.modal-content {
  background-color: var(--capable-white);
  max-width: 315px;
}

.modal-dialog-centered{
  justify-content: center;
}
</style>
