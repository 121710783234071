<template>
  <div
    v-if="!isLoading"
    class="container-warehouse d-flex flex-column justify-content-center align-items-center gap-4"
  >
    <div
      class="title-input-container col-12 p-0 d-flex flex-wrap justify-content-between justify-content-lg-around w-100"
    >
      <h2 class="title-warehouse text-blue f-bold">Project Warehouse</h2>
      <div class="d-flex gap-3">
        <BaseInput
          class="px-4 py-4 w-100"
          :placeholder="'Search by project'"
          v-model="projectName"
          :border="true"
        >
          <template #rightSlot>
            <div class="d-flex align-items-center mr-3">
              <BaseIcon :iconColor="`#b0b3b7`" width="23.187" height="22.871">
                <MagnifierIcon />
              </BaseIcon>
            </div>
          </template>
        </BaseInput>
        <ActionButton
          color="blue"
          :text="!isMobileDevice ? 'Advanced Filter' : ''"
          class="px-3 text-nowrap"
          data-toggle="modal"
          data-target="#advancedFilter"
          @mouseenter="toggleIcon = true"
          @mouseleave="toggleIcon = false"
        >
          <template #leftIcon>
            <div class="mx-3 settings">
              <baseIcon
                width="38.888"
                height="35.327"
                :iconColor="toggleIcon ? '#004aad' : '#fff'"
              >
                <SettingsWhite />
              </baseIcon>
            </div>
          </template>
        </ActionButton>
      </div>
    </div>

    <CardGrid v-if="originalProjects.length">
      <ProjectCard
        v-for="(project, index) in filteredByName"
        :user="user"
        :project="project"
        v-bind:key="index"
        cardType="WarehouseCard"
        :shadow="true"
      />
    </CardGrid>
    <h3 v-else class="title-warehouse text-blue f-regular">
      No projects found
    </h3>
    <ActionButton
      class="px-4 py-4 my-2"
      color="red"
      text="Add projects"
      @click="addProjects"
      v-if="originalProjects.length"
    />
    <AdvancedFilterModal
      :tools="tools"
      :projects="originalProjects"
      @search="handleSearch"
    />
  </div>
  <div  class="project-container" v-else>
    <div class="loader-whitebg"></div>
  </div>
</template>

<script>
import API from "../services/api";
import { mapState } from "vuex";
import ProjectCard from "../components/ProjectCard/projectCard.vue";
import CardGrid from "../components/ProjectCard/cardGrid.vue";
import ActionButton from "../components/actionButton.vue";
import BaseInput from "../components/input.vue";
import BaseIcon from "../components/baseIcon.vue";
import MagnifierIcon from "../assets/icons/magnifier.vue";
import SettingsWhite from "../assets/icons/settingsWhite.vue";
import AdvancedFilterModal from "../components/Modals/advancedFilter.vue";
import { checkDeviceType } from "../utils";

export default {
  data: () => ({
    isLoading: Boolean,
    projectName: "",
    tools: [],
    badges: [],
    projects: [],
    toolsRequired: [],
    preRequisite: [],
    projectLength: 0,
    defaultProjects: [],
    originalProjects: [],
    projectsSelected: [],
    showMore: false,
    toggleIcon: false,
    isMobileDevice: false,
  }),
  components: {
    ProjectCard,
    CardGrid,
    ActionButton,
    BaseInput,
    BaseIcon,
    MagnifierIcon,
    SettingsWhite,
    AdvancedFilterModal,
  },
  computed: {
    ...mapState("user", ["user"]),
    filteredByName() {
      if (!this.projectName) return this.originalProjects;
      return this.originalProjects.filter((p) =>
        p.name.toLowerCase().includes(this.projectName.toLowerCase())
      );
    },
  },
  methods: {
    async handleSearch(data) {
      this.projectName = "";
      const projects = await this.getDefaultProjects(data);
      this.originalProjects = projects;
    },
    async searchProject(index, toolId) {
      if (toolId) {
        if (this.toolsRequired.indexOf(toolId) > -1) {
          this.toolsRequired = this.toolsRequired.filter((id) => id !== toolId);
        } else {
          this.toolsRequired.push(toolId);
        }
      }
      if (index) {
        if (
          document.getElementById("btn-check-tool" + index).checked === true
        ) {
          $("#label-check-tool" + index).addClass("background-secondary2");
          $("#box-tool-selected" + index).addClass("background-secondary2");
        } else {
          $("#label-check-tool" + index).removeClass("background-secondary2");
          $("#box-tool-selected" + index).removeClass("background-secondary2");
        }
      }
      return API.defaultProjects
        .findDefaultProject({
          tools: this.toolsRequired,
          projectName: this.projectName,
        })
        .then((res) => {
          this.originalProjects = res.data;
        })
        .catch((err) => {
          this.originalProjects = this.defaultProjects;
        });
    },
    async getBadges() {
      return await API.badges.getBadges().then((res) => res.data);
    },
    async getDefaultTools() {
      return await API.defaultTools.getDefaultTools().then((res) => res.data);
    },
    async getDefaultProjects(filters) {
      return await API.defaultProjects
        .getDefaultProjects(filters)
        .then((res) => res.data);
    },
    async addProjects() {
      this.loading = true;

      this.projectsSelected = this.originalProjects
        .filter((project) => project.selected)
        .map((project) => ({ ...project, status: "published" }));
      await API.projects
        .AddDefaultProjects(this.projectsSelected)
        .then((res) => {
          if (this.projectsSelected.length === 0) {
            this.$toast.error(
              "No projects were selected. Select at least one and try again",
              {
                position: "top",
              }
            );
            this.originalProjects.forEach((project) => {
              project.selected = false;
            });
          } else {
            if (this.projectsSelected.length === 1) {
              this.$toast.success(
                `${this.projectsSelected.length} project was created`,
                {
                  position: "top",
                }
              );
              this.originalProjects.forEach((project) => {
                project.selected = false;
              });
            } else {
              this.$toast.success(
                `${this.projectsSelected.length} projects were created`,
                {
                  position: "top",
                }
              );
              this.originalProjects.forEach((project) => {
                project.selected = false;
              });
            }
          }
        })
        .then(() => {
          this.$router.push({ name: "My Projects" });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.getDefaultTools(),
      this.getBadges(),
      this.getDefaultProjects(),
    ]).then(([tools, badges, projects]) => {
      this.tools = tools;
      this.badges = badges;
      this.originalProjects = projects.map((p) => {
        return { ...p, selected: false };
      });
      this.defaultProjects = projects.map((p) => {
        return { ...p, selected: false };
      });
    });
    this.isMobileDevice = checkDeviceType();
    this.isLoading = false;
  },
};
</script>

<style scoped>
svg {
  cursor: pointer;
}

.settings svg {
  width: 30px;
}
.title-warehouse {
  font-size: 40px;
}

.collapsible {
  background: none;
  border: none;
  outline: none;
  font-family: "Quicksand", sans-serif;
  color: #707070;
  font-size: 36px;
}

.container-warehouse {
  width: auto;
  max-width: 1920px;
  margin: 0px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .title-warehouse {
    font-size: 20px;
    text-align: start;
  }
  .settings svg {
    width: 22px;
  }
}
@media (max-width: 676px) {
  .title-input-container {
    max-width: 316px;
  }
}
</style>
