<template>
  <div class="container d-flex flex-column create-project-page">
    <div class="row">
      <h2 class="form-title">
        {{ editing ? "Edit project" : "Create new project" }}
      </h2>
    </div>
    <div class="row justify-content-center">
      <ProjectTimeline
        @step-changed="onStepChanged"
        :current-step="currentStep"
        :steps="steps"
      />
      <div class="form-wrapper">
        <div class="row step-wrapper">
          <div class="col-12">
            <component
              :instantiateTextEditor="instantiateTextEditor"
              :is="currentStep.component"
              @fileUpload="handleFileUpload"
              @open-media-modal="handleOpenMediaModal"
              @openDisplayMediaModal="handleOpenDisplayMediaModal"
              :isMobileDevice="isMobileDevice"

              :current-step="currentStep"
              :steps="steps"
              @step-changed="onStepChanged"
              @set-skip-tools="setSkipTools"
              @auto-save-project="autoSaveProject"
              :editing="editing"
              />
          </div>
        </div>
        <GoToNextStep
          @step-changed="onStepChanged"
          :current-step="currentStep"
          :steps="steps"
          @project-save="saveProjects"
        />
        <div class="row">
          <div class="col-9"></div>
          <div v-if="false" class="col-3">
            <ActionButton
              color="blue"
              v-if="Object.keys(user).length"
              @click="openModalProjectAsStudent"
            >
              See as student <i class="icon-group ml-3"></i>
            </ActionButton>
          </div>
        </div>
      </div>
      <p v-if="errorMessage" class="text-danger">
        Error message: {{ this.errorMessage }}
      </p>
    </div>
    <ModalImportSteps @importStep="importStep" :user="user" />
    <ModalProjectAsStudent
      :isMobileDevice="isMobileDevice"
      :config="config"
      :errorMessages="errorMessages"
    />
    <MediaModal
      v-if="toggleModal"
      @fileUpload="handleFileUpload"
      :is-mobile-device="dataModalMedia && dataModalMedia.isMobileDevice"
      :index="dataModalMedia && dataModalMedia.index"
      :step="dataModalMedia && dataModalMedia.step"
      :modal-id="dataModalMedia && dataModalMedia.modalId"
    />
    <ViewMediaModal
      :data="viewMediaModalData"
      :modalId="`viewMediaModal-index-${viewMediaModalData?.stepIndex}`"
    />
  </div>
</template>

<script>
import ModalAddTool from "../components/ModalAddTool.vue";
import ModalImportSteps from "../components/importStepsModal.vue";
import ActionButton from "../components/actionButton.vue";
import MediaModal from "../views/createProject/components/MediaModal.vue";

import ProjectTimeline from "./createProject/components/ProjectTimeline.vue";
import StepName from "./createProject/components/StepName.vue";
import StepTheme from "./createProject/components/StepTheme.vue";
import StepTools from "./createProject/components/StepTools.vue";
import StepSupports from "./createProject/components/StepSupports.vue";
import StepPreRequisits from "./createProject/components/StepPreRequisits.vue";
import StepSteps from "./createProject/components/StepSteps.vue";
import GoToNextStep from "./createProject/components/GoToNextStep.vue";

import _ from "lodash";
import API from "../services/api";
import { mapActions, mapState } from "vuex";

import ReorderStepsModal from "../components/reorderStepsModal.vue";
import imageModal from "../components/modalImage.vue";
import ModalProjectAsStudent from "../components/modalProjectAsStudent.vue";
import ViewMediaModal from "../views/createProject/components/viewMediaModal.vue";

import { sortAlphabetically, wait } from "../utils";
import { shallowRef } from "vue";
const MAX_FILE_SIZE = 1024 * 2 * 1000; // 10MB
const emptyStep = {
  imagesURL: [],
  gifsURL: [],
  videosURL: [],
  name: "",
  description: "",
  needApproval: [],
  showListStudents: false,
  uploadingImage: false,
  uploadingGif: false,
  uploadingVideo: false,
  gifStatus: "noGif",
  videoStatus: "noVideo",
  hasMultipleChoice: false,
  stepNumber: 1,
  multipleChoice: {
    question: "",
    answer1: {
      answer: "",
      isCorrect: true,
    },
    answer2: {
      answer: "",
      isCorrect: false,
    },
    answer3: {
      answer: "",
      isCorrect: false,
    },
  },
};

export default {
  name: "createProjects",
  data() {
    return {
      toggleModal: false,
      currentStep: {
        name: "name",
        order: 0,
        component: shallowRef(StepName),
      },
      drag: [{ title: "number-1" }, { title: "number-2" }],
      GIF: "",
      studentProjectObject: {},
      selectedImgURL: "",
      gifURL: null,
      videoURL: null,
      editing: false,
      showList: false,
      uploadingImage: false,
      uploadingGif: false,
      uploadingVideo: false,
      gifStatus: "noGif",
      videoStatus: "noVideo",
      errorMessage: "",
      errorMessages: [],
      loading: false,
      imagePlans: "",
      badges: [],
      tools: [],
      projects: [],
      students: [],
      projectsRequired: [],
      stepNumber: 1,
      draftId: "",
      showReorderStepsModal: false,
      dataModalMedia: {
        isMobileDevice: null,
        index: null,
        step: null,
        modalId: null,
      },
      viewMediaModalData: {},
      skipTools: false
    };
  },
  components: {
    StepName,
    ActionButton,
    ProjectTimeline,
    GoToNextStep,
    ModalAddTool,
    ModalImportSteps,
    ReorderStepsModal,
    imageModal,
    ModalProjectAsStudent,
    MediaModal,
    ViewMediaModal,
  },
  methods: {
    ...mapActions("projects", [
      "updateDefaultProjects",
      "updateProjectDeleteDraft",
      "updateProjectDeleteDraft",
      "CreateNewDefaultProjects",
      "CreateNewProjects",
      "createNewDraft",
      "updateDraft",
      "deleteProjectWOutMessage",
      "updateUnautheticatedDraft",
      "createUnautheticatedDraft",
    ]),
    ...mapActions("signUp", ["setRole"]),
    ...mapActions("project", [
      "setProjectProp",
      "setProjectStepProp",
      "setProject",
      "resetProject",
    ]),
    instantiateTextEditor(index, isReorder = false) {
      let editor = new Jodit(`#editor-${index}`, {
        disablePlugins: [
          "about",
          "source",
          "video",
          "image",
          "file",
          "print",
          "preview",
        ],
        showTooltipDelay: 100,
        uploader: { url: "none" },
      });

      if (isReorder) {
        editor.value = this.project.steps[index].description;
      } else {
        this.setProjectStepProp({
          index,
          prop: "description",
          value: editor.value,
        });
      }

      return editor;
    },
    async importStep(step) {
      const steps = [...this.project.steps, step];
      this.setProjectProp({ prop: "steps", value: steps });
      await wait(0);
      this.instantiateTextEditor(this.project.steps.length - 1);
    },
    async handleOpenDisplayMediaModal(data) {
      this.viewMediaModalData = data;
      await wait(0);
      $(`#viewMediaModal-index-${this.viewMediaModalData?.stepIndex}`).modal(
        "show"
      );
    },
    async handleOpenMediaModal(data) {
      this.toggleModal = true;
      this.dataModalMedia = data;
      await wait(0);
      $(`#mediaModal-index-${this.dataModalMedia?.index}`).modal("show");
    },
    handleError(error) {
      this.errorMessages.push(error);
      this.$toast.error(error, {
        position: "top",
      });
    },
    async handleFileUpload(data) {
      const {
        e: {
          target: { files },
        },
        type,
        prop,
        index,
      } = data;

      const config = {
        imagesURL: { max: 1, text: "one image", type: "stepMedia" },
        videosURL: { max: 1, text: "one video", type: "stepMedia" },
        gifsURL: { max: 1, text: "one gif", type: "stepMedia" },
        plans: { max: 3, text: "three PDFs", type: "pdf" },
      };
      let currentContentLength;
      if (prop !== "projectImage") {
        currentContentLength =
          type === "pdf"
            ? this.project[prop].length
            : this.project.steps[index][prop].length;
        if (
          config[prop].type &&
          files.length + currentContentLength > config[prop].max
        ) {
          const errorMessage = `You can only add up to ${config[prop].text} per step.`;
          return this.handleError(errorMessage);
        }
      }

      for (let file of files) {
        if (file.size === MAX_FILE_SIZE) {
          const sizeExededError = "You exceeded the allowed image size.";
          return this.handleError(sizeExededError);
        } else {
          const { linkUrl } = await this.uploadFile(file);
          this.setValue(prop, type, linkUrl, index);
        }
      }
    },
    setValue(prop, type, url, index) {
      switch (type) {
        case "projectImage":
          return this.setProjectProp({ prop, value: url });

        case "pdf": {
          const container = [...this.project.plans];
          container.push(url);
          return this.setProjectProp({ prop, value: container });
        }

        case "stepMedia": {
          const step = { ...this.project.steps[index] };

          step[prop].push(url);

          return this.setProjectStepProp({ prop, index, value: step[prop] });
        }
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append("image", file, file.name);
      try {
        const res = await API.projects.UploadImageSteps(formData);
        return res.data;
      } catch (err) {
        console.log("err", err);
      }
    },

    onStepChanged(step) {
      console.log("Changed step! ", step);
      this.currentStep = step;
    },

    deleteProjectPlans(index) {
      this.project.plans.splice(index, 1);
    },

    createDataObject() {
      const steps = this.project.steps.map((step, index) => ({
        ...step,
        stepNumber: index + 1,
      }));

      const data = {
        name: this.project.name,
        description: this.project.description,
        toolsRequired: this.project.toolsRequired,
        preRequisite: this.project.preRequisite,
        projectsRequired: this.project.projectsRequired,
        plans: this.project.plans,
        supportingMaterial: this.project.supportingMaterial,
        projectTheme: this.project.projectTheme,
        projectImage: this.project.projectImage,
        projectLength: steps.length,
        steps: steps,
      };

      return data;
    },
    async createProject() {
      let initialDraft = this.createDataObject();
      if (this.project.attachedProject) {
        initialDraft = {
          ...initialDraft,
          attachedProject: this.project.attachedProject,
        };
      }
      try {
        let response;
        if (Object.keys(this.user).length) {
          response = await this.createNewDraft({ data: initialDraft });
        } else {
          response = await this.createUnautheticatedDraft({
            data: initialDraft,
          });
        }
        this.draftId = response.data._id;
      } catch (e) {
        this.$toast.error("Connection lost, auto-saving disabled", {
          position: "bottom-right",
          duration: false,
          maxToasts: 1,
        });
        console.error(e);
      }
    },
    async getNoToolsRequiredId() {
      let toolsRes;
      // Validate session
      if (Object.keys(this.user).length && this.user.role != 'admin') {
        toolsRes = await API.tools.getTools();
      } else {
        toolsRes = await API.defaultTools.getDefaultTools();
      }
      const toolsData = toolsRes.data;
      return toolsData.filter(item => item.name == "No tools required")[0]._id;
    },
    async autoSaveProject() {
      try {
        const data = this.createDataObject();

        const noToolsRequiredId = await this.getNoToolsRequiredId();
        if (this.skipTools) {
          // Clear array
          data.toolsRequired.length = 0;
          data.toolsRequired.push(noToolsRequiredId);
        }
        if (Object.keys(this.user).length) {
          const res = await this.updateDraft({ projectId: this.draftId, data });
        } else {
          this.updateUnautheticatedDraft({ projectId: this.draftId, data });
        }
      } catch (e) {
        this.$toast.error("Connection lost, auto-saving disabled", {
          position: "bottom-right",
          duration: false,
          maxToasts: 1,
        });
        console.error(e);
      }
      this.skipTools = false;
    },
    setSkipTools(value) {
      this.skipTools = value;
    },
    isProjectValid() {
      let isValid = true;
      const conditions = [
        {
          condition: this.project.name === "",
          message: "Please insert a project name",
        },
        {
          condition: this.project.description.length > 200,
          message: "The description length must be lower than 200 characters",
        },
        {
          condition: this.config.tools && this.project.toolsRequired < 1,
          message: "Please select a tool",
        },
        {
          condition:
            this.config.projectTheme && this.project.projectTheme === "",
          message: "Please insert a project theme",
        },
      ];

      for (let { condition, message } of conditions) {
        if (condition) {
          this.errorMessage = message;
          isValid = false;
          break;
        }
      }

      if (isValid && !this.questionsAreValid()) {
        isValid = false;
      }

      return isValid;
    },
    questionsAreValid() {
      let isValid = true;
      for (let i = 0; i < this.project.steps.length; i++) {
        const { hasMultipleChoice, multipleChoice } = this.project.steps[i];

        if (hasMultipleChoice) {
          if (multipleChoice.question == "") {
            this.errorMessage = `Multiplechoice from step ${
              i + 1
            } has an empty question`;
            console.log(this.errorMessage);

            isValid = false;
            break;
          }

          for (let j = 1; j <= 3; j++) {
            if (multipleChoice[`answer${j}`].answer == "") {
              this.errorMessage = `Answer ${j} for the multiplechoice in step ${
                i + 1
              } is empty`;
              console.log(this.errorMessage);

              isValid = false;
              break;
            }
          }
        }
      }
      return isValid;
    },
    async saveProjects() {
      const isValid = this.isProjectValid();
      if (!isValid) return;
      const data = this.createDataObject();
      if (this.project.ProjectId) {
        if (this.user?.role === "admin") {
          if (this.draftId === this.project.attachedProject) {
            await this.CreateNewDefaultProjects({ data, that: this });
          } else {
            await this.updateDefaultProjects({
              ProjectId: this.project.attachedProject,
              data,
              that: this,
            });
          }
          await this.deleteProjectWOutMessage({ id: this.draftId });
        } else if (this.user?.role === "teacher") {
          if (this.project.attachedProject === this.draftId) {
            /* Case when you try to edit a draft from a non existing project */
            await this.CreateNewProjects({
              id: this.draftId,
              data,
              that: this,
            });
            dataLayer.push({ event: "edit_project" });
          } else {
            /* Case when you try to edit a draft from an existing project */
            await this.updateProjectDeleteDraft({
              ProjectId: this.draftId,
              data: { ...data, attachedProject: this.project.attachedProject },
              that: this,
            });
            dataLayer.push({ event: "edit_project" });
          }
        }
      } else {
        if (this.user?.role === "admin") {
          await this.CreateNewDefaultProjects({ data, that: this });
          await this.deleteProjectWOutMessage({ id: this.draftId });
        } else if (this.user?.role === "teacher") {
          if (this.project.addToWarehouse) {
            await this.CreateNewDefaultProjects({ data, that: this });
          }
          await this.CreateNewProjects({ id: this.draftId, data, that: this });
          dataLayer.push({ event: "create_project" });
        } else {
          this.goToSignUp();
        }
      }
      if (Object.keys(this.user).length) {
        if (this.user?.role === "teacher") {
          this.$router.push("/projects");
        } else {
          this.$router.push("/defaultprojects");
        }
      }
    },

    createErrorMessagesList(project) {
      this.errorMessages = [];
      if (!project.name) {
        this.errorMessages.push("Project Name");
      }
      project.steps.forEach((element, index) => {
        if (!element.name) {
          this.errorMessages.push(`Step title ${index + 1}`);
        }
      });
    },
    openModalProjectAsStudent() {
      this.studentProjectObject = this.createDataObject();
      this.createErrorMessagesList(this.studentProjectObject);
      $("#modalStudentProject").modal("show");
    },
    goToSignUp() {
      this.setRole("teacher");
      localStorage.setItem("draftId", this.draftId);
      this.$router.push({ name: "Create Account" });
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    ...mapState(["project"]),
    steps() {
      const availableSteps = [
        {
          name: "name",
          available: true,
          component: shallowRef(StepName),
        },
        {
          name: "theme",
          available: this.config.projectTheme,
          component: shallowRef(StepTheme),
        },
        {
          name: "tools",
          available: this.config.tools,
          component: shallowRef(StepTools),
        },
        {
          name: `${this.isMobileDevice ? 'pre-req' : 'pre-requisite'}`,
          available: this.config.preRequisites,
          component: shallowRef(StepPreRequisits),
        },
        {
          name: "supports",
          available: this.config.supportingMaterial || this.config.plans,
          component: shallowRef(StepSupports),
        },
        {
          name: "steps",
          available: true,
          component: shallowRef(StepSteps),
        },
      ];

      return availableSteps
        .filter((s) => s.available)
        .map((s, index) => ({ ...s, order: index }));
    },
    disableButton() {
      return !!this.showReorderStepsModal;
    },
  },
  async mounted() {
    this.checkDeviceType();
    let result;
    if (this.$route.query.project || this.$route.query.defaultProject) {
      if (this.$route.query.project) {
        let projectId = this.$route.query.project;
        result = await API.projects
          .getProject(projectId)
          .then((res) => res.data);
      } else if (this.$route.query.defaultProject) {
        let defaultProjectId = this.$route.query.defaultProject;
        result = await API.defaultProjects
          .getDefaultProject(defaultProjectId)
          .then((res) => res.data);
      }
      this.editing = true;

      const {
        name = "",
        description = "",
        projectImage = "",
        toolsRequired = [],
        preRequisite = [],
        projectsRequired = [],
        plans = [],
        supportingMaterial = "",
        projectTheme = "",
        projectLength = "",
        attachedProject,
        _id = "",
        steps,
      } = result;

      const project = {
        ...this.project,
        name,
        description,
        projectImage,
        toolsRequired: toolsRequired.map((tools) => tools._id),
        preRequisite,
        projectsRequired,
        plans,
        supportingMaterial,
        projectTheme,
        projectLength,
        ProjectId: _id,
        attachedProject: attachedProject || _id,
      };

      project.steps = steps || [emptyStep];

      for (let key of Object.keys(project)) {
        this.setProjectProp({ prop: key, value: project[key] });
      }

      if (attachedProject || (await result.status) === "draft") {
        this.draftId = await result._id;
      }
    }
    /* Debounce function. You can find a concept explanation in https://www.freecodecamp.org/news/javascript-debounce-example/ */
    this.debouncedWatch = _.debounce(async () => {
      if (!this.draftId) await this.createProject();
      else await this.autoSaveProject();
    }, 500);

    /* Add watchers to all the properties from the project (reactive) object */
    this.$watch(
      "project",
      async () => {
        await this.debouncedWatch();
      },
      { deep: true }
    );
  },
  beforeUnmount() {
    this.debouncedWatch.cancel();
    this.resetProject();
  },
};
</script>

<style scoped>
.create-project-page {
  background-color: #fcf8f8;
  margin-bottom: 100px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 8px #00000021;
  padding: 20px 100px;
  padding-bottom: 0;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 5vh;
  gap: 4.5em;
}


.form-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #004aad;
  font-family: "Poppins", sans-serif;
  margin-bottom: 65px;
}

@media (max-width:768px) {
  .form-wrapper {
  display: flex;
  padding: 10px 20px;
  gap: 20px;
  width: 90%;
  }

  .form-title {
    font-size: 24px;
  }
}

</style>
