<template>
  <div
    class="modal fade p-0"
    id="createIdeaRoadmap"
    tabindex="-1"
    role="dialog"
    aria-labelledby="resetPasswordTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-responsive-table modal-idea-container"
      role="document"
    >
      <div class="modal-content pt-2 p-0">
        <div
          class="modal-header modals-header-padding modal-header-roadmap mt-2 mb-0"
        >
          <h5 class="modal-title title-roadmap-modal text-blue">
            Submit New Idea
          </h5>
          <button
            type="button"
            class="close modal-close-icon button-modals-close-padding"
            style="padding-top: 5px"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="closeModal"
          >
            <span aria-hidden="true" class="button-modals-close-size"
              ><i class="fa fa-times icon-close-modal"></i
            ></span>
          </button>
        </div>
        <div class="pt-4 container-roadmap-modal">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <BaseInput
                  class="p-3"
                  label="Title"
                  placeholder="Insert title"
                  v-model="ideaOjb.title"
                  :border="true"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <TextArea
                  class="p-3 w-100"
                  label="Description"
                  placeholder="What would you like to be able to do? How would that help you?"
                  v-model="ideaOjb.description"
                  :border="true"
                  rows="5"
                  required
                  maxlength="2000"
                />
              </div>
            </div>
          </div>
          <p class="text-danger text-center w-100" v-if="error">{{ error }}</p>
          <div
            class="d-sm-flex justify-content-between align-items-center mt-3"
          >
            <div v-if="!isStudent" class="roadmap-create-alert">
              <i class="fa fa-exclamation-circle roadmap-create-alert-icon"></i>
              Your idea will be published when it is approved
            </div>
            <div class="d-flex justify-content-center mt-2">
              <ActionButton
                color="blue"
                class="p-4"
                fontsize="text-16-px"
                text="Submit"
                @click="submit"
                :data-dismiss="
                  ideaOjb.title && ideaOjb.description ? 'modal' : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseInput from "./input.vue";
import TextArea from "./TextArea.vue";
import ActionButton from "./actionButton.vue";
export default {
  name: "modalCreateIdea",
  data() {
    return {
      isStudent: false,
      error: "",
      ideaOjb: {
        title: "",
        description: "",
      },
    };
  },
  components: { BaseInput, TextArea, ActionButton },
  methods: {
    submit() {
      if (!this.ideaOjb.title) {
        this.error = "Missing title";
        return;
      }
      if (!this.ideaOjb.description) {
        this.error = "Missing description";
        return;
      }
      const data = {
        title: this.ideaOjb.title,
        description: this.ideaOjb.description,
      };
      const url = "/api/ideas-board";
      return axios({ method: "post", url, data, maxRedirects: 0 })
        .then(() => {
          this.$toast.success("Successfully created Idea", {
            position: "top",
          });
          this.$emit("getideas");
          $("#createIdeaRoadmap").modal("hide");
        })
        .catch((e) => console.log(e));
    },
    closeModal() {
      $("#createIdeaRoadmap").modal("hide");
    },
    cleanModal() {
      this.ideaOjb.title = "";
      this.ideaOjb.description = "";
    },
  },
  mounted() {
    $("#createIdeaRoadmap").on("hidden.bs.modal", this.cleanModal);
  },
};
</script>
