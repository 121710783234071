<template>
  <div class="div-table-student d-flex justify-content-center">
    <div class="header-input d-flex justify-content-between w-100 mt-3 gap-4">
      <div
        class="mt-5 d-flex flex-column justify-content-center align-items-start w-100"
      >
        <h2 class="text-blue f-bold text-start">
          Welcome Teacher {{ teacher.name }}
        </h2>
        <h3 class="text-blue f-regular text-start">These are your students</h3>
      </div>
      <BaseInput
        placeholder="Search by name, username, grade or project"
        :border="true"
        class="px-4 py-4 w-100 font-16px lg-font-14px"
        :label-class="'font-16px lg-font-14px'"
        v-model="studentsFilter"
      />
    </div>

    <div class="row w-100 divContainerAdminGeneral d-md-none d-xs-block shadow">
      <div
        class="table-titles w-100 d-flex text-center align-items-center px-0"
      >
        <div
          class="col-7 d-flex flex-column align-items-start justify-content-center"
        >
          <div class="titlesTableMobile text-white f-bold">Name</div>
          <div class="titlesTableMobile text-white">Username</div>
        </div>
        <div class="col-2 p-0 titlesTableMobile text-white f-bold">Grade</div>
        <div class="col-3 p-0 titlesTableMobile text-white f-bold">Actions</div>
      </div>
      <div
        class="form-group py-4 w-100 d-flex justify-content-center"
        v-if="loading"
      >
        <div class="loader-whitebg"></div>
      </div>

      <StudentRowsMobile
        v-else-if="filteredStudentsList?.length"
        :students="filteredStudentsList"
        @openModalProjects="openModalProjects"
        @openModalResetPassword="openConfirmationModal"
      />

      <div v-else>
        <div
          class="box-no-students d-flex justify-content-center align-items-center"
        >
          <div class="text-no-students" style="font-size: 36px">
            No students to display.
          </div>
        </div>
        <div class="box-text-share">
          Add students to the dashboard with the student pin [above].
        </div>
      </div>
    </div>
    <div
      class="row w-100 divContainerAdminGeneral mt-3 d-none d-md-block shadow"
    >
      <div class="table-titles w-100 d-flex text-center align-items-center">
        <div class="col-2 titlesTableBadges f-bold text-white">Name</div>
        <div class="col-2 titlesTableBadges f-bold text-white">Username</div>
        <div class="col-1 titlesTableBadges f-bold text-white">Grade</div>
        <div class="col-2 titlesTableBadges f-bold text-white">Last Login</div>
        <div class="col-4 titlesTableBadges f-bold text-white">Projects</div>
        <div class="col-1 titlesTableBadges f-bold text-white">Actions</div>
      </div>
      <div
        class="form-group py-4 w-100 d-flex justify-content-center"
        v-if="loading"
      >
        <div class="loader-whitebg"></div>
      </div>

      <StudentsRows
        v-else-if="filteredStudentsList?.length"
        :students="filteredStudentsList"
        @openModal="openConfirmationModal"
      />

      <div v-else class="text-blue mb-5">
        <div
          class="box-no-students d-flex justify-content-center align-items-center"
        >
          <div class="text-no-students f-semibold" style="font-size: 36px">
            No students to display.
          </div>
        </div>
        <div class="box-text-share">
          Add students to the dashboard with the student pin [above].
        </div>
      </div>
    </div>
  </div>

  <confirmationModalWMessages
    ref="confirmationModalWMessages"
    @confirm="changePassword"
    :studentName="studentName"
    :successMessage="successMessage"
    :errorMessage="errorMessage"
    :id="id"
  />
  <WhiteModal title="Projects">
    <div class="d-flex flex-column">
      <div
        class="d-flex flex-column justify-content-center align-items-start pl-5 project-bottom pb-4"
      >
        <p class="text-blue f-bold">
          {{ student?.name }}
        </p>
        <div class="d-flex justify-content-start align-items-center gap-1">
          <h4 class="text-blue f-regular">
            {{ student?.username }}
          </h4>
          <h4 class="text-blue f-regular">|</h4>
          <h4 class="text-blue f-regular">
            Last login: {{ formatDate(student?.lastLogin) }}
          </h4>
        </div>
      </div>
      <div
        v-for="(project, index) in student?.projects"
        :key="index"
        :class="`d-flex justify-content-between w-100 ${
          student.projects.length - 1 != index ? 'project-bottom' : ''
        }`"
        @click="seeStudentProject(project._id, student._id)"
        data-bs-dismiss="modal"
      >
        <div class="d-flex project-data align-items-center w-50">
          <p class="pl-5 f-semibold">
            {{ project?.name }}
          </p>
        </div>
        <ProgressBar
          :percentage="project?.percentageOfCompletion"
          class="pl-1 pr-5 w-50"
        />
      </div>
    </div>
  </WhiteModal>
</template>

<script>
import moment from "moment";
import API from "../services/api";
import { mapActions } from "vuex";
import confirmationModalWMessages from "../components/confirmationModalWMessages.vue";
import BaseInput from "../components/input.vue";
import StudentsRows from "../components/StudentsRows.vue";
import StudentRowsMobile from "../components/StudentRowsMobile.vue";
import WhiteModal from "../components/Modals/whiteModal.vue";
import ProgressBar from "../components/progressBar.vue";

export default {
  data() {
    return {
      teacher: "",
      students: [],
      loading: false,
      id: "",
      studentName: "",
      successMessage: "",
      errorMessage: "",
      studentsFilter: "",
      student: {},
    };
  },
  components: {
    confirmationModalWMessages,
    BaseInput,
    StudentsRows,
    StudentRowsMobile,
    WhiteModal,
    ProgressBar,
  },
  computed: {
    filteredStudentsList() {
      if (!this.studentsFilter) return this.students;
      else {
        return this.students?.filter(
          (student) =>
            student.name
              .toLowerCase()
              .includes(this.studentsFilter.toLowerCase()) ||
            student.username
              .toLowerCase()
              .includes(this.studentsFilter.toLowerCase()) ||
            String(student.grade).includes(this.studentsFilter) ||
            student.projects?.filter((project) => {
              return project?.name
                .toLowerCase()
                .includes(this.studentsFilter.toLowerCase());
            }).length
        );
      }
    },
  },
  methods: {
    ...mapActions("accountRecovery", ["changeStudentPassword"]),
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    seeStudentProject(projectId, studentId) {
      return this.$router.push({
        path: "/teacher/see-student-project",
        query: { project: projectId, student: studentId },
      });
    },
    async changePassword(id) {
      const data = {
        studentId: id,
        newPass: "capablemaker123",
      };
      const response = await this.changeStudentPassword({ data: data });
      if (response) {
        this.successMessage = "Password succesfully changed.";
      } else {
        this.errorMessage = "Cannot change password, please try later.";
      }
    },
    seeStudentProject(projectId, studentId) {
      return this.$router.push({
        path: "/teacher/see-student-project",
        query: { project: projectId, student: studentId },
      });
    },
    openModalProjects(student) {
      this.student = student;
      this.student.projects = this.student.projects.filter((project) => {
        return !!project?.name;
      });
      $("#whiteModal").modal("show");
    },
    openConfirmationModal(student) {
      this.id = student._id;
      this.studentName = student.name;
      $("#confirmationModalWMessages").modal("show");
    },
  },
  async created() {
    try {
      this.loading = true;
      let stringUser = sessionStorage.getItem("user");
      this.teacher = JSON.parse(stringUser);
      this.students = await API.teacher
        .GetStudents(this.teacher._id)
        .then((res) => res.data);
      this.students.forEach(async (student) => {
        const data = await API.studentProjects
          .getMyProjects(student?._id)
          .then((res) => res.data);
        const notNull = data.filter((d) => d.project != null); //Once the db is deleted this shouldn't be an issue
        if (student.projects) {
          for (const project of student.projects) {
            let amountOfSteps = project?.steps?.length;
            let studentProject = notNull.find(
              (p) => p?.project?._id === project?._id
            );
            let amountOfCompletedSteps = studentProject?.steps?.length;
            if (project) {
              project.percentageOfCompletion = Math.floor(
                (amountOfCompletedSteps * 100) / amountOfSteps
              );
            }
          }
        }
      });
    } catch (e) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

.container {
  margin: 0px !important;
}
.header-input {
  align-items: end;
}

.titlesTableMobile {
  font-size: 13px;
}

.div-table-student {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: 88px;
  gap: 40px;
}

.div-table-student h1 {
  margin-top: 50px;
  font-size: 38px;
}

.table-titles {
  background-color: var(--capable-red);
  border-radius: 11px 11px 0 0;
  height: 78px;
}

.titlesTableBadges {
  font-size: 18px;
}

@media (max-width: 768px) {
  p {
    margin: 0px;
    font-size: 16px;
  }

  h4 {
    font-size: 12px;
  }
  .header-input h3,
  .header-input h2 {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .header-input h2 {
    font-size: 20px;
  }

  .header-input h3 {
    font-size: 16px;
  }
  .header-input {
    flex-direction: column;
    align-items: center;
  }

  .table-titles {
    background-color: var(--capable-red);
    border-radius: 11px 11px 0 0;
    height: 53px;
  }

  .box-no-students {
    margin-top: 15px;
    height: 150px;
    background-color: #f7f8f9;
  }
  .box-text-share {
    margin: auto;
    margin-top: 36px;
    text-align: center;
    max-width: 530px;
    font-size: 24px;
  }

  .project-data {
    height: 55px;
  }

  .project-data p {
    font-size: 14px;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  .div-table-student {
    gap: 25px;
  }

  .project-bottom {
    border-bottom: 0.5px solid var(--capable-grey);
  }
}
</style>
