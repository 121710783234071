<template>
  <div
  class="div_signup"
  >
    <div
      class="div_signup2 shadow p-3 mb-5 bg-white d-flex flex-column align-items-center justify-content-center"
    >
      <form
        class="h-75 d-flex flex-column align-items-center justify-content-center gap-3"
      >
        <div
          v-if="roleSelected"
          class="d-flex flex-column justify-content-center align-items-center gap-3"
        >
          <h3 class="font-size-30 f-bold">
            Hi {{ roleSelected === "teacher" ? " teacher!" : " student!" }}
          </h3>
          <p class="fill-fields f-medium text-blue">
            Fill the next fields to create your new account:
          </p>
        </div>
        <div
          class="form-row d-flex justify-content-center"
          v-if="roleSelected === 'teacher'"
        >
          <div class="form-group col-md-6 d-flex flex-column gap-3">
            <BaseInput
              label="Email"
              placeholder="Email address"
              v-model="email"
              :border="true"
              class="px-4 py-4 w-100 font-16px lg-font-14px"
              :label-class="'font-16px lg-font-14px'"
              autocomplete="on"
            />
            <BaseInput
              v-model="name"
              placeholder="name"
              label="Name"
              :border="true"
              class="px-4 py-4 w-100 font-16px lg-font-14px"
              :label-class="'font-16px lg-font-14px'"
              autocomplete="on"
            />
            <BaseInput
              v-model="school"
              placeholder="School"
              label="School"
              :border="true"
              class="px-4 py-4 w-100 font-16px lg-font-14px"
              :label-class="'font-16px lg-font-14px'"
              autocomplete="on"
            />
          </div>

          <div class="form-group col-md-6 d-flex flex-column gap-3">
            <div>
              <div class="d-flex justify-content-between">
                <label for="formFile" class="form-label font-16px lg-font-14px">Select grades</label>
                <div
                  class="d-flex justify-content-center align-items-center gap-3"
                >
                  <input
                    class="check-all"
                    type="checkbox"
                    v-model="isCheckAll"
                    @click="checkAll"
                  />
                  <label
                  class="font-16px lg-font-14px"
                  > All </label>
                </div>
              </div>

              <div class="container-check-signup-teacher mb-0">
                <div class="w-100">
                  <div class="d-flex justify-content-between">
                    <div
                      class="check"
                      v-for="(radio, index) in radios"
                      :key="index"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :value="radio.value"
                        v-model="grades"
                        @change="updateCheckAll"
                        :id="`checkbox-${index}`"
                        :name="`checkbox-${index}`"
                      />
                      <label
                        class="form-check-label text-blue f-regular py-4"
                        :for="`checkbox-${index}`"
                      >
                        {{ radio.item }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BaseInput
              v-model="contentAreaTaught"
              placeholder="Content Area Taught"
              label="Content Area Taught"
              :border="true"
                class="px-4 py-4 w-100 font-16px lg-font-14px"
                :label-class="'font-16px lg-font-14px'"
              autocomplete="on"
            />
            <div class="d-flex justify-content-between align-items-end">
              <BaseInput
                v-model="password"
                placeholder="Password"
                label="Password"
                :border="true"
                class="px-4 py-4 w-100 font-16px lg-font-14px"
                :label-class="'font-16px lg-font-14px'"
                type="password"
                autocomplete="on"
              />
              <p class="text-blue characters py-4 f-regular col-md-5  font-16px lg-font-14px">
                6-12 characters
              </p>
            </div>
          </div>

          <div class="mb-3 form-group col-md-12 mt-3">
            <FileUploader
              @change="processFile($event)"
              accept="image/*"
              placeholder="No file choosen"
              :class="['w-100']"
              :label="`Profile picture`"
              :optional="`(Optional)`"
              :border="true"
            />
          </div>
          <div
            class="form-group py-4 w-100 d-flex justify-content-center"
            v-if="uploadingImage"
          >
            <div class="loader-whitebg"></div>
          </div>

          <p v-if="errorMessage" class="text-danger text-center col-12">
            {{ errorMessage }}
          </p>
          <p v-else class="empty-space col-12"></p>
          <div
            class="div_signup_button col-md-12 mt-5 d-flex justify-content-center"
          >
            <ActionButton
              @click="signUp"
              :color="`red`"
              type="button"
              :text="`Create Account`"
              class="px-4 py-4"
              :fontsize="'text-16-px'"
            />
          </div>
          <div class="div_signup_button col-md-12 mt-5 justify-content-center">
            <input
              :disabled="disabled"
              @click="goBack"
              class="btn btn-primary btn-lg btnModalSuccess"
              style="position: fixed; bottom: 3%; right: 3%"
              type="button"
              value="Go back"
            />
          </div>
        </div>

        <div
          class="form-row d-flex justify-content-center gap-3"
          v-else-if="roleSelected === 'student'"
        >
          <div class="form-group col-md-5 d-flex flex-column gap-3">
            <BaseInput
              v-model="teacherPin"
              placeholder="Teacher Pin"
              label="Teacher pin"
              :border="true"
                class="px-4 py-4 w-100 font-16px lg-font-14px"
                :label-class="'font-16px lg-font-14px'"
              type="text"
              autocomplete="on"
              @change="addPin"
              @paste="pastePin"
            />
            <BaseInput
              v-model="name"
              placeholder="name"
              label="Name"
              :border="true"
                class="px-4 py-4 w-100 font-16px lg-font-14px"
                :label-class="'font-16px lg-font-14px'"
              autocomplete="on"
            />
          </div>
          <div class="form-group col-md-5 d-flex flex-column gap-3">
            <BaseInput
              v-model="username"
              placeholder="Username"
              label="Username"
              :border="true"
                class="px-4 py-4 w-100 font-16px lg-font-14px"
                :label-class="'font-16px lg-font-14px'"
              autocomplete="on"
            />
            <div class="d-flex justify-content-between align-items-end">
              <BaseInput
                v-model="password"
                placeholder="Password"
                label="Password"
                :border="true"
                class="px-4 py-4 w-100 font-16px lg-font-14px"
                :label-class="'font-16px lg-font-14px'"
                type="password"
                autocomplete="on"
              />
              <p class="text-blue characters f-regular py-4 col-5 font-16px lg-font-14px">
                6-12 characters
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <label for="formFile" class="form-label font-16px lg-font-14px">Select grade</label>
            <div class="w-100">
              <div class="d-flex justify-content-between">
                <div
                  class="check"
                  v-for="(radio, index) in radios"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :value="radio.value"
                    v-model="grade"
                    @change="updateCheckAll"
                    :id="`checkbox-${index}`"
                    :name="`checkbox-${index}`"
                  />
                  <label
                    class="form-check-label text-blue f-regular py-4"
                    :for="`checkbox-${index}`"
                  >
                    {{ radio.item }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p v-if="errorMessage" class="text-danger text-center col-12">
            {{ errorMessage }}
          </p>
          <p v-else class="empty-space col-12"></p>

          <div class="col-md-12 d-flex justify-content-center">
            <ActionButton
              :disabled="disabled"
              @click="signUp"
              :color="`red`"
              :text="'Create Account'"
              class="px-3 py-4"
              :fontsize="'text-16-px'"
            />
          </div>

          <div class="div_signup_button col-md-12 mt-5 justify-content-center">
            <input
              @click="goBack"
              class="btn btn-primary btn-lg btnModalSuccess"
              style="position: fixed; bottom: 3%; right: 3%"
              type="button"
              value="Go back"
            />
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center gap-5"
          v-else
        >
          <SelectTeacherOrStudent />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import API from "../services/api";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import SelectTeacherOrStudent from "./selectTeacherOrStudent.vue";
import BaseInput from "../components/input.vue";
import FileUploader from "../components/FileUploader.vue";
import ActionButton from "../components/actionButton.vue";

export default {
  data() {
    return {
      name: "",
      username: "",
      email: "",
      school: "",
      schoolEmail: "",
      grades: [],
      grade: "",
      isCheckAll: false,
      contentAreaTaught: "",
      profilePicture: "",
      class: "",
      password: "",
      errorMessage: "",
      teacherPin: "",
      pinVerified: false,
      radios: [
        { item: "6th", value: 6 },
        { item: "7th", value: 7 },
        { item: "8th", value: 8 },
        { item: "9th", value: 9 },
        { item: "10th", value: 10 },
        { item: "11th", value: 11 },
        { item: "12th", value: 12 },
      ],
      uploadingImage: false,
      disabled: false,
    };
  },
  components: { SelectTeacherOrStudent, BaseInput, FileUploader, ActionButton },
  methods: {
    ...mapActions("user", ["saveUser"]),
    ...mapActions("configs", ["getConfig"]),
    ...mapActions("projects", ["updateStatusAddTeacherId"]),
    ...mapActions("signUp", ["setRole"]),
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.grades = [];
      if (this.isCheckAll) {
        for (let key in this.radios) {
          this.grades.push(this.radios[key].value);
        }
      }
    },
    goBack() {
      this.setRole("");
      this.$router.push("/signup");
      this.errorMessage = "";
    },
    updateCheckAll() {
      if (this.grades.length == this.radios.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    async addPin() {
      this.errorMessage = "";
      return API.user
        .checkPin({ pin: this.teacherPin && this.teacherPin.trim() })
        .then((res) => {
          if (res.data.code === "pinNoMatch") {
            this.errorMessage = "Pin is invalid";
            this.radios = [
              { item: "6th", value: 6 },
              { item: "7th", value: 7 },
              { item: "8th", value: 8 },
              { item: "9th", value: 9 },
              { item: "10th", value: 10 },
              { item: "11th", value: 11 },
              { item: "12th", value: 12 },
            ];
          } else {
            let gradesFiltered = [];
            res.data.result.grades.map((grade) => {
              this.radios.map((g) => {
                if (g.value === grade) {
                  return gradesFiltered.push(g);
                }
              });
            });
            this.radios = gradesFiltered;
            this.pinVerified = true;
          }
        })
        .catch((e) => {
          console.log("e", e);
          this.pinVerified = false;
          this.errorPin = true;
        });
    },
    async pastePin(evt) {
      this.errorMessage = "";
      return API.user
        .checkPin({ pin: evt.clipboardData.getData("text/plain") })
        .then((res) => {
          console.log(res);
          if (res.data.code === "pinNoMatch") {
            this.errorMessage = "Pin is invalid";
            this.radios = [
              { item: "6th", value: 6 },
              { item: "7th", value: 7 },
              { item: "8th", value: 8 },
              { item: "9th", value: 9 },
              { item: "10th", value: 10 },
              { item: "11th", value: 11 },
              { item: "12th", value: 12 },
            ];
          } else {
            let gradesFiltered = [];
            res.data.result.grades.map((grade) => {
              this.radios.map((g) => {
                if (g.value === grade) {
                  return gradesFiltered.push(g);
                }
              });
            });
            this.radios = gradesFiltered;
            this.pinVerified = true;
          }
        })
        .catch((e) => {
          console.log("e", e);
          this.pinVerified = false;
          this.errorPin = true;
        });
    },
    processFile(e) {
      let file = e.target.files[0];
      this.errorMessage = "";
      if (!file) {
        return console.log("Fail");
      }
      if (file.size > 1024 * 10000) {
        this.errorMessage =
          "You exceeded the allowed image size. Maximum size allowed 2mb";
        return;
      } else {
        return this.uploadImage(file);
      }
    },
    uploadImage(file) {
      this.uploadingImage = true;
      const formData = new FormData();
      formData.append("image", file, file.name);
      return API.user
        .AddPictureProfile(formData)
        .then((res) => {
          const file = res.data;
          this.profilePicture = file.linkUrl;
        })
        .catch((err) => console.log("err", err))
        .finally((res) => {
          this.uploadingImage = false;
        });
    },
    async signUp(e) {
      e.preventDefault();
      if (!this.teacherPin && this.roleSelected === "student") {
        return (this.errorMessage = "Pin required!");
      }
      if (!this.pinVerified && this.roleSelected === "student") {
        return (this.errorMessage = "Pin invalid!");
      }
      if (!this.email && this.roleSelected === "teacher") {
        return (this.errorMessage = "Email required!");
      }
      if (
        (this.email && this.email.length < 6) ||
        (this.email &&
          this.email.length > 35 &&
          this.roleSelected === "teacher")
      ) {
        let validEmail = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
        if (!this.email)
          return (this.errorMessage = "Please insert a valid email!");
        if (!validEmail.exec(this.email))
          return (this.errorMessage = "Please insert a valid email!");
        return (this.errorMessage = "Please insert a valid email!");
      }
      if (
        (this.schoolEmail && this.schoolEmail.length < 6) ||
        (this.schoolEmail &&
          this.schoolEmail.length > 35 &&
          this.roleSelected === "student")
      ) {
        let validEmail = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
        if (!this.schoolEmail)
          return (this.errorMessage = "Please insert a valid school email!");
        if (!validEmail.exec(this.schoolEmail))
          return (this.errorMessage = "Please insert a valid school email!");
        return (this.errorMessage = "Please insert a valid school email!");
      }
      if (!this.username && this.roleSelected === "student") {
        return (this.errorMessage = "Username required!");
      }
      if (!this.name) {
        return (this.errorMessage = "Name required!");
      }
      if (this.name.length < 2 || this.name.length > 35) {
        return (this.errorMessage = "Please insert a valid name!");
      }
      if (!this.school && this.roleSelected === "teacher") {
        return (this.errorMessage = "School required!");
      }
      if (this.grades.length < 1 && this.roleSelected === "teacher") {
        return (this.errorMessage = "Grades required!");
      }
      if (!this.grade && this.roleSelected === "student") {
        return (this.errorMessage = "Grade required!");
      }
      if (!this.contentAreaTaught && this.roleSelected === "teacher") {
        return (this.errorMessage = "Content Area Taught Required!");
      }
      if (!this.password) {
        return (this.errorMessage = "Password Required!");
      }
      if (this.password.length < 6 || this.password.length >= 12) {
        return (this.errorMessage = "The password must be 6-12 characters!");
      }
      this.disabled = true;

      let data = {};
      if (this.roleSelected === "teacher") {
        data = {
          email: this.email,
          name: this.name,
          school: this.school,
          grades: this.grades,
          contentAreaTaught: this.contentAreaTaught,
          profilePicture: this.profilePicture,
          role: "teacher",
          password: this.password,
        };

        await API.user
          .CreateNewAccountTeacher(data)
          .then((res) => {
            console.log(res, "teacher signup");
            this.login();
            this.disabled = false;
          })
          .catch((error) => {
            this.disabled = false;
            this.errorMessage = _.get(
              error,
              "response.data.errors.message",
              "Error please try later."
            );
          });
      } else {
        data = {
          teacherPin: this.teacherPin,
          username: this.username,
          name: this.name,
          schoolEmail: this.schoolEmail,
          school: this.school,
          grade: this.grade,
          class: this.class,
          profilePicture: this.profilePicture,
          role: "student",
          password: this.password,
        };
        await API.user
          .CreateNewAccountStudent(data)
          .then((res) => {
            this.login();
            this.disabled = false;
          })
          .catch((error) => {
            this.disabled = false;
            this.errorMessage = _.get(
              error,
              "response.data.errors.message",
              "Error please try later."
            );
          });
      }
    },
    login() {
      this.errorMessage = "";
      if (!this.email && this.roleSelected === "teacher") {
        return (this.errorMessage = "Email required!");
      }
      if (!this.username && this.roleSelected === "student") {
        return (this.errorMessage = "Username required!");
      }
      if (!this.password) {
        return (this.errorMessage = "Password required!");
      }
      const data = {
        username: this.username,
        email: this.email,
        password: this.password,
      };
      this.loading = true;
      API.user
        .Login(data)
        .then((res) => {
          let userDecode = jwt_decode(res.data.token);
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          this.saveUser();
          this.getConfig();
          if (userDecode.role === "teacher") {
            this.$router.push("/dashboard-teacher");
          } else if (userDecode.role === "admin") {
            this.$router.push("/" + userDecode.role);
          } else if (userDecode.role === "student") {
            this.$router.push("/student");
          }
          const id = localStorage.getItem("draftId");
          if (id) {
            this.updateStatusAddTeacherId({
              projectId: id,
              data: { teacher: res.data.user._id },
            });
            localStorage.removeItem("draftId");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = _.get(
            error,
            "response.data.errors.message",
            "Error please try later."
          );
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapState("signUp", ["roleSelected"]),
    ...mapState("configs", ["config"]),
  },
};
</script>

<style scoped>
form{
    width: 90%;
  }

h3 {
  font-size: 40px;
  font-weight: 700;
  color: var(--capable-blue);
}

label {
  font-size: 18px;
  color: var(--capable-blue);
}

p{
  text-align: center;
}

.modal-body {
  padding: 10px 0px;
}

.check input {
  display: none;
}

.characters {
  font-size: 18px;
  padding: 0px;
  margin-bottom: 0px;
  text-align: end;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-30 {
  font-size: 30px;
}

.container-check-signup-teacher {
  display: flex;
  align-items: center;
  padding: 0px;
}

.div_signup {
  padding-bottom: 40px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--capable-light-orange);
}
.div_signup2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 11px;
  width: 959.613px;
  height: 775px;
}

.check-all {
  height: 18px;
  width: 18px;
  margin-bottom: 5px;
}

.fill-fields {
  font-size: 20px;
}

.font-16px {
  font-size: 16px;
}

.font-18px {
  font-size: 18px;
}


.font-20px {
  font-size: 20px;
}

.empty-space {
  height: 22.5px;
  margin: 0px;
}

.form-check-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  text-align: center;
  cursor: pointer;
  border: var(--capable-grey) 1px solid;
  border-radius: 11px;
  font-size: 16px;
}

#checkbox-0:checked ~ label[for="checkbox-0"],
#checkbox-1:checked ~ label[for="checkbox-1"],
#checkbox-2:checked ~ label[for="checkbox-2"],
#checkbox-3:checked ~ label[for="checkbox-3"],
#checkbox-4:checked ~ label[for="checkbox-4"],
#checkbox-5:checked ~ label[for="checkbox-5"],
#checkbox-6:checked ~ label[for="checkbox-6"] {
  background-color: var(--capable-grey);
  color: var(--capable-white);
}

@media (max-width: 1400px) {

.lg-font-14px {
  font-size: 14px;
}

  .lg-font-16px {
  font-size: 16px;
}
.lg-font-18px {
  font-size: 18px;
}
.lg-font-20px {
  font-size: 20px;
}
}

@media (max-width: 1024px) {
  .div_signup2 {
    gap: 20px;
    width: 700px;
    height: auto;
  }

  .form-check-label{
    width: 40px;
  }

  .characters{
    padding: 0px !important;
  }
}

@media (max-width: 1400px) {
  .div_signup2 {
    height: 700px;
    width: 866.747px
  }
}

@media (max-width: 768px) {
  .form-check-label{
    width: 40px;
  }
  .div_signup2 {
    width: 360px;
    height: auto;
  }

  .container-check-signup {
    flex-direction: column;
  }
  .form-row {
    width: 100%;
    min-width: 100px;
  }
  .form-check {
    min-width: 50px;
    margin: 10px 0px;
  }
}
</style>
