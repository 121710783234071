<template>
  <div
    :id="modalId"
    class="modal fade text-white"
    tabindex="-1"
    aria-labelledby="advancedFilterLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Actions for step {{ this.index + 1 }}</h5>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-dismiss="modal"
            @click="close"
            style="font-size: 18px"
          ></button>
        </div>
        <div class="modal-body text-description-modal">
          <div
            class="form-group col-md-12 text-center d-flex align-items-center justify-content-center gap-2"
            v-if="isMobileDevice"
          >
            <ActionButton color="red" class="h-60-px f-medium w-50">
              <template #leftIcon>
                <label for="camInput" class="cam-button-label">
                  Take photo
                </label>
              </template></ActionButton
            >
            <input
              @change="
                ($event) =>
                  $emit('fileUpload', {
                    e: $event,
                    prop: 'imagesURL',
                    type: 'stepMedia',
                    index: this.index,
                  })
              "
              name="camInput"
              id="camInput"
              type="file"
              capture="user"
              accept="image/*"
              v-show="false"
            />

            <ActionButton
              v-if="config.multimedia"
              color="red"
              class="h-60-px f-medium w-50 mx-0"
            >
              <template #leftIcon>
                <label for="camVideo" class="cam-button-label">
                  Record video
                </label>
              </template></ActionButton
            >
            <input
              @change="
                ($event) =>
                  $emit('fileUpload', {
                    e: $event,
                    prop: 'videosURL',
                    type: 'stepMedia',
                    index: this.index,
                  })
              "
              name="camVideo"
              id="camVideo"
              type="file"
              capture="user"
              accept="video/*"
              v-show="false"
            />
          </div>
          <div class="col-md-12" v-else>
            <video
              class="form-group col-md-12 text-center"
              ref="videos"
              width="360"
              height="240"
              playsinline
              v-show="isStreaming"
            ></video>

            <div class="row d-flex gap-5">
              <div
                class="col-12 mb-2 d-flex justify-content-center align-items-center"
                v-if="this.user.role !== 'student'"
              >
                <div
                  class="btn-group d-flex gap-5"
                  role="group"
                  aria-label="Basic example"
                >
                  <ActionButton
                    type="button"
                    :class="`p-3  ${
                      shouldModeButtonBeDisabled('image') && 'disabled'
                    }`"
                    color="red"
                    text="Photo mode"
                    :disabled="shouldModeButtonBeDisabled('image')"
                    fontsize="text-16-px"
                    @click="selectMode('image')"
                  />

                  <ActionButton
                    v-if="config.multimedia"
                    type="button"
                    :class="`p-3  ${
                      shouldModeButtonBeDisabled('video') && 'disabled'
                    }`"
                    color="red"
                    text="Video mode"
                    fontsize="text-16-px"
                    :disabled="shouldModeButtonBeDisabled('video')"
                    @click="selectMode('video')"
                  />

                  <ActionButton
                    type="button"
                    v-if="config.multimedia"
                    :class="`p-3  ${
                      shouldModeButtonBeDisabled('gif') && 'disabled'
                    }`"
                    color="red"
                    text="Gif mode"
                    fontsize="text-16-px"
                    :disabled="shouldModeButtonBeDisabled('gif')"
                    @click="selectMode('gif')"
                  />
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-center align-items-center mb-3"
                v-if="recording"
              >
                <ActionButton
                  type="button"
                  class="p-3"
                  color="red"
                  text="Stop recording"
                  fontsize="text-16-px"
                  @click="stopRecording(this.streaming.video ? 'video' : 'gif')"
                >
                  <template #leftIcon>
                    <i class="icon-stop mr-1"> </i>
                  </template>
                </ActionButton>
              </div>
              <div
                class="col-12 d-flex justify-content-center align-items-center mb-3"
                v-else-if="isStreaming"
              >
                <ActionButton
                  v-if="streaming.image"
                  type="button"
                  @click="capture('image')"
                  :class="`p-2 p-lg-3 ${shouldBeDisabled('image') && 'disabled'}`"
                  color="red"
                  text="Take photo"
                  :disabled="shouldBeDisabled('image')"
                  fontsize="text-16-px"
                >
                  <template #leftIcon>
                    <i class="fa fas fa-camera mr-1"></i>
                  </template>
                </ActionButton>

                <ActionButton
                  :disabled="shouldBeDisabled('video')"
                  @click="capture('video')"
                  type="button"
                  :class="`p-3 ${shouldBeDisabled('video') && 'disabled'}`"
                  color="red"
                  text="Record video"
                  fontsize="text-16-px"
                  v-else-if="streaming.video && !recording"
                >
                  <template #leftIcon>
                    <i class="icon-facetime-video mr-1"></i>
                  </template>
                </ActionButton>

                <ActionButton
                  :disabled="shouldBeDisabled('gif')"
                  @click="capture('gif')"
                  type="button"
                  :class="`p-3 ${shouldBeDisabled('gif') && 'disabled'}`"
                  color="red"
                  text="Capture gif"
                  fontsize="text-16-px"
                  v-else
                >
                  <template #leftIcon>
                    <i class="icon-circle mr-1"></i>
                  </template>
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RecordRTC from "recordrtc";
import { mapActions, mapState } from "vuex";
import { wait } from "../../../utils";
import ActionButton from "../../../components/actionButton.vue";
import FileUploader from "../../../components/FileUploader.vue";
export default {
  name: "mediaModal",
  components: {
    ActionButton,
    FileUploader,
  },
  props: ["isMobileDevice", "index", "step", "modalId"],
  data() {
    return {
      setCountdown: false,
      cameraCountdown: 0,
      videoStatus: "noVideo",
      gifStatus: "noGif",
      streaming: {
        image: true,
        video: false,
        gif: false,
      },
      recording: false,
      video: {},
      uploading: false,
    };
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("configs", ["config"]),
    ...mapState("user", ["user"]),
    isStreaming() {
      for (let key of Object.keys(this.streaming)) {
        if (this.streaming[key]) return true;
      }

      return false;
    },
    shouldBeDisabled() {
      return (button) => {
        switch (button) {
          case "gif":
            return this.uploading || this.step.gifsURL.length;
          case "video":
            return this.uploading || this.step.videosURL.length;
          case "image":
          default:
            return (
              this.uploading ||
              (this.step &&
                this.step.imagesURL &&
                this.step.imagesURL.length >= 3)
            );
        }
      };
    },
    shouldModeButtonBeDisabled() {
      return (mode) => {
        return this.streaming[mode] || this.recording;
      };
    },
  },
  mounted() {
    // Get the video element and its dimensions
    const self = this;

    $(`#${this.modalId}`).on("hidden.bs.modal", function () {
      self.closeCamera();
    });

    $(`#${this.modalId}`).on("show.bs.modal", function () {
      const video = self.getVideo();
      self.video = video;
      if (!self.isMobileDevice) {
        self.openStream();
      }
    });
  },

  beforeUnmount() {
    this.video = {};
    this.streaming = {
      image: true,
      video: false,
      gif: false,
    };
    this.recording = false;
    this.closeCamera();
  },
  methods: {
    ...mapActions("project", ["setProjectProp", "setProjectStepProp"]),
    close() {
      $(`#${this.modalId}`).modal("hide");
    },
    capture(mode) {
      switch (mode) {
        case "image":
          return this.takePhoto();

        default:
          return this.record(mode);
      }
    },
    selectMode(mode) {
      // Change button disabled status and text with computed properties
      for (let key of Object.keys(this.streaming)) {
        if (key === mode) {
          this.streaming[key] = true;
        } else {
          this.streaming[key] = false;
        }
      }
    },
    async takePhoto() {
      const width = this.video.videoWidth;
      const height = this.video.videoHeight;

      // Create a canvas element to draw the image
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      // Draw the video frame to the canvas
      canvas.getContext("2d").drawImage(this.video, 0, 0, width, height);
      let file = this.dataURItoBlob(canvas.toDataURL(), this.index);
      const stepNumber = this.project.steps.length;
      const imgNumber = this.project.steps[this.index].imagesURL.length + 1;
      const date = new Date().toISOString();
      file.name = `step-${stepNumber}number-${imgNumber}date-${date}`;
      this.$emit("fileUpload", {
        e: { target: { files: [file] } },
        prop: "imagesURL",
        type: "stepMedia",
        index: this.index,
      });
    },
    openStream() {
      // Request access to the camera
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          // Display the video stream in the video element
          this.video.srcObject = stream;
          this.video.play();
        });
    },
    async record(type) {
      this.recording = true;
      const isVideo = type === "video";

      this.setCountdown = true;
      this.cameraCountdown = 3;

      const countdownInterval = setInterval(() => {
        if (this.cameraCountdown > 1) {
          this.cameraCountdown -= 1;
        } else {
          clearInterval(countdownInterval);
        }
      }, 1000);

      const config = {
        type,
        frameRate: isVideo ? 30 : 1,
        quality: 1,
        width: 360,
        hidden: 240,
      };

      this.setCountdown = false;

      if (isVideo) {
        config["mimeType"] = "video/webm";
      }

      this.captureCamera(async (camera) => {
        this.recorder = RecordRTC(camera, config);
        // this.openStream(type);
        this.recorder.startRecording();
        this.recorder.camera = camera;
        await wait(31000);

        if (this.isRecording) {
          // If we are still recording after 30secs then automatically turn it off
          this.stopRecording(type);
        }
      });
    },
    captureCamera(callback) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(callback)
        .catch((error) => {
          console.error(error);
        });
    },
    async stopRecordingCallback(type) {
      this.recorder.camera.stop();
      const file = new File(
        [await this.recorder.getBlob()],
        `step_${this.index + 1}_${type}.${type === "video" ? "webm" : "gif"}`,
        { type: type === "video" ? "video/webm" : "image/gif" }
      );

      this.recorder.destroy();
      this.recorder = null;

      if (!file) {
        console.log("Fail");
      } else {
        this.uploading = true;
        this.$emit("fileUpload", {
          e: { target: { files: [file] } },
          prop: type === "video" ? "videosURL" : "gifsURL",
          type: "stepMedia",
          index: this.index,
        });
      }
      this.uploading = false;
    },
    getVideo() {
      return this.$refs.videos;
    },
    closeCamera() {
      this.video?.srcObject?.getTracks().forEach((track) => track.stop());
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },

    async stopRecording(type) {
      this.recording = false;

      new Promise((resolve, reject) => {
        return this.recorder.stopRecording(() => {
          return this.stopRecordingCallback(type).then(resolve).catch(reject);
        });
      });
    },
  },
};
</script>

<style scoped>
i {
  color: var(--capable-white);
}

button:hover > i {
  color: var(--capable-red);
}

video {
  border-radius: 8px;
}

.modal-header {
  border-bottom: 0px;
  margin: 0px;
}
.modal-content {
  background-color: var(--capable-blue);
}

.cam-button-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--capable-white);
  font-size: 16px;
  width: 100%;
  height: 100%;
}

.cam-button-label:hover {
  color: var(--capable-red);
}
</style>
