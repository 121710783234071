<template>
  <div class="container mt-5 divContainerAdminGeneral">   
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral" v-if="user.role === 'admin'">Default Tools</h2>
      <h2 class="titleAdminGeneral" v-else>Tools</h2>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn_admin_general" v-on:click="openModalTools()">Add New Tool</button>
      </div>
    </div>
    <saveTool ref="saveModalTools" :user="user"/>
    <confirmationModal ref="confirmModal" @confirm="deleteToolApi" />
    <hr class="w-100" />
    <p class="text-danger" v-if="errorMessage">
      {{ errorMessage }}
    </p>
    <div class="row w-100 divContainerAdminGeneral mt-3 d-none d-md-block">      
      <div class="d-flex w-100">
        <div class="col-2 titleAdminTableGeneral pl-0">Picture</div>
        <div class="col-2 titleAdminTableGeneral pl-0">Tool Item</div>
        <div class="col-3 titleAdminTableGeneral pl-0">Content Area</div>
        <div class="col-3 titleAdminTableGeneral pl-0">Modifications</div>
      </div>
      <div v-for="(tool, index) in tools" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">
          <img :src="tool.picture" class="imgTool" v-if="tool.picture">
          <div class="d-flex justify-content-center align-items-center noImgTool" v-else>
            No Image
          </div>
        </div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{tool.name}}</div>
        <div class="col-3 textTableAdminGeneral d-flex justify-content-start align-items-center">{{tool.contentArea}}</div>
        <div class="col-3 textTableAdminGeneral d-flex justify-content-start align-items-center">{{tool.modifications}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-edit p-2 icon_tool iconEdit" v-on:click="openModalTools(tool)"></i></div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(tool)"></i></div>
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !tools.length">
        <h1 class="m-5">No Tools to display.</h1>
      </div>

      <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
        <div class="loader-whitebg"></div>
      </div>
    </div>

    

      
    <div class="row w-100 card-deck d-md-none m-0 justify-content-start divCardToolList">
      
      <div class="col-sm-6 col-8 p-0 mt-5" v-for="(tool, index) in tools" :key="index">
        <div class="card">
          <div class="d-flex mb-3">
            <h5 class="card-title cardTitleTool col-8">{{tool.name}}</h5>
            <i class="fas fa-edit p-2 icon_tool iconEdit col-2" v-on:click="openModalTools(tool)"></i>
            <i class="fas fa-trash p-2 icon_tool iconDelete col-2" v-on:click="openConfirmationModal(tool)"></i>
          </div>
          <div class="w-100 divImageCardTool">
            <img :src="tool.picture" class="card-img-top imageCardTool" v-if="tool.picture">
            <div class="d-flex justify-content-center align-items-center noImgCardTool" v-else>
              No Image
            </div>
          </div>
          <div class="row card-body">
            <span class="col-6 cardSubtitle text-muted">Content Area</span>
            <span class="col-6 cardSubtitle text-muted">Modifications</span>
            <span class="col-6 cardContent">{{tool.contentArea}}</span>
            <span class="col-6 cardContent">{{tool.modifications}}</span>
          </div>
        </div>
      </div>  
      <div class="badges-list-border col-12 row-collapse-color2 text-center bg-light" v-if="!loading && !tools.length">
        <h1 class="my-5">No Tools to display.</h1>
      </div>  

    </div>
    
  </div>
</template>
<script>
import saveTool from './modalTools'
import confirmationModal from './confirmationDeleteModal.vue';
import { mapActions, mapState} from 'vuex'
import Admin from '../views/admin.vue';

export default {
  name: "defaultToolsList",
  data(){
    return{
      loading:false,
      errorMessage:"",
    }
  },
  components:{
    saveTool,
    confirmationModal,
    Admin,
  },
  props:['tools']
  ,
  computed:{
    ...mapState('user',['user'])
  },
  methods:{
    ...mapActions('tools', ['getTools', 'getDefaultTools', 'deleteTool', 'deleteDefaultTool']),

    openModalTools(tool = {}){
      this.$refs.saveModalTools.name = tool.name || ""
			this.$refs.saveModalTools.contentArea = tool.contentArea || ""
			this.$refs.saveModalTools.modifications = tool.modifications || ""
			this.$refs.saveModalTools.picture = tool.picture || ""
			this.$refs.saveModalTools.toolId = tool._id || ""
      $('#saveToolModal').modal('show')
    },
    
    openConfirmationModal(tool = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? "+tool.name || ""
      this.$refs.confirmModal.assetId = tool._id || ""
      $('#confirmationModal').modal('show')
    },


    deleteToolApi(id){
      this.loading = true
      if(this.user.role === "admin"){
        this.deleteDefaultTool(id)
        this.loading = false
      }
      else if(this.user.role === "teacher"){
        this.deleteTool(id)
        this.loading = false
      }
		},
  },
  mounted(){
  }
}
</script>