const objectives = axios => {
	return {
		async CreateNewObjective(data){
            return axios.post("/api/objectives", data)
        },
		async getObjectives(){
            return axios.get(`/api/objectives`)
        },
		async deleteObjective(id){
            return axios.delete(`/api/objectives/${id}`)
        },
		async updateObjective(id,data){
            return axios.put(`/api/objectives/${id}`, data)
        }
	}
}
export default objectives;