<template>
  <projectsAndDraftsTable
    :badges="badges"
    :tools="tools"
    :projects="projects"
    :isLoading="isLoading"
  />
</template>
<script>
import projectsAndDraftsTable from "../components/projectsAndDraftsTable";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      isLoading: Boolean,
    };
  },
  components: {
    projectsAndDraftsTable,
  },
  computed: {
    ...mapState("badges", ["badges"]),
    ...mapState("tools", ["tools"]),
    ...mapState("projects", ["projects"]),
  },
  methods: {
    ...mapActions("tools", ["getTools"]),
    ...mapActions("badges", ["getBadges"]),
    ...mapActions("projects", ["getProjects"]),
  },
  async created() {
    this.isLoading = true;
    await this.getBadges();
    await this.getTools();
    await this.getProjects();
    this.isLoading = false;
  },
};
</script>
