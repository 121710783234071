<template>
  <div class="d-flex justify-content-end align-items-center w-100">
    <p
    @click="(e) => $emit('flipCard', e)"
    :style="{ fontSize: fontSize }"
    > {{ text }} </p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    fontSize: {
      type: String,
      default: "18px"
    }
  }
}
</script>

<style scoped>
p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: var(--capable-blue);
  margin-bottom: 0px;
}

@media (max-width: 520px) {
  p {
    font-size: 18px;
  }
}
</style>
