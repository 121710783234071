<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar
      :routes="filteredRoutes"
      :user="user"
      :features="filteredFeatures"
      :isMobileDevice="isMobileDevice"
    />
    <router-view />
    <Footer v-if="showFooter" :user="user" />
  </div>
</template>

<script>
import API from "./services/api";
import jwt_decode from "jwt-decode";
import Footer from "./components/Footer.vue";
import { mapActions, mapState } from "vuex";
import routes from "./routes.vue";
import { store } from "../src/store/index";
import notificationsList from "./components/notificationsList.vue";
import Navbar from "./components/navbar.vue";
// @ts-check
export default {
  data() {
    return {
      listRoutes: [],
      userType: "",
      isMobileDevice: null,
      copied: false,
    };
  },
  components: {
    Navbar,
    notificationsList,
    Footer,
  },
  methods: {
    ...mapActions("signUp", ["setRole"]),
    backToAdmin() {
      API.admins
        .returnToAdmin()
        .then((res) => {
          let userDecode = jwt_decode(res.data);
          sessionStorage.setItem("token", res.data);
          sessionStorage.setItem("user", JSON.stringify(userDecode));
          this.$router.push("/admin");
        })
        .catch((err) => console.log(err));
    },
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(userAgent);
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
    filteredRoutes() {
      const role = this.user.role;
      const featuresList = this.config;
      return routes.filter((route) => {
        let hasMeta = route.meta;
        let rolePermission = route.meta?.routeBelongsTo == role;
        let isNotHidden = !route.meta?.hideFromNavbar;
        let hasFeatureInMeta = route.meta?.feature;
        let featureStatus = featuresList[route.meta?.feature];
        let showFeature = !hasFeatureInMeta || featureStatus;
        let belongsToDropdown = route.meta?.projectDropdown;

        if (route.name === "Home" && hasMeta && role) {
          return true;
        } else if (!hasMeta && !role) {
          return true;
        } else if (
          hasMeta &&
          rolePermission &&
          isNotHidden &&
          showFeature &&
          !belongsToDropdown
        ) {
          return true;
        }
        return false;
      });
    },
    filteredFeatures() {
      const role = this.user.role;
      return routes.filter((route) => {
        let hasMeta = route.meta;
        let rolePermission = route.meta?.routeBelongsTo == role;
        let isNotHidden = !route.meta?.hideFromNavbar;
        let belongsToDropdown = route.meta?.projectDropdown;

        if (hasMeta && rolePermission && isNotHidden && belongsToDropdown) {
          return true;
        }
        return false;
      });
    },
    showFooter() {
      const dontShow = []; //Add to this array the views name in which wants to hide the footer
      return !dontShow.includes(this.$route.name);
    },
  },
  created() {
    let stringifiedUser = sessionStorage.getItem("user");
    if (stringifiedUser) {
      store.dispatch("configs/getConfig");
    } else {
      store.dispatch("configs/getDefault");
    }
    this.checkDeviceType();
  },
};
</script>

<style scoped>
.test {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
