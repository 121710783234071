<template>
  <li class="d-flex flex-column align-items-center">
    <a
      v-if="!toggleCross"
      class="nav-link dropdown-toggle px-0 text-back fontFamily mr"
      data-bs-toggle="collapse"
      href="#"
      role="button"
      data-bs-target="#dropdown-notifications"
      aria-haspopup="true"
      aria-expanded="false"
      aria-controls="dropdown-notifications"
    >
      <baseIcon
        class="bell"
        width="35.676"
        height="42.832"
        :iconColor="`currentColor`"
      >
        <bellNotif
          v-if="
            notifications.find((notification) => notification.read == false)
          "
        />
        <bell v-else />
      </baseIcon>
    </a>
    <div
      class="collapse div-dropdown-notifications"
      id="dropdown-notifications"
    >
      <div
        v-for="(notification, index) in notificationsList"
        v-touch:swipe.left="swipeHandler(notification, index)"
        :key="index"
        :class="`notification-container border-bottom d-flex flex-column dropdown-item swipe-delete-item ${
          notification.deleted ? 'swipe-delete-item-leave-active' : ''
        } ${notification.read ? 'read' : ''}`"
        @click="markAsRead([notification._id])"
      >
        <div
          @click="redirect(notification)"
          class="d-flex justify-content-between"
        >
          <div
            class="d-flex justify-content-center align-items-start flex-column"
          >
            <h3 class="mb-1 text-ellipsis">{{ notification.title }}</h3>
            <h2 class="mb-1 text-ellipsis">{{ notification.description }}</h2>
          </div>
          <div
            :class="`date-container d-flex justify-content-between align-items-center ${!isMobileDevice && 'ml-5'}`"
          >
            <h3 class="date m-0">{{ parseDate(notification.updatedAt) }}</h3>
            <div
              v-if="!isMobileDevice"
              class="cross-container d-flex justify-content-center align-items-center ml-3"
            >
              <baseIcon
                @click="deleteNotif(notification._id, $event)"
                class="cursor-pointer"
                width="13.616"
                height="13.51"
                iconColor="#ffffff"
                data-dismiss="modal"
              >
                <CrossIcon />
              </baseIcon>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="notifications.length == 0"
        class="dropdown-item border-bottom notification-container d-flex justify-content-center"
      >
        <h2 class="m-3">You don't have notifications.</h2>
      </div>
      <div
        class="d-flex justify-content-between align-items-center bg-blue px-3 py-3 gap-5"
        v-if="notifications.length != 0"
      >
        <p
          v-if="notificationsToShow == 6"
          class="mark-all-as-read f-semibold"
          @click="viewMore($event)"
        >
          View more
        </p>
        <p v-else class="mark-all-as-read f-semibold" @click="viewLess($event)">
          View less
        </p>
        <p
          class="mark-all-as-read f-semibold"
          @click="markAllAsRead(notifications, $event)"
        >
          Mark all as read
        </p>

        <p class="mark-all-as-read f-semibold" @click="deleteAllNotif($event)">
          Delete all
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";
import baseIcon from "./baseIcon.vue";
import bell from "../assets/icons/bell.vue";
import bellNotif from "../assets/icons/bellNotif.vue";
import { wait, checkDeviceType } from "../utils";
import CrossIcon from "../assets/icons/cross.vue";

export default {
  data() {
    return {
      notificationsToShow: 6,
      notificationsList: [],
      isMobileDevice: false,
      toggleNotificationsCross: false,
    };
  },
  props: ["toggleCross"],
  mounted() {
    this.getNotificationsList();
    this.isMobileDevice = checkDeviceType();
  },
  components: {
    baseIcon,
    bellNotif,
    bell,
    CrossIcon,
  },
  computed: {
    ...mapState("notifications", ["notifications"]),
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("notifications", [
      "getNotifications",
      "markAsReadNotifications",
      "deleteNotification",
      "deleteAllNotifications",
    ]),
    async getNotificationsList() {
      if (Object.keys(this.user).length) {
        try {
          await this.getNotifications();
          this.notificationsList = this.selectNotificationsToShow([
            ...this.notifications.map((notification) => ({
              ...notification,
              deleted: false,
            })),
          ]);
        } catch (e) {
          console.error(e);
        }
      }
    },
    redirect(notification) {
      if (notification.modelType == "Student") {
        this.$router.push(
          `/see-student-project?project=${notification.meta.project}`
        );
      } else if (notification.meta.draft) {
        this.$router.push(`/project-drafts`);
      } else {
        this.$router.push(`/projects`);
      }
    },
    parseDate(date) {
      const numericDate = Date.parse(date);
      return new Date(numericDate).toLocaleDateString("en-US");
    },
    selectNotificationsToShow(array) {
      return array.slice(0, this.notificationsToShow);
    },
    viewMore(event) {
      event.stopPropagation();
      this.notificationsToShow = 20;
      this.getNotificationsList();
    },
    swipeHandler(item, i) {
      return (direction, mouseEvent) => {
        console.log("Swiped item ", i, ": ", item, " in direction ", direction);
        this.notificationsList[i].deleted = true;
        this.deleteNotif(this.notificationsList[i]._id);
      };
    },
    viewLess(event) {
      event.stopPropagation();
      this.notificationsToShow = 6;
      this.getNotificationsList();
    },
    async markAsRead(idList) {
      const data = { notifications: idList };
      try {
        await this.markAsReadNotifications({ data });
        await this.getNotificationsList();
      } catch (e) {
        console.error(e);
      }
    },
    markAllAsRead(notifications, event) {
      event.stopPropagation();
      return this.markAsRead(
        this.getIdList(this.selectNotificationsToShow(notifications))
      );
    },
    async deleteNotif(id, event) {
      try {
        await this.deleteNotification(id);
        await wait(300);
        await this.getNotificationsList();
      } catch (e) {
        console.error(error);
      }
    },
    async deleteAllNotif(event) {
      event.stopPropagation();
      try {
        await this.deleteAllNotifications();
        await this.getNotificationsList();
      } catch (e) {
        console.error(error);
      }
    },
    getIdList(notificationObjectList) {
      return notificationObjectList.map((notification) => notification._id);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}
h3 {
  font-size: 14px;
  border: black 1px;
  padding-top: 4px;
}

p {
  margin: 0px;
}

svg {
  color: var(--capable-blue);
}

.text-ellipsis {
  max-width: 70vw;
}

.date-container svg {
  height: 10px;
  width: 10px;
}

.swipe-delete-item {
  transition: all 0.3s ease;
}

.swipe-delete-item-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}

.collapse::-webkit-scrollbar {
  width: 5px;
}

.collapse::-webkit-scrollbar-track {
  background-color: var(--capable-white);
}

.collapse::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.cross-container {
  padding: 0.5rem;
}
.cross-container:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.border-bottom {
  min-width: 250px;
  border-bottom: 1px solid;
  border-color: var(--capable-white);
}

.notification-container {
  color: var(--capable-white);
  background-color: var(--capable-blue);
  transition: 200ms all;
  border-color: rgba(0, 0, 0, 0);
  scrollbar-width: 5px;
}

.notification-container:hover {
  cursor: pointer;
  background-color: var(--capable-red);
  color: var(--capable-white);
}

.icon-bell-alt::before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
}

.notification-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px transparent;
  color: rgba(0, 0, 0, 0.5);
}

.collapse {
  max-height: 500px;
  overflow: auto;
  padding: 0px;
  right: 0px !important;
  left: auto !important;
  border-radius: 0px 0px 11px 11px;
  background-color: var(--capable-blue);
  color: var(--capable-white);
  border: 0px;
}

.read {
  background-color: var(--capable-blue);
  color: var(--capable-grey);
}

.mark-all-as-read {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 2px;
  font-size: 14px;
}

.mark-all-as-read:hover {
  text-decoration: underline;
}

.icon-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 14px;
  height: 14px;
}

.icon-remove:hover {
  color: lightgray;
}

.bell {
  margin: 0 auto;
  transform: scale(0.5);
  color: var(--capable-blue);
}

.bg-blue {
  background-color: var(--capable-blue);
}

.div-dropdown-notifications {
  transition: 500 all;
  position: absolute;
  border-radius: 0px 0px 11px 11px;
  top: 70px;
  right: 0px;
}

@media (max-width: 992px) {
  .icon-circle {
    top: 10px;
    right: 11px;
  }

  .div-dropdown-notifications {
    top: 71px;
  }
}

@media (max-width: 520px) {
  .div-dropdown-notifications {
    width: 100vw;
  }
}
</style>
