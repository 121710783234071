<template>
  <div
    class="modal fade"
    id="importStepsModal"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="div_modal_form modal-body">
          <h3 class="messageDeleteModal">Import steps</h3>
        </div>
        <div class="container-select-step">
          <label class="label-select-project" for="selectStepId"
            >Import from</label
          >
          <div class="d-flex">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                @click="selectProjectWarehouse"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Project warehouse
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                @click="selectMyProjects"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                My projects
              </label>
            </div>
          </div>
          <label class="label-select-project" for="selectStepId"
            >Select project</label
          >
          <select
            id="selectStepId"
            class="form-select form-select-step"
            v-model="selected"
            @change="cleanSelected"
          >
            <option
              class="option-select"
              v-for="(project, index) in projects"
              :key="index"
              :value="project"
            >
              {{ project.name }}
            </option>
          </select>
          <div class="box-step-selected my-4">
            <div v-for="(step, indexS) in selected.steps" :key="indexS">
              <input
                type="radio"
                :id="indexS"
                :value="step"
                v-model="stepSelected"
              />
              <label
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="step"
                class="label-radio-step"
                :for="indexS"
                >Step {{ indexS + 1 }}
                | Description: {{ step?.description ? stepDescription(step.description).slice(0, 25) + '...' : 'No description available' }}
                | Image(s):
                <i
                  :class="
                    step?.imagesURL?.length ? 'icon-check' : 'icon-check-empty'
                  "
                ></i>
                | GIF:
                <i
                  :class="
                    step?.gifsURL?.length ? 'icon-check' : 'icon-check-empty'
                  "
                ></i>
                | Multiple choice:
                <i
                  :class="
                    step?.hasMultipleChoice ? 'icon-check' : 'icon-check-empty'
                  "
                ></i
              ></label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-lg btnModalSecondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary btn-lg btnModalSuccess"
            data-dismiss="modal"
            v-on:click="confirmImportStep()"
          >
            Import step
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "importStep",
  props: ["user"],
  data() {
    return {
      selected: {},
      stepSelected: {},
      availableProjects: [],
    };
  },
  computed: {
    ...mapState("projects", ["projects"]),
  },
  methods: {
    ...mapActions("projects", ["getProjects", "getDefaultProjects"]),
    cleanSelected() {
      this.stepSelected = {};
    },
    confirmImportStep() {
      const step = { ...this.stepSelected };
      this.$emit("importStep", step);
    },
    selectProjectWarehouse() {
      this.getDefaultProjects();
    },
    selectMyProjects() {
      this.getProjects();
    },
    stepDescription(text) {
      const regExp = /<[^>]+>(.*?)<\/[^>]+>/g;
      const results = [];
      let coincidencia;
      while ((coincidencia = regExp.exec(text)) !== null) {
          results.push(coincidencia[1]);
      }
      const result = results.join(" ")
      return result

    }
  },
  async mounted() {
    if (this.user) {
      this.getProjects();
    }
    if (this.projects.length > 0) {
      this.selected = this.projects[0];
    }
    $("#importStepsModal").on("hide.bs.modal", (e) => {
      this.selected = {};
      this.stepSelected = {};
    });
  },
};
</script>
<style scoped>
.container-select-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-select-step {
  width: 80%;
  padding: 0px 0px 0px 10px !important;
  border: 1px solid black;
  border-radius: 9px;
  font-size: 15px;
  margin-bottom: 10px;
}
.box-step-selected {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.box-step-selected div {
  display: flex;
  justify-content: center;
}
.label-radio-step {
  padding-left: 5px;
  padding-top: 8px;
  font-size: 13px;
}
.label-select-project {
  width: 80% !important;
  font-size: 15px;
}
</style>
