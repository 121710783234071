<template>
  <div class="student-container my-5">
    <h1 class="titleAdminGeneral text-blue f-bold">
      Welcome {{ user && user.name }}
    </h1>
    <div
      class="form-group py-4 w-100 d-flex justify-content-center"
      v-if="loading"
    >
      <div class="loader-whitebg"></div>
    </div>
    <div v-else class="mt-5 flex-and-direction gap-5">
      <div :class="`${selectedClass} test`">
        <div class="dashboard-title text-white f-bold">
          In progress projects
        </div>
        <div
          v-if="!inProgressProjects.length"
          class="text-blue f-bold pl-3 row-height"
        >
          None.
        </div>
        <ul v-else>
          <li
            @click="seeStudentProject(inProgressProject.project._id)"
            v-for="(inProgressProject, index) in inProgressProjects"
            :key="index"
            class="d-flex justify-content-between align-items-center px-3 border-bottom"
          >
            <div class="table-text text-blue f-bold w-40">
              {{
                inProgressProject?.project?.name.length < 15
                  ? inProgressProject?.project?.name
                  : `${inProgressProject?.project?.name.substring(0, 15)}...`
              }}
            </div>

            <ProgressBar
              :percentage="Math.round(inProgressProject.progress)"
              class="w-60"
            />
          </li>
        </ul>
      </div>

      <div :class="selectedClass">
        <div class="dashboard-title text-white f-bold">Completed Projects</div>
        <div
          v-if="!completedProjects.length"
          class="text-blue f-bold pl-3 row-height"
        >
          None.
        </div>
        <ul v-else>
          <li
            @click="seeStudentProject(completedProject.project._id)"
            v-for="(completedProject, index) in completedProjects"
            :key="index"
            class="d-flex justify-content-between px-3 border-bottom"
          >
            <div
              class="d-flex flex-column align-items-start justify-content-center w-40"
            >
              <div class="table-text text-blue f-bold">
                {{
                  completedProject?.project?.name.length < 15
                    ? completedProject?.project?.name
                    : `${completedProject?.project?.name.substring(0, 15)}...`
                }}
              </div>
              <p class="text-blue">
                {{ moment(completedProject?.updatedAt) }}
              </p>
            </div>
            <ProgressBar :percentage="100" class="w-60" />
          </li>
        </ul>
      </div>

      <div :class="selectedClass" v-if="config.lockedProjects">
        <div class="dashboard-title text-white f-bold">Locked projects</div>
        <div
          v-if="!lockedProjects.length"
          class="text-blue f-bold pl-3 row-height"
        >
          None.
        </div>
        <div v-else>
          <ul v-for="(lockedProject, index) in lockedProjects" :key="index">
            <li class="text-blue f-bold pl-3 table-text">
              {{
                lockedProject?.project?.name.length < 15
                  ? lockedProject?.project?.name
                  : `${lockedProject?.project?.name.substring(0, 15)}...`
              }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="config.badges" :class="selectedClass">
        <div class="dashboard-title text-white f-bold">Your badges</div>
        <div v-if="!myBadges.length" class="text-blue f-bold pl-3 row-height">
          None.
        </div>
        <ul v-else>
          <li
            v-for="(badge, index) in myBadges"
            :key="index"
            class="border-bottom px-3"
          >
            <BaseIcon iconColor="#004aad" width="31.753" height="31.756">
              <BadgeIcon />
            </BaseIcon>
            <div class="text-blue f-bold pl-3 table-text">
              {{ badge?.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import API from "../services/api";
import _ from "lodash";
import moment from "moment";
import ProgressBar from "../components/progressBar.vue";
import BaseIcon from "../components/baseIcon.vue";
import BadgeIcon from "../assets/icons/badge.vue";

export default {
  data() {
    return {
      student: "",
      myProjects: [],
      myBadges: [],
      completedProjects: [],
      inProgressProjects: [],
      lockedProjects: [],
      loading: false,
      selectedClass: "box-column",
    };
  },
  components: {
    ProgressBar,
    BaseIcon,
    BadgeIcon,
  },
  methods: {
    seeStudentProject(projectId) {
      this.$router.push({
        path: "/see-student-project",
        query: { project: projectId },
      });
    },
    moment(date) {
      return moment(date).format("MM/DD/YY");
    },
    selectClass() {
      if (this.config.lockedProjects && this.config.badges) {
        this.selectedClass += " w-25";
      } else if (this.config.lockedProjects !== this.config.badges) {
        this.selectedClass += " w-33";
      } else {
        this.selectedClass += " w-50";
      }
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("configs", ["config"]),
  },
  created() {
    this.loading = true;
    this.selectClass();
    API.studentBadges
      .getMyBadges(this.user._id)
      .then((res) => {
        this.myBadges = res.data.map((badge) => {
          return badge.badge;
        });
      })
      .catch((e) => console.log(e));

    API.studentProjects
      .getMyProjects(this.user._id)
      .then((res) => {
        this.myProjects = res.data;
        this.myProjects.forEach((project) => {
          project.stepsCompleted = project?.steps.filter((step) => {
            return step.status === "Completed" || step.status === "Approved";
          });
          if (
            project?.project?.steps?.length ===
              project?.stepsCompleted.length &&
            project?.project?.steps?.length != null
          ) {
            this.completedProjects.push(project);
          } else {
            project.progress =
              (project?.stepsCompleted?.length * 100) /
              project?.project?.steps?.length;
            if (project?.project?.steps != null) {
              this.inProgressProjects.push(project);
            }
          }
        });
      })
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped>
ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

li {
  padding: 0px;
  margin: 0px;
  height: 78px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

svg {
  width: 40px;
  height: 40px;
}

p {
  margin: 0px;
  font-size: 14px;
}

.table-text {
  font-size: 16px;
}

.row-height {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 78px;
}

.flex-and-direction {
  display: flex;
}

.student-container {
  align-self: center;
  width: 85%;
}

.box-column {
  height: 100%;
  font-size: 15px;
  background: var(--capable-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

/* w-25 and w-50 are defined by bootstrap */
.w-33 {
  width: 33%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60% !important;
}

.dashboard-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 78px;
  padding: 0px 1rem;
  background: var(--capable-blue);
  color: var(--capable-white);
  border-radius: 11px 11px 0px 0px;
  font-size: 20px;
  margin: 0px;
}

.border-bottom {
  border-bottom: 1px solid var(--capable-grey);
}

@media (max-width: 950px) {
  .dashboard-title {
    height: 53px;
    font-size: 14px;
  }
  .box-column {
    width: 95% !important;
  }

  .flex-and-direction {
    flex-direction: column;
  }
}
</style>
