const defaultTools = (axios) => {
  return {
    async CreateNewDefaultTool(data) {
      return axios.post("/api/default-tools", data);
    },
    async getDefaultTools() {
      return axios.get(`/api/default-tools`);
    },
    async UploadDefaultPicture(data) {
      return axios.post("/api/default-tools/picture", data);
    },
    async deleteDefaultTool(id) {
      return axios.delete(`/api/default-tools/${id}`);
    },
    async updateDefaultTool(id, data) {
      return axios.put(`/api/default-tools/${id}`, data);
    },
  };
};
export default defaultTools;
