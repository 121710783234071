export default{
  setProjects(state, projects){
    state.projects = projects
    state.errorProjectsAPI = ""
  },
  setDrafts(state, drafts){
    state.drafts = drafts
    state.errorProjectsAPI = ""
  },
  setErrorProjectsApi(state, errorProjectsAPI){
    state.errorProjectsAPI = errorProjectsAPI
  }
}