<template>
  <path
    id="Path_73"
    data-name="Path 73"
    d="M30.094,9.834c-1.687.749-3.286,1.45-4.873,2.178a.584.584,0,0,0-.228.448c-.02,1.05,0,2.1-.012,3.151a8.14,8.14,0,0,1-16.263.634c-.049-1.223-.032-2.451-.013-3.675a.62.62,0,0,0-.437-.675C5.793,10.812,3.329,9.706.856,8.618.372,8.405-.009,8.14,0,7.546c.009-.571.387-.82.851-1.026Q7.521,3.566,14.183.6A6.19,6.19,0,0,1,19.446.577q6.73,2.992,13.463,5.978A1.043,1.043,0,0,1,33.3,8.379a.935.935,0,0,0-.145.562c-.009,3.215,0,6.429-.008,9.644a1.559,1.559,0,0,1-1.289,1.691A1.505,1.505,0,0,1,30.1,18.694c-.009-2.753,0-5.506,0-8.259v-.6"
    transform="translate(-0.001 0)"
  />
  <path
    id="Path_74"
    data-name="Path 74"
    d="M20.427,218.759c-4.027,0-8.053.007-12.079,0a4.037,4.037,0,0,1-4.114-3.221,6.315,6.315,0,0,1,.5-3.728,10.564,10.564,0,0,1,4.808-5.126,38.1,38.1,0,0,1,4.132-1.817,1.883,1.883,0,0,1,2.172.54q1.494,1.51,2.988,3.021a2.057,2.057,0,0,0,3.216.009c.975-.983,1.957-1.959,2.917-2.956a2.12,2.12,0,0,1,2.382-.594,15.683,15.683,0,0,1,7.264,4.46,8.313,8.313,0,0,1,2.114,4.8,4.082,4.082,0,0,1-4.173,4.607c-4.042.014-8.085,0-12.127,0"
    transform="translate(-3.606 -178.597)"
  />
</template>

<script>
export default {};
</script>
