export default{
  setObjectives(state, objectives){
    state.objectives = objectives
    state.errorObjectivesAPI = ""
  },
  setErrorObjectivesApi(state, errorObjectivesAPI){
    state.errorObjectivesAPI = errorObjectivesAPI
  },
  filterObjective(state, {objectiveName, objectivesOriginal}){
    console.log(objectiveName, objectivesOriginal)
    if (objectiveName){
      let objectivesFiltered = state.objectives.filter(objective => {
        return objective.name.toLowerCase().indexOf(objectiveName.toLowerCase()) > -1
      })
      state.objectives = objectivesFiltered
    } else {
      state.objectives = objectivesOriginal
    }
  }
}