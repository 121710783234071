<template>
  <path
    id="Path_209"
    data-name="Path 209"
    d="M13.616,11.435v.4a.8.8,0,0,0-.026.087,1.669,1.669,0,0,1-.788,1.162,1.7,1.7,0,0,1-2.14-.282q-1.83-1.826-3.656-3.657a1.591,1.591,0,0,1-.115-.157,1.309,1.309,0,0,1-.113.164q-1.855,1.858-3.712,3.714c-.075.075-.151.15-.23.221a1.707,1.707,0,0,1-2.29-2.531Q2.45,8.644,4.361,6.737a1.02,1.02,0,0,1,.135-.1c-.06-.063-.092-.1-.126-.132L.864,3a2.373,2.373,0,0,1-.174-.183,1.645,1.645,0,0,1-.263-1.8A1.626,1.626,0,0,1,1.952,0,1.689,1.689,0,0,1,3.246.549q1.765,1.77,3.533,3.537a1.158,1.158,0,0,1,.108.16A1.721,1.721,0,0,1,7,4.1q.93-.932,1.861-1.862C9.4,1.7,9.931,1.142,10.49.618a1.7,1.7,0,0,1,2.75.693,1.67,1.67,0,0,1-.45,1.826Q11.1,4.826,9.416,6.512c-.033.033-.064.069-.114.123a1.717,1.717,0,0,1,.143.115q1.806,1.8,3.61,3.61a1.8,1.8,0,0,1,.491.792c.026.093.047.188.07.282"
    transform="translate(0 0)"
  />
</template>

<script>
export default {};
</script>
