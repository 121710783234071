<template>
  <div :class="`d-flex flex-column align-items-start justify-content-center w-100`">
    <label
    v-if="label"
    :class="$attrs['label-class'] + ' f-regular '"

    >
      {{ label }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <div :class="`input-container ${border && 'gray-border'}`" tabindex="0">
      <slot name="leftSlot" class="leftSlot" />
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        v-bind="$attrs"
      />
      <slot name="rightSlot" class="rightSlot" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  inheritAttrs: false,
  props: {
    label: {
      type: [String, Boolean],
      default: "",
    },
    modelValue: {
      type: String || Number,
      default: "",
    },
    border: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: "font-20px"
    }
  },
  data() {
    return {
      inputSelected: false,
    };
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
svg {
  max-height: 3rem;
  max-width: 3rem;
}

label {
  font-size: 20px;
  color: var(--capable-blue);
}

input {
  font-size: 20px;
}

input,
input:focus {
  border: none;
  border-radius: 9px;
}

textarea:focus,
input:focus {
  outline: none;
}

.input-container {
  width: 100%;
  border-radius: 9px;
  border: none;
  font-size: 15px;
  display: flex;
  background-color: var(--capable-white);
}

.landing-input {
  width: 425px;
}

.rightSlot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 0px 20px;
  color: rgba(0, 0, 0, 0.2);
}

.leftSlot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 0px 20px;
  color: rgba(0, 0, 0, 0.2);
}

.gray-border {
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
}

.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px;
}

.font-18px {
  font-size: 18px;
}

.font-20px {
  font-size: 20px;
}

@media (max-width: 1400px) {

  .lg-font-14px {
    font-size: 14px;
  }

  .lg-font-16px {
    font-size: 16px;
  }

  .lg-font-18px {
    font-size: 18px;
  }

  .lg-font-20px {
    font-size: 20px;
  }
}


@media (max-width: 768px) {
  .landing-input {
    width: 220px;
  }
  label,
  input {
    font-size: 15px;
  }
}
</style>
