import API from "../../services/api";
import _ from 'lodash'

export default{
  getBadges:( { commit } ) => {
    API.badges.getBadges()
      .then(res => commit('setBadges', res.data) )
      .catch(error => commit('errorBadgesAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteBadge:( { commit, dispatch }, id ) => {
    API.badges.deleteBadge(id)
      .then(res => dispatch('getBadges') )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  CreateNewBadge:( { commit, dispatch }, data ) => {
    API.badges.CreateNewBadge(data)
      .then(res => dispatch('getBadges') )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  updateBadge:( { commit, dispatch }, {BadgeId, data} ) => {
    API.badges.updateBadge(BadgeId, data)
      .then(res => dispatch('getBadges') )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  /* -- */

  getDefaultBadges:( { commit } ) => {
    API.defaultBadges.getDefaultBadges()
      .then(res => commit('setBadges', res.data) )
      .catch(error => commit('errorBadgesAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteDefaultBadge:( { commit, dispatch }, id ) => {
    API.defaultBadges.deleteDefaultBadge(id)
      .then(res => dispatch('getDefaultBadges') )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  CreateNewDefaultBadge:( { commit, dispatch }, data ) => {
    API.defaultBadges.CreateNewDefaultBadge(data)
      .then(res => dispatch('getDefaultBadges') )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },
  
  updateDefaultBadge:( { commit, dispatch }, {BadgeId, data} ) => {
    API.defaultBadges.updateDefaultBadge(BadgeId, data)
      .then(res => dispatch('getDefaultBadges') )
      .catch(error => commit('setErrorObjectivesApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },
}