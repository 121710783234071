<template>
  <div>
    <div class="section-component">
      <div class="container">
        <div class="d-flex justify-content-between align-items-end mt-4">
          <div class="form-group">
            <h1 class="form-title text-blue f-semibold">Roadmap</h1>
          </div>
          <div>
            <ActionButton
              :color="`blue`"
              class="px-3 py-3 mb-3"
              text="Submit idea"
              href="#createIdeaRoadmap"
              data-toggle="modal"
              @mouseenter="toggleIcon = true"
              @mouseleave="toggleIcon = false"
            >
              <template #leftIcon>
                <BaseIcon
                  width="13.616"
                  height="13.51"
                  class="mr-3"
                  :iconColor="toggleIcon ? '#004aad' : '#ffffff'"
                >
                  <CrossIcon />
                </BaseIcon>
              </template>
            </ActionButton>
          </div>
        </div>

        <div
          v-if="!loading && !ideas.length"
          class="roadmap-no-ideas-container label-input shadow text-blue"
        >
          <div>There are no ideas available.</div>
          <div>What are you waiting to be the first?</div>
        </div>

        <div
          class="form-group py-4 w-100 d-flex justify-content-center"
          v-if="loading"
        >
          <div class="loader-whitebg"></div>
        </div>

        <div v-else class="row card-idea-container">
          <div
            v-for="(idea, index) in ideas"
            :key="index"
            class="col-12 col-lg-3 col-md-6 p-0 d-flex flex-column"
          >
            <div class="m-2 card-idea shadow">
              <div class="card-header-idea">
                <div class="card-header-idea-title">
                  {{ idea.title.substr(0, 25) }}
                  {{ idea.title.length > 25 ? "..." : "" }}
                </div>
              </div>
              <div class="card-body-idea">
                {{ idea.description.substr(0, 200) }}
                {{ idea.description.length > 200 ? "..." : "" }}
              </div>
              <div class="card-idea-footer mt-auto">
                <div
                  v-if="idea.description.length > 200 || idea.title.length > 25"
                  class="container-featured-ideas mr-2"
                >
                  <a
                    @click="openModalViewIdea(idea)"
                    href="#viewIdea"
                    data-toggle="modal"
                    class="d-flex align-items-center"
                  >
                    <i class="fas fa-eye road-map-icon-comments"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalCreateIdea ref="createIdeaModal" @getideas="getIdeas" />
    <ModalViewIdea :ideaSelected="ideaSelected" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ModalCreateIdea from "../components/modalCreateIdea.vue";
import ModalViewIdea from "../components/modalViewIdea.vue";
import BaseIcon from "../components/baseIcon.vue";
import CrossIcon from "../assets/icons/cross.vue";
import ActionButton from "../components/actionButton.vue";
export default {
  name: "student-roadmap",
  data() {
    return {
      ideas: [],
      ideaSelected: {},
      loading: false,
      toggleIcon: false,
    };
  },
  components: {
    ModalCreateIdea,
    ModalViewIdea,
    BaseIcon,
    CrossIcon,
    ActionButton,
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    openModalViewIdea(idea) {
      this.ideaSelected = idea;
      $("#viewIdea").modal("show");
    },
    openModalCreateIdea() {
      $("#createIdeaRoadmap").modal("show");
      this.$refs.createIdeaModal.isStudent = true;
    },
    getIdeas() {
      this.loading = true;
      const url = "/api/ideas-board";
      return axios({ method: "get", url })
        .then((response) => {
          this.ideas = response.data
            .sort((b) => {
              if (b.status === "pending") return -1;
            })
            .filter((idea) => {
              return idea.student && idea.student._id === this.user._id;
            });
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getIdeas();
  },
};
</script>

<style scoped>
svg {
  transform: rotate(45deg);
}
</style>
