<template>
  <div class="container mb-5">
    <h3 class="mt-5 pt-5 text-center">Contact us!</h3>
    <div class="form-contact-us">
      <div class="form-group">
        <BaseInput
          v-model="email"
          :paddingX="'4'"
          :paddingY="'4'"
          placeholder="Email address"
          :class="['px-4', 'py-4', 'w-100', 'font-18px', 'lg-font-16px']"
          :label-class="'font-16px lg-font-14px'"
          label="Email address"
        />
      </div>
      <div class="form-group mt-3">
        <BaseInput
          v-model="name"
          :paddingX="'4'"
          :paddingY="'4'"
          placeholder="Name"
          :class="['px-4', 'py-4', 'w-100', 'font-18px', 'lg-font-16px']"
          :label-class="'font-16px lg-font-14px'"
          label="Name"
          fontSize="18px"
        />
      </div>
      <div class="form-group mt-3">
        <BaseInput
          v-model="subject"
          :paddingX="'4'"
          :paddingY="'4'"
          placeholder="Subject"
          :class="['px-4', 'py-4', 'w-100', 'font-18px', 'lg-font-16px']"
          :label-class="'font-16px lg-font-14px'"
          label="Subject"
        />
      </div>
      <div class="form-group mt-3">
        <TextArea
          :paddingX="'4'"
          :paddingY="'4'"
          placeholder="Message"
          :class="['px-4', 'py-4', 'w-100', 'font-18px', 'lg-font-16px']"
          label="Message"
          rows="4"
          v-model="message"
        />
      </div>
      <div class="mb-3 form-group col-md-12 mt-3 px-0">
        <FileUploader
          @change="processFile($event)"
          type="file"
          placeholder="No file choosen"
          :class="['w-100']"
        />
      </div>
      <div
        class="form-group py-4 w-100 d-flex justify-content-center"
        v-if="uploadingImage"
      >
        <div class="loader-whitebg"></div>
      </div>
      <p class="text-danger mt-3" v-if="errMessage">{{ errMessage }}</p>
      <div class="w-100 d-flex justify-content-center mt-5">
        <ActionButton
          :color="'red'"
          class="px-2 py-3"
          :text="'Send'"
          @click="sendEmail"
          fontsize="text-18-px"
        >
          <template #rightIcon> <div class="blankspace"></div> </template>
          <template #leftIcon> <div class="blankspace"></div> </template>
        </ActionButton>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../services/api";
import _ from "lodash";
import BaseInput from "./input.vue";
import TextArea from "./TextArea.vue";
import FileUploader from "./FileUploader.vue";
import ActionButton from "./actionButton.vue";
export default {
  data() {
    return {
      email: "",
      name: "",
      subject: "",
      message: "",
      attachment: "",
      errMessage: "",
      uploadingImage: false,
    };
  },
  components: {
    BaseInput,
    TextArea,
    FileUploader,
    ActionButton,
  },
  methods: {
    sendEmail() {
      let validEmail =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
      if (!this.email) {
        return (this.errMessage = "Email required");
      }
      if (!validEmail.exec(this.email)) {
        return (this.errMessage = "Invalid email");
      }
      if (!this.name) {
        return (this.errMessage = "Name required");
      }
      if (!this.subject) {
        return (this.errMessage = "Subject required");
      }
      if (!this.message) {
        return (this.errMessage = "Message required");
      }
      let data = {
        email: this.email,
        name: this.name,
        subject: this.subject,
        message: this.message,
        attachment: this.attachment,
      };
      API.contactUs.sendEmail(data);
      window.alert(
        "Your message is sent. Please wait 3-5 business days for a response."
      );
      this.$router.push("/");
    },
    processFile(e) {
      let file = e.target.files[0];
      this.errMessage = "";
      if (!file) {
        return console.log("Fail");
      }
      if (file.size > 1024 * 10000) {
        this.errMessage =
          "You exceeded the allowed image size. Maximum size allowed 2mb";
        return;
      } else {
        return this.uploadImage(file);
      }
    },
    uploadImage(file) {
      this.uploadingImage = true;
      const formData = new FormData();
      formData.append("attachment", file, file.name);
      return API.contactUs
        .addAttachment(formData)
        .then((res) => {
          const file = res.data;
          this.attachment = file.linkUrl;
        })
        .catch((err) => console.log("err", err))
        .finally((res) => {
          this.uploadingImage = false;
        });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 36px;
  font-weight: 700;
  color: var(--capable-blue);
}

.blankspace {
  width: 50px;
}

.form-contact-us {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 40px 20px;
  border-radius: 0.25rem;
}

@media (max-width: 1400px) {
  h3 {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  h3 {
  font-size: 20px;
}
}
</style>
