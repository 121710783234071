const admins = axios => {
	return {
		async getTeachers(){
      return axios.get(`/api/admin/teachers`)
    },
		async deleteTeacher(id){
      return axios.delete(`/api/admin/teachers/${id}`)
    },
    async updateTeacher(id, data){
      return axios.put(`/api/admin/teachers/${id}`, data)
    },
    async sideAccess(userId){
      return axios.get(`/api/admin/sideaccess/${userId}`)
    },
    async returnToAdmin(userId){
      return axios.get(`/api/admin/sideaccess/return`)
    }
	}
}
export default admins;