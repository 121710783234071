const contactUs = axios => {
	return {
		async sendEmail(data){
			return axios.post("/api/contact-us", data)
		},
		async addAttachment(data){
			return axios.post("/api/contact-us/attachment", data)
		},
	}
}
export default contactUs;