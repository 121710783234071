<template>
  <projectsList :badges="badges" :tools="tools" :projects="projects" />
</template>
<script>
import projectsList from "../components/projectsList";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    projectsList,
  },
  computed: {
    ...mapState("badges", ["badges"]),
    ...mapState("tools", ["tools"]),
    ...mapState("projects", ["projects"]),
  },
  methods: {
    ...mapActions("tools", ["getDefaultTools"]),
    ...mapActions("badges", ["getDefaultBadges"]),
    ...mapActions("projects", ["getDefaultProjects"]),
  },
  created() {
    this.getDefaultBadges();
    this.getDefaultTools();
    this.getDefaultProjects();
  },
};
</script>
