<template>
  <div>
    <defaultToolsList :tools="tools" />
    <p class="text-danger w-100 text-center" v-if="errorToolsAPI">
      {{ errorToolsAPI }}
    </p>
  </div>
</template>
<script>
import defaultToolsList from '../components/toolsList'
import { mapActions, mapState} from 'vuex'
export default {
  data(){
    return{
			loading:false
    }
  },
	components:{
		defaultToolsList
	},
  methods:{
		...mapActions('tools', ['getDefaultTools'])
  },
  computed:{
		...mapState('tools',['tools', 'errorToolsAPI'])
	},
	created(){
		this.getDefaultTools()
	},
}
</script>