import axios from "axios";
import user from "./user";
import teacher from "./teacher";
import tools from "./tools";
import objectives from "./objectives";
import projects from "./projects";
import studentProjects from "./student-projects";
import studentBadges from "./student-badges";
import badges from "./badges";
import admins from "./admins";
import defaultTools from "./defaultTools";
import defaultObjectives from "./defaultObjectives";
import defaultBadges from "./defaultBadges";
import defaultProjects from "./defaultProjects";
import contactUs from "./contactUs";
import configs from "./configs";
import accountRecovery from "./accountRecovery";
import notifications from "./notifications";

axios.defaults.baseURL = process.env.API_BASE_URL;

// @ts-check

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      Authorization: `Token ${window.sessionStorage.getItem("token")}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const API = (axios) => {
  return {
    user: user(axios),
    teacher: teacher(axios),
    tools: tools(axios),
    objectives: objectives(axios),
    projects: projects(axios),
    studentProjects: studentProjects(axios),
    studentBadges: studentBadges(axios),
    badges: badges(axios),
    admins: admins(axios),
    defaultTools: defaultTools(axios),
    defaultObjectives: defaultObjectives(axios),
    defaultBadges: defaultBadges(axios),
    defaultProjects: defaultProjects(axios),
    contactUs: contactUs(axios),
    configs: configs(axios),
    accountRecovery: accountRecovery(axios),
    notifications: notifications(axios),
  };
};

export default API(axios);
