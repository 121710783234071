import routes from '../../routes.vue'
export default {
  userRoutes: (state) => {
    const userType = state.user.role
    return routes.filter(route => {
      if(route.name === "Home" && route.meta && userType){
        return true
      }else if(!route.meta && !userType){
        return true
      }else if(route.meta && route.meta.routeBelongsTo == userType && !route.meta.hideFromNavbar){
        return true
      }
      return false
    })
  }
}