import _ from "lodash";

export default {
  setProjectProp({ commit, dispatch }, { prop, value }) {
    commit("setProjectProp", { prop, value });
  },
  setProjectStepProp({ commit, dispatch }, { prop, index, value }) {
    commit("setProjectStepProp", { prop, index, value });
  },
  addProjectStep({ commit, dispatch }, { step }) {
    commit("addProjectStep", { step });
  },
  deleteProjectStep({ commit, dispatch }, { index }) {
    commit("deleteProjectStep", { index });
  },
  resetProject({ commit }) {
    commit("resetProject");
  },
};
