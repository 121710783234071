<template>
  <path
    id="Path_45"
    data-name="Path 45"
    d="M23.187,21.216a2.023,2.023,0,0,1-.609,1.154,1.87,1.87,0,0,1-2.678-.136q-2.492-2.485-4.976-4.977a2.326,2.326,0,0,1-.169-.213,9.3,9.3,0,0,1-7.72,1.39,9.077,9.077,0,0,1-5.392-3.79,9.363,9.363,0,1,1,15.677-.3c.056.059.116.124.178.186q2.408,2.409,4.819,4.813a2.678,2.678,0,0,1,.869,1.417ZM15.707,9.364A6.332,6.332,0,1,0,9.4,15.7a6.346,6.346,0,0,0,6.308-6.333"
    transform="translate(0 0)"
  />
</template>

<script>
export default {};
</script>
