<template>
  <div class="container mt-5 divContainerAdminGeneral">
    <div class="divAdminGeneral w-100">
      <div>
        <h2 class="titleAdminGeneral" v-if="user.role === 'teacher'">Badges</h2>
        <h2 class="titleAdminGeneral" v-else>Default Badges</h2>
      </div>
      <div class="d-flex justify-content-between">
        <div class="input-group col-md-4">
          <input type="text" id="searchQuery" class="form-control inputsForm" placeholder="Search by badge name or requirement" v-model="searchQuery" @keyup="searchBadges(searchQuery)">
          <button class="btn btn-outline-secondary input-group-text" @click="clearFilter()" type="button" id="button-addon2">CLEAR</button>
        </div>        
        <router-link to="/add-new-badge" >
          <div :class="{'': objectives.length, 'hint--bottom': !objectives.length}" aria-label="You have to create an objective first">
            <button type="button" class="btn btn_admin_general" :user="user" :objectives="objectives">Add New Badge</button>
          </div>
        </router-link>  
      </div>
      <confirmationModal ref="confirmModal" @confirm="deleteBadgeApi" />
      <hr class="w-100" />
      <div class="d-none d-md-flex row w-100 divContainerAdminGeneral mt-3 mx-0">      
        <div class="d-flex w-100">
          <div class="col-5 titlesTableBadges pl-0">Name</div>
          <div class="col-2 titlesTableBadges pl-0">Date</div>
          <div class="col-3 titlesTableBadges pl-5">Requirements</div>
          <div v-if="students.length > 0" class="col-2 titlesTableBadges" style="padding-right: 0;padding-left: 0;text-align: end;">Assign to Students</div>
        </div>
        <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !badges.length">
          <h1 class="m-5">No Badges to display.</h1>
        </div>
        <div v-for="(badge, index) in badges" :key="index" :class="'badges-list-border d-flex flex-wrap '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1') ">
        
          <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center">{{badge.name}}</div>
          <div class="col-3 textTableAdminGeneral d-flex justify-content-start align-items-center">{{formatDate(badge.createdAt)}}</div>
          <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center">
            <i class="fas fa-chevron-down icon_tool iconCollapse collapsed" :id="'ButtonObjectivesListCollpaseDown'+index" data-toggle="collapse" :href="'#BadgeObjectives'+index" role="button" aria-expanded="false" :aria-controls="'BadgeObjectives'+index" v-on:click="displayButtonListCollapse(index)"></i>
            <i class="fas fa-chevron-up icon_tool iconCollapse collapsed d-none" :id="'ButtonObjectivesListCollpaseUp'+index" data-toggle="collapse" :href="'#BadgeObjectives'+index" role="button" aria-expanded="false" :aria-controls="'BadgeObjectives'+index" v-on:click="displayButtonListCollapse(index)"></i>
          </div>
          <div v-if="user.role === 'teacher'" class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-edit p-4 icon_tool iconEdit" v-on:click="editBadge(badge)"></i></div>
          <div v-if="user.role === 'admin'" class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-edit p-4 icon_tool iconEdit" v-on:click="editBadgeAdmin(badge)"></i></div>
          <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-trash-alt p-4 icon_tool iconDelete" v-on:click="openConfirmationModal(badge)"></i></div>
          <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center">
            <span v-if="students.length > 0" class="hint--top hint--success" data-hint="Assign Students">
              <i class="icon-group" style="color: black;font-size: 20px;" @click="openModalAssign(badge)"></i>     
            </span> 
          </div>
          <div class="collapse" :id="'BadgeObjectives'+index" >
            <div class="py-3 px-4">
              <ul>
                <li class="badges-objectives-list" v-for="(objective, index) in badge.objectives" :Key="index">{{objective.name}}</li>      
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
          <div class="loader-whitebg"></div>
      </div>
      <div class="row w-100 card-deck d-md-none m-0 justify-content-start divCardToolList">  
        <div class="col-sm-6 p-0 mt-5" v-for="(badge, index) in badges" :key="index">
          <div class="card">
            <div class="d-flex">
              <h5 class="card-title cardTitleTool col-12 pl-0 text-center">{{badge.name}}</h5>
            </div>
            <div class="row card-body d-flex justify-content-center align-items-center pt-0">
              <span class="col-12 cardSubtitle text-muted text-center">Date</span>
              <span class="col-12 cardContent text-center">{{formatDate(badge.createdAt)}}</span>
              <span class="col-12 cardSubtitle text-muted text-center pt-3">Objectives: </span>
              <div class="col-12 textTableAdminGeneral d-flex justify-content-center align-items-center flex-column">
                <i class="fas fa-chevron-up icon_tool iconCollapse collapsed d-none" data-toggle="collapse" :id="'ButtonObjectivesCollpaseUp'+index" :href="'#BadgeObjectivesCard'+index" role="button" aria-expanded="false" :aria-controls="'BadgeObjectivesCard'+index" v-on:click="displayButtonCollapse(index)"></i>
                <i class="fas fa-chevron-down icon_tool iconCollapse collapsed" data-toggle="collapse" :id="'ButtonObjectivesCollpaseDown'+index" :href="'#BadgeObjectivesCard'+index" role="button" aria-expanded="false" :aria-controls="'BadgeObjectivesCard'+index" v-on:click="displayButtonCollapse(index)"></i>    
              </div>
              <div class="collapse" :id="'BadgeObjectivesCard'+index" >
                <div class="py-3 px-4">
                  <ul>
                    <li class="cardContent" v-for="(objective, index) in badge.objectives" :Key="index">{{objective.name}}</li>      
                  </ul>
                </div>
              </div>
            
            </div>
            
            <div class="d-flex justify-content-around">
              <i v-if="user.role === 'teacher'" class="fas fa-edit p-3 icon_tool iconEdit col-2" v-on:click="editBadge(badge)"></i>
              <i v-if="user.role === 'admin'" class="fas fa-edit p-3 icon_tool iconEdit col-2" v-on:click="editBadgeAdmin(badge)"></i>
              <i class="fas fa-trash-alt p-3 icon_tool iconDelete col-2" v-on:click="openConfirmationModal(badge)"></i>
              <span v-if="students.length > 0" class="hint--top hint--success d-flex align-items-center" data-hint="Assign Students">
                <i class="icon-group" style="color: black;font-size: 20px;" @click="openModalAssign(badge)"></i>     
              </span>
            </div>
          </div>
        </div>   
        <div class="badges-list-border col-12 row-collapse-color2 text-center bg-light p-0" v-if="!loading && !badges.length">
          <h1 class="my-5">No Badges to display.</h1>
        </div> 
      </div>
    </div>
  </div>
  <ModalAssignBadge ref="assignStudentsModal" />
</template>
<script>
import API from "../services/api";
import ModalAssignBadge from './ModalAssignBadge.vue';
import confirmationModal from './confirmationDeleteModal.vue';
import moment from 'moment';
import { mapActions, mapState, mapMutations } from 'vuex'
import badges from '../services/api/badges';
export default {
  // CHECK THIS LINE TOMORROW 
  inheritAttrs:false,
  // CHECK THIS LINE TOMORROW 
  name: "badgesList",
  data(){
    return{
      name:'',
      loading: false,
      Allobjectives:[],
      students: [],
      teacher: "",
      searchQuery: "",
      badgesOriginal: [],
    }
  },
  components:{
		confirmationModal,
    ModalAssignBadge
  },
  props:['objectives'],
  computed:{
    ...mapState('user',['user']),
    ...mapState('badges',['badges'])
  },
  methods:{
    ...mapActions('badges', ['deleteBadge', 'deleteDefaultBadge']),
    ...mapMutations('badges',['filterBadge']),
    getStudents(){
      API.teacher.GetStudents(this.user._id)
      .then(res => { 
          this.students = res.data
      })
      .catch(err => {
          console.log(err)
      })
    },
    openModalAssign(badge){
      $('#modal-assign-badge').modal('show')
      this.$refs.assignStudentsModal.teacher = this.user;
      this.$refs.assignStudentsModal.students = this.students;
      this.$refs.assignStudentsModal.badge = badge;
    },
    formatDate(date){
      return moment(date).format('MM/DD/YYYY')
    },
    editBadge(badge){
      this.$router.push({path: '/add-new-badge', query: {badge: badge._id}})
    },
    editBadgeAdmin(defaultBadgeId){
      this.$router.push({path: '/add-new-badge', query: {defaultBadge: defaultBadgeId._id}})
    },
    openConfirmationModal(badge = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? "+badge.name || ""
			this.$refs.confirmModal.assetId = badge._id || ""
			$('#confirmationModal').modal('show')
    },
    deleteBadgeApi(id){
      this.loading = true
      if(this.user.role === "teacher"){
        this.deleteBadge(id)
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The badge has been successfully deleted',
        })
        this.loading = false
      }else if (this.user.role === "admin"){
        this.deleteDefaultBadge(id)
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: 'The badge has been successfully deleted',
        })
        this.loading = false
      }
      this.loading = false
    },
    displayButtonCollapse(index){
      if($('#ButtonObjectivesCollpaseUp'+index).hasClass('collapsed')){
          $('#ButtonObjectivesCollpaseUp'+index).removeClass('d-none')
          $('#ButtonObjectivesCollpaseDown'+index).addClass('d-none')
        }else{
          $('#ButtonObjectivesCollpaseUp'+index).addClass('d-none')
          $('#ButtonObjectivesCollpaseDown'+index).removeClass('d-none')
      }
    },
    displayButtonListCollapse(index){
      if($('#ButtonObjectivesListCollpaseUp'+index).hasClass('collapsed')){
          $('#ButtonObjectivesListCollpaseUp'+index).removeClass('d-none')
          $('#ButtonObjectivesListCollpaseDown'+index).addClass('d-none')
        }else{
          $('#ButtonObjectivesListCollpaseUp'+index).addClass('d-none')
          $('#ButtonObjectivesListCollpaseDown'+index).removeClass('d-none')
      }
    },
    searchBadges(){
      this.filterBadge({searchQuery:this.searchQuery, badgesOriginal: this.badgesOriginal})
    },
    clearFilter(){
      this.searchQuery = ''
      this.badgesOriginal = this.badges
    }
  },
  mounted(){
    window.addEventListener('getStudentBadges', (e) => {
      this.getStudents()
    }) 
  },
  created(){
    this.getStudents()
    setTimeout(() => {
      this.badgesOriginal = this.badges
    }, 1000)
  }
}
</script>