<template>
  <path
    id="Path_85"
    data-name="Path 85"
    d="M0,26.962V4.591a1.527,1.527,0,0,0,.076-.226A5.233,5.233,0,0,1,5.216.006q15.76-.012,31.52,0a5.24,5.24,0,0,1,5.027,3.841c.069.247.126.5.189.744V26.88c-.02.034-.054.067-.058.1a5.353,5.353,0,0,1-5.526,4.5c-6.712-.059-13.426-.018-20.139-.018-3.643,0-7.286.007-10.929,0A5.219,5.219,0,0,1,.216,27.652c-.068-.232-.144-.46-.216-.691m16.684-8.63a2.426,2.426,0,0,1,.265-.415q3.89-3.9,7.791-7.793a4.5,4.5,0,0,1,.716-.607,3.041,3.041,0,0,1,3.916.527q4.352,4.338,8.692,8.688c.1.1.211.2.385.358v-.479c0-4.356-.025-8.712.016-13.068A1.907,1.907,0,0,0,36.427,3.49q-15.443.039-30.886,0A1.91,1.91,0,0,0,3.487,5.526c.035,5.517.015,11.034.016,16.55,0,.143.013.285.023.491.158-.149.267-.245.37-.348l5.939-5.938a3.092,3.092,0,0,1,4.812.01c.645.645,1.289,1.291,2.037,2.04"
    transform="translate(0 0)"
    fill="#FFFFFF"
  />
  <path
    id="Path_86"
    data-name="Path 86"
    d="M70.995,67.554a3.49,3.49,0,1,1-3.463-3.507,3.468,3.468,0,0,1,3.463,3.507"
    transform="translate(-58.77 -58.8)"
    fill="#FFFFFF"
  />
</template>

<script>
export default {};
</script>
