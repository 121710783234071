<template>
  <div
    class="modal fade"
    id="modalConfig"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="objectiveModalLabel">Config</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="d-flex justify-content-center w-100">
            <label class="label-check col-md-3" :for="`input_name`"
              >Config name:
            </label>
            <input
              class="form-check col-md-7"
              type="text"
              :id="`input_name`"
              v-model="config.name"
            />
          </div>
          <div class="d-flex justify-content-center w-100">
            <label class="label-check col-md-3" :for="`input_users`"
              >Users list:
            </label>

            <input
              class="form-check col-md-7"
              type="text"
              :id="`input_users`"
              v-model="config.users"
              :disabled="config.default"
            />
          </div>
          <div class="d-flex justify-content-center w-100">
            <label class="label-check col-md-3">Features: </label>
            <div class="div_modal_form modal-body col-md-7">
              <form
                class="d-flex flex-column align-items-center w-100"
                @submit.prevent="submit"
              >
                <div class="d-flex justify-content-between w-100">
                  <label
                    class="label-check col-md-6"
                    :for="`input_checkbox_${key}`"
                    >Select all</label
                  >
                  <input
                    class="form-check"
                    type="checkbox"
                    @click="checkAll"
                    :checked="allFeaturesChecked"
                  />
                </div>
                <div
                  v-for="(feature, key) in config.features"
                  class="d-flex justify-content-between w-100"
                  :key="key"
                >
                  <label
                    class="label-check col-md-6"
                    :for="`input_checkbox_${key}`"
                    >{{ names[key] }}</label
                  >
                  <input
                    class="form-check"
                    type="checkbox"
                    :id="`input_checkbox_${key}`"
                    v-model="config.features[key]"
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            :class="
              [
                'default d-flex justify-content-center mb-4',
                config.default ? '' : 'gray-background',
              ].join(' ')
            "
          >
            <label class="label-check" :for="`input_checkbox_default`">
              Set as default:
            </label>

            <input
              class="form-check"
              type="checkbox"
              :id="`input_checkbox_default`"
              v-model="config.default"
            />
          </div>
          <p v-if="errorMessage" class="text-danger mb-3">{{ errorMessage }}</p>
          <div v-if="!errorMessage" class="free-space mb-3"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-lg btnModalSecondary"
            data-bs-dismiss="modal"
            @click="errorMessage = ''"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary btn-lg btnModalSuccess"
            @click="sendConfig()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "modalConfig",
  props: ["id", "config"],
  data() {
    return {
      names: {
        badges: "Badges",
        multimedia: "Multimedia",
        reorderSteps: "Reorder steps",
        tools: "Tools",
        objectives: "Objectives",
        generatePdf: "Generate PDF",
        signUpStudent: "Optionals in sign-up (student)",
        preRequisites: "Pre requisites",
        plans: "Plans",
        projectImg: "Project images",
        supportingMaterial: "Supporting material",
        projectTheme: "Project theme",
        lockedProjects: "Locked projects",
        studentUploadImages: "Upload images (student)",
      },
      errorMessage: "",
    };
  },
  computed: {
    allFeaturesChecked() {
      if (this.config.features) {
        const features = Object.values(this.config.features);
        const containFalse = features.includes(false);
        if (!containFalse) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions("configs", ["updateConfig", "postConfig"]),
    async updateCfg(id, data) {
      await this.updateConfig({
        id: id,
        data: data,
      });
    },

    async postCfg(data) {
      await this.postConfig({
        data: data,
      });
    },
    closeModal() {
      this.id = "";
      $("#modalConfig").modal("hide");
    },

    async sendConfig() {
      let emails = this.config.users;
      if (Array.isArray(this.config.users)) {
        emails = this.config.users.join(",");
      }
      const configuration = {
        features: this.config.features,
        users: emails ? emails.split(",") : [],
        default: this.config.default,
        name: this.config.name,
      };

      if (!configuration.name) {
        this.errorMessage = "Please add a name";
        return;
      }
      if (!emails && !configuration.default) {
        this.errorMessage = "Please add users";
        return;
      }

      if (this.id) {
        this.updateCfg(this.id, configuration);
      } else {
        this.postCfg(configuration);
      }
      this.errorMessage = "";
      this.closeModal();
    },
    checkAll() {
      const features = Object.keys(this.config.features);
      if (this.allFeaturesChecked) {
        features.forEach((feature) => {
          this.config.features[feature] = false;
        });
      } else {
        features.forEach((feature) => {
          this.config.features[feature] = true;
        });
      }
    },
  },
};
</script>

<style scoped>
input {
  max-width: 250px;
}
.label-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  font-family: "Quicksand", sans-serif;
  margin: 0;
  padding: 0;
}
.form-check {
  display: flex;
  text-align: start;
}

.div_modal_form {
  max-width: 50%;
  margin: 10px;
}

.default {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1.5px solid black;
  border-radius: 100px;
  width: 160px;
  background: var(--secondary);
  color: white;
}

.gray-background {
  background-color: gray;
}

.text-danger {
  font-size: 14px;
}

.free-space {
  height: 21px;
}
</style>
