<template>
  <div
    v-for="(student, index) in students"
    :key="index"
    :class="`d-flex background-white p-0 ${
      index != students.length - 1 ? 'bottom-border' : 'border-rounded'
    }`"
  >
    <div
      class="col-7 d-flex flex-column justify-content-center align-items-start right-border"
    >
      <p class="text-blue f-bold">
        {{ student.name }}
      </p>
      <h3 class="text-blue f-medium">
        {{ student.username }}
      </h3>
    </div>

    <div
      class="col-2 d-flex justify-content-center align-items-center right-border"
    >
      <p class="text-blue f-bold">
        {{ `${student.grade}th` }}
      </p>
    </div>

    <div class="col-3 px-1 d-flex justify-content-between align-items-center">
      <div class="w-100 d-flex justify-content-around">
        <span class="hint--top" :data-hint="'Student projects'">
          <BaseIcon
            width="24.156"
            height="25.311"
            @click="$emit('openModalProjects', student)"
            iconColor="currentColor"
          >
            <SettingLamp />
          </BaseIcon>
        </span>
        <span class="hint--top" :data-hint="'Change password'">
          <BaseIcon
            width="32.503"
            height="39.72"
            @click="$emit('openModalResetPassword', student)"
          >
            <LockIcon />
          </BaseIcon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ProgressBar from "./progressBar.vue";
import BaseIcon from "./baseIcon.vue";
import LockIcon from "../assets/icons/lock.vue";
import SettingLamp from "../assets/icons/settingLamp.vue";

export default {
  props: ["students"],
  methods: {
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    seeStudentProject(projectId, studentId) {
      return this.$router.push({
        path: "/teacher/see-student-project",
        query: { project: projectId, student: studentId },
      });
    },
  },
  components: {
    ProgressBar,
    BaseIcon,
    LockIcon,
    SettingLamp,
  },
  emits: ["openModalProjects", "openModalResetPassword"],
};
</script>

<style scoped>
p {
  font-size: 12px;
  margin-bottom: 0px;
}

h3 {
  font-size: 11px;
}

svg {
  width: 25px;
  height: 25px;
  color: var(--capable-blue);
}

.background-white {
  background-color: var(--capable-white);
}

.hint--top {
  cursor: pointer;
}
.project-link:hover {
  cursor: pointer;
}
.bottom-border {
  border-bottom: 0.5px solid var(--capable-grey);
}
.right-border {
  border-right: 0.5px solid var(--capable-grey);
}

.border-rounded {
  border-radius: 0px 0px 11px 11px;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6 {
  min-height: 53px;
}
</style>
