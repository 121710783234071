const configs = axios => {
    return {
        async getConfig() {
            return axios.get(`/api/configs/mine`)
        },
        async getDefault() {
            return axios.get(`/api/configs/default`)
        },
        async getAllConfigs() {
            return axios.get(`/api/configs`)
        },
        async deleteConfig(id) {
            return axios.delete(`/api/configs/${id}`)
        },
        async updateConfig(id, data) {
            return axios.put(`/api/configs/${id}`, data)
        },
        async postConfig(data) {
            return axios.post(`/api/configs`, data)
        }
    }
}
export default configs;