<template>
  <div class="container">
    <div class="form-title">Ideas Board</div>

    <div class="table-responsive-md">
      <table class="table table-container">
        <thead>
          <tr class="ideas-table-header">
            <td>Teacher</td>
            <td>Email</td>
            <td>Idea</td>
            <td>Status</td>
            <td class="text-center">Actions</td>
          </tr>
        </thead>
        <tbody v-for="(idea, index) in ideas" :key="index">
          <tr v-if="!idea.student" >
            <td> {{ idea && idea.teacher && idea.teacher.name }} </td>
            <td> {{ idea && idea.teacher && idea.teacher.email }} </td>
            <td> {{ idea && idea.title }} </td>
            <td> {{ idea && idea.status }} </td> 
            <td class="text-center">
              <a @click="openModalViewIdea(idea)" href="#viewIdea" data-toggle="modal" class="p-1 mt-0 mb-0" style="cursor: pointer">
                <span class="hint--top hint--info" data-hint="See Idea">
                  <i class="fas fa-eye icon-eye-table"></i>
                </span>
              </a>
              <button @click="approvedIdea(idea._id, index)" class="p-1 mt-0 mb-0  btn-admin-disabled" style="cursor: pointer" :disabled="idea.status === 'completed'">
                <span class="hint--top hint--success" data-hint="Approve idea">
                  <i :class="idea.status === 'completed' ? 'fa fa-check icon-check-table-sadmin-grey' : 'fa fa-check icon-check-table-sadmin'"></i>
                </span>
              </button>
              <a @click="completedIdea(idea._id, index)" class="p-1 mt-0 mb-0" style="cursor: pointer">
                <span class="hint--top hint--success" data-hint="Completed Idea">
                  <i class="fa fa-flag-checkered icon-completed-table-sadmin"></i>
                </span>
              </a>
              <button @click="rejectedIdea(idea._id, index)" class="p-1 mt-0 mb-0 btn-admin-disabled" style="cursor: pointer" :disabled="idea.status === 'completed'">
                <span class="hint--top hint--error" data-hint="Reject Idea">
                  <i :class="idea.status === 'completed' ? 'fa fa-ban icon-reject-table-sadmin-grey' : 'fa fa-ban icon-reject-table-sadmin'"></i>
                </span>
              </button>
              <a @click="deleteIdea(idea._id, index)" class="p-1 mt-0 mb-0" style="cursor: pointer" >
                <span class="hint--top hint--error" data-hint="Delete Idea">
                  <i class="fas fa-trash-alt" style="font-size: 18px;color: #000000b8;"></i>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!ideas.length">
      <div style="text-align:center;font-style: italic;" class="table-td-empty">None Ideas</div>
    </div>
  </div>
  <ModalViewIdea :ideaSelected="ideaSelected" />

</template>


<script>
import ModalViewIdea from "../components/modalViewIdea.vue";
import axios from 'axios';
export default {
  name: "AdminIdeasBoard",
  data(){
    return {
      ideas: [],
      ideaSelected: {},
    }
  },
  components:{
    ModalViewIdea,
  },
  methods: { 
      openModalViewIdea(idea){
        this.ideaSelected = idea
        $('#viewIdea').modal('show')
      },
      deleteIdea(ideaId, index) {
        const url = `/api/ideas-board/sadmin/delete/${ideaId}`
        return axios({ method: 'delete', url })
        .then(data => {
          this.ideas[index].status = "deleted"
        })
        .catch(e => {
          console.log(e)
        })
      },
      completedIdea(ideaId, index) {
        const url = `/api/ideas-board/sadmin/completed/${ideaId}`
        return axios({ method: 'put', url })
        .then(data => {
          this.ideas[index].status = "completed"
        })
        .catch(e => {
          console.log(e)
        })
      },
      approvedIdea(ideaId, index) {
        axios.put(`/api/ideas-board/sadmin/approved/${ideaId}`)
        .then(data => {
          this.ideas[index].status = "approved"
        })
        .catch(e => {
          console.log(e)
        })
      },
      rejectedIdea(ideaId, index) {
        axios.put(`/api/ideas-board/sadmin/rejected/${ideaId}`)
        .then(data => {
          this.ideas[index].status = "rejected"
        })
        .catch(e => {
          console.log(e)
        })
      }
    },
    created(){
      const url = '/api/ideas-board/sadmin/'
      return axios({ method: 'get', url })
        .then(response => response.data)
        .then(data => {
          this.ideas = data
        })
        .catch(e => {
          console.log(e)
        })
    }
}
</script>