<template>
  <div>
    <teacherList />
  </div>
</template>
<script>
import teacherList from '../components/teachersList'
export default {
  data(){
    return{

    }
  },
  components:{
    teacherList
  },



}
</script>