const studentBadges = axios => {
	return {
        async getMyBadges(studentId){
            return axios.get(`/api/student-badges/${studentId}`)
        },
        
        async assingBadge(data){
            return axios.post("/api/student-badges/assing-badge", data)
        },
	}
}

export default studentBadges;