<template>
  <div
    @click.stop
    class="d-flex justify-content-between align-items-center w-100 header-card"
  >
  <div
      v-if="user.role === 'admin' || user.role === 'teacher'"
      class="d-flex justify-content-between align-items-center w-100"
    >

      <div class="onoffswitch">
        <input
          type="checkbox"
          name="onoffswitch"
          class="onoffswitch-checkbox"
          :id="'visibility' + index"
          tabindex="0"
          v-model="project.hide"
          @change="changeVisibility(project)"
        />
        <label class="onoffswitch-label mb-0" :for="'visibility' + index">
          <span class="onoffswitch-inner" v-if="!isDraft"></span>
          <span class="onoffswitch-switch" v-if="!isDraft"></span>
        </label>
      </div>
      <div class="d-flex align-items-end">
        <div v-if="config.generatePdf && !isDraft" class="ml-2">
          <span class="hint--top" data-hint="Generate PDF">
            <BaseIcon
              width="45.204"
              height="37.714"
              @click="emitOpenPDF(project.pdfUrl)"
            >
              <DownloadIcon />
            </BaseIcon>
          </span>
        </div>
        <div class="ml-2" v-if="!isDraft">
          <span class="hint--top" data-hint="Make a Copy">
            <BaseIcon
              width="35.254"
              height="38.461"
              @click="copyProject(project)"
              :iconColor="`#004aad`"
            >
              <CopyIcon />
            </BaseIcon>
          </span>
        </div>
        <div class="ml-2">
          <span class="hint--top" data-hint="Edit Project">
            <BaseIcon
              width="38.107"
              height="38.107"
              @click="editProject(project)"
            >
              <EditIcon />
            </BaseIcon>
          </span>
        </div>
        <div class="ml-2">
          <span class="hint--top" data-hint="Delete Project">
            <BaseIcon
              width="33.843"
              height="41.652"
              @click="emitOpenDeleteModal(project)"
            >
              <TrashIcon />
            </BaseIcon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../baseIcon.vue";
import CrossIcon from "../../assets/icons/cross.vue";
import CopyIcon from "../../assets/icons/copy.vue";
import DownloadIcon from "../../assets/icons/download.vue";
import EditIcon from "../../assets/icons/edit.vue";
import TrashIcon from "../../assets/icons/trash.vue";

import API from "../../services/api";
export default {
  props: ["text", "user", "project", "config", "isDraft", "index"],
  components: {
    BaseIcon,
    CrossIcon,
    CopyIcon,
    DownloadIcon,
    EditIcon,
    TrashIcon,
  },
  methods: {
    editProject(project) {
      this.$router.push({
        path: "/create-projects",
        query: { project: project._id },
      });
    },
    async changeVisibility(project) {
      API.projects
        .updateVisibility(project._id, project.hide)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    async copyProject(project) {
      await API.projects
        .copyProject(project)
        .then((res) => {
          this.$toast.success("Copied project successfully", {
            position: "top",
          });
          this.$router.push({
            path: "/create-projects",
            query: { project: res.data._id },
          });
        })
        .catch((err) => console.log(err));
    },
    emitOpenPDF(pdfUrl) {
      this.$parent.$emit("open-pdf", pdfUrl);
    },
    emitOpenDeleteModal(pdfUrl) {
      this.$parent.$emit("delete-confirmation", pdfUrl);
    },
  },
};
</script>

<style scoped>
p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--capable-blue);
  margin-bottom: 8px;
}

svg {
  height: 25px;
}

@media (max-width: 768px) {
  svg {
    height: 20px;
    width: 30px;
  }

  .header-card{
    margin-top: 20px;
  }

}
</style>
