<template>
  <path id="Path_31" data-name="Path 31" d="M0,0H30.81V30.81H0Z" fill="none" />
  <path
    id="Path_32"
    data-name="Path 32"
    d="M3,21.405H26.108V18.838H3Zm0-6.419H26.108V12.419H3ZM3,6V8.568H26.108V6Z"
    transform="translate(0.851 1.703)"
  />
</template>

<script>
export default {};
</script>
