<template>
  <path
    id="Path_164"
    data-name="Path 164"
    d="M204.785,230.591c-.856,0-1.711.012-2.567-.007a.463.463,0,0,0-.511.343,5.3,5.3,0,1,1-.018-4.251.541.541,0,0,0,.609.38q2.587-.019,5.175,0a1.76,1.76,0,0,1,.569,3.425,2.718,2.718,0,0,1-.773.107c-.828.013-1.656,0-2.484,0"
    transform="translate(-170.356 -198.787)"
  />
  <path
    id="Path_165"
    data-name="Path 165"
    d="M4.429,115.182c.87,0,1.739-.012,2.608.007a.493.493,0,0,0,.544-.361,5.3,5.3,0,1,1,.053,4.326.668.668,0,0,0-.734-.461c-1.7.02-3.4.016-5.093,0a1.713,1.713,0,0,1-1.651-1.154,1.758,1.758,0,0,1,1.7-2.361c.856.005,1.711,0,2.567,0"
    transform="translate(-0.044 -99.281)"
  />
  <path
    id="Path_166"
    data-name="Path 166"
    d="M255.72,5.273a5.3,5.3,0,1,1,5.262,5.325,5.274,5.274,0,0,1-5.262-5.325"
    transform="translate(-227.429 0)"
  />
  <path
    id="Path_167"
    data-name="Path 167"
    d="M12.424,35.341q-5.257,0-10.513,0a1.765,1.765,0,0,1-.294-3.514A3.25,3.25,0,0,1,2.07,31.8q10.368,0,20.737,0A1.826,1.826,0,0,1,24.7,32.982a1.761,1.761,0,0,1-1.68,2.354c-1.545.015-3.09,0-4.636,0h-5.96"
    transform="translate(-0.056 -28.282)"
  />
  <path
    id="Path_168"
    data-name="Path 168"
    d="M8.84,255.465c2.346,0,4.693,0,7.039,0a1.757,1.757,0,0,1,1.7,1.2,1.669,1.669,0,0,1-.616,1.947,2.271,2.271,0,0,1-1.151.37c-4.651.025-9.3.021-13.954.013a1.771,1.771,0,1,1,.02-3.536q3.478-.005,6.956,0"
    transform="translate(0 -227.202)"
  />
  <path
    id="Path_169"
    data-name="Path 169"
    d="M200.621,143.689c2.346,0,4.693-.01,7.039,0a1.756,1.756,0,0,1,.187,3.5c-.11.008-.221.01-.331.01q-6.894,0-13.788,0a1.754,1.754,0,0,1-1.9-1.421,1.726,1.726,0,0,1,1.633-2.086c1.02-.03,2.042-.011,3.064-.011q2.05,0,4.1,0"
    transform="translate(-170.574 -127.787)"
  />
</template>

<script>
export default {};
</script>
