<template>
  <div
    class="modal fade"
    id="confirmationModalWMessages"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-blue">
        <div class="modal-header">
          <h5 class="modal-title">Change password</h5>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
          </button>
        </div>
        <div class="div_modal_form modal-body">
          <h3
            v-if="!errorMessage && !successMessage"
            class="messageDeleteModal"
          >
            Are you sure you want to change the {{ studentName }} password to
            <span class="password">capablemaker123</span> ?
          </h3>
          <h3 v-if="successMessage" class="d-flex justify-content-center align-items-center">
            {{ successMessage }}
          </h3>
          <h3 v-if="errorMessage" class="d-flex justify-content-center align-items-center text-danger">{{ errorMessage }}</h3>
        </div>
        <div class="modal-footer">
          <ActionButton
            type="button"
            color="grey"
            class="p-3"
            data-bs-dismiss="modal"
            :text="`${!errorMessage && !successMessage ? 'Cancel' : 'Close'}`"
          />
          <ActionButton
            v-if="!errorMessage && !successMessage"
            type="button"
            color="red"
            class="p-3"
            @click="confirm()"
            :text="`Confirm`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from './actionButton.vue';

export default {
  name: "confirmationModalWMessages",
  props: ["studentName", "id", "successMessage", "errorMessage"],
  components: { ActionButton },
  methods: {
    confirm() {
      this.$emit("confirm", this.id);
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 20px;
}

.modal-header {
  margin: 0px;
  padding: 15px;
}

.div_modal_form {
  height: 110px;
}

.text-success {
  margin: auto;
}

.password {
  color: var(--capable-red);
}

.modal-content {
  background-color: var(--capable-white);
}
</style>
