<template>
  <div>
    <div class="section-component" id="">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center mt-4">
          <div class="form-group">
            <h1 class="form-title text-blue f-semibold">Feedback Board</h1>
          </div>
          <div>
            <ActionButton
              :color="`blue`"
              class="px-3 py-3 mb-3"
              text="Submit idea"
              href="#createIdeaRoadmap"
              data-toggle="modal"
            >
              <template #leftIcon>
                <BaseIcon
                  width="13.616"
                  height="13.51"
                  class="mr-3"
                  :iconColor="toggleSettings ? '#004aad' : '#ffffff'"
                >
                  <CrossIcon />
                </BaseIcon>
              </template>
            </ActionButton>
          </div>
        </div>
        <div
          v-if="ideas && !ideas.length"
          class="roadmap-no-ideas-container label-input shadow text-blue"
        >
          <div>There are no ideas available.</div>
          <div>What are you waiting to be the first?</div>
        </div>
        <div v-else class="row card-idea-container">
          <div
            v-for="(idea, index) in ideas"
            :key="index"
            class="col-12 col-lg-3 col-md-6 p-0 d-flex flex-column"
          >
            <div
              v-if="idea.status === 'approved' || idea.status === 'completed'"
              class="m-2 card-idea"
            >
              <div class="card-header-idea">
                <div class="card-header-idea-title">
                  {{ idea.title.substr(0, 25) }}
                  {{ idea.title.length > 25 ? "..." : "" }}
                </div>
              </div>
              <div class="card-body-idea">
                {{ idea.description.substr(0, 200) }}
                {{ idea.description.length > 200 ? "..." : "" }}
              </div>
              <div class="card-idea-footer mt-auto">
                <div>
                  <div
                    v-if="idea.status === 'approved'"
                    class="card-header-idea-status-1"
                  >
                    {{ idea.status.toUpperCase() }}
                  </div>
                  <div
                    v-if="idea.status === 'completed'"
                    class="card-header-idea-status-3"
                  >
                    {{ idea.status.toUpperCase() }}
                  </div>
                </div>
                <div
                  v-if="idea.description.length > 200 || idea.title.length > 25"
                  class="container-featured-ideas mr-2"
                >
                  <a
                    @click="openModalViewIdea(idea)"
                    href="#viewIdea"
                    data-toggle="modal"
                    class="d-flex align-items-center"
                  >
                    <i class="fas fa-eye road-map-icon-comments"></i>
                  </a>
                </div>
                <div class="container-featured-ideas px-2">
                  <div class="label-input ideas-card-counter">
                    {{ idea && idea.featured && idea.featured.length }}
                  </div>
                  <button
                    @click="addFeaturedIdeas(idea, index)"
                    class="d-flex align-items-center pb-1 button-add-featured"
                    :disabled="user._id === idea.teacher"
                  >
                    <i
                      :class="
                        idea.featured.indexOf(user._id) > -1
                          ? 'fa fa-star ideas-icon-star-yellow'
                          : 'fa fa-star ideas-icon-star'
                      "
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-title text-blue f-semibold">Student ideas</div>

        <div
          v-if="!studentIdeas.length"
          class="roadmap-no-ideas-container label-input shadow text-blue"
        >
          <div>There are no ideas available.</div>
        </div>

        <div v-else class="table-responsive-md">
          <table class="table table-container">
            <thead>
              <tr class="ideas-table-header" style="font-size: 15px">
                <td>Student</td>
                <td>Username</td>
                <td>Idea</td>
                <td class="text-center">Actions</td>
              </tr>
            </thead>
            <tbody v-for="(studentIdea, index) in studentIdeas" :key="index">
              <tr
                v-if="studentIdea.student && studentIdea.teacher === user._id"
                style="font-size: 15px"
              >
                <td>
                  {{
                    studentIdea &&
                    studentIdea.student &&
                    studentIdea.student.name
                  }}
                </td>
                <td>
                  {{
                    studentIdea &&
                    studentIdea.student &&
                    studentIdea.student.username
                  }}
                </td>
                <td>{{ studentIdea && studentIdea.title }}</td>
                <td class="text-center">
                  <a
                    @click="openModalViewIdeaStudent(studentIdea)"
                    href="#viewIdea"
                    data-toggle="modal"
                    class="p-1 mt-0 mb-0"
                    style="cursor: pointer"
                  >
                    <span class="hint--top hint--info" data-hint="See Idea">
                      <i class="fas fa-eye icon-eye-table"></i>
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ModalCreateIdea />
    <ModalViewIdea :ideaSelected="ideaSelected" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ModalCreateIdea from "../components/modalCreateIdea.vue";
import ModalViewIdea from "../components/modalViewIdea.vue";
import ActionButton from "../components/actionButton.vue";
import BaseIcon from "../components/baseIcon.vue";
import PlusIcon from "../assets/icons/plus.vue";
import CrossIcon from "../assets/icons/cross.vue";
export default {
  name: "ideasBoard",
  data() {
    return {
      ideas: [],
      studentIdeas: [],
      ideaSelected: {},
      toggleIcon: false,
    };
  },
  components: {
    ModalCreateIdea,
    ModalViewIdea,
    ActionButton,
    BaseIcon,
    PlusIcon,
    CrossIcon,
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    openModalViewIdea(idea) {
      this.ideaSelected = idea;
      $("#viewIdea").modal("show");
    },
    addFeaturedIdeas(idea, index) {
      const url = `/api/ideas-board/featured/${idea._id}`;
      return axios({ method: "put", url })
        .then((response) => {
          this.ideas[index] = response.data;
        })
        .catch((err) => console.log(err));
    },
    openModalViewIdeaStudent(studentIdea) {
      this.ideaSelected = studentIdea;
      $("#viewIdea").modal("show");
    },
  },
  created() {
    const url = "/api/ideas-board";
    return axios({ method: "get", url })
      .then((response) => {
        this.ideas = response.data
          .sort((b) => {
            if (b.status === "approved") return -1;
          })
          .filter((idea) => {
            return !idea.student;
          });
        this.studentIdeas = response.data
          .sort((b) => {
            if (b.status === "pending") return -1;
          })
          .filter((studentIdea) => {
            return studentIdea.student && studentIdea.teacher === this.user._id;
          });
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
svg {
  transform: rotate(45deg);
}
</style>
