const badges = (axios) => {
  return {
    async CreateNewBadge(data) {
      return axios.post("/api/badges", data);
    },
    async getBadges() {
      return axios.get(`/api/badges`);
    },
    async getBadge(id) {
      return axios.get(`/api/badges/${id}`);
    },
    async deleteBadge(id) {
      return axios.delete(`/api/badges/${id}`);
    },
    async updateBadge(id, data) {
      return axios.put(`/api/badges/${id}`, data);
    },
  };
};
export default badges;
