<template>
  <div
    class="modal fade"
    id="modalStudentProject"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div
      :class="`modal-dialog ${
        isMobileDevice ? 'modal-fullscreen' : ''
      } modal-fullscreen d-flex justify-content-center align-items-center`"
    >
      <div :class="`modal-content ${isMobileDevice ? '' : 'w-50'}`">
        <div
          class="modal-header d-flex justify-content-between align-items-center my-0"
        >
          <h5 class="modal-title" id="objectiveModalLabel">
            Project as student
          </h5>
          <button
            type="button"
            class="btn-close font-16"
            aria-label="Close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div
          class="project-container d-flex flex-column justify-content-center align-items-center"
        >
          <div class="mt-3">
            <p
              class="text-danger text-center w-100 d-flex"
              style="padding-bottom: 10px"
            ></p>
          </div>
          <div v-if="errorMessages.length">
            <p class="text-danger text-center w-100"> Be aware that the next items are missing: </p>
            <ul>
              <li
                v-for="(message, index) in errorMessages"
                class="text-danger text-center w-50"
                style="padding-bottom: 5px"
                v-bind:key="index"
              >
                {{ message }}
              </li>
            </ul>
          </div>
          <h1>{{ project.name }}</h1>
          <div
            :class="
              project.supportingMaterial
                ? 'project-title-supporting-material'
                : 'project-title'
            "
            v-if="project.supportingMaterial"
          >
            <button
              v-if="project.supportingMaterial"
              class="btn btn-primary"
              @click="openSupportinMaterial(project.supportingMaterial)"
            >
              SUPPORTING MATERIAL
            </button>
          </div>
          <div class="step-description">
            <h2>
              <b>{{ stepToShow.name || project.steps[0].name }}</b>
            </h2>
            <h3>Step description:</h3>
            <h4
              v-html="
                stepToShow.description
                  ? stepToShow.description
                  : project.steps[0].description
              "
            ></h4>
            <h3>Step pictures:</h3>
            <div v-if="stepToShow?.imagesURL?.length > 0">
              <div
                v-for="(image, index) in stepToShow?.imagesURL"
                :key="index"
                class="d-flex flex-column justify-content-center m-5"
              >
                <h4 class="d-flex justify-content-center">
                  Image {{ index + 1 }}
                </h4>
                <div class="d-flex justify-content-center align-items-center">
                  <img class="projectIMG" :src="image" />
                </div>
              </div>
            </div>
            <div v-else-if="project.steps[0].imagesURL.length > 0">
              <div
                v-for="(image, index) in project.steps[0].imagesURL"
                :key="index"
                class="d-flex flex-column justify-content-center m-5"
              >
                <h4 class="d-flex justify-content-center">
                  Image {{ index + 1 }}
                </h4>
                <div class="d-flex justify-content-center align-items-center">
                  <img class="projectIMG" :src="image" />
                </div>
              </div>
            </div>

            <h3>Step gifs:</h3>
            <div v-if="stepToShow?.gifsURL?.length > 0">
              <div
                v-for="(gif, index) in stepToShow?.gifsURL"
                :key="index"
                class="d-flex flex-column justify-content-center m-5"
              >
                <h4 class="d-flex justify-content-center">GIF:</h4>
                <div class="d-flex justify-content-center">
                  <img class="projectIMG" :src="gif" />
                </div>
              </div>
            </div>
            <div v-else-if="project.steps[0].gifsURL?.length > 0">
              <div
                v-for="(gif, index) in project.steps[0].gifsURL"
                :key="index"
                class="d-flex flex-column justify-content-center m-5"
              >
                <h4 class="d-flex justify-content-center">GIF:</h4>
                <div class="d-flex justify-content-center">
                  <img class="projectIMG" :src="gif" />
                </div>
              </div>
            </div>

            <h3>Step videos:</h3>
            <div v-if="stepToShow?.videosURL?.length > 0">
              <div
                v-for="(gif, index) in stepToShow?.videosURL"
                :key="index"
                class="d-flex flex-column justify-content-center m-5"
              >
                <h4>Video {{ index + 1 }}:</h4>
                <video
                  class="text"
                  height="240"
                  controls
                  muted
                  playsinline
                  :src="stepToShow?.videosURL[index]"
                  type="video/webm"
                ></video>
              </div>
            </div>
            <div v-else-if="project.steps[0].videosURL?.length > 0">
              <div
                v-for="(video, index) in project.steps[0].videosURL"
                :key="index"
                class="d-flex flex-column justify-content-center m-5"
              >
                <h4>Video {{ index + 1 }}:</h4>
                <video
                  class="text"
                  height="240"
                  controls
                  muted
                  playsinline
                  :src="video"
                  type="video/webm"
                ></video>
              </div>
            </div>
          </div>
          <div class="progress-steps w-100">
            <span
              ><b>Total Progress: ({{ Math.round(progress) }} %)</b></span
            >
            <section>
              <small :style="'width:' + progress + '%'"></small>
            </section>
          </div>
          <div
            class="mt-5 student-steps-container d-flex justify-content-center"
          >
            <div
              v-for="(step, index) in project.steps"
              :key="index"
              style="margin: 15px"
              @click="stepToShow = step"
            >
              <div
                style="
                  text-align: center;
                  background-color: #004aad;
                  color: white;
                  border-radius: 12px 12px 0 0;
                  font-size: 25px;
                  border: 2px solid black;
                  border-bottom: 0;
                "
              >
                STEP {{ index + 1 }}
              </div>
              <div class="px-3 box-step-student" style="padding: 15px">
                <div class="box-step-image">
                  <div style="opacity: 80% !important"></div>
                  <div style="opacity: 80% !important"></div>
                  <div
                    v-if="!step?.imagesURL?.length"
                    style="
                      width: 200px;
                      background: #f1f1f1;
                      height: 200px;
                      border-radius: 8px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div class="image-step-student"></div>
                  </div>
                  <img v-else :src="step?.imagesURL[0]" />
                </div>
                <div class="student-steps-name">
                  {{ step?.name ? step.name : "" }}
                </div>
              </div>
              <div class="project-actions">
                <i
                  v-if="config.studentUploadImages"
                  class="upload-image-blocked icon-camera"
                ></i>
                <span
                  class="open-multiple-choice hint--right hint--info hint--large"
                  :data-hint="getCorrectAnswer(step)"
                >
                  <i
                    :class="{
                      'icon-question': true,
                      ' border-radius-left': !config.studentUploadImages,
                    }"
                    v-if="step?.hasMultipleChoice"
                  ></i>
                </span>
                <input
                  class="checkbox-step"
                  type="checkbox"
                  :id="index + 'id'"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <Button
            :text="'Close'"
            :fontsize="'font-20'"
            class="mr-5"
            :button_type="'close_button'"
            data-bs-target="#modalStudentProject"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './button.vue';
import { mapActions, mapState } from "vuex";

export default {
  name: "modalSeeStudentProject",
  props: ["config", "errorMessages"],
  computed: {
    ...mapState(["project"]),
    stepToShow() {
      return this.project && this.project.steps && this.project.steps[0]
    },
  },
  methods: {
    checkDeviceType() {
      const { userAgent } = navigator;
      let regexp = /android|iphone|kindle|ipad/i;

      this.isMobileDevice = regexp.test(userAgent);
    },
    getCorrectAnswer(step) {
      if(!step) {
        return;
      }
      let correctAnswer;
      for (let answer in step.multipleChoice ) {
        if(step.multipleChoice[answer].isCorrect) {
          correctAnswer = answer.answer
        }
      }
      return `Question: ${step.multipleChoice.question} || Answer ${correctAnswer}`
    }
  },
  async created() {
    this.checkDeviceType();
  },
  data() {
    return {
      isMobileDevice: null, // true => load inputs / false => rtcRecorder library (desktop),
      selectedImgURL: "",
      progress: 0,
      stepsWOutName: [],
    };
  },
  components: {
    Button,
  },
};
</script>

<style scoped>
video {
  width: 360px;
}
.projectIMG {
  max-width: 550px;
}

@media (max-width: 650px) {
  video {
    width: 240px;
  }
  .projectIMG {
    max-width: 150px;
  }
}
.multiple-choice-solved-step {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #cbcbcb;
  text-align: center;
  z-index: 2;
}

.question-mark {
  font-size: 30px;
  color: white;
}

.project-actions {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 2px solid black;
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #004aad;
}

.upload-image {
  font-size: 35px;
  background-color: #004aad;
  color: white;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 10px;
  padding: 10px;
  transition: 0.5s;
}

.upload-image:hover {
  background-color: white;
  color: #004aad;
  transition: 0.5s;
}

.open-multiple-choice {
  font-size: 35px;
  background-color: #004aad;
  color: white;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 0px;
  padding: 10px;
  transition: 0.5s;
}

.border-radius-left {
  border-radius: 0 0 0 10px;
}

.open-multiple-choice:hover {
  background-color: white;
  color: #004aad;
  transition: 0.5s;
}

.take-picture-option {
  display: inline-block;
  font-weight: 400 !important;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0.5px solid black;
  border-radius: 4px !important;
  background: var(--secondary);
  color: white;
}

.take-picture-option:hover {
  background: var(--secondary2) !important;
}

.cancel {
  display: inline-block;
  font-weight: 400 !important;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 0.5px solid gray;
  border-radius: 4px !important;
  background: white;
  color: gray;
}

.studentIMG {
  max-width: 450px;
}

.open-multiple-choice {
  padding: 0;
}

.upload-image-blocked {
  font-size: 35px;
  background-color: #004aad;
  color: grey;
  flex-grow: 3;
  text-align: center;
  border-radius: 0 0 0 10px;
  padding: 10px;
  transition: 0.5s;
  cursor: not-allowed;
}

.font-16 {
  font-size: 16px !important;
}

@media (max-width: 650px) {
  .studentIMG {
    max-width: 250px;
  }

  .modal-content {
    border-radius: 0px !important;
  }
}

.modal-dialog {
  max-width: none;
}

.modal {
  padding-right: 0px !important;
}

.modal-fullscreen {
  width: 100vw !important;
  max-width: none !important;
  height: auto !important;
  min-height: 100vh;
  margin: 0 !important;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.project-container {
  display: flex;
}
</style>
