<template>
  <button :class="`${color}-bg d-flex justify-content-center align-items-center action-button d-md-none`">
    <slot name="leftIcon" />
    <h2 :class="`text-${color} ${fontsize}`">
      <slot> {{ text }} </slot>
    </h2>
    <slot name="rightIcon" />
  </button>
</template>

<script>
export default {
  name: "ActionButton",
  props: ["text", "color", "fontsize"],
};
</script>

<style scoped>
h2 {
  margin: 0px;
  color: var(--capable-white);
  font-size: 20px;
  font-weight: 500;
}

button {
  border: none;
  border-radius: 8px;
}

button:hover {
  border-radius: 8px;
}

.text-14-px {
  font-size: 14px;
}

.text-16-px {
  font-size: 16px;
}

.text-18-px {
  font-size: 18px;
}

.text-20-px {
  font-size: 20px;
}

.w-170-px {
  width: 180px;
}

.h-60-px {
  height: 60px;
}

/* Backgrounds */

.blue-bg {
  background-color: var(--capable-blue);
  transition: 100ms ease-out;
}

.red-bg {
  background-color: var(--capable-red);
  transition: 100ms ease-out;
}

.grey-bg {
  background-color: var(--capable-grey);
  transition: 100ms ease-out;
}

.white-bg {
  background-color: var(--capable-white);
  transition: 100ms ease-out;
}

/* Hovers */
.blue-bg:hover,
.red-bg:hover,
.grey-bg:hover {
  background-color: var(--capable-white);
}

.blue-bg:hover {
  outline: 2px solid var(--capable-blue);
}

.blue-bg:hover>h2 {
  color: var(--capable-blue);
}

.red-bg:hover {
  outline: 2px solid var(--capable-red);
}

.red-bg:hover>h2,
.red-bg:hover>i {
  color: var(--capable-red);
}

.grey-bg:hover {
  outline: 2px solid var(--capable-grey);
}

.grey-bg:hover>h2 {
  color: var(--capable-grey);
}

.disabled {
  background-color: var(--capable-grey);
  outline: 0px;
  opacity: 0.8;
  cursor: default;
}

.disabled:hover {
  background-color: var(--capable-grey);
  outline: 0px;
  opacity: 0.8;
}

.disabled-green {
  background-color: var(--capable-green);
  outline: 0px;
  cursor: default;
}

.disabled-green:hover {
  background-color: var(--capable-green);
  outline: 0px;
}

.disabled:hover>h2,
.disabled-red:hover>h2,
.disabled-green:hover>h2 {
  color: var(--capable-white);
}

.orange-bg {
  background: rgb(240, 168, 0);
  outline: 0px;
  cursor: default;
}

.orange-bg:hover {
  background: rgb(240, 168, 0);
  outline: 0px;
}

@media (max-width:768px) {
  h2 {
    font-size: 18px;
  }
}

@media (max-width:520px) {
  h2 {
    font-size: 15px;
  }

  @media (max-width: 1400px) {

    .lg-text-14-px {
      font-size: 14px;
    }

    .lg-text-16-px {
      font-size: 16px;
    }

    .lg-text-18-px {
      font-size: 18px;
    }

    .lg-text-20-px {
      font-size: 20px;
    }
  }

}
</style>
