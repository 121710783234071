<template>
  <confirmationModal ref="confirmModal" @confirm="deleteObjectiveApi" />
  <div class="form-create-projects">
    <form class="w-100 py-5 px-4">
      <div class="form-row">              
        <h2 class="title-header-projects">{{ editing ? 'Edit a Badge': 'Add a New Badge'}}</h2>

        <div class="form-group col-md-12">
          <label for="nameEdit" class="labelForm">Badge name<span class="text-danger">*</span></label>
          <input type="text" id="nameEdit" class="form-control inputsForm" placeholder="name" v-model="name">
          <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
        </div>

        <div class="form-group col-md-12 mt-5" style="border: 1px solid #a2bcd1;padding: 30px 60px;border-radius: 10px;" id="objectiveEdit">
          <label for="nameEdit" class="labelForm">{{ edit ? 'Edit Objective': 'Add new objective'}}</label>
          <input type="text" class="form-control inputsForm mb-3" placeholder="Objective name" v-model="nameObjective"/>
          <p class="text-danger" v-if="errorMessageObj">{{ errorMessageObj }}</p>
          <div class="d-flex justify-content-end align-items-center" v-if="!edit">
            <button type="button" class="btn btn-primary btn-lg btnModalAddObjInBadge" v-on:click="createObjectiveInModal">Add Objective</button>
          </div>
          <div class="d-flex justify-content-end align-items-center" v-else>
            <button type="button" class="btn btn-primary btn-lg btnModalCloseEditObjInBadge mt-3" v-on:click="CancelEditObjectiveInModal">Close</button>
            <button type="button" class="btn btn-primary btn-lg btnModalAddObjInBadge mt-3 ml-3" v-on:click="createObjectiveInModal">Save</button>
          </div>
        </div>

        <div class="input-group col-md-4 mt-4 mb-2">
          <input type="text" id="objectiveName" class="form-control inputsForm" placeholder="Search by objective" v-model="objectiveName" @keyup="searchObjective(objectiveName)">
          <button class="btn btn-outline-secondary input-group-text" @click="clearFilter()" type="button" id="button-addon2">CLEAR</button>
        </div>

        <div class="form-group col-md-12">
          <label class="col-10 labelForm mt-3">Objectives<span class="text-danger">*</span></label>
          <label class="col-2 labelForm mt-3">Actions</label>
          <div class="form-check form-group" style="display: flex;" v-for="(objective, index) in this.objectivesOriginal" :key="index">
            <input class="form-check-input" type="checkbox" :id="index+'id'" :value="objective._id" v-model="objectivesSelected">
            <label class="col-10 form-check-label" :for="index+'id'" >{{objective.name}}</label>
            <a href="#objectiveEdit" class="col-1" style="display: flex;justify-content: center;align-items: center;" :id="index+'IconId'" v-on:click="editObjectiveInModal(objective, index)">
              <i class="fas fa-pen ml-2 iconEdit" style="color: black;"></i>
            </a>
            <div class="col-1 d-flex justify-content-center align-items-center"><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(objective)"></i></div>
          </div>
          <p class="text-danger" v-if="errorMessageObjList">{{ errorMessageObjList }}</p>
        </div>

        <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !objectives.length || this.objectivesOriginal.length == 0">
          <h1 class="m-5">No objectives to display.</h1>
        </div>
        <div class="form-group py-4 w-100 d-flex justify-content-center" v-if="loading">
          <div class="loader-whitebg"></div>
        </div>

      </div>
    </form>
  </div>
    <div style="position: sticky;bottom: 0;height: 120px;width: 100%;border-top: 1px solid #e7e7e7;display: flex;justify-content: center;align-items: center;background: #fff;">
      <div>
        <button  type="button" class="btn btn-primary btn-lg btnModalSuccess form-row" v-on:click="saveBadges()" >{{ editing ? 'Save Edit': 'Add Badge'}}</button>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import API from "../services/api";
import confirmationModal from '../components/confirmationDeleteModal.vue';
export default {
  name: "add-new-badge",
  data(){
    return{
      name:'',
      objectivesSelected:[],
      errorMessage:'',
      BadgeId: '',
      loading: false,
      nameObjective:"",
      errorMessageObj:"",
      edit: false,
      idObjectiveEdit: "",
      editing: false,
      objectiveName: "",
      objectivesOriginal: {},
      errorMessageObjList: ""
    }
  },
  computed:{
    ...mapState('user',['user']),
    ...mapState('objectives',['objectives']),
  },
  components:{
    confirmationModal
  },
  methods:{
    ...mapActions('badges',['CreateNewBadge','updateBadge','CreateNewDefaultBadge','updateDefaultBadge']),
    ...mapActions('objectives',['CreateNewObjective', 'CreateNewDefaultObjective', 'updateDefaultObjective', 'updateObjective', 'deleteDefaultObjective', 'deleteObjective']),
    editObjectiveInModal(objective, index){
      for(let i = 0; i < this.objectives.length ; i++){
        if(index != i){
          document.getElementById(i+'id').disabled = true;
        }else{
          document.getElementById(i+'id').disabled = false;
        }
      }
      this.idObjectiveEdit = objective._id
      this.errorMessageObj = ""
      this.edit = true
      this.nameObjective = objective.name
    },
    CancelEditObjectiveInModal(){
      for(let i = 0; i < this.objectives.length ; i++){
        document.getElementById(i+'id').disabled = false;
      }
      this.edit = false
      this.nameObjective = ""
      this.idObjectiveEdit = ""
    },
    createObjectiveInModal(){
      this.errorMessageObj = ""
      if (this.nameObjective === "") {
        this.errorMessageObj = "Please insert a valid objective name";
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Please insert a valid objective name',
        })
        return;
      }
      this.errorMessageObj = ""
      const data = {
        name: this.nameObjective,
      }
      if(this.idObjectiveEdit){
        if(this.user.role === "admin"){
          this.updateDefaultObjective({objectiveId:this.idObjectiveEdit, data, that:this})
          setTimeout(() => {
            this.objectivesOriginal = this.objectives
          }, 1000);
        }else if(this.user.role === "teacher"){
          this.updateObjective({objectiveId:this.idObjectiveEdit, data, that:this})
          setTimeout(() => {
            this.objectivesOriginal = this.objectives
          }, 1000);
        }
      }else{
        if(this.user.role === "admin"){
          this.CreateNewDefaultObjective({data, that:this})
          setTimeout(() => {
            this.objectivesOriginal = this.objectives
          }, 1000);
        }else if(this.user.role === "teacher"){
          this.CreateNewObjective({data, that:this})
          setTimeout(() => {
            this.objectivesOriginal = this.objectives
          }, 1000);
        }
      }
      this.CancelEditObjectiveInModal()
    },
    saveBadges(){
      this.errorMessage = "";
      this.errorMessageObjList = "";
      if(this.name === ""){
        this.errorMessage = "Please insert a valid name";
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Please insert a valid name',
        })
        return;
      }else if(this.objectivesSelected.length == 0){
        this.errorMessageObjList = "Please select an objective";
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select an objective',
        })
        return;
      }
      const data = {
        name: this.name,
        objectives: this.objectivesSelected,
      };
      if(this.BadgeId){
        if(this.user.role === "admin"){
          this.updateDefaultBadge({BadgeId:this.BadgeId, data})
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The badge has been successfully updated',
          })
        }else if(this.user.role === "teacher"){
          this.updateBadge({BadgeId:this.BadgeId, data})
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The badge has been successfully updated',
          })
        }
      }else{
        if(this.user.role === "admin"){
          this.CreateNewDefaultBadge(data) 
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The badge has been successfully created',
          })
        }else if(this.user.role === "teacher"){
          this.CreateNewBadge(data)
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The badge has been successfully created',
          })
        }
      }
      if(this.user.role === "teacher"){
        this.$router.push({ path: '/badges'})
      } else {
        this.$router.push({ path: '/defaultbadges'})
      }  
      this.CancelEditObjectiveInModal()
      this.name = '',
      this.objectivesSelected = []
      this.BadgeId = ''
      this.errorMessage = "";
      this.errorMessageObj = "";
      this.errorMessageObjList = "";
    },
    openConfirmationModal(objective = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? "+objective.name || ""
      this.$refs.confirmModal.assetId = objective._id || ""
      $('#confirmationModal').modal('show')
    },
    deleteObjectiveApi(id){
      this.loading = true
      if(this.user.role === "admin"){
        this.deleteDefaultObjective({id, that:this})
        this.loading = false
        setTimeout(() => {
          this.objectivesOriginal = this.objectives
        }, 1000);
      }else if(this.user.role === "teacher"){
        this.deleteObjective({id, that:this})
        this.loading = false
        setTimeout(() => {
          this.objectivesOriginal = this.objectives
        }, 1000);
      }
    },
    searchObjective(){
      if (this.objectiveName){
        let objectivesFiltered = this.objectives.filter(objective => {
          return objective.name.toLowerCase().indexOf(this.objectiveName.toLowerCase()) > -1
        })
        this.objectivesOriginal = objectivesFiltered
      } else {
        this.objectivesOriginal = this.objectives
      }
    },
    clearFilter(){
      this.objectiveName = ''
      this.objectivesOriginal = this.objectives
    }
  },
  mounted() {
    if (this.$route.query.badge) {
      this.BadgeId = this.$route.query.badge
      this.editing = true
      API.badges.getBadge(this.BadgeId)
      .then(res => {
        this.name = res.data.name || ""
        this.objectivesSelected = res.data.objectives.map(objective => objective._id) || [] 
        this.BadgeId = res.data._id || ""
      }) 
    } else if (this.$route.query.defaultBadge){
      let defaultBadgeId = this.$route.query.defaultBadge
      this.editing = true
      API.defaultBadges.getDefaultBadge(defaultBadgeId)
      .then(res => {
        this.name = res.data.name || ""
        this.objectivesSelected = res.data.objectives.map(objective => objective._id) || [] 
        this.BadgeId = res.data._id || ""
      })
    }
  },
  created(){
    this.objectivesOriginal = this.objectives
  }
}
</script>
