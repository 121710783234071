<template>
  <div
    class="modal fade"
    id="modalPdf"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog d-flex justify-content-center align-items-center">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="objectiveModalLabel">PDF</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <iframe id="pdfFrame" :src="pdfUrl"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalPdf",
  props: ["pdfUrl"],
};
</script>

<style scoped>

.modal-dialog{
  max-width: 1000px !important;
  height: 1000px !important;
}

.modal-content{
  height: 600px !important;
}

iframe{
  width: 100%;
  height: 100%;
}

</style>
