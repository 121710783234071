<template>
  <path
    id="Path_31"
    data-name="Path 31"
    d="M8.212,4.9,11.182,2c.432-.421.858-.849,1.3-1.262A2.343,2.343,0,0,1,16.44,2.076a2.3,2.3,0,0,1-.751,2.081Q14.106,5.695,12.532,7.24,11.29,8.453,10.049,9.666a2.357,2.357,0,0,1-3.554,0Q3.625,6.876.759,4.08A2.345,2.345,0,1,1,4.042.733Q5.8,2.441,7.556,4.16c.229.225.428.482.656.741"
    transform="translate(10.475) rotate(90)"
  />
</template>

<script>
export default {};
</script>
