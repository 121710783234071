<template>
  <div class="row justify-content-center">
    <div class="col-12 text-center step-description mx-0">
      Add project name, description and select a cover image.
    </div>
    <div class="col-12 d-flex flex-column gap-3">
      <div class="row px-0 justify-content-center">
        <div class="col-10 col-md-6 pl-0 pr-2 no-padding-mobile">
          <InputComponent
            :modelValue="project.name"
            @update:modelValue="
              (e) => setProjectProp({ prop: 'name', value: e })
            "
            :label="'Project name'"
            :border="true"
            :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px']"
            :label-class="'font-16px lg-font-14px'"
            :required="true"
          />
        </div>
        <div class="col-10 col-md-6 pl-2 pr-0 no-padding-mobile">
          <InputComponent
            :modelValue="project.description"
            @update:modelValue="
              (e) => setProjectProp({ prop: 'description', value: e })
            "
            :label="'Project description'"
            :border="true"
            :paddingX="'4'"
            :paddingY="'4'"
            :class="['px-4', 'py-4', 'w-100', 'font-16px', 'lg-font-14px']"
            :label-class="'font-16px lg-font-14px'"
            fontSize="16px"
          />
        </div>
      </div>
    </div>
    <div class="col-10 col-md-12 py-2 mt-2" v-if="config.projectImg">
      <div class="row justify-content-center px-0">
        <FileUploader
            label="Profile image"
            @change="processFile($event)"
            :border="true"
            :paddingY="'4'"
            type="file"
            placeholder="No file choosen"
            :class="['w-100', 'px-0', 'font-16px', 'lg-font-14px']"
            :label-class="'font-16px lg-font-14px'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from "../../../components/FileUploader.vue";
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
export default {
  name: "StepName",
  components: {
    InputComponent,
    FileUploader,
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("configs", ["config"]),
  },
  methods: {
    ...mapActions("project", ["setProjectProp", "handleFileUpload"]),
    processFile(e) {
      this.$emit("fileUpload", {
        e,
        type: "projectImage",
        prop: "projectImage",
      });
    },
  },
};
</script>

<style scoped>
.step-description {
  color: #004aad;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 768px) {
  .no-padding-mobile {
    padding: 0px !important;
  }
}
</style>
