<template>
  <div class="row d-flex gap-2 mb-5">
    <div class="col-12 text-center step-description mx-0">
      Are there any links or pdfs that may support project completion or
      extensions?
    </div>
    <div class="mb-3 form-group col-md-12" v-if="config.plans">
      <label for="formFile" class="form-label">Plans (maximum 3 pdf)</label>
      <FileUploader
        @change="processFile($event)"
        :border="true"
        :paddingY="'4'"
        type="file"
        accept=".pdf"
        multiple="true"
        placeholder="No file choosen"
        :class="['w-100']"
      />
    </div>
    <div class="form-group col-md-12" v-if="config.supportingMaterial">
      <InputComponent
        :label="`Supporting Material`"
        placeholder="Please provide a link for your supporting material (Google Docs for example...)"
        v-model="project.supportingMaterial"
        :border="true"
        :class="['p-4', 'w-100']"
      />
    </div>
  </div>
</template>

<script>
import FileUploader from "../../../components/FileUploader.vue";
import { mapActions, mapState } from "vuex";
import InputComponent from "../../../components/input.vue";
export default {
  name: "StepName",
  components: {
    InputComponent,
    FileUploader,
  },
  computed: {
    ...mapState(["project"]),
    ...mapState("configs", ["config"]),
  },
  methods: {
    ...mapActions("project", ["setProjectProp"]),
    processFile(e) {
      this.$emit("fileUpload", { e, type: "pdf", prop: "plans" });
    },
  },
};
</script>

<style scoped>
.step-description {
  color: #004aad;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}
</style>
