<template>
  <path
    id="Path_56"
    data-name="Path 56"
    d="M20.095,0c.257.079.517.148.77.239a3.871,3.871,0,0,1,2.563,3.6c0,.433,0,.866,0,1.364h.441q3.314,0,6.628,0a3.228,3.228,0,0,1,3.345,3.361c0,.813,0,1.627,0,2.44A1.275,1.275,0,0,1,32.5,12.363q-15.573,0-31.146,0A1.279,1.279,0,0,1,0,11.009c0-.827,0-1.654,0-2.48a3.227,3.227,0,0,1,3.3-3.32q3.314-.005,6.628,0h.481c0-.423,0-.812,0-1.2A3.877,3.877,0,0,1,13.562.073,1.315,1.315,0,0,0,13.749,0Zm.732,5.193c0-.462.015-.881,0-1.3a1.3,1.3,0,0,0-1.307-1.286q-2.6-.023-5.2,0a1.23,1.23,0,0,0-1.255,1.058,11.411,11.411,0,0,0-.014,1.527Z"
    transform="translate(-0.001)"
    fill="#004aad"
  />
  <path
    id="Path_57"
    data-name="Path 57"
    d="M33.781,137.744H47.931c.628,0,.724.116.693.74-.133,2.679-.258,5.359-.388,8.039q-.229,4.71-.46,9.419c-.071,1.475-.139,2.951-.193,4.427a4.026,4.026,0,0,1-1.721,3.373,3.665,3.665,0,0,1-2.132.681q-9.963,0-19.925,0a3.886,3.886,0,0,1-3.845-3.883c-.165-3.112-.286-6.226-.434-9.339s-.305-6.2-.458-9.3q-.088-1.786-.171-3.573c-.02-.45.124-.585.61-.585H33.781m-5.221,11.713q0-2.115,0-4.23c0-1.383.009-.158,0-1.541A1.3,1.3,0,0,0,26.78,142.4a1.367,1.367,0,0,0-.826,1.407c0,5.5,0,8.4,0,13.906a2.822,2.822,0,0,0,.006.285,1.3,1.3,0,0,0,1.808,1.1,1.333,1.333,0,0,0,.794-1.346q0-4.149,0-8.3m3.9-.04v4.027c0,1.464-.008,2.929,0,4.393a1.292,1.292,0,0,0,1.812,1.263,1.36,1.36,0,0,0,.793-1.387c0-5.5,0-8.4,0-13.906,0-.081,0-.163,0-.244a1.3,1.3,0,0,0-2.6,0c-.009.121,0,.244,0,.366,0,2.7,0,2.788,0,5.486m6.508,0v3.946c0,1.491-.008,2.983,0,4.474a1.292,1.292,0,0,0,1.811,1.264,1.36,1.36,0,0,0,.794-1.387c0-5.5,0-8.4,0-13.906,0-.081,0-.163,0-.244a1.3,1.3,0,0,0-2.6,0c-.009.121,0,.244,0,.366,0,2.7,0,2.788,0,5.486"
    transform="translate(-16.843 -122.773)"
    fill="#004aad"
  />
</template>

<script>
export default {};
</script>
