<template>
  <div class="modal fade" id="createAccountModal" tabindex="-1" aria-labelledby="createAccountModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createAccountModalLabel"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" v-on:click="closeModal"></button>
            </div>
            <div class="div_modal_form modal-body" style="height: 80px;">
              <h5 style="display: flex; align-items: center; font-size: 20px; text-align: center;">You need to create an account for use this</h5>
            </div>
            <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg btnModalSecondary" v-on:click="closeModal">Close</button>
              <button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="redirectCreateAccount()">Create Account</button>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  methods:{
    redirectCreateAccount() {
      $('#createAccountModal').modal('hide')
      this.$router.push({ path: "/signup" });
    },
    closeModal(){
      $('#createAccountModal').modal('hide')
    },
  }
}
</script>