<template>
    <div class="modal fade" id="saveToolModal" tabindex="-1" aria-labelledby="saveToolModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
							<div class="modal-header">
									<h5 class="modal-title" id="saveToolModalLabel">Tool</h5>
									<button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" v-on:click="closeModal"></button>
							</div>
							<div class="div_modal_form modal-body">

									<form class="w-100 py-5 px-4">
										<div class="form-row">
											<div class="form-group col-md-6">
												<label for="nameEdit" class="labelForm">Name<span class="text-danger">*</span></label>
												<input type="text" id="nameEdit" class="form-control inputsForm" placeholder="name"  v-model="name">
											</div>
											<div class="form-group col-md-6">
												<label for="contentAreaEdit" class="labelForm">Content Area<span class="text-danger">*</span></label>
												<input type="text" class="form-control inputsForm" id="contentAreaEdit" placeholder="content area" v-model="contentArea">
											</div>
										</div>
										<div class="form-group">
											<label for="modificationsEdit" class="labelForm">Modifications</label>
											<input type="text" class="form-control inputsForm" id="modificationsEdit" placeholder="modifications" v-model="modifications" v-on:keyup.enter="saveTool()">
										</div>

                    <div class="mb-3">
                      <label for="formFile" class="form-label">Tool Image</label>
                      <input class="form-control" type="file" accept="image/*" id="formFile" @change="processFile">
                    </div>
										<div class="form-group py-4 w-100 d-flex justify-content-center" v-if="uploadingImage">
											<div class="loader-whitebg"></div>
										</div>
                    <div v-if="!uploadingImage && picture" class="position-relative">
                      <div class="delete-tool-picture"><i class="fas fa-window-close delete-tool-picture-icon" @click="deleteToolPicture"></i></div>
                      <img :src="picture" alt="" class="tool-img">
                    </div>

										<p class="text-danger" v-if="errorMessage">
											{{ errorMessage }}
										</p>
										
									</form>

							</div>
							<div class="modal-footer">
								<button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg btnModalSecondary" v-on:click="closeModal">Close</button>
								<button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="saveTool()" v-if="toolId">Edit</button>
								<button type="button" class="btn btn-primary btn-lg btnModalSuccess" v-on:click="saveTool()" v-else>Add Tool</button>
							</div>
					</div>
        </div>
    </div>
</template>
<script>
import API from "../services/api";
import _ from 'lodash'
import confirmationCloseModal from './confirmationCloseModal';
import { mapActions } from 'vuex'
export default {
    data(){
			return{
				name: "",
				contentArea: "",
				modifications: "",
				picture: "",
        pictureName: "",
        uploadingImage: false,
				toolId:"",
				teacherId:"",
				errorMessage: "",
			}
		},
		components:{confirmationCloseModal},
		props:["user"],
		methods:{
			...mapActions('tools', ['getTools', 'CreateNewTool', 'updateTool', 'getDefaultTools', 'updateDefaultTool', 'CreateNewDefaultTool']),
      deleteToolPicture(){
        this.picture = '';
        this.pictureName = ''
				document.getElementById('formFile').value = ""
      },
      processFile(e){
        let file = e.target.files[0];
        this.errorMessage = ""
        if (!file) {
          return console.log("Fail")
        } if (file.size > 1024 * 2000) {
          this.errorMessage = "You exceeded the allowed image size. Maximum size allowed 2mb"
          return
        } else {          
          return this.uploadImage(file);
        }
      },
      uploadImage(file){
        this.uploadingImage = true
        const formData = new FormData();
        formData.append(
          "image", file, file.name
        )
				if(this.user.role === "teacher"){
					return API.tools.UploadPicture(formData)
          .then(result => {
						const file = result.data
            this.uploadingImage = false
            this.picture = file.linkUrl;
            this.pictureName = file.filename;
          })
          .catch(err => {
            this.uploadingImage = false
            console.log("err", err)
          });
				}else if(this.user.role === "admin"){
					return API.defaultTools.UploadDefaultPicture(formData)
          .then(result => {
						const file = result.data
            this.uploadingImage = false
            this.picture = file.linkUrl;
            this.pictureName = file.filename;
          })
          .catch(err => {
            this.uploadingImage = false
            console.log("err", err)
          });
				}
				this.errorMessage = "";
      },
			saveTool() {
				this.errorMessage = "";
        if(this.uploadingImage){
          return
        }
				if (this.name === "") {
					this.errorMessage = "Error entered a correct name.";
					return;
				} else if (this.contentArea === "") {
					this.errorMessage = "Error entered a this Content Area";
					return;
				}
				const data = {
					name: this.name,
					contentArea: this.contentArea,
					modifications: this.modifications,
          picture: this.picture,
				};
				if(this.toolId){
					if(this.user.role === "teacher"){
						this.updateTool({toolId : this.toolId, data})
					}else if(this.user.role === "admin"){
						this.updateDefaultTool({toolId : this.toolId, data})
					}
				}else{
					if(this.user.role === "teacher"){
						this.CreateNewTool(data)
					}else if(this.user.role === "admin"){
						this.CreateNewDefaultTool(data)
					}
				}
				this.errorMessage = "";
				this.name=""
				this.contentArea=""
				this.modifications=""
        this.picture = ""
        this.pictureName = ""
				document.getElementById('formFile').value = ""
				$('#saveToolModal').modal('hide')
    	},
			closeModal(){
				$('#saveToolModal').modal('hide')
			},
		}
}
</script>