<template>
  <div class="modal fade" id="modal-assign-student" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style="text-align: initial;"> Assign student to {{project.name}} project </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="confirmClose">
            <span aria-hidden="true" style="font-size: 25px;">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="display: flex;flex-direction: column;padding: 0px 20px;">

          <h3>Classes</h3>
          <div class="form-group col-md-12 container-check-signup" style="justify-content: initial;">
            <div class="form-check" v-for="(grade, index) in teacher.grades" :key="index">
              <input class="form-check-input" type="checkbox" v-model="classSelected[grade]" @change="classChecked">{{grade}}th
            </div>
          </div>

          <hr>

          <h3>Students</h3>
          <div style="display: flex;flex-wrap: wrap;padding: 10px 20px;">
            <div class="form-check form-group mx-0 px-2" style="width: 51%;margin-left: 20px;">
              <div style="margin-left: 20px;">
                <input class="form-check-input" type="checkbox" v-model="checkAll" @click="checkedAll"><b>Select all</b>
              </div> 
            </div>

            <div class="form-check form-group mx-0 px-2" v-for="(student, index) in students" :key="index" style="width: 50%">
              <span v-if="student.projects && student.projects.indexOf(project._id) > -1" class="hint--top hint--warning" data-hint="Assigned Student">
                <div style="margin-left: 20px;">
                  <input :disabled="student.projects && student.projects.indexOf(project._id) > -1" class="form-check-input" type="checkbox" v-bind:value="student._id" v-model="studentsSelected" @change="updateCheckAll">
                  <p style="overflow: hidden;text-overflow: ellipsis;width: 170px;color: #bfbfbf;">{{student.name}}</p>   
                </div>
              </span>
              <div v-else>
                <div style="margin-left: 20px;">
                  <input class="form-check-input" type="checkbox" v-bind:value="student._id" v-model="studentsSelected" @change="updateCheckAll">
                  <p style="overflow: hidden;text-overflow: ellipsis;width: 170px;">{{student.name}}</p>
                </div>
              </div>
            </div> 

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" @click="assingProject" class="btn btn_admin_general" data-dismiss="modal">Assign Project</button>
        </div>
      </div>
    </div>
  </div> 
</template>

<style scoped>
.hint--top:before {
  margin-bottom: -11px;
  left: calc(25% - 6px);
}
.hint--top:after {
  bottom: 100%;
  left: 26%;
}
</style>

<script>
import API from "../services/api";
export default {
  name: "ModalAssignStudent",
  data() {
    return {
      students: [],
      teacher: {},
      grades: [],
      checkAll: false,
      classSelected: {},
      project: {},
      studentsSelected: []
    }
  },
  methods: { 
    assingProject(){
      let data = {
        studentsId: this.studentsSelected,
        teacher: this.teacher._id,
        project: this.project._id,
        studentPick: {
          answer: 0,
          isCorrect: false
        },
        isSolved: false,
      }
      API.studentProjects.assingProject(data)
      .then(() => {
        this.$toast.success("Project assigned successfully", {
          position: "top",
        });
        var event = new CustomEvent('getStudents');
        window.dispatchEvent(event);
          dataLayer.push({'event': 'assign_project'});
      })
      .catch(err => {
        this.$toast.error("try again later", {
          position: "top",
        });
      })
      .finally(
        this.studentsSelected = [],
        $('#modal-assign-student').modal('hide')
      )
    },
    classChecked(){
      this.students.map((student) => {
        if(this.classSelected[student.grade]) {
          if (this.studentsSelected.indexOf(student._id) == -1) {
            this.studentsSelected.push(student._id)
          } 
        } else {
          if (this.studentsSelected.indexOf(student._id) != -1) {
            this.studentsSelected.splice(this.studentsSelected.indexOf(student._id),1)
          }
        }         
      })
    },
    checkedAll(){
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        const classes = Object.keys(this.classSelected )
        for (let i = 0; i < classes.length; i++) {
          this.classSelected[classes[i]] = true
        }
      } else {
        const classes = Object.keys(this.classSelected )
        for (let i = 0; i < classes.length; i++) {
          this.classSelected[classes[i]] = false 
        }
      }
      this.studentsSelected = [];
      if(this.checkAll){
        this.students.forEach(student => {
          if(student.projects && student.projects.indexOf(this.project._id) > -1) {
            
          } else {
            this.studentsSelected.push(student._id);
          }
        })
      }
    },
    updateCheckAll(){
      if (this.studentsSelected.length == this.students.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    confirmClose(){
      this.studentsSelected = [],
      this.checkAll = false;
      this.classSelected = {};
      $('#modal-assign-student').modal('hide')  
    },
    cleanModal(){
      this.studentsSelected = [],
      this.checkAll = false;
      this.classSelected = {};
    }
  },
  mounted() {
    this.grades = [6,7,8,9]
    this.grades.forEach((grade) => this.classSelected[grade] = false)
    $('#modal-assign-student').on("hidden.bs.modal", this.cleanModal)
  }
}

</script>

<style scoped>

@media (max-width: 768px) {
  .form-group{
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

}

</style>


