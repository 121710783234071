const notifications = axios => {
    return {
      async getNotifications() {
        return axios.get(`/api/notifications`);
        },
      async markAsReadNotifications(data) {
        return axios.post(`/api/notifications`, data);
      },
      async deleteNotification(id) {
        return axios.delete(`/api/notifications/${id}`);
      },
      async deleteAllNotifications() {
        return axios.delete(`/api/notifications/all`);
      }
    }
  }
  export default notifications;