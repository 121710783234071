import API from "../../services/api";
import _ from "lodash";

const errorCb = (commit, error) =>
  commit(
    "setErrorProjectsApi",
    _.get(error, "response.data.errors.message", "Error please try later.")
  );

export default {
  getDefaultProjectsThemes: async ({ commit }) => {
    try {
      const res = await API.defaultProjects.getDefaultProjectsThemes();
      return res.data;
    } catch (e) {
      commit(
        "setErrorProjectsApi",
        _.get(e, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  getProjectsThemes: async ({ commit }) => {
    try {
      const res = await API.projects.getProjectsThemes();
      return res.data;
    } catch (e) {
      commit(
        "setErrorProjectsApi",
        _.get(e, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  getProjects: ({ commit }, filters) => {
    API.projects
      .getProjects(filters)
      .then((res) => commit("setProjects", res.data))
      .catch((e) => {
        commit(
          "setErrorProjectsApi",
          _.get(e, "response.data.errors.message", "Error please try later.")
        );
      });
  },

  getDrafts: async ({ commit }) => {
    try {
      const res = await API.projects.getDrafts();
      commit("setDrafts", res.data);
    } catch (e) {
      commit(
        "setErrorProjectsApi",
        _.get(e, "response.data.errors.message", "Error please try later.")
      );
    }
},

  deleteProject: ({ commit, dispatch }, { id, that }) => {
    API.projects
      .deleteProject(id)
      .then((res) => {
        that.$swal({
          icon: "success",
          title: "Success!",
          text: "The project has been successfully deleted",
        });
        dispatch("getProjects");
      })
      .catch((error) => {
        that.$swal({
          icon: "error",
          title: "Oops...",
          text: "Error deleting project",
        });
      });
  },

  deleteDraft: async ({ dispatch }, { id, that }) => {
    try {
      await API.projects.deleteProject(id);
      that.$swal({
        icon: "success",
        title: "Success!",
        text: "The project has been successfully deleted",
      });
      dispatch("getDrafts");
    } catch (e) {
      that.$swal({
        icon: "error",
        title: "Oops...",
        text: "Error deleting project",
      });
    }
  },

  deleteProjectWOutMessage: async ({ dispatch }, { id }) => {
    try {
      await API.projects.deleteProject(id);
    } catch (e) {
      console.error(e);
    }
  },

  CreateNewProjects: async ({ dispatch }, { id, data, that }) => {
    try {
      await API.projects.updateProject(id, data);
      that.$swal({
        icon: "success",
        title: "Success!",
        text: "The project has been successfully created",
      });
      dispatch("getProjects");
    } catch (e) {
      that.$swal({
        icon: "error",
        title: "Oops...",
        text: "Error creating project",
      });
    }
  },

  createNewDraft: async ({ commit }, { data }) => {
    try {
      return await API.projects.createNewDraft(data);
    } catch (error) {
      commit(
        "setErrorProjectsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  updateDraft: async ({ commit }, { projectId, data }) => {
    try {
      return await API.projects.updateDraft(projectId, data);
    } catch (error) {
      commit(
        "setErrorProjectsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  createUnautheticatedDraft: async ({ commit }, { data }) => {
    try {
      return await API.projects.createUnautheticatedDraft(data);
    } catch (error) {
      errorCb(error);
    }
  },

  updateUnautheticatedDraft: async ({ commit }, { projectId, data }) => {
    try {
      return await API.projects.updateUnautheticatedDraft(projectId, data);
    } catch (error) {
      errorCb(error);
    }
  },

  updateStatusAddTeacherId: async ({ commit }, { projectId, data }) => {
    try {
      return await API.projects.updateStatusAddTeacherId(projectId, data);
    } catch (error) {
      errorCb(error);
    }
  },

  updateProjectDeleteDraft: async ({ dispatch }, { ProjectId, data, that }) => {
    try {
      await API.projects.updateProjectDeleteDraft(ProjectId, data);
      that.$swal({
        icon: "success",
        title: "Success!",
        text: "The project has been successfully updated",
      });
      dispatch("getProjects");
    } catch (e) {
      that.$swal({
        icon: "error",
        title: "Oops...",
        text: "Error updating project",
      });
    }
  },

  /* ---- */
  getDefaultProjects: async ({ commit }, filters) => {
    try {
      const res = await API.defaultProjects.getDefaultProjects(filters);
      commit("setProjects", res.data);
    } catch (error) {
      commit(
        "setErrorProjectsApi",
        _.get(error, "response.data.errors.message", "Error please try later.")
      );
    }
  },

  deleteDefaultProjects: ({ commit, dispatch }, { id, that }) => {
    API.defaultProjects
      .deleteDefaultProjects(id)
      .then((res) => {
        that.$swal({
          icon: "success",
          title: "Success!",
          text: "The default project has been successfully deleted",
        });
        dispatch("getDefaultProjects");
      })
      .catch((error) => {
        that.$swal({
          icon: "error",
          title: "Oops...",
          text: "Error deleting default project",
        });
      });
  },

  CreateNewDefaultProjects: ({ commit, dispatch }, { data, that }) => {
    API.defaultProjects
      .CreateNewDefaultProjects(data)
      .then((res) => {
        that.$swal({
          icon: "success",
          title: "Success!",
          text: "The default project has been successfully created",
        });
        dispatch("getDefaultProjects");
      })
      .catch((error) => {
        that.$swal({
          icon: "error",
          title: "Oops...",
          text: "Error creating default project",
        });
      });
  },

  updateDefaultProjects: ({ commit, dispatch }, { ProjectId, data, that }) => {
    API.defaultProjects
      .updateDefaultProjects(ProjectId, data)
      .then((res) => {
        that.$swal({
          icon: "success",
          title: "Success!",
          text: "The default project has been successfully updated",
        });
        dispatch("getDefaultProjects");
      })
      .catch((error) => {
        that.$swal({
          icon: "error",
          title: "Oops...",
          text: "Error updating default project",
        });
      });
  },
};
