<template>
  <path
    id="Path_75"
    data-name="Path 75"
    d="M213.068,0a9.2,9.2,0,0,1,1.577.709,3,3,0,0,1,1.323,2.549q.026,6.808,0,13.615a3.255,3.255,0,0,1-3.081,3.249c-2.434.068-4.871.017-7.358.017v-.4c0-1.156.011-2.313,0-3.469a7.023,7.023,0,0,0-4.31-6.739c-.054-.025-.1-.056-.185-.1a5.842,5.842,0,0,0,1.286-2.261,5.6,5.6,0,0,0-4.5-7.1,1.254,1.254,0,0,1-.2-.069Z"
    transform="translate(-177.918)"
  />
  <path
    id="Path_76"
    data-name="Path 76"
    d="M18.359,0a13.293,13.293,0,0,0-1.789.587,5.568,5.568,0,0,0-1.825,8.6c.062.075.121.153.138.174a21.456,21.456,0,0,0-2.023,1.386,6.71,6.71,0,0,0-2.4,4.994c-.052,1.316-.014,2.636-.016,3.954,0,.135,0,.27,0,.435-.142.007-.262.018-.381.018q-3.264,0-6.528,0A3.362,3.362,0,0,1,0,16.613Q0,10.085,0,3.557A3.348,3.348,0,0,1,2.812.05a.471.471,0,0,0,.1-.05Z"
    transform="translate(0)"
  />
  <path
    id="Path_77"
    data-name="Path 77"
    d="M139.9,120.985h-12.67c0-.6-.007-1.178,0-1.759a29.305,29.305,0,0,1,.081-3.09,4.735,4.735,0,0,1,4.405-4.064,26.063,26.063,0,0,1,4.059.059,4.644,4.644,0,0,1,4.1,4.319c.109,1.495.021,3,.021,4.536"
    transform="translate(-114.545 -100.853)"
  />
  <path
    id="Path_78"
    data-name="Path 78"
    d="M145.951,322.392h-11.16a1.585,1.585,0,0,1-.031-.24,33.642,33.642,0,0,1,.092-3.46,3.755,3.755,0,0,1,2.244-2.96.6.6,0,0,1,.467.02,5.9,5.9,0,0,0,5.549,0,.549.549,0,0,1,.58.01,4.029,4.029,0,0,1,2.252,3.606c.032.993.006,1.988.006,3.026"
    transform="translate(-121.325 -284.201)"
  />
  <path
    id="Path_79"
    data-name="Path 79"
    d="M11.22,322.384H1.374c-.924,0-1.353-.419-1.343-1.34A17.92,17.92,0,0,1,.11,318.7a3.843,3.843,0,0,1,2.228-2.978.642.642,0,0,1,.5.008,5.919,5.919,0,0,0,5.553-.007.52.52,0,0,1,.547,0,4.03,4.03,0,0,1,2.276,3.592c.037,1,.007,2.012.007,3.072"
    transform="translate(-0.028 -284.175)"
  />
  <path
    id="Path_80"
    data-name="Path 80"
    d="M269.821,322.591a35.264,35.264,0,0,1,.027-3.683,3.717,3.717,0,0,1,2.23-2.97.6.6,0,0,1,.468.006,5.988,5.988,0,0,0,5.592.011.637.637,0,0,1,.5-.007,4,4,0,0,1,2.3,3.569c.031.645.014,1.293,0,1.939a1.1,1.1,0,0,1-1.12,1.148q-4.961.011-9.923,0a.315.315,0,0,1-.08-.015"
    transform="translate(-242.886 -284.4)"
  />
  <path
    id="Path_81"
    data-name="Path 81"
    d="M26.192,229.824a3.73,3.73,0,1,1-3.7-3.753,3.738,3.738,0,0,1,3.7,3.753"
    transform="translate(-16.865 -203.532)"
  />
  <path
    id="Path_82"
    data-name="Path 82"
    d="M157.215,233.531a3.73,3.73,0,1,1,3.713-3.738,3.74,3.74,0,0,1-3.713,3.738"
    transform="translate(-138.168 -203.532)"
  />
  <path
    id="Path_83"
    data-name="Path 83"
    d="M291.951,226.072a3.73,3.73,0,1,1-3.747,3.7,3.736,3.736,0,0,1,3.747-3.7"
    transform="translate(-259.471 -203.533)"
  />
  <path
    id="Path_84"
    data-name="Path 84"
    d="M160.574,22.469A3.357,3.357,0,1,1,157.21,25.8a3.361,3.361,0,0,1,3.364-3.331"
    transform="translate(-141.537 -20.229)"
  />
</template>

<script>
export default {};
</script>
