<template>
  <div class="container mt-5 divContainerAdminGeneral">
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral">Configurations</h2>
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn_admin_general"
          v-on:click="openModalConfig()"
        >
          Add New Config
        </button>
      </div>
    </div>
    <div class="row w-100 divContainerAdminGeneral mt-3 d-lg-block">
      <div
        v-for="(config, index) in allConfigs"
        :key="index"
        :class="
          'badges-list-border d-flex ' +
          (index % 2 ? 'row-collapse-color2' : 'row-collapse-color1')
        "
      >
        <div
          class="col-8 textTableAdminGeneral d-flex justify-content-start align-items-center"
        >
          {{ config.name }}
        </div>
        <div
          class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center"
        >
          <div v-if="config.default">
            <div class="default">Default</div>
          </div>
        </div>
        <div
          class="col-2 d-flex textTableAdminGeneral d-flex justify-content-around align-items-center"
        >
          <i
            class="fas fa-edit p-3 icon_tool iconEdit col-2"
            @click="openModalConfig(config._id)"
          ></i>
          <i
            class="fas fa-trash p-2 icon_tool iconDelete"
            @click="openConfirmationModal(config._id)"
          ></i>
        </div>
      </div>
      <div
        class="badges-list-border col-12 row row-collapse-color2 text-center bg-light"
        v-if="!loading && !allConfigs.length"
      >
        <h1 class="m-5">No configs to display.</h1>
      </div>
    </div>
  </div>
  <confirmationModal
    ref="confirmModal"
    @confirm="deleteConfigAPI"
    :message="'Are you sure you want to delete it?'"
    :id="id"
  />
  <modalConfig :config="config" :id="id" />
</template>
<script>
import API from "../services/api";
import { mapActions, mapState } from "vuex";
import confirmationModal from "../components/confirmationModal.vue";
import modalConfig from "../components/modalConfigs.vue";
export default {
  data() {
    return { loading: false, id: "", config: {} };
  },
  components: {
    confirmationModal,
    modalConfig,
  },
  computed: {
    ...mapState("configs", ["allConfigs"]),
  },
  methods: {
    ...mapActions("configs", ["getAllConfigs", "deleteConfig"]),
    async getAllConfigsAction() {
      this.loading = true;
      await this.getAllConfigs();
      this.loading = false;
    },
    async deleteConfigAPI(id) {
      this.deleteConfig(id);
    },
    openConfirmationModal(id) {
      this.id = id;
      $("#confirmationModal").modal("show");
    },
    openModalConfig(id) {
      this.getAllConfigsAction();
      if (id) {
        this.config = this.allConfigs.find((config) => config._id === id);
        this.id = id;
      } else {
        this.config = {
          name: "",
          users: [],
          features: {
            badges: false,
            multimedia: false,
            reorderSteps: false,
            tools: false,
            objectives: false,
            generatePdf: false,
            signUpStudent: false,
            preRequisites: false,
            plans: false,
            projectImg: false,
            supportingMaterial: false,
            projectTheme: false,
            lockedProjects: false,
            studentUploadImages: false,
          },
          default: false,
        };
        this.id = "";
      }
      $("#modalConfig").modal("show");
    },
  },
  mounted() {
    this.getAllConfigsAction();
  },
};
</script>
<style scoped>
.default {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1.5px solid black;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;
  width: 100px;
  font-size: 1.5rem !important;
  font-family: "Quicksand", sans-serif;
  background: var(--secondary);
  color: white;
}

</style>
