<template>
  <div
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="advancedFilterLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-white">Media</h5>
        </div>
        <div class="modal-body text-description-modal">
          <div
            v-if="
              this.data.media?.type === 'image' ||
              this.data.media?.type === 'gif'
            "
            class="container d-flex justify-content-center align-items-center"
          >
            <img :src="this.data.media?.url" class="w-100 h-100" />
          </div>
          <div
            v-else-if="this.data.media?.type === 'video'"
            class="container d-flex justify-content-center align-items-center"
          >
            <video autoplay controls class="w-100 h-100">
              <source :src="this.data.media?.url" />
            </video>
          </div>
        </div>
        <div class="modal-footer">
          <ActionButton
            color="red"
            class="px-4 py-3"
            text="Close"
            data-dismiss="modal"
            @click="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ActionButton from "../../../components/actionButton.vue";

export default {
  name: "view-media-modal",
  props: ["data", "modalId"],
  computed: {
    ...mapState(["project"]),
  },
  components: { ActionButton },
  methods: {
    ...mapActions("project", ["setProjectStepProp"]),
    removeMedia() {
      const {
        media: { type, url },
        stepIndex,
      } = this.data;
      if (type === "image") {
        const imagesURL = this.project.steps[stepIndex].imagesURL.filter(
          (img) => img != url
        );

        this.setProjectStepProp({
          index: stepIndex,
          prop: "imagesURL",
          value: imagesURL,
        });
      } else {
        const prop = type === "video" ? "videosURL" : "gifsURL";
        this.setProjectStepProp({
          index: stepIndex,
          prop,
          value: [],
        });
      }

      this.close();
    },
    close() {
      $(`#${this.modalId}`).modal("hide");
    },
  },
};
</script>

<style scoped>
.modal-header{
  border-bottom: 0px;
}

.modal-footer{
  border-top: 0px;
}

.modal-title {
  font-size: 20px;
}
.modal-content {
  background-color: var(--capable-blue);
}

.container {
  margin-bottom: 10px;
}
</style>
