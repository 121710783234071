import API from "../../services/api";
import _ from 'lodash'

export default {
  getDefault: async ({ commit }) => {
    try {
      const res = await API.configs.getDefault();
      commit('setConfig', res.data);
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."))
    }
  },
  getConfig: async ({ commit }) => {
    try {
      const res = await API.configs.getConfig();
      commit('setConfig', res.data);
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."))
    }
  },

  getAllConfigs: async ({ commit }) => {
    try {
      const res = await API.configs.getAllConfigs();
      commit('setAllConfigs', res.data);
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."))
    }
  },

  deleteConfig: async ({ commit, dispatch }, id) => {
    try {
      await API.configs.deleteConfig(id);
      await dispatch('getAllConfigs');
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

  updateConfig: async ({ commit, dispatch }, { id, data }) => {
    try {
      await API.configs.updateConfig(id, data);
      await dispatch('getAllConfigs');
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

  postConfig: async ({ commit, dispatch }, { data }) => {
    try {
      await API.configs.postConfig(data);
      await dispatch('getAllConfigs');
    } catch (error) {
      commit('setErrorConfigApi', _.get(error, "response.data.errors.message", "Error please try later."));
    }
  },

}