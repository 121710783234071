export default {
  setConfig(state, config) {
    state.config = config.features;
    state.errorConfigAPI = "";
  },
  setAllConfigs(state, allConfigs) {
    state.allConfigs = allConfigs;
    state.errorAllConfigsAPI = "";
  },
  setErrorConfigApi(state, errorConfigAPI) {
    state.errorConfigAPI = errorConfigAPI;
  },
};
