<template>
  <path
    id="Path_44"
    data-name="Path 44"
    d="M15.395,0H17.1c.126.021.253.039.378.064.884.177,1.789.285,2.648.546a12.319,12.319,0,0,1,5.884,4.055,11.484,11.484,0,0,1,2.816,7.065c.077,1.949.046,3.9.062,5.854,0,.149,0,.3,0,.445a13.371,13.371,0,0,1,1.48.1A2.745,2.745,0,0,1,32.5,21.01c-.031,5.248-.01,10.5-.014,15.746a3.8,3.8,0,0,1-.075.885,2.676,2.676,0,0,1-2.8,2.079q-13.361,0-26.721,0A2.671,2.671,0,0,1,.009,36.871q-.018-7.989,0-15.979a2.663,2.663,0,0,1,2.849-2.832c.241,0,.482,0,.749,0v-.477c0-1.59-.007-3.18,0-4.77A13.366,13.366,0,0,1,4.2,8.767,12.364,12.364,0,0,1,7.24,3.831,12,12,0,0,1,13.15.389c.739-.17,1.5-.261,2.245-.389m8.051,18.039c.008-.1.02-.178.02-.254,0-1.758.022-3.516-.007-5.274a7.212,7.212,0,0,0-14.42-.066C9,14.229,9.031,16.014,9.031,17.8c0,.074.014.148.022.242Z"
    transform="translate(0)"
    fill="#004aad"
  />
</template>

<script>
export default {};
</script>
