const defaultProjects = (axios) => {
  return {
    async getDefaultProjectsThemes() {
      return axios.get("/api/default-projects/themes");
    },
    async CreateNewDefaultProjects(data) {
      return axios.post("/api/default-projects", data);
    },
    async getDefaultProjects(filters = {}) {
      return axios.get(
        `/api/default-projects?filters=${JSON.stringify(filters)}`
      );
    },
    async findDefaultProject({ tools, projectName }) {
      return axios.get(
        `/api/default-projects/find/?tools=${tools || ""}&projectName=${
          projectName || ""
        }`
      );
    },
    async UploadDefaultPlansPdf(data) {
      return axios.post("/api/default-projects/plan", data);
    },
    async getDefaultProject(defaultProjectId) {
      return axios.get(`/api/default-projects/${defaultProjectId}`);
    },
    async deleteDefaultProjects(id) {
      return axios.delete(`/api/default-projects/${id}`);
    },
    async updateDefaultProjects(id, data) {
      return axios.put(`/api/default-projects/${id}`, data);
    },
  };
};
export default defaultProjects;
