<template>
  <div
    class="modal fade"
    id="multipleChoiceStudentSolvedModal"
    tabindex="-1"
    aria-labelledby="multipleChoiceStudentSolvedModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="d-flex flex-column">
          <button
            type="button"
            class="btn-close btn-close-white"
            aria-label="Close"
            @click="closeModal"
          ></button>
          <h5
            class="modal-title text-white"
            id="multipleChoiceStudentSolvedModalLabel"
          >
            Multiple choice
          </h5>
        </div>
        <div class="div_modal_form modal-body d-flex flex-column gap-3 p-5">
          <div class="h2 text-white">
           {{ this.step.multipleChoice?.question}}
          </div>
          <div
            :class="`answer ${
              this.step.multipleChoice?.answer1.isCorrect
                ? this.userPick?.isSolved && 'correct'
                : ''
            } ${
              userPick?.studentPick.answerNumber === 1
                ? this.step.multipleChoice?.answer1.isCorrect
                  ? ''
                  : 'incorrect'
                : ''
            }`"
          >
            {{ this.step.multipleChoice?.answer1.answer }}

            <div class="d-flex">
              <div
                v-show="
                  this.step.multipleChoice?.answer1.isCorrect &&
                  this.userPick?.isSolved
                "
              >
                <BaseIcon width="40.01" height="29.095" iconColor="#FFFFFF">
                  <TickIcon />
                </BaseIcon>
              </div>
              <div
                v-show="
                  !this.step.multipleChoice?.answer1.isCorrect &&
                  this.userPick?.isSolved
                "
              >
                <BaseIcon width="13.616" height="13.51">
                  <CrossIcon />
                </BaseIcon>
              </div>
            </div>
          </div>
          <div
            :class="`answer ${
              this.step.multipleChoice?.answer2.isCorrect &
                this.userPick?.isSolved && 'correct'
            } ${
              userPick?.studentPick.answerNumber === 2
                ? this.step.multipleChoice?.answer2.isCorrect
                  ? ''
                  : 'incorrect'
                : ''
            }`"
          >
            {{ this.step.multipleChoice?.answer2.answer }}

            <div class="d-flex">
              <div
                v-show="
                  this.step.multipleChoice?.answer2.isCorrect &
                  this.userPick?.isSolved
                "
              >
                <BaseIcon width="40.01" height="29.095" iconColor="#FFFFFF">
                  <TickIcon />
                </BaseIcon>
              </div>
              <div
                v-show="
                  !this.step.multipleChoice?.answer2.isCorrect &
                  this.userPick?.isSolved
                "
              >
                <BaseIcon width="13.616" height="13.51">
                  <CrossIcon />
                </BaseIcon>
              </div>
            </div>
          </div>
          <div
            :class="`answer ${
              this.step.multipleChoice?.answer3.isCorrect &
                this.userPick?.isSolved && 'correct'
            } ${
              userPick?.studentPick.answerNumber === 3
                ? this.step.multipleChoice?.answer3.isCorrect
                  ? ''
                  : 'incorrect'
                : ''
            }`"
          >
            {{ this.step.multipleChoice?.answer3.answer }}

            <div class="d-flex">
              <div
                v-show="
                  this.step.multipleChoice?.answer3.isCorrect &
                  this.userPick?.isSolved
                "
              >
                <BaseIcon width="40.01" height="29.095" iconColor="#FFFFFF">
                  <TickIcon />
                </BaseIcon>
              </div>
              <div
                v-show="
                  !this.step.multipleChoice?.answer3.isCorrect &&
                  this.userPick?.isSolved
                "
              >
                <BaseIcon width="13.616" height="13.51">
                  <CrossIcon />
                </BaseIcon>
              </div>
            </div>
          </div>
          <div
            :class="
              this.userPick.isCorrect ? 'h2 correct-message' : 'h2 wrong-answer'
            "
          >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import BaseIcon from "./baseIcon.vue";
import TickIcon from "../assets/icons/tick.vue";
import CrossIcon from "../assets/icons/cross.vue";
export default {
  data() {
    return {
      questionAnswered: false,
      correctAnswer: 0,
    };
  },
  components: { BaseIcon, TickIcon, CrossIcon },
  props: ["step", "user", "project", "sendData", "userPick"],
  methods: {
    closeModal() {
      $("#multipleChoiceStudentSolvedModal").modal("hide");
    },
    /* selectedAnswer(){
      this.userPick.studentPick
    } */
  },
};
</script>

<style scoped>
svg {
  width: 15px;
}

.modal-content {
  background-color: var(--capable-blue);
}

.answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: var(--capable-blue);
  border-radius: 8px;
  height: 35px;
  padding: 0px 10px;
  font-size: 18px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-25px);
  opacity: 0;
}

.correct-message {
  color: green;
}

.wrong-answer {
  color: red;
}

.modal-title {
  justify-self: center !important;
  align-self: center !important;
}

.btn-close {
  margin: 0px;
  padding: 1.5rem;
  color: white !important;
  opacity: 0.9 !important;
  align-self: flex-end;
  justify-self: flex-end;
}

.btn-close:hover {
  opacity: 1 !important;
}

.correct {
  background-color: var(--capable-green);
}

.incorrect {
  background-color: var(--capable-red);
}
</style>
