<template>
  <path
    id="Path_209"
    data-name="Path 209"
    d="M12.091,10.154v.354a.714.714,0,0,0-.023.077,1.482,1.482,0,0,1-.7,1.032,1.507,1.507,0,0,1-1.9-.251Q7.842,9.745,6.221,8.119a1.413,1.413,0,0,1-.1-.139,1.163,1.163,0,0,1-.1.145q-1.647,1.65-3.3,3.3c-.067.067-.134.133-.2.2A1.516,1.516,0,0,1,.483,9.372q1.693-1.7,3.39-3.389a.906.906,0,0,1,.12-.086c-.053-.056-.082-.087-.112-.117L.767,2.666A2.107,2.107,0,0,1,.613,2.5,1.46,1.46,0,0,1,.38.9,1.444,1.444,0,0,1,1.734,0,1.5,1.5,0,0,1,2.882.487Q4.45,2.059,6.02,3.628a1.029,1.029,0,0,1,.1.142,1.528,1.528,0,0,1,.1-.129q.826-.827,1.653-1.653c.482-.481.953-.974,1.449-1.439a1.506,1.506,0,0,1,2.442.616,1.483,1.483,0,0,1-.4,1.622q-1.5,1.5-3,3c-.029.029-.057.061-.1.11a1.525,1.525,0,0,1,.127.1q1.6,1.6,3.206,3.206a1.6,1.6,0,0,1,.436.7c.023.083.042.167.062.25"
    transform="translate(0 0)"
    fill="#EE4B2B"
  />
</template>

<script>
export default {};
</script>
