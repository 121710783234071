export default{
  setBadges(state, badges){
    state.badges = badges
    state.errorBadgesAPI = ""
  },
  setErrorBadgesAPI(state, errorBadgesAPI){
    state.errorBadgesAPI = errorBadgesAPI
  },
  filterBadge(state, {searchQuery, badgesOriginal}){
    if (searchQuery){
      let badgesFiltered = state.badges.filter(badge => {
        objectiveExist = false;
        badge.objectives.forEach(objective => {
          if (objective.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1){
            objectiveExist = true
          }
        })
        return badge.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 || objectiveExist
      })
      state.badges = badgesFiltered
    } else {
      state.badges = badgesOriginal
    }
  }
}