<template>
  <div class="box">
    <div
      class="d-flex flex-wrap gap-5 justify-content-center align-items-center mb-5"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>
