// @ts-check
import { createApp } from "vue";
import App from "./app";
import router from "./router";
import { store } from "./store";
import Toaster from "@meforma/vue-toaster";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App);

app
  .use(store)
  .use(Vue3TouchEvents, {
    disableClick: true,
  })
  .use(router)
  .use(Toaster)
  .use(VueSweetalert2)
  .use(VueSplide)
  .mount("#app");
