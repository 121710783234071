import API from "../../services/api";
import _ from 'lodash'

export default{
  getStudents:( { commit } ) => {
    API.admins.getStudents()
      .then(res => commit('setStudents', res.data) )
      .catch(error => commit('errorStudentsAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteStudent:( { commit, dispatch }, id ) => {
    API.admins.deleteStudent(id)
      .then(res => dispatch('getStudents'))
      .catch(error => commit('errorStudentsAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  updateStudent:( { commit, dispatch }, {studentId, data} ) => {
    API.admins.updateStudent(studentId, data)
      .then(res => dispatch('getStudents') )
      .catch(error => commit('errorStudentsAPI', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

}