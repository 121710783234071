export default {
  name: "",
  description: "",
  toolsRequired: [],
  preRequisite: [],
  projectsRequired: [],
  plans: [],
  plansName: "",
  supportingMaterial: "",
  projectImage: "",
  projectTheme: "",
  ProjectId: "",
  attachedProject: "",
  projectLength: 1,
  status: "",
  addToWarehouse: false,
  steps: [
    {
      name: "",
      description: "",
      imagesURL: [], // Length 3
      gifsURL: [], // Length 1
      videosURL: [], // Length 1
      cameraCountdown: 3,
      needApproval: [],
      showListStudents: false,
      uploadingImage: false,
      uploadingGif: false,
      uploadingVideo: false,
      gifStatus: "noGif",
      videoStatus: "noVideo",
      isStreamingImage: false,
      isStreamingVideo: false,
      hasMultipleChoice: false,
      stepNumber: 1,
      multipleChoice: {
        question: "",
        answer1: {
          answer: "",
          isCorrect: true,
        },
        answer2: {
          answer: "",
          isCorrect: false,
        },
        answer3: {
          answer: "",
          isCorrect: false,
        },
      },
    },
  ],
  recorder: null,
};
