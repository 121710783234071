<template>
  <div class="media-viewer row align-items-center">
    <div
      class="col-2 d-flex h-100 justify-content-center carousel-item-container align-items-center"
    >
      <button
        @click="go('prev')"
        class="btn secondary-btn carousel-button"
        type="button"
        :disabled="current === 0"
      >
        <baseIcon width="10.475" height="16.469">
          <arrowLeft fill="#FFFFFF" />
        </baseIcon>
      </button>
    </div>

    <div
      class="col-2 d-flex h-100 justify-content-center py-4 align-items-center"
    >
      <div
        class="carousel-button"
        v-if="media(this.index)[current]?.type === 'image'"
        :key="media(this.index)[current].url"
      >
        <baseIcon width="187.426" height="72.014"><gallery /> </baseIcon>
      </div>
      <div class="carousel-button" v-else>
        <div
          class="w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <i class="icon-facetime-video"></i>
        </div>
      </div>
    </div>
    <div
      class="col-2 d-flex h-100 justify-content-center py-4 align-items-center"
    >
      <span class="text-light">{{ media(this.index)[current]?.type }}</span>
    </div>

    <div
      @click="displayMedia"
      class="col-2 cursor-pointer d-flex h-100 justify-content-center py-3 align-items-center"
    >
      <div class="carousel-button" style="display: flex; align-items: center">
        <div class="icon-eye">
          <baseIcon width="44.676" height="29.328"><eye /> </baseIcon>
        </div>
      </div>
    </div>
    <div
      @click="deleteMedia"
      class="col-2 d-flex h-100 justify-content-center py-1 align-items-center cursor-pointer"
    >
      <div class="carousel-button" style="display: flex; align-items: center">
        <div class="trash-icon-carousel ml-2">
          <baseIcon class="arrowDown" width="33.843" height="41.652">
            <trash />
          </baseIcon>
        </div>
      </div>
    </div>

    <div
      class="col-2 d-flex h-100 justify-content-center carousel-item-container align-items-center"
    >
      <button
        class="btn secondary-btn carousel-button"
        type="button"
        :disabled="current === this.media(this.index).length - 1"
        @click="go('next')"
      >
        <baseIcon width="10.475" height="16.469">
          <arrowRight fill="#FFFFFF" />
        </baseIcon>
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ActionButton from "../../../components/actionButton.vue";
import baseIcon from "../../../components/baseIcon.vue";
import eye from "../../../assets/icons/eye.vue";
import trash from "../../../assets/icons/trash-secondary.vue";
import arrowLeft from "../../../assets/icons/arrowLeft.vue";
import arrowRight from "../../../assets/icons/arrowRight.vue";
import gallery from "../../../assets/icons/gallery.vue";
export default {
  name: "media-carousel",
  components: {
    baseIcon,
    eye,
    trash,
    arrowLeft,
    arrowRight,
    gallery,
    ActionButton,
  },
  data() {
    return {
      current: 0,
    };
  },
  computed: {
    ...mapState(["project"]),
    media() {
      return (index) => {
        const {
          imagesURL = [],
          gifsURL = [],
          videosURL = [],
        } = this.project.steps[index];
        const images = imagesURL.map((url) => ({ type: "image", url }));
        const gifs = gifsURL.map((url) => ({ type: "gif", url }));
        const videos = videosURL.map((url) => ({ type: "video", url }));
        return images.concat(gifs, videos);
      };
    },
  },
  methods: {
    ...mapActions("project", ["setProjectProp", "setProjectStepProp"]),
    displayMedia() {
      const data = {
        stepIndex: this.index,
        media: this.media(this.index)[this.current],
      };

      this.$parent.$parent.$emit("openDisplayMediaModal", data);
    },
    go(direction) {
      if (direction === "next") {
        this.current = this.current + 1;
      } else {
        this.current = this.current - 1;
      }
    },
    deleteMedia() {
      const { url, type } = this.media(this.index)[this.current];
      let prop,
        value = [];
      if (type === "image") {
        prop = "imagesURL";
        value = this.project.steps[this.index][prop].filter(
          (image) => image != url
        );
      } else if (type === "gif") {
        prop = "gifsURL";
      } else {
        prop = "videosURL";
      }

      this.setProjectStepProp({ index: this.index, prop, value });
      this.current = 0;
    },
  },
  props: ["index"],
};
</script>

<style scoped>
.media-viewer {
  width: 100%;
  height: 5rem;
  background-color: var(--capable-blue);
  border: none;
  border-radius: 8px;
}
.carousel-button {
  height: 100%;
  width: 100%;
  color: var(--capable-white);
}

.carousel-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.carousel-item-container {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.icon-eye svg {
  width: 25px;
  height: 20px;
  display: flex;
  align-content: center;
}
.trash-icon-carousel svg {
  width: 20px;
  height: 25px;
  display: flex;
  align-content: center;
}
</style>
