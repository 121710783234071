<template>
  <div class="d-flex flex-wrap gap-3">
    <div  v-bind:key="index" v-for="(option, index) in options">
      <input
        type="radio"
        :value="option"
        :id="option"
        :checked="option === modelValue"
        @input="$emit('update:modelValue', option)"
      />
      <label :for="option"> {{option}} </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    modelValue: {
      type: String,
    },
    options: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
input[type="radio"]:not(:checked),
input[type="radio"]:checked {
  display: none;
}

input[type="radio"] + label {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  border: 1px solid var(--capable-white);
  color: var(--capable-white);
  background-color: var(--capable-blue);
  margin-bottom: 10px;
  border-radius: 11px;
  font-size: 14px;
}

input[type="radio"]:checked + label {
  border: 1px solid var(--capable-red);
  color: var(--capable-white);
  background-color: var(--capable-red);
}
</style>
