import jwt_decode from 'jwt-decode'
import { store } from '../store'

export const isLogin = (to, from, next) => {
    let token = sessionStorage.getItem('token')
        if(token){
          store.dispatch('user/saveUser')
          next()
        }else{
          next('/login')
        }
}

export const isTeacher = (to, from, next) => {
    let token = sessionStorage.getItem('token')
    store.dispatch('user/saveUser')
        if(token){
          let userDecode = jwt_decode(token)
          if(userDecode.role === 'teacher'){
            next()
          }else{
            next('/login')
          }
        }else{
          next('/login')
        }
}

export const isStudent = (to, from, next) => {
  let token = sessionStorage.getItem('token')
  store.dispatch('user/saveUser')
      if(token){
        let userDecode = jwt_decode(token)
        if(userDecode.role === 'student'){
          next()
        }else{
          next('/login')
        }
      }else{
        next('/login')
      }
}

export const isAdmin = (to, from, next) => {
    let token = sessionStorage.getItem('token')
    store.dispatch('user/saveUser')
        if(token){
          let userDecode = jwt_decode(token)
          if(userDecode.role === 'admin'){
            next()
          }else{
            next('/'+userDecode.role)
          }
        }else{
          next('/'+userDecode.role)
        }
}

export const isTeacherOrAdmin = (to, from, next) => {
  let token = sessionStorage.getItem('token')
  store.dispatch('user/saveUser')
      if(token){
        let userDecode = jwt_decode(token)
        if(userDecode.role === 'admin' || userDecode.role === 'teacher'){
          next()
        }else{
          next('/'+userDecode.role)
        }
      }else{
        next('/'+userDecode.role)
      }
}

