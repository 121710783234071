<template>
  <div class="container d-flex justify-content-center align-items-center">
    <div class="div_signup container">
      <div class="div_signup2 mt-5 shadow p-3 mb-5 bg-white">
        <div class="h-25 d-flex justify-content-center">
          <h2 class="mt-5 f-bold text-blue">Profile</h2>
        </div>
        <form
          class="h-75 ml-3 mb-5 w-100 d-flex flex-column align-items-center justify-content-start"
        >
          <div class="form-row">
            <div
              class="form-group col-md-12 mt-5"
              v-if="user.role === 'teacher'"
            >
              <div class="d-flex align-items-end">
                <BaseInput
                  type="email"
                  id="email"
                  class="form-control inputsForm"
                  v-model="user.email"
                  placeholder="Email"
                  :class="['px-4', 'py-4', 'w-100']"
                  label="Email"
                  :disabled="editEmail"
                  :border="true"
                />
                <i
                  class="fas fa-edit  icon_tool iconEditProfile"
                  v-if="editEmail"
                  v-on:click="functionEditEmail"
                ></i>
                <i
                  class="far fa-check-circle  icon_tool iconConfirm"
                  v-else
                  v-on:click="functionEditEmail"
                ></i>
                <i
                  class="far fa-times-circle  icon_tool iconCancel"
                  v-if="!editEmail"
                  v-on:click="functionCancelEditMail"
                ></i>
              </div>
            </div>
            <div class="form-group col-md-12 mt-5">
              <div class="d-flex align-items-end">
                <BaseInput
                  type="text"
                  class="form-control inputsForm"
                  id="name"
                  v-model="user.name"
                  :disabled="editName"
                  :class="['px-4', 'py-4', 'w-100']"
                  label="Name"
                  :border="true"
                />
                <i
                  class="fas fa-edit  icon_tool iconEditProfile"
                  v-if="editName"
                  v-on:click="functionEditName"
                ></i>
                <i
                  class="far fa-check-circle  icon_tool iconConfirm"
                  v-else
                  v-on:click="functionEditName"
                ></i>
                <i
                  class="far fa-times-circle  icon_tool iconCancel"
                  v-if="!editName"
                  v-on:click="functionCancelEditName"
                ></i>
              </div>
            </div>

            <div class="form-group col-md-12 mt-5">
              <div class="d-flex align-items-end">
                <BaseInput
                  :class="['px-4', 'py-4', 'w-100']"
                  label="Password"
                  type="password"
                  class="form-control inputsForm"
                  id="password"
                  v-model="password"
                  placeholder="Password"
                  :disabled="editPassword"
                  :border="true"
                />
                <i
                  class="fas fa-edit  icon_tool iconEditProfile"
                  v-if="editPassword"
                  v-on:click="functionEditPassword"
                ></i>
                <i
                  class="far fa-check-circle  icon_tool iconConfirm"
                  v-else
                  v-on:click="functionEditPassword"
                ></i>
                <i
                  class="far fa-times-circle  icon_tool iconCancel"
                  v-if="!editPassword"
                  v-on:click="functionCancelEditPassword"
                ></i>
              </div>
            </div>
          </div>

          <p class="text-danger text-center w-100" v-if="errorMessage">
            {{ errorMessage }}
          </p>

          <p class="text-danger text-center w-100" v-if="errorUserAPI">
            {{ errorUserAPI }}
          </p>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditIcon from '../assets/icons/edit.vue';
import BaseIcon from '../components/baseIcon.vue';
import BaseInput from "../components/input.vue";
export default {
  data() {
    return {
      editName: true,
      editEmail: true,
      editPassword: true,
      password: "",
      errorMessage: "",
      userEmail: "",
      userName: "",
    };
  },
  computed: {
    ...mapState("user", ["user", "errorUserAPI"]),
  },
  components: {
    BaseInput,
    BaseIcon,
    EditIcon
  },
  methods: {
    ...mapActions("user", ["updateUser", "saveUser"]),
    functionEditEmail() {
      if (this.user.email.length < 6 || this.user.email.length > 35) {
        this.errorMessage = "Please insert a valid email";
        return;
      }
      if (this.editEmail) {
        this.editEmail = !this.editEmail;
        return;
      }
      const data = {
        email: this.user.email,
        username: this.user.email.toLowerCase(),
      };
      this.updateUser(data);
      this.errorMessage = "";
      this.editEmail = !this.editEmail;
    },
    functionEditName() {
      if (this.user.name.length <= 3 || this.user.name.length > 25) {
        this.errorMessage = "Please insert a valid name";
        return;
      }
      if (this.editName) {
        this.editName = !this.editName;
        return;
      }
      const data = {
        name: this.user.name,
      };
      this.updateUser(data);
      this.errorMessage = "";
      this.editName = !this.editName;
    },
    functionEditPassword() {
      if (this.editPassword) {
        this.editPassword = !this.editPassword;
        return;
      }
      if (this.password.length < 6 || this.password.length >= 20) {
        this.errorMessage = "Please insert a valid password";
        return;
      }
      const data = {
        password: this.password,
      };
      this.updateUser(data);
      this.errorMessage = "";
      this.editPassword = !this.editPassword;
    },
    functionCancelEditMail() {
      this.editEmail = true;
      this.user.email = this.userEmail;
    },
    functionCancelEditName() {
      this.editName = true;
      this.user.name = this.userName;
    },
    functionCancelEditPassword() {
      this.editPassword = true;
      this.password = "";
    },
  },
  created() {
    this.userEmail = this.user.email;
    this.userName = this.user.name;
  },
};
</script>

<style scoped>
h2{
  font-size: 40px;
}

i{
  font-size: 25px;
}

.iconConfirm::before{
  padding: 1.5rem 0.5rem 1.5rem 0.5rem;
  color: var(--capable-green);
}

.iconCancel::before{
  padding: 1.5rem 0.5rem 1.5rem 0.5rem;
  color: var(--capable-red);
}

.iconEditProfile::before{
  padding: 1.5rem 2rem;
  color: var(--capable-blue);
}
.div_signup2{
  border-radius: 11px;
}
</style>