<template>
  <path
    id="Path_32"
    data-name="Path 32"
    d="M107.012,340.942a17.3,17.3,0,0,1-2.424-.838,6.443,6.443,0,0,1-3.33-4.288,1.467,1.467,0,0,1-.021-.2H114.3a5.91,5.91,0,0,1-.9,2.219,6.6,6.6,0,0,1-4.693,3.031.955.955,0,0,0-.189.072Z"
    transform="translate(-89.922 -298.11)"
    fill="#004aad"
  />
  <path
    id="Path_33"
    data-name="Path 33"
    d="M17.806,35.7q-7.236,0-14.472,0a3.111,3.111,0,0,1-3.252-2.45,3.063,3.063,0,0,1,1.1-3.141,11.965,11.965,0,0,0,2.528-3.04,12.078,12.078,0,0,0,1.642-6.184c0-1.729-.02-3.458.013-5.186A12.463,12.463,0,0,1,15.659,3.784c.352-.059.425-.193.412-.51-.021-.515-.017-1.032,0-1.547a1.771,1.771,0,0,1,3.541-.011c.02.557.016,1.115,0,1.673-.005.239.068.336.317.38A12.431,12.431,0,0,1,30.1,13.7a15.3,15.3,0,0,1,.222,2.617c.046,1.881-.038,3.768.079,5.643a11.425,11.425,0,0,0,3.94,8,3.444,3.444,0,0,1,1.324,2.287A3.105,3.105,0,0,1,32.487,35.7c-1.6.019-3.207,0-4.81,0H17.806"
    transform="translate(0 0)"
    fill="#004aad"
  />
</template>

<script>
export default {};
</script>
