<template>
  <path
    id="Path_203"
    data-name="Path 203"
    d="M0,27.985V9.019a.351.351,0,0,0,.058-.095A4.278,4.278,0,0,1,4.55,5.27q2.3,0,4.607,0H9.61c0-.458,0-.872,0-1.288A3.8,3.8,0,0,1,13.577,0q5.921-.01,11.841,0a4.17,4.17,0,0,1,1.092.117,3.78,3.78,0,0,1,2.876,3.78c0,.44,0,.88,0,1.363h.455c1.573,0,3.149-.009,4.721,0A4.294,4.294,0,0,1,39,9.721c0,1.155,0,2.309,0,3.465q0,7.043,0,14.087a4.348,4.348,0,0,1-4.57,4.478Q19.5,31.737,4.579,31.746c-.165,0-.328,0-.494-.014A4.158,4.158,0,0,1,.459,29.341,9.7,9.7,0,0,1,0,27.985m19.511.046a9.522,9.522,0,1,0-9.532-9.476,9.554,9.554,0,0,0,9.532,9.476"
    transform="translate(0 0)"
  />
  <path
    id="Path_204"
    data-name="Path 204"
    d="M22.359,15.347a6.287,6.287,0,1,1-6.256-6.3,6.3,6.3,0,0,1,6.256,6.3"
    transform="translate(3.428 3.17)"
  />
</template>

<script>
export default {};
</script>
