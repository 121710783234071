<template>
  <nav class="navbar navbar-expand-lg navbar-light header fw-bold" id="nav">
    <div class="d-flex gap-3">
      <button class="navbar-toggler d-lg-none" type="button">
        <baseIcon v-if="!toggleCrossMenu" width="30.81" height="30.81" :iconColor="'#004aad'" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <MenuIcon />
        </baseIcon>
        <baseIcon v-else width="18.717" height="18.73" :iconColor="'#004aad'" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <CrossIcon />
        </baseIcon>
      </button>

      <a class="capable-logo mr-3" :href="user.role == 'teacher'
          ? '/dashboard-teacher'
          : user.role
            ? '/' + user.role
            : '/'
        ">
        <baseIcon width="187.426" height="72.014">
          <Logo />
        </baseIcon>
      </a>

      <div v-if="!isMobileDevice && user.role == 'teacher'" class="box-pin d-none d-md-flex" @click="copyStudentPin">
        <div :class="{ 'hint--right student-pin box-pin-text': true, 'hint--success': copied }"
          :data-hint="copied ? 'Copied!' : 'Copy Pin'">
          Student Pin: {{ user.teacherPin }}

          <baseIcon class="copyIcon" width="35.254" height="38.461">
            <copyIcon />
          </baseIcon>
        </div>
      </div>
    </div>
    <div class="div-navbar-collapse d-flex">
      <button class="navbar-toggler d-none d-xs-flex" type="button">
        <baseIcon width="30.81" height="30.81" :iconColor="'#004aad'" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <MenuIcon />
        </baseIcon>
      </button>

      <div class="collapse navbar-collapse text-end" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto d-flex align-items-center w-100"
          :data-target="isMobileDevice ? '#navbarSupportedContent' : ''" :data-toggle="isMobileDevice ? 'collapse' : ''">
          <li :class="`nav-item active  justify-content-start align-items-center w-100  ${route.name !== 'Log In' && route.name !== 'Create Account'
              ? 'd-flex'
              : 'd-none d-lg-flex'
            }`" v-for="(route, index) in routes" :key="index">
            <div class="nav-link d-flex justify-content-center" v-if="!route.name == ''">
              <router-link :class="`mr-3 fontFamily route ${route?.meta.isButton && 'none-decoration'
                }`" @click="refreshRole(route.name)" :to="route.path">
                <ActionButton v-if="route?.meta.isButton && !this.isMobileDevice" :fontsize="'text-16-px'" class="px-4 py-3 none-decoration text-nowrap"
                  :text="route.name" :color="route.name == 'Log In' ? 'red' : 'blue'" />
                <p v-else class="text-nowrap">
                  {{
                    route.name === "DashboardAdmin" ? "Dashboard" : route.name
                  }}
                </p>
              </router-link>

              <span class="sr-only">(current)</span>
            </div>
          </li>

          <li class="nav-item active" v-if="user.sideAccessFrom === 'admin'">
            <hr class="d-lg-none m-0" />
            <button class="nav-link buttonLogout fontFamily d-flex" v-on:click="backToAdmin">
              <span class="d-none d-lg-flex fontFamily font-weight-bold">|</span>
              Back to Admin
            </button>
          </li>
        </ul>
      </div>
      <div id="toggle-cross" class="d-flex justify-content-end">
        <li class="d-flex flex-column justify-content-center align-items-center"
          v-if="user.role && features && features.length > 0" id="test">
          <a v-if="!toggleCross" class="nav-link dropdown-toggle pl-0 route fontFamily mr-2" href="#" role="button"
            aria-haspopup="true" aria-expanded="false" data-toggle="collapse" data-target="#dropdown-projects"
            aria-controls="dropdown-projects">
            <div class="align-items-center route d-none d-md-flex">
              Projects
              <baseIcon class="arrowDown" width="16.5" height="10.5" :iconColor="'#004aad'">
                <arrowDown />
              </baseIcon>
            </div>
            <div class="d-flex d-md-none">
              <baseIcon width="24.156" height="25.311" :iconColor="'#004aad'">
                <SettingLampIcon />
              </baseIcon>
            </div>
          </a>
          <div class="collapse div-dropdown-projects" :data-target="isMobileDevice ? '#dropdown-projects' : ''"
            :data-toggle="isMobileDevice ? 'collapse' : ''" id="dropdown-projects">
            <router-link v-for="(route, index) in features" class="dropdown-item dropdownItem fontFamily text-start"
              :to="route.path" v-bind:key="index">{{ route.name }}</router-link>
          </div>
        </li>
        <li class="d-flex flex-column align-items-center">
          <a v-if="!toggleCross" :class="`nav-link dropdown-toggle pl-0 route fontFamily ${!isMobileDevice && !user.role ? 'd-lg-none' : ''
            }`" href="#" role="button" aria-haspopup="true" aria-expanded="false" data-toggle="collapse"
            data-target="#dropdown-profile" aria-controls="dropdown-profile" data-dismiss="#navbarSupportedContent">
            <div class="d-flex align-items-center box-teacher-name">
              <baseIcon class="personIcon" width="41.065" height="41.084" :iconColor="'#004aad'">
                <personIcon />
              </baseIcon>
              <baseIcon class="arrowDown d-none d-md-block" width="16.5" height="10.5" :iconColor="'#004aad'">
                <arrowDown />
              </baseIcon>
            </div>
          </a>

          <div class="collapse div-dropdown-profile" :data-target="isMobileDevice ? '#dropdown-profile' : ''"
            :data-toggle="isMobileDevice ? 'collapse' : ''" id="dropdown-profile">
            <div class="d-flex justify-content-start dropdown-item d-md-none" v-if="user.role == 'teacher'"
              @click="copyStudentPin">
              <div :class="{
                'hint--bottom d-flex align-items-center f-medium text-white': true,
                'hint--success': copied,
              }" :data-hint="copied ? 'Copied!' : 'Copy Pin'">
                <baseIcon class="copyIcon" width="35.254" height="38.461" :iconColor="'#FFFFFF'">
                  <copyIcon />
                </baseIcon>
                Pin: {{ user.teacherPin }}
              </div>
            </div>

            <router-link v-if="user.role" class="dropdown-item dropdownItem fontFamily text-start"
              to="/profile">Profile</router-link>
            <button v-if="user.role" class="dropdown-item dropdownItem fontFamily text-start" v-on:click="logout">
              Log out
            </button>
            <router-link v-if="!user.role" class="dropdown-item dropdownItem fontFamily text-start" to="/login">Log
              in</router-link>
            <router-link v-if="!user.role" class="dropdown-item dropdownItem fontFamily text-start" to="/signup">Create
              account</router-link>
          </div>
        </li>

        <notificationsList v-if="user.role == 'teacher' || user.role == 'student'" :toggleCross="toggleCross" />

        <baseIcon v-if="toggleCross" class="mr-2" width="18.717" height="18.73" :iconColor="'#004aad'"
          data-bs-toggle="collapse" aria-expanded="false">
          <CrossIcon />
        </baseIcon>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
import baseIcon from "./baseIcon.vue";
import copyIcon from "../assets/icons/copy.vue";
import arrowDown from "../assets/icons/arrowDown.vue";
import personIcon from "../assets/icons/person.vue";
import notificationsList from "./notificationsList.vue";
import Logo from "../assets/logos/logo.vue";
import ActionButton from "./actionButton.vue";
import MenuIcon from "../assets/icons/menu.vue";
import SettingLampIcon from "../assets/icons/settingLamp.vue";
import CrossIcon from "../assets/icons/crossThin.vue";

// @TODO: change size of input and lens icon

export default {
  props: ["user", "routes", "features", "isMobileDevice"],
  data() {
    return {
      copied: false,
      toggleCrossMenu: false,
      toggleCross: false,
    };
  },
  methods: {
    ...mapActions("user", ["removeUser"]),
    async wait(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
    async copyStudentPin() {
      try {
        await navigator.clipboard.writeText(this.user.teacherPin);
        this.copied = true;
        await this.wait(3000);
        this.copied = false;
      } catch (e) {
        console.log(e);
      }
    },
    logout() {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      this.removeUser();
      this.$router.push("/");
      this.userType = "";
    },
    refreshRole(name) {
      if (name == "Sign Up") {
        this.setRole("-");
      }
    },
  },
  mounted() {
    /* JQuery doesnt recognize 'this' */
    const self = this;

    $(document).click(function (event) {
      if (!$(event.target).is(".navbar-collapse *")) {
        $(".collapse").collapse("hide");
      }
    });

    $("#navbarSupportedContent").on("show.bs.collapse", function () {
      if (self.isMobileDevice) {
        self.toggleCrossMenu = true;
      }
    });
    $("#navbarSupportedContent").on("hide.bs.collapse", function () {
      self.toggleCrossMenu = false;
    });

    $("#toggle-cross").on("show.bs.collapse", function () {
      if (self.isMobileDevice) {
        self.toggleCross = true;
      }
    });
    $("#toggle-cross").on("hide.bs.collapse", function () {
      self.toggleCross = false;
    });
  },
  components: {
    notificationsList,
    baseIcon,
    arrowDown,
    personIcon,
    copyIcon,
    Logo,
    ActionButton,
    MenuIcon,
    SettingLampIcon,
    CrossIcon,
  },
};
</script>

<style scoped>
.navbar-toggler {
  order: -1;
}

a {
  display: flex;
  align-items: center;
}

a p {
  margin: 0px;
  font-size: 16px;
}

a button {
  text-decoration: none;
}

svg {
  color: var(--capable-blue);
}

a div {
  font-size: 20px;
}

.navbar {
  height: 70px;
  padding: 0px !important;
}

.navbar-toggler {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  border: 0px;
  outline: 0px;
  box-shadow: 0 0 0 0;
}

.arrowDown {
  margin: 0 auto;
  transform: scale(0.7);
}

.personIcon {
  margin: 0 auto;
  transform: scale(0.5);
}

.copyIcon {
  margin: 0 auto;
  transform: scale(0.5);
  color: white;
}

.route {
  font-family: "Poppins", sans-serif;
  color: var(--capable-blue);
  font-weight: 500;
  font-size: 16px;
}

.header {
  background-color: var(--capable-white) !important;
  font-size: 18px;
  justify-content: space-around !important;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.student-pin {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 11px;
  padding: 2px 14px;
  color: var(--capable-white );
  background-color: var(--capable-red);
  font-family: "Poppins", sans-serif;
}

.box-pin {
  display: flex;
  align-items: center;
}

.box-pin:hover {
  cursor: pointer;
}

.box-pin-text {
  font-size: 14px;
}


.box-pin-text svg {
  width: 32px !important;
}


.student-pin svg {
  width: 50px;
  height: 50px;
}

.mobile {
  justify-content: end;
}

.dropdown-menu {
  border-radius: 0px 0px 11px 11px;
  padding: 0px;
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: transparent;
  border: 0px solid;
}

.dropdown-item:last-child,
.nav-item:last-child {
  border-radius: 0px 0px 11px 11px;
}

.dropdown-item {
  background-color: var(--capable-blue);
  color: white !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  min-height: 40px;
}

.dropdown-item:hover {
  background-color: var(--capable-red);
}

.div-dropdown-projects,
.div-dropdown-profile {
  position: absolute !important;
  text-align: start;
  top: 70px;
}


.capable-logo svg {
  width: 143.144px;
  height: 55px;
}


@media (max-width: 991px) {
  a p {
    font-size: 16px;
  }

  .navbar {
    height: 70px;
  }

  .navbar-collapse {
    background: var(--capable-blue);
    border-radius: 0px 0px 11px 11px;
    position: absolute;
    top: 71px;
    left: 0px;
  }

  .div-dropdown-projects,
  .div-dropdown-profile {
    position: absolute !important;
    text-align: start;
    border-radius: 0px 0px 11px 11px;
    top: 71px;
    right: 0px;
    min-width: 100px;
  }

  .navbar-collapse p,
  .navbar-collapse li div {
    color: white;
  }

  .nav-item {
    width: 100%;
    cursor: pointer;
    min-width: 150px;
    padding: 0 8px 0;
  }

  .nav-item a {
    width: 100%;
    text-decoration: none;
  }

  .nav-link {
    min-height: 40px;
    width: 100%;
  }

  .nav-link a {
    text-align: start;
  }

  .nav-item:hover {
    background-color: var(--capable-red);
  }

  svg {
    color: var(--capable-white);
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-menu {
    position: relative !important;
    top: 0px;
  }

  .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0px;
    cursor: pointer;
    width: 100%;
  }

  .dropdown-item:hover {
    background-color: var(--capable-red);
  }

  .capable-logo svg {
    width: 110px;
  }

  .dropdown-item {
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
  }

  .dropdown-item svg {
    height: 30px;
    width: 25px;
  }

  #toggle-cross {
    width: 165px;
  }
}
</style>
