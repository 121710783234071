<template>
  <div class="footer d-flex justify-content-around align-items-center">
    <div class="footer-logo">
      <a v-if="user.role == 'teacher'" href="/dashboard-teacher"
        ><baseIcon class="logo" width="187.426" height="72.014">
          <whiteLogo /> </baseIcon
      ></a>
      <a v-else :href="user.role ? '/' + user.role : '/'"
        ><baseIcon class="logo" width="187.426" height="72.014">
          <whiteLogo /> </baseIcon
      ></a>
    </div>
    <h3 class="f-bold text-nowrap">TECH TRADES. MAKE MAKERS</h3>
    <div
      class="footer-info d-flex flex-column justify-content-center align-items-center"
    >
      <h2>CapableMaker 2023. All right Reserved.</h2>
      <a target="_blank" rel="nofollow" href="https://www.softwareontheroad.com/?utm_source=capablemaker&utm_medium=banner">
        <h2>Developed by Software On The Road <i class="icon-smile"></i></h2>
      </a>
    </div>
  </div>
</template>

<script>
import whiteLogo from "../assets/logos/whiteLogo.vue";
import baseIcon from "./baseIcon.vue";
export default {
  name: "Footer",
  props: ["user"],
  components: {
    whiteLogo,
    baseIcon,
  },
};
</script>

<style scoped>
h2 {
  font-size: 12px;
  color: var(--capable-white);
}

h3 {
  font-size: 18px;
  color: var(--capable-white);
  font-weight: 600;
}

a:hover {
  color: white;
}

i {
  font-size: 16px;
}

.footer {
  margin-top: auto;
  height: 150px;
  background-color: var(--capable-blue);
}

@media (max-width: 768px) {
  h2 {
    font-size: 13px;
  }

  h3 {
    margin-top: 13px;
    font-size: 13px;
    text-align: center;
  }

  .footer {
    flex-direction: column;
    margin-top: auto;
    height: auto;
    background-color: var(--capable-blue);
  }

  .footer-logo svg {
    margin-top: 35px;
    height: 75px;
    width: 195px;
  }

  .footer-info {
    border-top: 0.5px solid var(--capable-white);
    margin-top: 30px;
    padding: 15px;
  }
}
</style>
