<template>
    <div class="modal fade" id="modalDescription" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
            <h5 class="modal-title" id="objectiveModalLabel">{{ title }}</h5>
          </div>
                          <div class="div_modal_form modal-body">
                                <span class="message" v-html="message"></span>
                              </div>
                              <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary btn-lg btnModalSecondary" data-bs-dismiss="modal">Close</button>
                              </div>
                      </div>
          </div>
      </div>
  </template>

  <script>
import { title } from 'process';

  export default {
      name: "modalDescription",
    props: ['message', 'title']
  }
  </script>

  <style scoped>
    .message {
        font-size: 2rem;
    }

</style>